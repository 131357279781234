<header class="header-holder">
  <nav>
    <div class="wrapper">
      <div class="logo">
        <a *ngIf="!exchangeCompany" [attr.href]="(quotesWebUrl$ | async) + '' + (getAffiliateUtmSuffix() | async)">
          <img [src]="(logoUrl$ | async)" alt="Dental Insurance" />
        </a>
        <a *ngIf="exchangeCompany"
          [attr.href]="(quotesWebUrl$ | async)+'/quoting/exchange'+ (exchangeCompany.exchangeCampaign.name.toLowerCase()==='amwins' ? '/amwins' : '')+(getAffiliateUtmSuffix() | async)">
          <img
            [src]="(quotesWebUrl$ | async) + '/assets/img/exchange/' + (this.exchangeCompany.logo ? this.exchangeCompany.logo : this.exchangeCompany.exchangeCampaign.logo )"
            alt="Dental Insurance" />
        </a>
      </div>
      <ng-container [style.display]="exchangeCompany ? 'none': 'block' ">
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
      </ng-container>


      <ul class="nav-links">
        <div class="gray-button">
          <label for="close-btn" class="btn close-btn back-btn">
            <span><i class="fas fa-chevron-left"></i>Back</span>
          </label>
        </div>
        <li class="variant-mega" id="find-plan" [style.display]="exchangeCompany ? 'none': 'block' ">
          <a class="desktop-item">Find My Plan</a>
          <label for="showMega" class="mobile-item" id="plans-mobile">Find My Plan
            <i class="fas fa-chevron-right" id="plans-closed"></i>
            <i class="fas fa-chevron-down" id="plans-opened"></i>
          </label>
          <div class="mega-box" id="find-my-plan">
            <div class="content desktop">
              <div class="row">
                <h2>Find My Plan</h2>
              </div>

              <div class="row">
                <div class="one_half">
                  <header>Get a Quote</header>
                  <ul class="mega-links">
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/static-census' + (getAffiliateUtmSuffix() | async)"
                        title="Link to start a quote for a dental plan.">Dental
                        Plans</a>
                    </li>
                    <li *ngIf="(showVision$ | async)">
                      <a [attr.href]="(quotesWebUrl$ | async) + '/static-census' + (getAffiliateUtmSuffix() | async)"
                        title="Link to start a quote for a dental plan.">Vision
                        Plans</a>
                    </li>
                    <li *ngIf="(showBundled$ | async)">
                      <a [attr.href]="(quotesWebUrl$ | async) + '/static-census' + (getAffiliateUtmSuffix() | async)"
                        title="Link to start a quote for a dental plan.">Dental,
                        Vision &amp; Hearing (Bundled) Plans</a>
                    </li>
                  </ul>
                </div>

                <div class="one_half last">
                  <header>Insurance Companies</header>
                  <ul class="mega-links-img">
                    <li
                      *ngIf="((isCarrierAvailable('ameritas') | async ) || (isCarrierAvailable('ameritasny') | async))">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/ameritas' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for Ameritas."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/ameritas-small.png'"
                          alt="Company logo for Ameritas" /></a>
                    </li>
                    <li *ngIf="isCarrierAvailable('anthembcbs') | async">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/anthem' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for Anthem Bluecross Blueshield."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/anthembcbs-small.png'"
                          alt="Company logo for Anthem Bluecross Blueshield" /></a>
                    </li>
                    <li *ngIf="(isCarrierAvailable('cigna') | async)">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/cigna' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for Cigna."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/cigna-small.png'"
                          alt="Company logo for Cigna" /></a>
                    </li>
                    <li *ngIf="isCarrierAvailable('deltadental') | async">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/delta-dental' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for Delta Dental."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/deltadental-small.png'"
                          alt="Company logo for Delta Dental" /></a>
                    </li>
                    <li *ngIf="isCarrierAvailable('guardian') | async">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/guardian' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for Guardian."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/guardian-small.png'"
                          alt="Company logo for Guardian" /></a>
                    </li>
                    <li *ngIf="isCarrierAvailable('humana') | async">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/humana' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for Humana."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/humana-small.png'"
                          alt="Company logo for Humana" /></a>
                    </li>
                    <li *ngIf="isCarrierAvailable('metlife') | async">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/metlife' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for MetLife."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/metlife-small.png'"
                          alt="Company logo for MetLife" /></a>
                    </li>
                    <li
                      *ngIf="(isCarrierAvailable('renaissance') | async) || (isCarrierAvailable('renaissancemw') | async)">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/renaissance' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for Renaissance."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/renaissance-small.png'"
                          alt="Company logo for Renaissance" /></a>
                    </li>
                    <li *ngIf="isCarrierAvailable('vsp') | async">
                      <a [attr.href]="(quotesWebUrl$ | async)+'/vsp' + (getAffiliateUtmSuffix() | async)"
                        title="Link to the company page for VSP."><img
                          [src]="(quotesWebUrl$ | async) + '/assets/img/carrier-logos/vsp-small.png'"
                          alt="Company logo for VSP" /></a>
                    </li>
                  </ul>
                  <a class="extra-link" *ngIf="(showPages$ | async)"
                    [attr.href]="(quotesWebUrl$ | async)+'/coverage/dental-insurance-companies' + (getAffiliateUtmSuffix() | async)"
                    title="Link to page displaying all available Carriers and Plans on the site.">All
                    Companies</a>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li [style.display]="(showPages$ | async) && !exchangeCompany? 'block': 'none' " class="variant-mega-2">
          <a class="desktop-item">Learn</a>
          <label for="showDrop" class="mobile-item" id="learn-mobile">Learn
            <i class="fas fa-chevron-right" id="learn-closed"></i>
            <i class="fas fa-chevron-down" id="learn-opened"></i>
          </label>

          <div class="mega-box" id="learn">
            <div class="content desktop">
              <div class="row">
                <h2>Learn</h2>
              </div>

              <div class="row">
                <div>
                  <header>Top Articles</header>

                  <ul class="mega-links">
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/resources/dental-plans/ppo-dental-insurance/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to article on PPO plans.">What is a PPO Plan</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/coverage/dppo-dhmo/'"
                        title="Link to information on the difference between PPO and HMO plans.">PPO
                        vs HMO Plans</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/resources/specialty-dentistry/out-of-network-dentist/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to article on cost differences between in-network and out-of-network plans..">Out
                        of Network Dentist Costs</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/benefits/waiting-period/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to information on how waiting periods work.">Insurance and
                        Waiting Periods</a>
                    </li>
                  </ul>
                </div>

                <div>
                  <header>Topics</header>
                  <ul class="mega-links">
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/benefits/dental-insurance-101/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to basic information on how dental insurance works.">Dental
                        Insurance 101</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/resources/oral-health/choosing-a-dentist/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to a guide on how to choose a dentist.">Choosing a
                        Dentist</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/resources/dental-care-and-your-budget/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to an article about managing finances when seeking dental care.">Dental
                        Care and Your Budget</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/frequently-asked-questions/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to Frequently Asked Question on oral health care.">FAQs</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/resources/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to our full list of Learning Center Resources.">More…</a>
                    </li>
                  </ul>
                </div>

                <div>
                  <header>State Specific Information</header>
                  <ul class="mega-links">
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/dental-insurance-california/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to information on dental options in California.">California</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/dental-insurance-florida/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to information on dental options in Florida.">Florida</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/dental-insurance-texas/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to information on dental options in Texas.">Texas</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/dental-insurance-new-york/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to information on dental options in New York.">New York</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/dental-insurance-north-carolina/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to information on dental options in North Carolina.">North
                        Carolina</a>
                    </li>
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/dental-plans-by-state/' + (getAffiliateUtmSuffix() | async)"
                        title="Link to information on dental options in All States.">All
                        States</a>
                    </li>
                  </ul>
                </div>

                <div>
                  <header>Who we are</header>
                  <ul class="mega-links">
                    <li>
                      <a [attr.href]="(quotesWebUrl$ | async) + '/about/' + (getAffiliateUtmSuffix() | async)"
                        title="Information on our Company.">About Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li class="phone">
          <a *ngIf="!exchangeCompany" [attr.href]="'tel:' + (phone$ | async)">{{(phone$ | async)}}</a>
          <a *ngIf="exchangeCompany" [attr.href]="'tel:' + (this.exchangeCompany.phone ? this.exchangeCompany.phone : this.exchangeCompany.exchangeCampaign.phone)">{{(this.exchangeCompany.phone ? this.exchangeCompany.phone : this.exchangeCompany.exchangeCampaign.phone )}}</a>
        </li>        
      </ul>

      <div class="btn menu-btn phone-mobile"><a *ngIf="!exchangeCompany" [attr.href]="'tel:' + (phone$ | async)" id="phone_text_mobile"><i
            class="fas fa-phone"></i></a>
            <a *ngIf="exchangeCompany" [attr.href]="'tel:' + (this.exchangeCompany.phone ? this.exchangeCompany.phone : this.exchangeCompany.exchangeCampaign.phone)" id="phone_text_mobile"><i
              class="fas fa-phone"></i></a></div>
      <label for="menu-btn" class="btn menu-btn hamburger" id="hamburger" *ngIf="!exchangeCompany"><i
          class="fas fa-bars"></i></label>
    </div>
  </nav>
</header>


<!-- <header class="top-fixed no-print" *ngIf="(affiliate | async) || defaultAffiliate; let affiliate">
  <div class="container top-header-container">
    <div class="logo">
      <a *ngIf="!exchangeCompany"
        [attr.href]="quotesWebUrl + '?utm_campaign='+affiliate.affiliateId + '&utm_source=' + campaignSourceName +  (agentId ? ('&utm_term='+agentId): '')">
        <img
          [src]="quotesWebUrl + '/assets/img/affiliate_logos/' + (affiliate.logoUrl === '' ? defaultAffiliate.logoUrl : affiliate.logoUrl ) + '.png'"
          alt="Dental Insurance" />
      </a>
      <a *ngIf="exchangeCompany"
        [attr.href]="quotesWebUrl+'/quoting/exchange'+ (exchangeCompany.exchangeCampaign.name.toLowerCase()==='amwins' ? '/amwins' : '')+'?utm_campaign='+affiliate.affiliateId +  (agentId ? ('&utm_term='+agentId): '')">
        <img
          [src]="quotesWebUrl + '/assets/img/exchange/' + (this.exchangeCompany.logo ? this.exchangeCompany.logo : this.exchangeCompany.exchangeCampaign.logo )"
          alt="Dental Insurance" />
      </a>
    </div>

    <div class="phone">
      <ng-container *ngIf="!exchangeCompany">
        <a href="tel:{{( campaign ? campaign.phone : (affiliate.phone.length >= 12) ? affiliate.phone : defaultAffiliate.phone)}}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="phone-icon" alt="support phone">
            <path
              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
          </svg>
        </a>
        <div class="phone-info">
          <a href="tel:{{ campaign ? campaign.phone : ((affiliate.phone.length >= 12) ? affiliate.phone : defaultAffiliate.phone)}}">{{ campaign 
            ? campaign.phone : ((affiliate.phone.length >= 12) ? affiliate.phone : defaultAffiliate.phone) }}</a>
        </div>
        <button *ngIf="!careington" class="menu-toggler" (click)="menuTogglerClick()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="menu-toggler-icon" alt="support phone">
            <rect y="35" width="512" height="100"></rect>
            <rect y="205" width="512" height="100"></rect>
            <rect y="375" width="512" height="100"></rect>
          </svg>
        </button>
      </ng-container>
      <ng-container *ngIf="exchangeCompany">
        <a href="tel:{{(exchangeCompany.phone) ? exchangeCompany.phone : exchangeCompany.exchangeCampaign.phone}}">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="phone-icon" alt="support phone">
            <path
              d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
          </svg>
        </a>
        <div class="phone-info">
          <a href="tel:{{(exchangeCompany.phone) ? exchangeCompany.phone : exchangeCompany.exchangeCampaign.phone}}">{{(exchangeCompany.phone)
            ? exchangeCompany.phone : exchangeCompany.exchangeCampaign.phone}}</a>
        </div>
        <button *ngIf="!exchangeCompany" class="menu-toggler" (click)="menuTogglerClick()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="menu-toggler-icon" alt="support phone">
            <rect y="35" width="512" height="100"></rect>
            <rect y="205" width="512" height="100"></rect>
            <rect y="375" width="512" height="100"></rect>
          </svg>
        </button>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="!exchangeCompany && !careington">
    <nav class="main-navigation" [ngClass]="showMenu ? 'show-flex' : ''">
      <ul class="menu">
        <li><a [attr.href]="quotesWebUrl + (getAffiliateUtmSuffix() | async)">Home</a></li>

        <li class="menu-item-has-children"><a [attr.href]="">Dental and
            Vision Rate Quotes<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              class="menu-icon">
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg></a>
          <ul class="sub-menu">
            <li><a [attr.href]="quotesWebUrl + '/quoting/' + (getAffiliateUtmSuffix() | async)">Get A Quote</a>
            <li class="menu-item-has-children"><a [attr.href]="">Dental
                Insurance
                Companies<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  class="menu-icon">
                  <path d="M6.028 0v6.425l5.549 5.575-5.549 5.575v6.425l11.944-12z" />
                </svg></a>
              <ul class="sub-menu">
                <li *ngIf="isCarrierAvailable(affiliate, 'ameritas') || isCarrierAvailable(affiliate, 'ameritasny')">
                  <a [attr.href]="quotesWebUrl + '/ameritas' + (getAffiliateUtmSuffix() | async)">Ameritas Dental</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'anthembcbs')">
                  <a [attr.href]="quotesWebUrl + '/anthem' + (getAffiliateUtmSuffix() | async)">Anthem Dental</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'californiadental')">
                  <a [attr.href]="quotesWebUrl + '/california-dental' + (getAffiliateUtmSuffix() | async)">California
                    Dental Network</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'deltadental')">
                  <a [attr.href]="quotesWebUrl + '/delta-dental' + (getAffiliateUtmSuffix() | async)">Delta Dental
                    Insurance</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'dhs')">
                  <a [attr.href]="quotesWebUrl + '/dental-health-services' + (getAffiliateUtmSuffix() | async)">Dental
                    Health Services</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'dominion')">
                  <a [attr.href]="quotesWebUrl + '/dominion-national' + (getAffiliateUtmSuffix() | async)">Dominion
                    National</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'guardian')">
                  <a [attr.href]="quotesWebUrl + '/guardian' + (getAffiliateUtmSuffix() | async)">Guardian Dental
                    Insurance</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'humana')">
                  <a [attr.href]="quotesWebUrl + '/humana' + (getAffiliateUtmSuffix() | async)">Humana Dental</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'metlife')">
                  <a [attr.href]="quotesWebUrl + '/metlife' + (getAffiliateUtmSuffix() | async)">Metlife Dental</a>
                </li>
                <li *ngIf="isCarrierAvailable(affiliate, 'nationwide')  || isCarrierAvailable(affiliate, 'ncd')">
                  <a [attr.href]="quotesWebUrl + '/nationwide' + (getAffiliateUtmSuffix() | async)">Nationwide Dental
                    Insurance</a>
                </li>
                <li
                  *ngIf="isCarrierAvailable(affiliate, 'renaissance') || isCarrierAvailable(affiliate, 'renaissancemw')">
                  <a [attr.href]="quotesWebUrl + '/renaissance' + (getAffiliateUtmSuffix() | async)">Renaissance
                    Dental</a>
                </li>
              </ul>
            </li>
            <li *ngIf="isCarrierAvailable(affiliate, 'careington')">
              <a [attr.href]="">Discount Dental Plans <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                  viewBox="0 0 24 24" class="menu-icon">
                  <path d="M6.028 0v6.425l5.549 5.575-5.549 5.575v6.425l11.944-12z" />
                </svg></a>
              <ul class="sub-menu">
                <li>
                  <a [attr.href]="quotesWebUrl + '/careington' + (getAffiliateUtmSuffix() | async)">Careington</a>
              </ul>
            </li>
            <li>
              <a *ngIf="this.showVision"
                [attr.href]="quotesWebUrl + '/vision-plans' + (getAffiliateUtmSuffix() | async)">Vision Plans</a>
            </li>
          </ul>
        </li>
        <li>
          <a *ngIf="affiliate.isPrerendered" [attr.href]="">
            Plans by State <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
              class="menu-icon">
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg></a>
          <ul class="sub-menu">
            <li><a
                [attr.href]="quotesWebUrl + '/dental-insurance-california' + (getAffiliateUtmSuffix() | async)">California
                dental insurance</a>
            </li>
            <li><a [attr.href]="quotesWebUrl + '/dental-insurance-florida' + (getAffiliateUtmSuffix() | async)">Florida
                dental insurance</a>
            </li>
            <li><a [attr.href]="quotesWebUrl + '/dental-insurance-new-york' + (getAffiliateUtmSuffix() | async)">New York
                dental insurance</a></li>
            <li><a
                [attr.href]="quotesWebUrl + '/dental-insurance-north-carolina' + (getAffiliateUtmSuffix() | async)">North
                Carolina dental insurance</a></li>
            <li><a [attr.href]="quotesWebUrl + '/dental-insurance-texas' + (getAffiliateUtmSuffix() | async)">Texas
                dental insurance</a>
            </li>
            <li><a [attr.href]="quotesWebUrl + '/dental-plans-by-state/' + (getAffiliateUtmSuffix() | async)">See All
                States</a></li>
          </ul>
        </li>
        <li>
          <a *ngIf="affiliate.isPrerendered"
            [attr.href]="quotesWebUrl + '/resources' + (getAffiliateUtmSuffix() | async)">Learning
            Centers<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="menu-icon">
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg>
          </a>
          <ul class="sub-menu">
            <li><a
                [attr.href]="quotesWebUrl + '/resources/dental-plans/ppo-dental-insurance/' + (getAffiliateUtmSuffix() | async)">PPO
                Dental Insurance</a></li>
            <li><a
                [attr.href]="quotesWebUrl + '/resources/dental-plans/indemnity-dental-insurance/' + (getAffiliateUtmSuffix() | async)">Indemnity
                Dental Insurance</a>
            </li>
            <li><a
                [attr.href]="quotesWebUrl + '/resources/dental-plans/hmo-dental-insurance/' + (getAffiliateUtmSuffix() | async)">HMO
                Dental Insurance</a></li>
            <li><a [attr.href]="quotesWebUrl + '/benefits/no-waiting-period/' + (getAffiliateUtmSuffix() | async)">Dental
                Insurance No Waiting Periods</a></li>
            <li><a [attr.href]="quotesWebUrl + '/benefits/covers-implants/' + (getAffiliateUtmSuffix() | async)">Dental
                Insurance that Covers Implants</a></li>
            <li><a
                [attr.href]="quotesWebUrl + '/resources/specialty-dentistry/braces-dental-coverage/' + (getAffiliateUtmSuffix() | async)">Will
                my Dental Insurance Cover
                Braces?</a></li>
            <li><a
                [attr.href]="quotesWebUrl + '/benefits/immediate-coverage/' + (getAffiliateUtmSuffix() | async)">Immediate
                Dental Insurance</a></li>
            <li><a
                [attr.href]="quotesWebUrl + '/benefits/full-coverage-dental-insurance-plans/' + (getAffiliateUtmSuffix() | async)">Full
                Coverage Dental Insurance</a>
            </li>
            <li><a [attr.href]="quotesWebUrl + '/resources/' + (getAffiliateUtmSuffix() | async)">Learning Center
                Home</a></li>
          </ul>
        </li>
      </ul>
    </nav>
  </ng-container>
</header> -->