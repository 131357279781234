import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { NgClass, NgIf } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-existing-coverage-humana',
    templateUrl: './existing-coverage-humana.component.html',
    styleUrls: ['./existing-coverage-humana.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, NgIf, DisclaimersComponent]
})
export class ExistingCoverageHumanaComponent implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;
  public todayDate!: string;
  public confirmBox!: boolean;
  public signature!: string;
  public hasCoverage!: boolean;
  public showValidation = false;

  constructor(private router: Router, private enrollmentService: EnrollmentService) { }

  ngOnInit(): void {
    const availableDate = new Date();
    this.todayDate = availableDate.toLocaleDateString('en-US');
  }

  onSubmit() {
    if (!this.existingCoverageForm.form.valid) {
      this.showValidation = true;
      return;
    }
    this.router.navigate(['/contact']);
  }

  onBackClick() {
    this.router.navigate(['/applicant']);
  }

}
