import { Affiliate } from "../models/affiliate.model";

export const CoreAffiliates: Affiliate[] = [
    {
        affiliateId: 1019,
        name: 'DentalInsurance.com',
        phone: '800-296-3800',
        hoursOfOperation: 'Mon-Fri 6am-5pm (PT)',
        logoUrl: 'www',
        disclaimer: null,
        isPrerendered: true,
        availableCarriers: null,
        hasVision: true,
        hasBundled: true,
        availableCampaigns: null
    },
    {
        affiliateId: 950,
        name: 'SEM Branded',
        phone: '888-562-0484',
        hoursOfOperation: null,
        logoUrl: null,
        disclaimer: null,
        isPrerendered: true,
        availableCarriers: null,
        hasVision: true,
        hasBundled: true,
        availableCampaigns: null
    },
    {
        affiliateId: 951,
        name: 'SEM Remarketing',
        phone: '888-562-0484',
        hoursOfOperation: null,
        logoUrl: null,
        disclaimer: null,
        isPrerendered: true,
        availableCarriers: null,
        hasVision: true,
        hasBundled: true,
        availableCampaigns: null
    },
    {
        affiliateId: 952,
        name: 'SEM Delta Ads',
        phone: '844-222-3308',
        hoursOfOperation: null,
        logoUrl: null,
        disclaimer: null,
        isPrerendered: true,
        availableCarriers: null,
        hasVision: true,
        hasBundled: true,
        availableCampaigns: null
    },
    {
        affiliateId: 953,
        name: 'SEM Agent General',
        phone: '888-562-0484',
        hoursOfOperation: null,
        logoUrl: null,
        disclaimer: null,
        isPrerendered: true,
        availableCarriers: null,
        hasVision: true,
        hasBundled: true,
        availableCampaigns : null
    },
    {
        affiliateId: 996,
        name: 'Bing - Carriers General',
        phone: '844-222-3308',
        hoursOfOperation: null,
        logoUrl: null,
        disclaimer: null,
        isPrerendered: true,
        availableCarriers: null,
        hasVision: true,
        hasBundled: true,
        availableCampaigns: null
    },
    {
        affiliateId: 997,
        name: 'Google - Carriers General',
        phone: '888-562-0484',
        hoursOfOperation: null,
        logoUrl: null,
        disclaimer: null,
        isPrerendered: true,
        availableCarriers: null,
        hasVision: true,
        hasBundled: true,
        availableCampaigns: null
    },
    {
        affiliateId: 999,
        name: 'Google - Generic CPC',
        phone: '888-562-0484',
        hoursOfOperation: null,
        logoUrl: null,
        disclaimer: null,
        isPrerendered: true,
        availableCarriers: null,
        hasVision: true,
        hasBundled: true,
        availableCampaigns: null
    }
];