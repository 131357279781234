<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
    <div class="container">

        <app-breadcrumbs [index]="1"></app-breadcrumbs>

        <div class="plan-wrapper">
            <aside class="side-widget no-mobile">
                <app-plan-widget></app-plan-widget>
                <app-support-widget></app-support-widget>
            </aside>
            <section class="application-content">
                <h1>Replacement Coverage Information</h1>
                <div class="separator-left"></div>
                <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">

                    <div *ngIf="stateAbbr === 'LA'">

                        <div class="application-questions text-center">
                            <h4>Do you have any dental insurance coverage currently in force?</h4>
                            <div class="radio-group enroll-radio-group"
                                [ngClass]="{'radioBtn-invalid': showValidation === true && hasCoverage === undefined, 'radioBtn-valid': showValidation === true && hasCoverage !== undefined}">
                                <label for="hasCoverageYes">
                                    <input type="radio" id="hasCoverageYes" [(ngModel)]="hasCoverage" name="hasCoverage"
                                        [value]="true" required />Yes
                                </label>
                                <label for="hasCoverageNo">
                                    <input type="radio" id="hasCoverageNo" [(ngModel)]="hasCoverage" name="hasCoverage"
                                        [value]="false" required />No
                                </label>
                            </div>
                            <div [hidden]="!showValidation || hasCoverage !== undefined" class="invalid-feedback">
                                Please select one
                            </div>
                        </div>

                        <div *ngIf="hasCoverage" class="col-12">
                            <hr>
                            <h3>Why are we asking this?</h3>

                            <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF LIMITED BENEFIT INSURANCE</h3>

                            <p>If you have other limited benefit insurance and if you intend to lapse or otherwise
                                terminate
                                that coverage and replace it with this policy issued by The Guardian Life Insurance
                                Company
                                of America, please read below.</p>
                            <ol>
                                <li>Certain health conditions which you may presently have (pre-existing conditions)
                                    may
                                    not
                                    be immediately or fully covered under the new Guardian policy. This could result
                                    in
                                    the
                                    denial of or a delay in a claim for benefits under the Guardian policy, whereas
                                    a
                                    similar claim might have been payable under your present policy.</li>

                                <li>You may wish to secure the advice of your present insurer or its agent regarding
                                    the
                                    proposed replacement of your present policy. This is not only your right, but it
                                    is
                                    also
                                    in your best interests to make sure you understand all the relevant factors
                                    involved
                                    in
                                    replacing your present coverage.</li>

                                <li>If, after due consideration, you still wish to terminate your present policy and
                                    replace
                                    it with the Guardian policy, read the copy of the application and be sure that
                                    all
                                    questions are answered fully and correctly. Omissions or misstatements in the
                                    application could cause an otherwise valid claim to be denied. Carefully check
                                    the
                                    application and write to The Guardian Life Insurance Company of America within
                                    30
                                    days
                                    if any information is not correct and complete, or if any medical history has
                                    been
                                    left
                                    out of the application.</li>

                                <li>Your new policy provides 30 days within which you may decide without cost
                                    whether
                                    you
                                    desire to keep the policy. For your own information and protection, you should
                                    be
                                    aware
                                    of and seriously consider certain factors which may affect the insurance
                                    protection
                                    available to you under the Guardian policy.</li>
                            </ol>

                            <p>The Guardian Life Insurance Company of America<br>
                                REPL-LBI-2019-LA</p>
                        </div>
                    </div>

                    <div *ngIf="stateAbbr === 'MA'">

                        <div class="application-questions">
                            <h4>Do you have any dental insurance coverage currently in force?</h4>
                            <div class="radio-group enroll-radio-group"
                                [ngClass]="{'radioBtn-invalid': showValidation === true && hasCoverage === undefined, 'radioBtn-valid': showValidation === true && hasCoverage !== undefined}">
                                <label for="hasCoverageYes">
                                    <input type="radio" id="hasCoverageYes" [(ngModel)]="hasCoverage" name="hasCoverage"
                                        [value]="true" required />Yes
                                </label>
                                <label for="hasCoverageNo">
                                    <input type="radio" id="hasCoverageNo" [(ngModel)]="hasCoverage" name="hasCoverage"
                                        [value]="false" required />No
                                </label>
                            </div>
                            <div [hidden]="!showValidation || hasCoverage !== undefined" class="invalid-feedback">
                                Please select one
                            </div>
                        </div>

                        <div *ngIf="hasCoverage">
                            <div class="application-questions">
                                <h4>Is this coverage being replaced by proposed coverage?</h4>
                                <div class="radio-group enroll-radio-group"
                                    [ngClass]="{'radioBtn-invalid': showValidation === true && coverageReplacement === undefined, 'radioBtn-valid': showValidation === true && coverageReplacement !== undefined}">
                                    <label for="coverageReplacementYes">
                                        <input type="radio" id="coverageReplacementYes"
                                            [(ngModel)]="coverageReplacement" name="coverageReplacement" [value]="true"
                                            required />Yes
                                    </label>
                                    <label for="coverageReplacementNo">
                                        <input type="radio" id="coverageReplacementNo" [(ngModel)]="coverageReplacement"
                                            name="coverageReplacement" [value]="false" required />No
                                    </label>
                                </div>
                                <div [hidden]="!showValidation || coverageReplacement !== undefined"
                                    class="invalid-feedback">
                                    Please select one
                                </div>
                            </div>

                            <div class="col-12">
                                <hr>
                                <h3>Why are we asking this?</h3>
                                <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF ACCIDENT AND SICKNESS INSURANCE
                                </h3>
                                <p>According to Your application, You intend to lapse or otherwise terminate Your
                                    present
                                    policy and replace it with a policy to be issued by The Guardian Life Insurance
                                    Company
                                    of America. For Your own information and protection, certain facts should be
                                    pointed
                                    out
                                    to You which could affect Your rights to coverage under the new policy.</p>
                                <ol>
                                    <li>Health conditions which You may presently have, may not be covered under the
                                        new
                                        policy. This could result in a claim for benefits being denied which may
                                        been
                                        payable under Your present policy.</li>
                                    <li>Even though some of Your present health conditions may be covered under the
                                        new
                                        policy, these conditions may be subject to certain waiting periods under the
                                        new
                                        policy before coverage is effective.</li>
                                    <li>Questions in the application for the new policy must be answered truthfully
                                        and
                                        completely; otherwise, the validity of the policy and the payment of any
                                        benefits
                                        thereunder may be voided.</li>
                                    <li>It may be to Your advantage to secure the advice of Your present carrier or
                                        its
                                        agent regarding the proposed replacement of Your present policy. This is
                                        Your
                                        right,
                                        under the policy You have chosen.</li>
                                </ol>
                                <p>IP-DEN-REPL-16-MA</p>
                            </div>
                        </div>

                    </div>

                    <br />
                    <br />
                    <hr />
                    <div class="two-buttons-block">
                        <button type="submit" class="enroll button-right"><span>Continue</span></button>
                        <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
                    </div>
                </form>
                <app-disclaimers></app-disclaimers>
            </section>
        </div>

    </div>
</main>