import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location, NgIf, NgClass, NgFor } from '@angular/common';

import { EnrollmentService } from '../shared/services/enrollment.service';
import { FamilyMember } from '../shared/models/family-member.model';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { NgForm, FormsModule } from '@angular/forms';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { SocialSecurityComponent } from '../social-security/social-security.component';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-ssn-form',
    templateUrl: './ssn-form.component.html',
    styleUrls: ['./ssn-form.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgIf, SocialSecurityComponent, NgClass, NgFor, DisclaimersComponent]
})
export class SsnFormComponent implements OnInit {
  @ViewChild('ssnForm', { static: true }) public ssnForm!: NgForm;

  public applicant!: FamilyMember;
  public dependents!: Array<FamilyMember>;
  public requestCensusSSN!: boolean;
  public carrierShortName!: string;
  public showValidation = false;
  public stateAbbr!: string;
  constructor(private router: Router, private enrollmentService: EnrollmentService, private location: Location) { }

  ngOnInit() {
    if (!this.enrollmentService.requestApplicantSSN) {
      this.router.navigate(['/paymentoptions']);
    } else {
      this.applicant = this.enrollmentService.census.familyMembers.find(member => member.familyRelationship === FamilyRelationship.APPLICANT)!;
      this.requestCensusSSN = this.enrollmentService.requestCensusSSN;
      if (this.requestCensusSSN) {
        this.dependents = this.enrollmentService.census.familyMembers.filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT);
      }
      this.carrierShortName = this.enrollmentService.plan.carrier.shortName.toLowerCase();
      this.stateAbbr = this.enrollmentService.census.stateAbbr;
    }
  }

  onSubmit() {
    if (!this.ssnForm.form.valid) {
      this.showValidation = true;
      return;
    }
    this.router.navigate(['/paymentoptions']);
  }

  onBackClick() {
    const dependentsCount = this.enrollmentService.census.familyMembers
      .filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT).length;

    if (this.enrollmentService.requestApplicantDentistInfo) {
      if (this.enrollmentService.plan.carrier.shortName.toLowerCase() === 'metlife') {
        this.router.navigate(['/metlife-dentist']);
      } else { this.router.navigate(['/dentist']); }

    }
    else 
      if (this.enrollmentService.plan.carrier.shortName.toLowerCase().startsWith('anthem') ||
      this.enrollmentService.plan.carrier.shortName.toLowerCase().startsWith('empire'))  {
        this.router.navigate(['/anthem-previous-coverage']);
      } 
    else 
      if (this.enrollmentService.plan.carrier.shortName.toLowerCase().startsWith('cigna')) {
        this.router.navigate(['/cigna-previous-coverage']);
      } 
    else if (dependentsCount > 0) {
      this.router.navigate(['/census']);
    }  else {
              this.router.navigate(['/contact']);
      }
    
  }

  getFullMemberName(member: FamilyMember): string {
    return this.getFullName(member.firstName, member.middleInitial, member.lastName);
  }

  getFullName(firstName: string, middleInitial: string, lastName: string): string {
    let fullName = firstName;
    if (middleInitial != null && middleInitial.length > 0) {
      fullName += ' ' + middleInitial;
    }
    fullName += ' ' + lastName;
    return fullName;
  }

}
