import { FamilyMember } from './family-member.model';
import { ReplacementCoverageProduct } from './replacement-coverage-product.enum';

export class CignaExistingCoverage {
    public familyMember!: FamilyMember;
    public hasCoverage!: boolean;
    public policyId!: string;
    public insurerName!: string;
    public coverageType!: ReplacementCoverageProduct;
    public effectiveDate!: string;
    public lastDateOfCoverage!: string;
    //public hadOrthoCoverage!: boolean;
    //public willTerminate!: boolean;
    //public willReplace!: boolean;
}