<div class="ef-phone enroll-phone-el">
    (<input name="phoneArea" type="tel" pattern="[0-9]*" [(ngModel)]="phoneArea" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="XXX"
        maxlength="3" class="three-digits">)
    <input name="phonePrefix" type="tel" pattern="[0-9]*" [(ngModel)]="phonePrefix" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="XXX"
        maxlength="3" (focus)="onFocusPhonePrefixFunction()" class="three-digits">-
    <input name="phoneSuffix" type="tel" pattern="[0-9]*" [(ngModel)]="phoneSuffix" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="XXXX"
        maxlength="4" (focus)="onFocusPhoneSuffixFunction()" class="four-digits">
</div>