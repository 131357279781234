export class Address {
    public addressLine1!: string;
    public addressLine2!: string;
    public city!: string;
    public county!: string;
    public stateAbbr!: string;
    public zip!: string;

    public phone!: string;
    public email!: string;
    public marketingConsent!: boolean;
    public writtenCorrespondence!: boolean;
}
