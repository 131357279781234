import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { EnrollmentService } from './shared/services/enrollment.service';
import { MetlifeService } from './shared/services/metlife.service';
import { ExchangeCompany } from './shared/models/exchange-company.model';
import { AffiliateService } from './shared/services/affiliate.service';
import { FooterExchangeComponent } from './footer-exchange/footer-exchange.component';
import { FooterComponent } from './footer/footer.component';
import { NgIf } from '@angular/common';
import { HeaderComponent } from './header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [EnrollmentService, MetlifeService],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, NgIf, FooterComponent, FooterExchangeComponent]
})
export class AppComponent implements OnInit {
  public quotesWebUrl!: string;
  public exchangeCompany!: ExchangeCompany | null;
  public careington = false;

  constructor(
    private router: Router, private enrollmentService: EnrollmentService, private affiliateService: AffiliateService) {
    this.enrollmentService.exchCompany.subscribe(x => this.exchangeCompany = x);
    this.affiliateService.affiliate.subscribe(x => {
      x.subscribe((t:any) => {
        this.quotesWebUrl = this.enrollmentService.getQuotesUrl(x.isPrerendered);
        if (t.affiliateId >= 2572 && t.affiliateId <= 2576)
          this.careington = true;
      })
    });

  }

  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
