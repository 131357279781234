import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { PaymentType } from '../shared/models/paymentType';
import { Notice } from '../shared/models/notice.model';
import { InitialPaymentType } from '../shared/models/initialPaymentType';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { NgIf, NgClass } from '@angular/common';
import { EnrollmentService } from '../shared/services/enrollment.service';

@Component({
    selector: 'app-guardian-authorizations',
    templateUrl: './guardian-authorizations.component.html',
    styleUrls: ['./guardian-authorizations.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GuardianAuthorizationsComponent), multi: true }],
    standalone: true,
    imports: [NgIf, NgClass, FormsModule]
})
export class GuardianAuthorizationsComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() public initialPaymentType!: InitialPaymentType;
  @Input() public authorizationNotices!: Array<Notice>;
  @Input() public emailAddress!: string;
  @Input() public isAgentAssisted!: boolean;
  @Input() public showValidation!: boolean;

  public paymentTypes = PaymentType;
  public paymentCheckbox!: boolean;
  public eConsentText!: string;
  public insuranceAgreement!: string;
  public additionalNotice!: string;
  public fraudNotice!: string;
  public assistedNotice!: string;
  public additionalAssistedNotice!: string;
  public paymentType!: PaymentType;
  public emailConfirm!: string;
  public eConsentCheckbox!: boolean;
  public onlineTermsCheckbox!: boolean;
  public plan!: string;

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: any) => { };

  constructor(private enrollmentService: EnrollmentService) {
    this.plan = this.enrollmentService.plan.name;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    if (this.authorizationNotices) {
      this.eConsentText = this.authorizationNotices.find(n => n.type === 1) ? this.authorizationNotices.find(n => n.type === 1)?.text! : '';
      this.insuranceAgreement = this.authorizationNotices.find(n => n.type === 2) ? this.authorizationNotices.find(n => n.type === 2)?.text! : '';
      this.fraudNotice = this.authorizationNotices.find(n => n.type === 3) ? this.authorizationNotices.find(n => n.type === 3)?.text! : '';
      this.additionalNotice = this.authorizationNotices.find(n => n.type === 4) ? this.authorizationNotices.find(n => n.type === 4)?.text! : '';
      this.assistedNotice = this.authorizationNotices.find(n => n.type === 5) ? this.authorizationNotices.find(n => n.type === 5)?.text! : '';
      this.additionalAssistedNotice = this.authorizationNotices.find(n => n.type === 6) ? this.authorizationNotices.find(n => n.type === 6)?.text! : '';
    }
    if (this.initialPaymentType) {
      this.paymentType = this.initialPaymentType.paymentType;
    }
  }

  get value(): boolean | null {
    if (this.paymentCheckbox && this.eConsentCheckbox && this.onlineTermsCheckbox &&
      (this.isAgentAssisted || (this.emailAddress && this.emailConfirm && (this.emailAddress.toLowerCase() === this.emailConfirm.toLowerCase())))) {
      return true;
    } else {
      return null;
    }
  }

  set value(newValue: boolean) {
    this.propagateChange(this.value);
  }

  writeValue(value: boolean): void {
    this.value = value;
  }
  registerOnChange(fn: (value: string) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  onModelChange() {
    this.propagateChange(this.value);
  }

}
