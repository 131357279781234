<form #anthemForm="ngForm" [ngClass]="{ showValidationContainer: showValidation == true }">
  <h3>Payment Authorization</h3>
  <div [innerHTML]="generalPaymentAuthorization">
  </div>
  <div *ngIf="initialPaymentType" class="form-check"
    [ngClass]="{'checkbox-invalid': showValidation == true && !paymentCheckbox}">

    <input type="checkbox" class="form-check-input check-input-big" name="paymentCheckbox" id="paymentCheckbox"
      [(ngModel)]="paymentCheckbox" required #paymentCheckboxInput="ngModel">
    <label *ngIf="paymentType === paymentTypes.CreditCard" for="paymentCheckbox" [innerHTML]="ccPaymentAuthorization"
      [ngClass]="{ 'error-label': paymentCheckboxInput.invalid }">
    </label>
    <label *ngIf="paymentType === paymentTypes.EFT" for="paymentCheckbox" [innerHTML]="achPaymentAuthorization"
      [ngClass]="{ 'error-label': paymentCheckboxInput.invalid }">
    </label>
    <div [hidden]="!showValidation || paymentCheckbox === true" class="invalid-feedback">
      Please check box
    </div>
  </div>

  <div class="pre-scrollable" *ngIf="legalNotice" [innerHTML]="legalNotice"></div>
  <div *ngFor="let member of requiredMembers; let i=index">
    <hr>
    <div class="form-row">
      <div class="form-group-block col-12">
        <p><strong>Electronic Signature for {{member.firstName}} {{member.lastName}} ({{member.birthdate}})</strong></p>
        <br />
        <p>All applicants over age 18 must personally read, agree to, sign and date this application.</p>
        <br />
        <p class="font-italic">Please type your name in the spaces below to electronically sign your application</p>
      </div>
    </div>
    <div class="form-row form-row-valign-bottom">
      <div class="form-group-block col-3">
        <label for="firstName{{i}}"
          [ngClass]="{ 'error-label': (!firstNameField.valid || (firstNameField.value && firstNameField.value.toLowerCase() !== member.firstName.toLowerCase()))}">First
          name:</label>
        <input type="text" id="firstName{{i}}" [(ngModel)]="firstNameVerifications[i]" name="firstName{{i}}"
          #firstNameField="ngModel" required
          [ngClass]="{'input-invalid': showValidation == true && (!firstNameField.valid || firstNameField.value.toLowerCase() !== member.firstName.toLowerCase()),
          'input-valid': showValidation == true && firstNameField.valid && firstNameField.value.toLowerCase() === member.firstName.toLowerCase()}">
        <p class="notice">{{getMemberTitle(member)}}<br *ngIf="getMemberTitle(member) === 'Child'" /> first
          name</p>
        <div [hidden]="!showValidation || firstNameField.valid" class="invalid-feedback">
          {{getMemberTitle(member)}} first name is required
        </div>
        <div
          [hidden]="!showValidation || !(firstNameField.valid && firstNameField.value.toLowerCase() !== member.firstName.toLowerCase())"
          class="invalid-feedback">
          Entered name doesn't match "{{member.firstName}}"
        </div>
      </div>
      <div class="form-group-block col-2">
        <label for="mi{{i}}">MI <span class="break">(optional):</span></label>
        <input type="text" id="mi{{i}}" name="mi{{i}}">
      </div>
      <div class="form-group-block col-3">
        <label for="lastName{{i}}"
          [ngClass]="{ 'error-label': (!lastNameField.valid || (lastNameField.value && lastNameField.value.toLowerCase() !== member.lastName.toLowerCase()))}">Last
          name:</label>
        <input type="text" id="lastName{{i}}" [(ngModel)]="lastNameVerifications[i]" name="lastName{{i}}"
          #lastNameField="ngModel" required
          [ngClass]="{'input-invalid': showValidation == true && (!lastNameField.valid || lastNameField.value.toLowerCase() !== member.lastName.toLowerCase()),
          'input-valid': showValidation == true && lastNameField.valid && lastNameField.value.toLowerCase() === member.lastName.toLowerCase()}">
        <p class="notice">{{getMemberTitle(member)}}<br *ngIf="getMemberTitle(member) === 'Child'" /> last
          name</p>
        <div [hidden]="!showValidation || lastNameField.valid" class="invalid-feedback">
          {{getMemberTitle(member)}} last name is required
        </div>
        <div
          [hidden]="!showValidation || !(lastNameField.valid && lastNameField.value.toLowerCase() !== member.lastName.toLowerCase())"
          class="invalid-feedback">
          Entered name doesn't match "{{member.lastName}}"
        </div>
      </div>
      <div class="form-group-block col-2">
        <label for="city{{i}}" [ngClass]="{ 'error-label': cityField.invalid }"><span
            class="break">&nbsp;</span>City:</label>
        <input type="text" id="city{{i}}" [(ngModel)]="cityVerifications[i]" name="city{{i}}" #cityField="ngModel"
          required [ngClass]="{'showValidation': showValidation == true}">
        <div [hidden]="!showValidation || cityField.valid" class="invalid-feedback">
          Please enter city
        </div>
      </div>
      <div class="form-group-block col-2">
        <label for="state{{i}}" [ngClass]="{ 'error-label': stateField.invalid }"><span
            class="break">&nbsp;</span>State:</label>
        <select id="state{{i}}" [(ngModel)]="stateVerifications[i]" #stateField="ngModel" name="stateVerifications{{i}}"
          required [ngClass]="{'showValidation': showValidation == true}">
          <option [ngValue]="null" disabled selected>Select</option>
          <option *ngFor="let stAbbr of statesAbbr; let i = index;" [ngValue]="stAbbr">
            {{stAbbr}}
          </option>
        </select>
        <div [hidden]="!showValidation || stateField.valid" class="invalid-feedback">
          Select state
        </div>
      </div>
    </div>

    <br />

    <div class="form-row">
      <div class="form-group-block col-12">
        <p class="font-italic">Please re-type your name in the spaces below to confirm your electronic signature</p>
      </div>
    </div>

    <div class="form-row form-row-valign-bottom">
      <div class="form-group-block col-3">
        <label for="firstNameRetyped{{i}}"
          [ngClass]="{ 'error-label': (!firstNameRetypedField.valid || (firstNameRetypedField.value && firstNameField.value && firstNameRetypedField.value.toLowerCase() !== firstNameField.value.toLowerCase())) }">First
          name:</label>
        <input type="text" id="firstNameRetyped{{i}}" [(ngModel)]="firstNameVerificationsReTyped[i]"
          name="firstNameRetyped{{i}}" #firstNameRetypedField="ngModel" required
          [ngClass]="{'input-invalid': showValidation == true && (!firstNameRetypedField.valid || firstNameRetypedField.value.toLowerCase() !== firstNameField.value.toLowerCase()),
          'input-valid': showValidation == true && firstNameRetypedField.valid && firstNameRetypedField.value.toLowerCase() === firstNameField.value.toLowerCase()}">
        <p class="notice">{{getMemberTitle(member)}}<br *ngIf="getMemberTitle(member) === 'Child'" />
          first name</p>
        <div [hidden]="!showValidation || firstNameRetypedField.valid" class="invalid-feedback">
          {{getMemberTitle(member)}} first name is required
        </div>
        <div
          [hidden]="!showValidation || !(firstNameRetypedField.valid && firstNameRetypedField.value.toLowerCase() !== firstNameField.value.toLowerCase())"
          class="invalid-feedback">
          Entered values must match
        </div>
      </div>
      <div class="form-group-block col-2">
        <label for="miv{{i}}">MI <span class="break">(optional):</span></label>
        <input type="text" id="miv{{i}}" name="miv{{i}}">
      </div>
      <div class="form-group-block col-3">
        <label for="lastNameRetyped{{i}}"
          [ngClass]="{ 'error-label': (!lastNameRetypedField.valid || (lastNameRetypedField.value && lastNameField.value && lastNameRetypedField.value.toLowerCase() !== lastNameField.value.toLowerCase())) }">Last
          name:</label>
        <input type="text" id="lastNameRetyped{{i}}" [(ngModel)]="lastNameVerificationsReTyped[i]"
          name="lastNameRetyped{{i}}" #lastNameRetypedField="ngModel" required
          [ngClass]="{'input-invalid': showValidation == true && (!lastNameRetypedField.valid || lastNameRetypedField.value.toLowerCase() !== lastNameField.value.toLowerCase()),
          'input-valid': showValidation == true && lastNameRetypedField.valid && lastNameRetypedField.value.toLowerCase() === lastNameField.value.toLowerCase()}">
        <p class="notice">{{getMemberTitle(member)}}<br *ngIf="getMemberTitle(member) === 'Child'" />
          last name</p>
        <div [hidden]="!showValidation || lastNameRetypedField.valid" class="invalid-feedback">
          {{getMemberTitle(member)}} last name is required
        </div>
        <div
          [hidden]="!showValidation || !(lastNameRetypedField.valid && lastNameRetypedField.value.toLowerCase() !== lastNameField.value.toLowerCase())"
          class="invalid-feedback">
          Entered values must match
        </div>
      </div>
      <div class="form-group-block col-3">
        <label for="date{{i}}">Date Electronically Signed:</label>
        <input type="text" id="date{{i}}" [value]="todaysDate | date : 'MM/dd/yyyy'" name="date{{i}}" disabled>
      </div>
    </div>
    <br>
    <div class="form-row">
      <div class="form-check col-12" [ngClass]="{'checkbox-invalid': showValidation == true && !signatures[i]}">
        <input type="checkbox" class="form-check-input check-input-big" name="signatures{{i}}" id="signatures{{i}}"
          [(ngModel)]="signatures[i]" #signatureInput="ngModel" required>
        <label for="signatures{{i}}" [ngClass]="{ 'error-label': signatureInput.invalid }"><strong>YOUR SIGNATURE(S) ON
            THIS PAGE INDICATE(S) THAT YOU HAVE READ, UNDERSTAND
            AND
            AGREE TO
            ALL THE PROVISIONS SET FORTH ON THIS APPLICATION AND THAT YOU UNDERSTAND AND AGREE TO THEM</strong></label>
        <div [hidden]="!showValidation || signatures[i] === true" class="invalid-feedback">
          Please check box
        </div>
      </div>
    </div>
  </div>
</form>