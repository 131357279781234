<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">
    <app-breadcrumbs [index]="1"></app-breadcrumbs>
    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>
      <section class="application-content">
        <h1>Replacement Coverage Information</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">
          <div class="application-questions text-center">
            <h4>Do you currently (or within the last 2 months) have {{(Products[product]).toLowerCase()}} insurance?</h4>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && hasCoverage === undefined, 'radioBtn-valid': showValidation === true && hasCoverage !== undefined}">
              <label for="hasCoverageYes">
                <input type="radio" id="hasCoverageYes" [(ngModel)]="hasCoverage" name="hasCoverage" [value]="true"
                  required />Yes
              </label>
              <label for="hasCoverageNo">
                <input type="radio" id="hasCoverageNo" [(ngModel)]="hasCoverage" name="hasCoverage" [value]="false"
                  required />No
              </label>
            </div>
            <div [hidden]="!showValidation || hasCoverage !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>
          <div *ngIf="hasCoverage">
            <div class="application-questions text-center">
              <h4>Is the {{(Products[product]).toLowerCase()}} insurance applied for intended to replace any existing insurance?</h4>
              <div class="radio-group enroll-radio-group"
                [ngClass]="{'radioBtn-invalid': showValidation === true && coverageReplacement === undefined, 'radioBtn-valid': showValidation === true && coverageReplacement !== undefined}">
                <label for="coverageReplacementYes">
                  <input type="radio" id="coverageReplacementYes" [(ngModel)]="coverageReplacement"
                    name="coverageReplacement" [value]="true" required />Yes
                </label>
                <label for="coverageReplacementNo">
                  <input type="radio" id="coverageReplacementNo" [(ngModel)]="coverageReplacement"
                    name="coverageReplacement" [value]="false" required />No
                </label>
              </div>
              <div [hidden]="!showValidation || coverageReplacement !== undefined" class="invalid-feedback">
                Please select one
              </div>
            </div>
          </div>
          <br />
          <div *ngIf="hasCoverage && coverageReplacement && (Products[product]).toLowerCase() != 'vision'">
            <div class="application-questions text-center">
              <h4>Is the dental insurance applied for replacing a fully insured plan (not DHMO, discount, or schedule plan)?</h4>
              <div class="radio-group enroll-radio-group"
                [ngClass]="{'radioBtn-invalid': showValidation === true && isFullyInsured === undefined, 'radioBtn-valid': showValidation === true && isFullyInsured !== undefined}">
                <label for="isFullyInsuredYes">
                  <input type="radio" id="isFullyInsuredYes" [(ngModel)]="isFullyInsured"
                    name="isFullyInsured" [value]="true" required />Yes
                </label>
                <label for="isFullyInsuredNo">
                  <input type="radio" id="isFullyInsuredNo" [(ngModel)]="isFullyInsured"
                    name="isFullyInsured" [value]="false" required />No
                </label>
              </div>
              <div [hidden]="!showValidation || isFullyInsured !== undefined" class="invalid-feedback">
                Please select one
              </div>
            </div>
          </div>
          <div *ngIf="(hasCoverage && coverageReplacement && isFullyInsured) || (hasCoverage && coverageReplacement && (Products[product]).toLowerCase() == 'vision')">
            <h4>Coverage Replacement Information</h4>
            <div class="separator-left"></div>
            <div class="form-row">
              <div class="form-group-block col-6">
                <label for="policyNumber" [ngClass]="{ 'error-label': policyNumberField.invalid }">Contract Type or
                  Policy Number:</label>
                <input type="text" id="policyNumber" [(ngModel)]="policyNumber" name="policyNumber"
                  #policyNumberField="ngModel" required maxlength="32" placeholder="Policy Number"
                  [ngClass]="{ showValidation: showValidation == true }">
                <div [hidden]="!showValidation || policyNumberField.valid" class="invalid-feedback">
                  Please enter contract type or policy number
                </div>
              </div>
              <div class="form-group-block col-6">
                <label for="carrier" [ngClass]="{ 'error-label': carrierField.invalid }">Company Name:</label>
                <input type="text" id="carrier" [(ngModel)]="carrier" name="carrier" #carrierField="ngModel" required
                  maxlength="32" placeholder="Company Name" [ngClass]="{ showValidation: showValidation == true }">
                <div [hidden]="!showValidation || carrierField.valid" class="invalid-feedback">
                  Please enter company name
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-6">
                <label for="terminationDate" [ngClass]="{ 'error-label': terminationDateField.invalid }">Termination
                  Date</label>
                <app-date id="terminationDate" [(ngModel)]="terminationDate" name="terminationDate"
                  #terminationDateField="ngModel" required
                  [ngClass]="{ showValidationComponent: showValidation == true }">
                </app-date>
                <div [hidden]="!showValidation || terminationDateField.valid" class="invalid-feedback">
                  Please enter termination date
                </div>
              </div>
            </div>
            <div *ngIf="stateAbbr === 'IN'">
              <br />
              <h4><a href="https://www.ameritas.com/OCM/GetFile?doc=407582" target="_blank">Customer Notice</a></h4>
            </div>
            <div *ngIf="stateAbbr === 'LA'">
              <br />
              <h4><a href="https://www.ameritas.com/OCM/GetFile?doc=533109" target="_blank">Customer Notice</a></h4>
              <h4><a href="https://www.ameritas.com/OCM/GetFile?doc=533110" target="_blank">Assisting Agent Notice</a>
              </h4>
            </div>
            <div *ngIf="stateAbbr === 'NC'">
              <br />
              <h4><a href="https://www.ameritas.com/OCM/GetFile?doc=407583" target="_blank">Customer Notice</a></h4>
            </div>
            <div *ngIf="stateAbbr === 'SC'">
              <br />
              <h4><a href="https://www.ameritas.com/OCM/GetFile?doc=407584" target="_blank">Customer Notice</a></h4>
            </div>
            <div *ngIf="stateAbbr !== 'IN' && stateAbbr !== 'LA' && stateAbbr !== 'NC' && stateAbbr !== 'SC'">
              <br />
              <h4><a href="https://www.ameritas.com/OCM/GetFile?doc=407584" target="_blank">Customer Notice</a></h4>
            </div>
          </div>
          <div *ngIf="hasCoverage && coverageReplacement === false || (hasCoverage && coverageReplacement === true && isFullyInsured === false)">
            <p *ngIf="(Products[product]).toLowerCase() != 'vision'">You are not eligible for CPC. <br /></p>
            <p class="application-text-warning"><strong>NOTE: Applicant and dependents cannot have duplicate policies underwritten 
              by Ameritas, or any brand that is underwritten by Ameritas (i.e. two dental or two vision plans).
            </strong></p>
            <div *ngIf="stateAbbr === 'SC'">
              <br />
              <h4><a href="https://www.ameritas.com/OCM/GetFile?doc=597539" target="_blank">Duplication notice</a>
              </h4>
             <!-- <p class="application-text-warning"><strong>NOTE: Applicant and dependents cannot have duplicate policies
                  underwritten by Ameritas, or any brand that is underwritten by Ameritas (i.e., two dental or two
                  vision plans).</strong></p> -->
            </div>
           <!-- <div *ngIf="stateAbbr === 'LA'">
              <br />
              <p class="application-text-warning"><strong>NOTE: Applicant and dependents cannot have duplicate policies
                  underwritten by Ameritas, or any brand that is underwritten by Ameritas (i.e., two dental or two
                  vision plans).</strong></p>
            </div> -->
          </div>
          <div *ngIf="hasCoverage && coverageReplacement === true && isFullyInsured" class="col-12">
            <p>Credit for prior coverage (CPC) is available for those replacing a fully insured dental plan from another carrier. 
              CPC is not available if the prior plan was a DHMO, discount or schedule plan. The gap in coverage must be no more 
              than two months from the new plan effective date, and prior coverage should have been in effect for at least 12 
              months. By providing the policy number and company name, policyholders may receive increased benefit coverage 
              (co-insurance) day one for Preventive, Basic and Major dental services. There will be no changes day one to the 
              annual maximum or benefit coverage levels for orthodontia or hearing benefits, including waiting periods for these 
              additional benefits. CPC is not available in all states.</p>
            <p class="application-text-warning"><strong>NOTE: Applicant and dependents cannot have duplicate policies
                underwritten by Ameritas, or any brand that is underwritten by Ameritas (i.e., two dental or two vision
                plans).</strong></p>
          </div>
          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>
        </form>
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>