<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
    <div class="container">

        <app-breadcrumbs [index]="1"></app-breadcrumbs>

        <div class="plan-wrapper">
            <aside class="side-widget no-mobile">
                <app-plan-widget></app-plan-widget>
                <app-support-widget></app-support-widget>
            </aside>
            <section class="application-content">
                <br />
                <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">

                    <div class="application-questions text-center">
                        <h4>Are you replacing current coverage?</h4>
                        <div class="radio-group enroll-radio-group"
                            [ngClass]="{'radioBtn-invalid': showValidation === true && hasCoverage === undefined, 'radioBtn-valid': showValidation === true && hasCoverage !== undefined}">
                            <label for="hasCoverageYes">
                                <input type="radio" id="hasCoverageYes" [(ngModel)]="hasCoverage" name="hasCoverage"
                                    [value]="true" required />Yes
                            </label>
                            <label for="hasCoverageNo">
                                <input type="radio" id="hasCoverageNo" [(ngModel)]="hasCoverage" name="hasCoverage"
                                    [value]="false" required />No
                            </label>
                        </div>
                        <div [hidden]="!showValidation || hasCoverage !== undefined" class="invalid-feedback">
                            Please select one
                        </div>
                    </div>

                    <br>
                    <ng-container *ngIf="hasCoverage">

                        <div class="col-12">
                            <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF LIMITED BENEFIT INSURANCE</h3>
                            <p>According to your application, you intend to lapse or otherwise terminate existing
                                limited
                                benefit insurance and replace it with a policy to be issued by Humana Health Benefit
                                Plan of
                                Louisiana, Inc. For your own information and protection, you should be aware of and
                                seriously
                                consider certain factors that may affect the insurance protection available to you under
                                the
                                new
                                policy.</p>
                            <ol>

                                <li>Health conditions which you may presently have, (preexisting conditions) may not be
                                    immediately or fully covered under the new policy. This could result in denial or
                                    delay
                                    of a
                                    claim for benefits present under the new policy, whereas a similar claim might have
                                    been
                                    payable under your present policy.</li>
                                <li>You may wish to secure the advice of your present insurer or its producer regarding
                                    the
                                    proposed replacement of your present policy. This is not only your right but it is
                                    also
                                    in
                                    your best interests to make sure you understand all the relevant factors involved in
                                    replacing your present coverage.</li>
                                <li>If, after due consideration, you still wish to terminate your present policy and
                                    replace
                                    it
                                    with new coverage, be certain to truthfully and completely answer all questions on
                                    the
                                    application concerning your medical/health history. Failure to include all material
                                    medical
                                    information on an application may provide a basis for the company to deny any future
                                    claims
                                    and to refund your premium as though your policy had never been in force. After the
                                    application has been completed and before you sign it, reread it carefully to be
                                    certain
                                    that all information has been properly recorded.</li>
                                <li>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input check-input-big" id="confirmBox"
                                            name="confirmBox" [(ngModel)]="confirmBox" required
                                            [ngClass]="{'checkbox-invalid': showValidation == true && !confirmBox}">
                                        <label for="confirmBox" [ngClass]="{ 'error-label': !confirmBox }">
                                            <strong>By checking this blank, I agree to receive this notice exclusively
                                                by
                                                electronic means only.</strong>
                                        </label>
                                        <div [hidden]="!showValidation || confirmBox === true" class="invalid-feedback">
                                            Please check box
                                        </div>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        <div class="form-row">
                            <div class="col-12">
                                The above “Notice to Applicant” was delivered to me on: {{todayDate}}
                            </div>
                        </div><br>
                        <div class="form-row">
                            <div class="col-6">
                                <label for="signature" [ngClass]="{ 'error-label': signatureInput.invalid }">Applicant's
                                    Signature:</label>
                                <input type="text" id="signature" [(ngModel)]="signature" name="signature"
                                    #signatureInput="ngModel" required
                                    [ngClass]="{ showValidation: showValidation == true }" />

                                <div [hidden]="!showValidation || signatureInput.valid" class="invalid-feedback">
                                    Applicant's signature is required
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <br />
                    <br />
                    <hr />
                    <div class="two-buttons-block">
                        <button type="submit" class="enroll button-right"><span>Continue</span></button>
                        <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
                    </div>
                </form>
                <app-disclaimers></app-disclaimers>
            </section>
        </div>
    </div>
</main>