import { Component, OnInit, forwardRef, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Affiliate } from '../shared/models/affiliate.model';
import { AffiliateService } from '../shared/services/affiliate.service';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-metlife-start-questions',
    templateUrl: './metlife-start-questions.component.html',
    styleUrls: ['./metlife-start-questions.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => MetlifeStartQuestionsComponent), multi: true }],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass]
})
  export class MetlifeStartQuestionsComponent implements OnInit, ControlValueAccessor {
    @ViewChild('metlifeStartQuestionsForm', { static: true }) public metlifeStartQuestionsForm!: NgForm;
  
    @Input() showValidation!: boolean;
    public isLicensed!: boolean;
    public confirmCovered!: boolean;
    public affiliate!: Observable<Affiliate>;
    public defaultAffiliate: Affiliate;
    public isAssisted: boolean;
    public isDHMO: boolean;
  
    private propagateChange = (_: boolean | null) => { };
    private propagateTouched = (_: boolean) => { };
  
    constructor(private affiliateService: AffiliateService, public enrollmentService: EnrollmentService) {
      this.defaultAffiliate = this.affiliateService.defaultAffiliate;
      this.affiliateService.affiliate.subscribe(x => this.affiliate = x);
      this.isDHMO = this.enrollmentService.plan.planType.name === 'DHMO';
      this.isAssisted = (this.enrollmentService.agent !== '' && this.enrollmentService.agent !== 'null' && !this.enrollmentService.isEmailedQuote);      
    }
  
    ngOnInit(): void {
      this.metlifeStartQuestionsForm.statusChanges?.subscribe(x => { this.onModelChange(); });
    }
  
    onModelChange() {
      if (!this.metlifeStartQuestionsForm.valid) {
        this.propagateChange(true);
      } else {
        if (this.isLicensed !== undefined && this.confirmCovered !== undefined) {
          if (!this.isLicensed || !this.confirmCovered) {
            this.propagateChange(true);
          } else {
            this.propagateChange(false);
          }
        } else {
          this.propagateChange(null);
        }
      }
    }
  
    get value(): boolean | null {
      if (this.isLicensed !== undefined && this.confirmCovered !== undefined) {
        if (!this.isLicensed || !this.confirmCovered) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    }
  
    set value(newValue: boolean) {
    }
  
    writeValue(value: boolean): void {
    }
    registerOnChange(fn: (value: boolean | null) => void): void {
      this.propagateChange = fn;
    }
    registerOnTouched(fn: () => void): void {
      this.propagateTouched = fn;
    }
  }