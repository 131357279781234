import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { NgIf, NgClass } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-existing-coverage-guardian',
    templateUrl: './existing-coverage-guardian.component.html',
    styleUrls: ['./existing-coverage-guardian.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgIf, NgClass, DisclaimersComponent]
})
export class ExistingCoverageGuardianComponent implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;
  public stateAbbr!: string;
  public hasCoverage!: boolean;
  public coverageReplacement!: boolean;
  public showValidation = false;

  constructor(private router: Router, private enrollmentService: EnrollmentService) { }

  ngOnInit(): void {
    this.stateAbbr = this.enrollmentService.census.stateAbbr;
    this.hasCoverage = this.enrollmentService.existingCoverageHasCoverage;
    this.coverageReplacement = this.enrollmentService.existingCoverageCoverageReplacement;
  }


  onSubmit() {
    if (!this.existingCoverageForm.form.valid) {
      this.showValidation = true;
      return;
    }
    this.enrollmentService.existingCoverageHasCoverage = this.hasCoverage;
    this.enrollmentService.existingCoverageCoverageReplacement = this.coverageReplacement;
    this.router.navigate(['/contact']);
  }

  onBackClick() {
    this.router.navigate(['/applicant']);
  }

}
