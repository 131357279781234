<hr>
<form #anthemStartQuestionsForm="ngForm" [ngClass]="{ showValidationContainer: showValidation == true }">
  <div *ngIf="state !== 'CA'">
    <div class="application-questions">
      <h4>Are all the applicants listed on the application <span *ngIf="state !== 'NY'">legal</span> residents of
        {{state}}?</h4>
      <div class="radio-group enroll-radio-group"
        [ngClass]="{'radioBtn-invalid': showValidation === true && (isResident === false || isResident === undefined) , 'radioBtn-valid': showValidation === true && (isResident !== undefined  && isResident === true)}">
        <label for="isResidentYes">
          <input type="radio" id="isResidentYes" [(ngModel)]="isResident" name="isResidentYes" [value]="true"
            required />Yes
        </label>
        <label for="isResidentNo">
          <input type="radio" id="isResidentNo" [(ngModel)]="isResident" name="isResidentNo" [value]="false"
            required />No
        </label>
      </div>
      <div [hidden]="!showValidation || isResident !== undefined" class="invalid-feedback">
        Please select one
      </div>
    </div>
  </div>
  <div class="text-center application-text-warning" *ngIf="state !== 'CA' && isResident === false">
    <p><strong>
        Based on your response to the legal residency question, you are not eligible for this coverage with Anthem at
        this time, however you can search for available coverage options in state of your residence.</strong>
    </p>
  </div>
  <ng-container *ngIf="state == 'NY'">
    <div class="application-questions">
      <h4>Are any applicants currently receiving Social Security Disability, Medicaid or other government program
        benefits, or are unable to work due to disability or receiving Workers' Compensation benefits?</h4>
      <div class="radio-group enroll-radio-group"
        [ngClass]="{'radioBtn-invalid': showValidation === true && enrollmentService.anthemDisabilityQuestions.isSSDisability === undefined , 'radioBtn-valid': showValidation === true && enrollmentService.anthemDisabilityQuestions.isSSDisability !== undefined}">
        <label for="isSSDisabilityYes">
          <input type="radio" id="isSSDisabilityYes"
            [(ngModel)]="enrollmentService.anthemDisabilityQuestions.isSSDisability" name="isSSDisabilityYes"
            [value]="true" required />Yes
        </label>
        <label for="isSSDisabilityNo">
          <input type="radio" id="isSSDisabilityNo"
            [(ngModel)]="enrollmentService.anthemDisabilityQuestions.isSSDisability" name="isSSDisabilityNo"
            [value]="false" required />No
        </label>
      </div>
      <div [hidden]="!showValidation || enrollmentService.anthemDisabilityQuestions.isSSDisability !== undefined"
        class="invalid-feedback">
        Please select one
      </div>
    </div>


    <div *ngIf="enrollmentService.anthemDisabilityQuestions.isSSDisability === true">
      <div class="form-row">
        <div class="form-group-block col-6">
          <label for="disabilityWho" [ngClass]="{ 'error-label': disabilityWhoInput.invalid }">Who:</label>
          <input type="text" id="disabilityWho" [(ngModel)]="enrollmentService.anthemDisabilityQuestions.disabilityWho"
            name="disabilityWho" #disabilityWhoInput="ngModel" required maxlength="128"
            [ngClass]="{ showValidation: showValidation == true }">
          <div [hidden]="!showValidation || disabilityWhoInput.valid" class="invalid-feedback">
            Who is required
          </div>
        </div>
        <div class="form-group-block col-6">
          <label for="disabilityReason" [ngClass]="{ 'error-label': disabilityReasonInput.invalid }">Reason:</label>
          <input type="text" id="disabilityReason"
            [(ngModel)]="enrollmentService.anthemDisabilityQuestions.disabilityReason" name="disabilityReason"
            #disabilityReasonInput="ngModel" required maxlength="256"
            [ngClass]="{ showValidation: showValidation == true }">
          <div [hidden]="!showValidation || disabilityReasonInput.valid" class="invalid-feedback">
            Reason is required
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group-block col-6">
          <label for="effectiveDateDisability" [ngClass]="{ 'error-label': effectiveDateDisabilityInput.invalid }">Start date of benefits:</label>
          <app-date id="effectiveDateDisability"
            [(ngModel)]="enrollmentService.anthemDisabilityQuestions.effectiveDateDisability"
            name="effectiveDateDisability" #effectiveDateDisabilityInput="ngModel" required
            [ngClass]="{ showValidationComponent: showValidation == true }">
          </app-date>
          <div [hidden]="!showValidation || effectiveDateDisabilityInput.valid" class="invalid-feedback">
            Start date is required
          </div>
        </div>
        <div class="form-group-block col-6">
          <label for="lastDateDisability" [ngClass]="{ 'error-label': lastDateDisabilityInput.invalid }">End date of benefits:</label>
          <app-date id="lastDateDisability" [(ngModel)]="enrollmentService.anthemDisabilityQuestions.lastDateDisability" #lastDateDisabilityInput="ngModel"
            name="lastDateDisability">
          </app-date>
        </div>
      </div>

    </div>

  </ng-container>
  <ng-container *ngIf="state !== 'MO' && state !== 'NY' && state !== 'OH'">

    <div class="application-questions">
      <h4>Are any applicants currently incarcerated (with more than 60 days left to serve before release) as a result
        of
        a conviction? (not just pending disposition of charges)</h4>
      <div class="radio-group enroll-radio-group"
        [ngClass]="{'radioBtn-invalid': showValidation === true && (isIncarcerated === true || isIncarcerated === undefined) , 'radioBtn-valid': showValidation === true && (isIncarcerated !== undefined  && isIncarcerated === false)}">
        <label for="isIncarceratedYes">
          <input type="radio" id="isIncarceratedYes" [(ngModel)]="isIncarcerated" name="isIncarceratedYes"
            [value]="true" required />Yes
        </label>
        <label for="isIncarceratedNo">
          <input type="radio" id="isIncarceratedNo" [(ngModel)]="isIncarcerated" name="isIncarceratedNo" [value]="false"
            required />No
        </label>
      </div>
      <div [hidden]="!showValidation || isIncarcerated !== undefined" class="invalid-feedback">
        Please select one
      </div>
    </div>
    <p *ngIf="isIncarcerated === true" class="application-text-warning text-center"><strong>
        Based on your response to the incarcerated question, you are not eligible for this coverage with Anthem at
        this time, however you can apply for this Anthem coverage without applicant who is currently
        incarcerated.</strong>
    </p>


  </ng-container>
  <ng-container *ngIf="state === 'CA' || state === 'CO' || state === 'NV'">
    <hr />

    <div class="application-questions" *ngIf="(affiliate | async) || defaultAffiliate; let affiliate">
      <h4>If applicant does not speak, read or write English and Interpreter is completing this application, you
        cannot proceed with online enrollment, however you can apply for this Anthem coverage by contacting us
        at <br><strong>{{campaign ? campaign.phone : affiliate.phone}}</strong>
      </h4>
    </div>

  </ng-container>
  <ng-container *ngIf="state === 'ME'">

    <div class="application-questions">
      <h4>Do you have a child age 26 or over who is incapable of self-sustaining employment by reason of a physical, mental,
        intellectual, or developmental impairment for whom coverage is being requested under this contract?
      </h4>
      <div class="radio-group enroll-radio-group"
        [ngClass]="{'radioBtn-invalid': showValidation === true && (dependentOver26 === true || dependentOver26 === undefined) , 'radioBtn-valid': showValidation === true && (dependentOver26 !== undefined  && dependentOver26 === false)}">
        <label for="dependentOver26Yes">
          <input type="radio" id="dependentOver26Yes" [(ngModel)]="dependentOver26" name="dependentOver26Yes"
            [value]="true" required />Yes
        </label>
        <label for="dependentOver26No">
          <input type="radio" id="dependentOver26No" [(ngModel)]="dependentOver26" name="dependentOver26No" [value]="false"
            required />No
        </label>
      </div>
      <div [hidden]="!showValidation || isIncarcerated !== undefined" class="invalid-feedback">
        Please select one
      </div>
    </div>
    <p *ngIf="dependentOver26 === true" class="application-text-warning text-center"><strong>
      Based on your response to the disabled dependent over age 26 question, you can not enroll for this coverage online. 
      To determine eligibility, you must submit a paper application and separate disabled dependent form, which can be found on anthem.com</strong>
    </p>


  </ng-container>
</form>