<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">

    <app-breadcrumbs [index]="3"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>
      <section class="application-content">
        <h1>Social Security Number</h1>
        <div class="separator-left"></div>
        <h3>Your social security number is only used for application purposes</h3>

        <form (ngSubmit)="onSubmit()" #ssnForm="ngForm">
          <div *ngIf="applicant" class="form-row">
            <div class="col-5">
              <label for="ssn" [ngClass]="{ 'error-label': ssn.invalid }">{{getFullMemberName(applicant)}} SSN:</label>
              <app-social-security [(ngModel)]="applicant.ssn" name="ssn" #ssn="ngModel"
                [required]="!carrierShortName.startsWith('empire') && !(carrierShortName.startsWith('anthem') && stateAbbr === 'NY') && !carrierShortName.startsWith('cigna')"
                [ngClass]="{ showValidationComponent: showValidation == true }">
              </app-social-security>
              <div [hidden]="!showValidation || ssn.valid" class="invalid-feedback">
                SSN required
              </div>
            </div>
          </div>
          <div *ngFor="let dep of dependents; let i = index" class="form-row">
            <div class="col-5">
              <label for="ssn" [ngClass]="{ 'error-label': ssn.invalid }">{{getFullMemberName(dep)}} SSN:</label>
              <app-social-security [(ngModel)]="dep.ssn" id="ssn+{{i}}" name="ssn+{{i}}" #ssn="ngModel"
                [required]="!carrierShortName.startsWith('empire') && !(carrierShortName.startsWith('anthem') && stateAbbr === 'NY') && !carrierShortName.startsWith('cigna')"
                [ngClass]="{ showValidationComponent: showValidation == true }">
              </app-social-security>
              <div [hidden]="!showValidation || ssn.valid" class="invalid-feedback">
                SSN required
              </div>
            </div>
          </div>
          <div
            *ngIf="carrierShortName.startsWith('empire') || (carrierShortName.startsWith('anthem') && stateAbbr === 'NY') || carrierShortName.startsWith('cigna')">
            <p>* Another identifying number will be provided for applicants who do not wish to provide a Social Security
              number or do not have one.</p>
          </div>

          <br />
          <br />
          <hr>
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>
        </form>
        <br />
        <br />
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>