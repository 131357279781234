import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { PaymentType } from '../shared/models/paymentType';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { CreditCard } from '../shared/models/creditCard';
import { EFT } from '../shared/models/eft';
import { PaymentFrequency } from '../shared/models/paymentFrequency';
import { Frequency } from '../shared/models/frequency';
import { InitialPaymentType } from '../shared/models/initialPaymentType';
import { IPaymentInfo } from '../shared/models/paymentInfo';
import { PaymentProcessingDescription } from '../shared/models/payment-processing-description.model';
import { Notice } from '../shared/models/notice.model';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { Address } from '../shared/models/address.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Census } from '../shared/models/census.model';
import { environment } from 'src/environments/environment';
import { AffiliateService } from '../shared/services/affiliate.service';
import { PaymetricGatewayComponent } from '../paymetric-gateway/paymetric-gateway.component';
import { PaymetricResponse } from '../shared/models/paymetricResponse';
import { NgForm, FormsModule } from '@angular/forms';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { AnthemAuthorizationsComponent } from '../anthem-authorizations/anthem-authorizations.component';
import { GuardianAuthorizationsComponent } from '../guardian-authorizations/guardian-authorizations.component';
import { AmeritasAuthorizationsComponent } from '../ameritas-authorizations/ameritas-authorizations.component';
import { CignaAuthorizationsComponent } from '../cigna-authorizations/cigna-authorizations.component';
import { EftComponent } from '../eft/eft.component';
import { CreditCardComponent } from '../credit-card/credit-card.component';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { NgIf, NgClass, NgFor, NgSwitch, NgSwitchCase, DatePipe } from '@angular/common';
import { DateComponent } from '../date/date.component';
declare const $XIPlugin: any;

@Component({
    selector: 'app-payment-method-form',
    templateUrl: './payment-method-form.component.html',
    styleUrls: ['./payment-method-form.component.scss'],
    standalone: true,
    imports: [NgIf, BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, NgFor, NgSwitch, NgSwitchCase, DatePipe, DateComponent, PaymetricGatewayComponent, CreditCardComponent, EftComponent, AmeritasAuthorizationsComponent, GuardianAuthorizationsComponent, AnthemAuthorizationsComponent, CignaAuthorizationsComponent, DisclaimersComponent]
})
export class PaymentMethodFormComponent implements OnInit {
  //@HostBinding('class.wrap') wrap = true;
  @ViewChild('paymetric') paymetric!: PaymetricGatewayComponent;
  @ViewChild('paymentMethodForm', { static: true }) public form!: NgForm;

  public selectedPaymentFrequency!: PaymentFrequency;
  public selectedInitialPaymentType!: InitialPaymentType;
  public selectedOngoingPaymentType!: PaymentType | null;
  public initialPaymentInfo!: IPaymentInfo;
  public ongoingPaymentInfo!: IPaymentInfo;
  public showOngoingPaymentOptions!: boolean;
  public availableDifferentOngoingPaymentType!: PaymentType;
  public authorizationNotices!: Array<Notice>;
  public ongoingPaymentDayOptions!: Array<number>;
  public initialPaymentDayOptions!: Array<string>;
  public carrierShortName!: string;
  public loading!: boolean;
  public error!: string;
  public allowDifferentBillingAddress!: boolean;
  public allowTrustPilot!: boolean;


  public initialPaymentsProcessingDescriptions!: Array<PaymentProcessingDescription>;
  public ongoingPaymentsProcessingDescriptions!: Array<PaymentProcessingDescription>;

  public applicantAddress!: Address;
  public census!: Census;

  public frequencies = Frequency;
  public paymentTypes = PaymentType;

  public signature!: string;
  public signatureverification!: string;
  public noticeChkboxes: Array<boolean> = new Array<boolean>();
  public authorizationBoxes!: boolean;

  public useDifferentPaymentMethod!: boolean;
  public showAgentRecordingNotice!: boolean;
  public pausedRecording!: boolean;
  public resumedRecording!: boolean;
  public isAssisted!: boolean;
  public appId!: number;
  public premium!: number;
  public accessToken!: string;
  public paymetricResponse!: PaymetricResponse;
  public signatureToken!: string;
  public isExchange = false;
  public showValidation = false;
  public hasEnrollmentAssistance!: boolean;
  public translatorName!: string;
  public translatorverification!: string;
  public applicantCantReadEnglish!: boolean;
  public applicantCantSpeakEnglish!: boolean;
  public applicantCantWriteEnglish!: boolean;
  public assistanceDate!: string;
  public stateAbbr!: string;
  public translateApplication!: boolean;
  public translateConditions!: boolean;
  public citySignature!: string;
  public stateSignature!: string;
  public signatureDate!: string;
  public plan!: string;

  private inhouseAgents = environment.inhouseAgents;

  constructor(private router: Router, private enrollmentService: EnrollmentService, private affiliateService: AffiliateService) { }

  ngOnInit() {
    this.enrollmentService.exchCompany.subscribe(x => { this.isExchange = true; });
    this.premium = this.enrollmentService.premium!;
    this.carrierShortName = this.enrollmentService.plan.carrier.shortName;
    this.applicantAddress = this.enrollmentService.address;
    this.census = this.enrollmentService.census;
    this.selectedPaymentFrequency = this.enrollmentService.selectedPaymentFrequency;
    this.ongoingPaymentDayOptions = this.enrollmentService.ongoingPaymentDayOptions;
    this.initialPaymentDayOptions = this.enrollmentService.initialPaymentDayOptions;
    this.allowDifferentBillingAddress = this.enrollmentService.allowDifferentBillingAddress;
    this.stateAbbr = this.enrollmentService.census.stateAbbr;
    this.plan = this.enrollmentService.plan.name;

    // Guardian only - for 16 to 25th only CC available
    if (this.carrierShortName.toLowerCase() === 'guardian' && !this.plan.includes('VSP Dental Vision') ) {

      const availableDate = new Date();
      let currentMonth = availableDate.getMonth();

      if (availableDate.getDate() > 15 && availableDate.getDate() <= 25) {
        availableDate.setMonth(currentMonth + 1);
        availableDate.setDate(1);
        if (this.enrollmentService.census.requestedEffectiveDate === availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, '')) {
          this.selectedPaymentFrequency.initialPaymentTypes.splice(1, 1);
        }
      }
    }

    // Delta Dental only - for 21 to 25th only CC available
    if (this.carrierShortName.toLowerCase() === 'deltadental' || this.carrierShortName.toLowerCase() === 'renaissancemw') {

      const availableDate = new Date();
      let currentMonth = availableDate.getMonth();

      if (availableDate.getDate() > 20 && availableDate.getDate() <= 25) {
        availableDate.setMonth(currentMonth + 1);
        availableDate.setDate(1);
        if (this.enrollmentService.census.requestedEffectiveDate === availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, '')) {
          this.selectedPaymentFrequency.initialPaymentTypes.splice(1, 1);
        }
      }
    }

    if (this.selectedPaymentFrequency.initialPaymentTypes.length === 1) {
      this.selectedInitialPaymentType = this.selectedPaymentFrequency.initialPaymentTypes[0];
      this.initializeInitialPaymentType();
      this.toggleOngoingPaymentOptions();
    }

    this.initialPaymentsProcessingDescriptions = this.enrollmentService.initialPaymentsProcessingDescriptions;
    this.ongoingPaymentsProcessingDescriptions = this.enrollmentService.ongoingPaymentsProcessingDescriptions;
    this.authorizationNotices = this.enrollmentService.authorizationNotices;

    let applicant = this.enrollmentService.census.familyMembers.find(x => x.familyRelationship === FamilyRelationship.APPLICANT);
    this.signature = applicant?.firstName!;
    if (applicant?.middleInitial != null && applicant?.middleInitial.length > 0) {
      this.signature += ' ' + applicant.middleInitial;
    }
    this.signature += ' ' + applicant?.lastName;

    if (this.isInhouseAgent() && !this.enrollmentService.isEmailedQuote) {
      this.showAgentRecordingNotice = true;
      this.isAssisted = true;
    }
    this.isAssisted = (this.enrollmentService.agent !== undefined && this.enrollmentService.agent !== null && this.enrollmentService.agent !== '' && this.enrollmentService.agent !== 'null' && !this.enrollmentService.isEmailedQuote);

    if (this.carrierShortName.toLowerCase() === 'vsp') {
      this.loading = true;
      this.enrollmentService.postEnrollmentToAPI().subscribe({
        next: confirmationId => {
          this.loading = false;
          this.enrollmentService.confirmationId = confirmationId;
          this.appId = confirmationId;
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          this.error = err.error;
        }
      });
    }

    if (this.carrierShortName.toLowerCase() === 'aetna'){
      this.citySignature = this.applicantAddress.city;
      this.stateSignature = this.stateAbbr;
      const todaysDate = new Date();
      this.signatureDate = todaysDate.toLocaleDateString('en-US');
    }
  }

  onInitialPaymentTypeChange() {
    if (this.showAgentRecordingNotice) {
      this.resumedRecording = false;
    }

    this.initializeInitialPaymentType();
    this.toggleOngoingPaymentOptions();
  }

  onOngoingPaymentTypeChange() {
    this.initializeOngoingPaymentType();
  }

  onSubmit() {
    this.showValidation = true;

    if (!this.form.form.valid || !this.isSigned())
      return;

    this.loading = true;

    /*if (this.carrierShortName.toLowerCase() === 'vsp1' && this.selectedInitialPaymentType.paymentType === this.paymentTypes.CreditCard) {
      this.enrollmentService.initialPaymentInfo = this.initialPaymentInfo;
      this.enrollmentService.postCCToAPI().subscribe({
        next: () => {
          this.paymetric.submitPayment();
          let intId = setInterval(() => {
            this.enrollmentService.checkVSP().subscribe(result => {
              if (result) {
                clearInterval(intId);
                this.router.navigate(['/thankyou']);
              }
            })
          }, 10000);
        },
        error: (err: HttpErrorResponse) => {
          this.loading = false;
          this.error = err.error;
        }
      });

      return;
    }*/

    if ((this.carrierShortName.toLowerCase() === 'vsp' || this.carrierShortName.toLowerCase().includes("ameritas") || this.carrierShortName.toLowerCase().includes("anthem") || this.carrierShortName.toLowerCase() === 'dominion')
     && this.selectedInitialPaymentType.paymentType === this.paymentTypes.CreditCard) {
      if (!this.isValidCard()) {
        this.loading = false;
        this.error = "Only Visa or Mastercard is accepted."
        return;
      }
    }

    if (this.carrierShortName.toLowerCase() === 'vsp' && this.selectedInitialPaymentType.paymentType === this.paymentTypes.CreditCard) {    

      this.enrollmentService.getVSPAccessAjaxToken().subscribe((response: string) => {
        var response1 = response.split(':');
        this.enrollmentService.accessToken = response1[0];
        this.accessToken = response1[0];
        this.signatureToken = response1[1];
        this.enrollmentService.signatureToken = response1[1];
        this.submitPaymetric();
      });


      const delay = 2;
      const limit = 2;
      let i = 1;
      let timeId = setTimeout(() => {
        let intId = setInterval(() => {
          this.enrollmentService.getVSPResponsePacket().subscribe(response => {
            this.paymetricResponse = response;
            if (this.paymetricResponse.success) {
              clearInterval(intId);
              let c = this.initialPaymentInfo;
              if (c instanceof CreditCard) {
                c.cardNumber = this.paymetricResponse.token;
              }
              this.enrollmentService.initialPaymentInfo = c;
              this.enrollmentService.postCCToAPI().subscribe({
                next: () => {

                  this.router.navigate(['/thankyou']);
                }, error: (err: HttpErrorResponse) => {
                  this.loading = false;
                  this.error = err.error;
                }
              });
            } else {
              i++;
              if (i > limit) {
                clearInterval(intId);
                this.loading = false;
                this.error = "Error obtaining response from paymetric."
              }
            }
          });
        }, delay * 5000);
      }, 5000);

      return;
    }

    if (this.carrierShortName.toLowerCase() === 'cigna') 
    {
        this.enrollmentService.enrollmentAssistance = this.hasEnrollmentAssistance;
        this.enrollmentService.enrollmentTranslator = this.translatorName;
        this.enrollmentService.cantReadEnglish = this.applicantCantReadEnglish;
        this.enrollmentService.cantSpeakEnglish = this.applicantCantSpeakEnglish;
        this.enrollmentService.cantWriteEnglish = this.applicantCantWriteEnglish;
    }
    
    this.enrollmentService.allowTrustPilot = this.allowTrustPilot || (((this.enrollmentService.affiliateId >= 940 && this.enrollmentService.affiliateId < 1000) || this.enrollmentService.affiliateId === this.affiliateService.defaultAffiliate.affiliateId)
      && this.carrierShortName.toLowerCase().startsWith('delta'));
    this.enrollmentService.initialPaymentInfo = this.initialPaymentInfo;
    this.enrollmentService.ongoingPaymentInfo = this.ongoingPaymentInfo;
    this.enrollmentService.postEnrollmentToAPI().subscribe({
      next: confirmationId => {
        this.loading = false;
        this.enrollmentService.confirmationId = confirmationId;
        this.router.navigate(['/thankyou']);
      }, error: (err: HttpErrorResponse) => {
        this.loading = false;
        this.error = err.error;
      }
    });
  }

  initializeInitialPaymentType() {
    if (this.selectedInitialPaymentType.paymentType === PaymentType.CreditCard) {
      let cc = new CreditCard();
      cc.billingAddress = new Address();
      this.initialPaymentInfo = cc;
    } else if (this.selectedInitialPaymentType.paymentType === PaymentType.EFT) {
      let eft = new EFT();
      eft.billingAddress = new Address();
      this.initialPaymentInfo = eft;    
    }
  }

  initializeOngoingPaymentType() {
    if (this.selectedOngoingPaymentType === PaymentType.CreditCard) {
      this.ongoingPaymentInfo = new CreditCard();
    } else if (this.selectedOngoingPaymentType === PaymentType.EFT) {
      this.ongoingPaymentInfo = new EFT();
    }
  }

  toggleOngoingPaymentOptions() {

    if (this.selectedInitialPaymentType.ongoingPaymentTypes &&              // if there are ongoing payment options availabel and if NOT
      !(this.selectedInitialPaymentType.ongoingPaymentTypes.length === 1 && // only available ongoing option is the same as initial
        this.selectedInitialPaymentType.ongoingPaymentTypes[0] === this.selectedInitialPaymentType.paymentType)) {
      for (let i = 0; this.selectedInitialPaymentType.ongoingPaymentTypes.length; i++) {
        if (this.selectedInitialPaymentType.ongoingPaymentTypes[i] !== this.selectedInitialPaymentType.paymentType) {
          this.availableDifferentOngoingPaymentType = this.selectedInitialPaymentType.ongoingPaymentTypes[i];
          break;
        }
      }
      this.showOngoingPaymentOptions = true;
    } else {
      this.showOngoingPaymentOptions = false;
    }
  }

  toggleDifferentPaymentMethod() {
    if (this.useDifferentPaymentMethod) {
      this.selectedOngoingPaymentType = this.availableDifferentOngoingPaymentType;
      this.initializeOngoingPaymentType();
    } else {
      this.selectedOngoingPaymentType = null;
    }

  }

  isSigned() {
    if (this.carrierShortName.toLowerCase().startsWith('anthem') || this.carrierShortName.toLowerCase().startsWith('empire') 
    || (this.carrierShortName.toLowerCase() === 'cigna' && (this.stateAbbr === 'NJ' || this.stateAbbr === 'VA'))) {
      return true;
    } else {
      if (this.carrierShortName.toLowerCase() === 'cigna' && this.hasEnrollmentAssistance)
      {
        if (!this.translatorName || !this.translatorverification)
          return false;
        else return this.translatorName.toLowerCase() === this.translatorverification.toLowerCase();
      }
      if (!this.signature || !this.signatureverification) {
        return false;
      } else {
        return this.signature.toLowerCase() === this.signatureverification.toLowerCase();
      }
    }
  }

  showTrustPilot(): boolean {
    if ((this.enrollmentService.affiliateId === this.affiliateService.defaultAffiliate.affiliateId ||         // only for genereic traffic
      this.enrollmentService.affiliateId === 1965 ||                                                  // and PPC-affiliates
      (this.enrollmentService.affiliateId > 940 && this.enrollmentService.affiliateId < 1000)) && this.isInhouseAgent() && !this.enrollmentService.isEmailedQuote)
    // || (((this.enrollmentService.affiliateId >= 940 && this.enrollmentService.affiliateId < 1000) || this.enrollmentService.affiliateId === this.affiliateService.defaultAffiliate.affiliateId) 
    // && this.carrierShortName.toLowerCase().startsWith('delta'))
    {
      return true;
    } else {
      return false;
    }
  }

  isInhouseAgent(): boolean {
    return (this.enrollmentService.agent != undefined && this.enrollmentService.agent != null
      && this.inhouseAgents.indexOf(this.enrollmentService.agent.toLowerCase()) !== -1);
  }

  onRecordingResumed(resumed: boolean): void {
    this.resumedRecording = resumed;
  }

  onBackClick() {
    this.router.navigate(['/paymentoptions']);
  }

  isValidCard(): boolean {
    let c = this.initialPaymentInfo;
    if (c instanceof CreditCard) {
      if (c.cardNumber.startsWith('34') || c.cardNumber.startsWith('37') || c.cardNumber.startsWith('6'))
        return false;
      else return true;
    }
    else {
      return true;
    }
  }

  submitPaymetric(): void {
    console.log(this.accessToken);
    let c = this.initialPaymentInfo;
    if (c instanceof CreditCard) {
      var cc = c.cardNumber;
      var myData = $XIPlugin.createJSRequestPacket(environment.merchantGuid, this.accessToken);
      myData.addField($XIPlugin.createField('creditcard', true, cc));
      console.log(myData);
      $XIPlugin.ajax({
        url: environment.paymetricPostUrl,
        type: "POST",
        data: myData,
        success: function (a: any) {
          //function triggered on success.
          console.log('post success');
        },
        error: function (a: any) {
          //function triggered on failure.          
          console.log('post error');
        },
        beforeSend: function (a: any) {
          //function triggered before ajax call.
          console.log('beforesend');
        },
        complete: function (a: any) {
          //function triggered after ajax call.
          // After success or failure
          console.log('post complete');
        },
        validation: function (a: any) {
          //Run custom validation
          console.log('submit validation');
          return true;
        }
      });
    }
  }
}
