<div *ngIf="loading" class="spinner">
  <div class="loading-text">Processing Transaction<span>Please do not refresh the page.</span></div>
</div>

<main id="application" class="main-wrapper">
  <div class="container">

    <app-breadcrumbs [index]="6"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-print">
        <app-plan-widget [hideChangeLinks]="true"></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>

      <section *ngIf="!loading" class="application-content">
        <h1 class="no-print">Thank You</h1>
        <div class="separator-left"></div>

        <h2>Your application <span *ngIf="carrier !== 'metlife'">{{confirmationId}}</span> has been submitted...</h2>
        <p>* Note: Once payment is processed and eligibility is confirmed you will be automatically approved for this
          plan.</p>
        <br />
        <div class="alert alert-success no-print" role="alert">
          Please print for your record
          <button class="align-right" onclick="window.print();">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer"
              viewBox="0 0 16 16">
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"></path>
              <path
                d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z">
              </path>
            </svg>
          </button>
        </div>

        <!-- <div *ngIf="carrier.startsWith('ameritas')" class="alert alert-success no-print" role="alert">
          <a [attr.href]="getPdfUrl()" target="_blank">Download
            enrollment PDF</a>
        </div> -->

        <h2>Thank You {{applicant.firstName}} {{applicant.lastName}}.</h2>
        <p *ngIf="carrier !== 'californiadental'">Your application for {{plan.name}} has been submitted. Please review
          the
          policy as it will provide details on
          all covered
          services and any exclusions and limitations that may apply.</p>
        <p *ngIf="carrier.startsWith('ameritas') && eDelivery">
          An email will be sent shortly to {{email}} with instructions on how to create your online account.
        </p>
        <p *ngIf="carrier.startsWith('ameritas') && !eDelivery">
          Your application is being processed. You will receive your policy and ID card via mail within 10 days or less.
        </p>
        <p *ngIf="carrier !== 'metlife' && carrier !== 'californiadental' && carrier!== 'nationwidevsp' && !carrier.startsWith('ameritas')
          && !carrier.startsWith('delta') && carrier !== 'renaissancemw'">
          * Please allow
          {{(carrier === 'nationwide')?
          '1-3': '7-10'}} business days to
          receive your enrollment package via email. {{(carrier === 'ncd') ? 'Upon enrollment, you should receive an
          email
          titled ' :
          'The subject line of the email will read, ' }} “Welcome to {{plan.carrier.name}}”. The email will contain the
          web address to go to, along with a username
          and password to login and retrieve your kit. If an email address is not provided, a member service
          representative will call
          you to either get an email address or get an updated email address (if it bounces back).</p>

        <p *ngIf="carrier === 'metlife'">* Please allow 3 business days to receive your enrollment package via email (or
          7
          to 10 days for kits sent via U.S. mail). The subject line of the email will read, “Member Enroll
          Confirmation”.
          The email will contain a link to your welcome kit including a completed enrollment form, policy and other
          important information.</p>
        <p *ngIf="carrier === 'californiadental'">* Your application for California Dental Network Plan 595 has been
          submitted.<br>
          You can expect to receive a membership packet and I.D. card by USPS within next two weeks.<br>
          Please review the policy as it will provide details on all covered services and any exclusions and limitations
          that may apply.
        </p>
        <p *ngIf="carrier === 'nationwidevsp'">* Your enrollment package will be delivered via email within 24 hours of
          processing enrollment. You will get your login credentials to access your enrollment packet. Packet includes
          plan policy, HIPAA and privacy notice, and benefit summary document for vision. Schedule of benefits,
          Guaranty notice, Amendment notice, privacy notice, NSBA Letter, HIPAA and Privacy notice for dental.
          One ID card for both dental and vision plans.
        </p>
        <p *ngIf="carrier.startsWith('delta') || carrier === 'renaissancemw'">* You will receive your enrollment package
          from your
          plan administrator, Morgan White, via email within 24 to 48 hours. The subject line of the email will be:
          “Enrollment Confirmation.”
          To help ensure successful delivery of the email, please add info&#64;morganwhite.com and
          CustomerSupport&#64;DentalInsurance.com to
          your safe sender list. You may also want to add the Morgan White customer service number (800) 800-1397 to
          your phone contact list.
          <br><br>
          The Enrollment Confirmation email will include a link to your Coverage Documents. Please review the policy as
          it will provide
          details on all covered services and any exclusions and limitations that may apply. (If an email address is not
          provided (or incorrect)
          a member service representative will call you.)
        </p>

        <div class="thank-you-info">
          <div class="logo-150">
            <img src="{{plan.carrier.logoUrl}}" width="150" height="55" class="img-fluid">
          </div>
          <br />
          <h4>YOUR APPLICATION DETAILS</h4>
          <span>{{plan.name}}</span><br /><br />

          <h4>APPLICATION INFORMATION</h4>
          <span>Applicant: {{applicant.firstName}} {{applicant.lastName}} ({{applicant.birthdate | date :
            'MM/dd/yyyy'}})</span><br />
          <span>Email: {{address.email}}</span><br />
          <span>Address: {{address.addressLine1}}<br />{{address.addressLine2}} {{address.city}}, {{address.stateAbbr}}
            {{address.zip}}</span><br />
          <span>Phone:
            <!-- {{[address.phone.slice(0, 5), ' ', address.phone.slice(5)].join('')}}  -->
            {{address.phone}}</span><br />
          <span>Number of Members: {{totalNumber}}</span><br />

          <span *ngIf="spouse">Spouse: {{spouse.firstName}} {{spouse.lastName}} ({{spouse.birthdate | date :
            'MM/dd/yyyy'}})<br /></span>
          <span *ngFor="let child of children; let i = index;">Child: {{child.firstName}} {{child.lastName}}
            ({{child.birthdate | date : 'MM/dd/yyyy'}})<br /></span>

          <span>Confirmation Number: {{confirmationId}}</span><br /><br />


          <h4>PAYMENT INFORMATION</h4>
          <span>Total Initial Payment: {{totalInitialPaymentAmount | currency:'USD':'symbol':'1.2-2'}}</span><br />
          <span>Total Ongoing Payment: {{totalOngoingPaymentAmount | currency:'USD':'symbol':'1.2-2'}}</span>

        </div>


        <!-- <h3>Applicant Information</h3>
      <table class="table table-sm">
        <thead>
          <tr class="table-info-blue">
            <th scope="col">Applicant Name</th>
            <th scope="col">E-mail</th>
            <th scope="col">Address</th>
            <th scope="col">Phone</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{{applicant.firstName}} {{applicant.lastName}}</th>
            <td>{{address.email}}</td>
            <td>{{address.addressLine1}}
              <br /> {{address.addressLine2}}
              <br /> {{address.city}}, {{address.stateAbbr}} {{address.zip}}
            </td>
            <td>{{address.phone}}</td>
          </tr>
        </tbody>
      </table>
      <h3>Census Information</h3>
      <table class="table table-sm">
        <thead>
          <tr class="table-info-blue">
            <th scope="col" colspan="2">Census Information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span>
                Applicant: {{applicant.firstName}} {{applicant.lastName}}
                ({{applicant.birthdate | date : 'MM/dd/yyyy'}})
              </span>
            </td>
            <td>
              <span *ngIf="spouse">
                Spouse: {{spouse?.firstName}} {{spouse?.lastName}} ({{spouse?.birthdate | date : 'MM/dd/yyyy'}})
              </span>
            </td>
          </tr>
          <tr *ngFor="let child of children; let i = index;">
            <td colspan="2">
              <span>
                Child: {{child.firstName}} {{child.lastName}} ({{child.birthdate | date : 'MM/dd/yyyy'}})
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-sm mb-10">
        <thead>
          <tr class="table-info-blue">
            <th scope="col">Total Initial Payment</th>
            <th scope="col">Total Ongoing Payment</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{{totalInitialPaymentAmount | currency:'USD':'symbol':'1.2-2'}}
              <br />
            </th>
            <th>{{totalOngoingPaymentAmount | currency:'USD':'symbol':'1.2-2'}}
              <br />
            </th>
          </tr>
        </tbody>
      </table> -->
        <app-disclaimers></app-disclaimers>
      </section>

    </div>
  </div>
</main>