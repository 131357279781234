<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">

    <app-breadcrumbs [index]="1"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>
      <section class="application-content">
        <h1>Replacement Coverage Information</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">

          <div class="application-questions text-center">
            <h4 *ngIf="plan.includes('No Waiting')">Do you have full dental insurance coverage currently in force?</h4>
            <h4 *ngIf="!plan.includes('No Waiting')">Have you had Dental Coverage for the past 12 months?</h4>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && coverageReplacement === undefined, 'radioBtn-valid': showValidation === true && coverageReplacement !== undefined}">
              <label for="coverageReplacementYes">
                <input type="radio" id="coverageReplacementYes" [(ngModel)]="coverageReplacement"
                  name="coverageReplacement" [value]="true" required />Yes
              </label>
              <label for="coverageReplacementNo">
                <input type="radio" id="coverageReplacementNo" [(ngModel)]="coverageReplacement"
                  name="coverageReplacement" [value]="false" required />No
              </label>
            </div>
            <div [hidden]="!showValidation || coverageReplacement !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <div class="row">
            <div class="col-12" *ngIf="coverageReplacement== false && plan.includes('No Waiting')">
              <br />
              <p class="text-center application-text-warning"><strong>
                  This plan requires at least 12 months of continuous fully insured prior coverage with no
                  lapse.</strong>
              </p>
            </div>
          </div>

          <br />
          <div *ngIf="coverageReplacement">
            <h4>Coverage Information</h4>
            <div class="separator-left"></div>

            <div class="form-row">
              <div class="form-group-block col-9" *ngIf="!plan.includes('No Waiting')">
                <label for="coverageType" [ngClass]="{ 'error-label': coverageTypeField.invalid }">Coverage Type:
                </label>
                <select id="coverageType" [(ngModel)]="coverageType" name="coverageType" #coverageTypeField="ngModel"
                  required [ngClass]="{ showValidation: showValidation == true }">
                  <option [ngValue]="null" disabled selected>Select</option>
                  <option [ngValue]="coverageTypeOptions.BASIC">Insurance for Cleanings, Exams, and X-Rays</option>
                  <option [ngValue]="coverageTypeOptions.FULL">Insurance for Cleanings, Exams, X-Rays and Fillings,
                    Crowns, Dentures or Root Canals</option>
                  <option [ngValue]="coverageTypeOptions.DISCOUNT">Discount Dental Plan - only provided discounts
                  </option>
                </select>
                <div [hidden]="!showValidation || coverageTypeField.valid" class="invalid-feedback">
                  Please select one
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group-block col-6">
                <label for="carrier" [ngClass]="{ 'error-label': carrierField.invalid }">Insurance Company Name:</label>
                <input type="text" id="carrier" [(ngModel)]="carrier" name="carrier" #carrierField="ngModel" required
                  maxlength="30" placeholder="Insurance Company Name"
                  [ngClass]="{ showValidation: showValidation == true }">
                <div [hidden]="!showValidation || carrierField.valid" class="invalid-feedback">
                  Please enter insurance company name
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group-block col-6">
                <label for="carrier" [ngClass]="{ 'error-label': policyNumberField.invalid }">Policy Number:</label>
                <input type="text" id="policyNumber" [(ngModel)]="policyNumber" name="policyNumber"
                  #policyNumberField="ngModel" required maxlength="20" placeholder="Policy Number"
                  [ngClass]="{ showValidation: showValidation == true }">
                <div [hidden]="!showValidation || policyNumberField.valid" class="invalid-feedback">
                  Please enter policy number
                </div>
              </div>
            </div>

            <div class="form-row">
              <!--              <div class="col-6">
                  <label for="effectiveDate">Effective Date of Previous Dental Plan<br>(Date the member began their
                    previous
                    Dental Plan -- the dental plan before enrolling into NCD Dental):</label>
                  <app-date id="effectiveDate" [(ngModel)]="effectiveDate" name="effectiveDate"
                    #effectiveDateField="ngModel" required></app-date>
                  <div [hidden]="effectiveDateField.valid || effectiveDateField.pristine" class="invalid-feedback">
                    Effective Date is required
                  </div>
                </div>
  -->
              <div class="col-9">
                <label for="terminationDate" [ngClass]="{ 'error-label': terminationDateField.invalid }">Termination
                  Date: </label>
                <app-date id="terminationDate" [(ngModel)]="terminationDate" name="terminationDate"
                  [minDate]="getMinTermDate()" #terminationDateField="ngModel" required
                  [ngClass]="{ showValidationComponent: showValidation == true }"></app-date>
                <p class="notice">(must be within 63 days prior to the requested effective date)</p>
                <div [hidden]="!showValidation || terminationDateField.valid" class="invalid-feedback">
                  Please enter valid termination date
                </div>
              </div>
            </div>
          </div>


          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>

        </form>

        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>