<div [ngClass]="{ showValidationContainer: showValidation === true }">
  <div *ngIf="initialPaymentType">
    <h3>Payment Consent</h3>
    <div class="form-check" [ngClass]="{'checkbox-invalid': showValidation == true && !paymentCheckbox }">
      <input type="checkbox" class="form-check-input check-input-big" name="paymentCheckbox" id="paymentCheckbox"
        [(ngModel)]="paymentCheckbox" (ngModelChange)="onModelChange()" required #paymentCheckboxInput="ngModel"
        [ngClass]="{'showValidation-box': showValidation == true}">
      <label *ngIf="paymentType === paymentTypes.CreditCard" for="paymentCheckbox" [ngClass]="{ 'error-label': paymentCheckboxInput.invalid }"><strong>
          <span>By authorizing Guardian to draft my credit card account for premium payments, I understand the following:
          </span>
          <ol>
            <li>My initial premium payment will be drafted in 1 - 2 days and future recurring payments will be drafted
              on
              or around the 26th of the month prior to the next payment period.</li>
            <li>The amount due will be based on the payment frequency I elected during the enrollment process.</li>
            <li>Premium amounts are subject to change with at least 60 days advance notice of any rate adjustment.</li>
            <li>To change the method of payment, I can call Guardian at 1-866-569-9900 at least one business day prior
              to
              the
              withdrawal date.</li>
          </ol>
        </strong></label>
      <label *ngIf="paymentType === paymentTypes.EFT" for="paymentCheckbox" [ngClass]="{ 'error-label': paymentCheckboxInput.invalid }"><strong>
          <span>By authorizing Guardian to draft my bank account for premium payments, I understand the following:</span>
          <ol>
            <li>My initial premium payment will be drafted in 1 - 2 days and future recurring payments will be drafted
              on
              or around the 26th of the month prior to the next payment period.</li>
            <li>The amount due will be based on the payment frequency I elected during the enrollment process.</li>
            <li>Premium amounts are subject to change with at least 60 days advance notice of any rate adjustment.</li>
            <li>To change the method of payment, I can call Guardian at 1-866-569-9900 at least one business day prior
              to
              the withdrawal date.</li>
          </ol>
        </strong></label>
      <div [hidden]="!showValidation || paymentCheckbox === true" class="invalid-feedback">
        Please check box
      </div>
    </div>
  </div>
  <br>
  <h3 *ngIf="!isAgentAssisted">Electronic Consent</h3>
  <h3 *ngIf="isAgentAssisted">Voice Consent</h3>
  <div *ngIf="isAgentAssisted" [innerHTML]="assistedNotice"></div>
  <div class="pre-scrollable" *ngIf="!isAgentAssisted" [innerHTML]="eConsentText"></div><br />
  <br>
  <ng-container *ngIf="!isAgentAssisted">
    <p>The email address you provided to receive the electronic transmission of required documents is:</p>
    <div class="form-row">
      <div class="form-group-block col-6">
        <label for="email" [ngClass]="{ 'error-label': emailField.invalid }"><strong>Email:</strong></label>
        <input type="email" name="email" id="email2" [(ngModel)]="emailAddress" #emailField="ngModel" readonly
          [ngClass]="{'showValidation': showValidation == true}">
      </div>
      <div class="form-group-block col-6">
        <label for="emailConfirm" [ngClass]="{ 'error-label': (!emailConfirmField.valid || (emailConfirmField.value && emailField.value && emailConfirmField.value.toLowerCase() !== emailField.value.toLowerCase())) }"><strong>Confirm Email:</strong></label>
        <input type="email" name="emailConfirm" id="emailConfirm" [(ngModel)]="emailConfirm"
          #emailConfirmField="ngModel" (ngModelChange)="onModelChange()" required
          [ngClass]="{'input-invalid': showValidation == true && (!emailConfirmField.valid || emailConfirmField.value.toLowerCase() !== emailField.value.toLowerCase()),
        'input-valid': showValidation == true && emailConfirmField.valid && emailConfirmField.value.toLowerCase() === emailField.value.toLowerCase()}">
        <div [hidden]="!showValidation || emailConfirmField.valid" class="invalid-feedback">
          Email confirmation is required
        </div>
        <div [hidden]="!showValidation || !(emailConfirmField.valid && emailConfirmField.value !== emailField.value)"
          class="invalid-feedback">
          Entered values don't match
        </div>
      </div>

    </div>
    <p>Unless you opt out, the Company will use the email address you have provided. Please carefully consider the
      e-mail
      address that you provide, in case you share this email address with other individuals, as the Company may send you
      private or confidential information.</p>
    <p>If you need to update the email address you provided for any electronic process or other contact information, you
      may contact the Service Team at 1-866-569-9900 or by updating your information in the account
      profile section of the member web portal at www.guardianlife.com.
    <p>The Company’s address and toll-free telephone number is listed below.</p>
    <p><strong>The Guardian Life Insurance Company of America<br>
        10 Hudson Yards<br>
        New York, NY 10001<br>
        1-800-627-4200</strong>
    </p>
  </ng-container>
  <div class="form-check" [ngClass]="{'checkbox-invalid': showValidation == true && !eConsentCheckbox }">
    <input type="checkbox" class="form-check-input check-input-big" name="eConsentCheckbox" id="eConsentCheckbox"
      [(ngModel)]="eConsentCheckbox" (ngModelChange)="onModelChange()" required
      [ngClass]="{'showValidation': showValidation == true}" #eConsentCheckboxInput="ngModel">
    <label for="eConsentCheckbox" [ngClass]="{ 'error-label': eConsentCheckboxInput.invalid }"><strong>By checking this box, I hearby consent to receive
        the electronic transmission of required documents as per the terms of the Electronic
        Consent Agreement.</strong></label>
    <div [hidden]="!showValidation || eConsentCheckbox === true" class="invalid-feedback">
      Please check box
    </div>
  </div>
  <br>
  <div *ngIf="isAgentAssisted" [innerHTML]="additionalAssistedNotice"></div>
  <ng-container *ngIf="!isAgentAssisted">
    <h3>Insurance Agreement, Online Terms & Conditions, and Legal Notices</h3>
    <div class="pre-scrollable" [innerHTML]="insuranceAgreement"></div>
    <br>
    <div [innerHTML]="additionalNotice"></div>
  </ng-container>
  <h3>Fraud Notice</h3>
  <div [innerHTML]="fraudNotice"></div>

  <div *ngIf="isAgentAssisted">
    <p><em>Can you confirm that all of the statements you’ve provided and confirmed are complete and accurate to the
        best
        of your knowledge, information and belief? (Y/N)</em></p>
    <p>Finally, do I have your permission to submit your application? (Y/N)</p>
    <br>
  </div>
  <div class="form-check" [ngClass]="{'checkbox-invalid': showValidation == true && !onlineTermsCheckbox}">
    <input type="checkbox" class="form-check-input check-input-big" name="onlineTermsCheckbox" id="onlineTermsCheckbox"
      [(ngModel)]="onlineTermsCheckbox" (ngModelChange)="onModelChange()" required #onlineTermsCheckboxInput="ngModel"
      [ngClass]="{'showValidation': showValidation == true}">
    <label *ngIf="!plan.includes('Vision')" for="onlineTermsCheckbox" [ngClass]="{ 'error-label': onlineTermsCheckboxInput.invalid }"><strong>
        By checking this box, I hearby agree to the Insurance Agreement, <a target="_blank"
          href="/assets/pdfs/guardian/online-terms-and-conditions.pdf">Online
          Terms & Conditions</a>, and I have read the Legal Notices.</strong></label>
    <label *ngIf="plan.includes('Vision')" for="onlineTermsCheckbox" [ngClass]="{ 'error-label': onlineTermsCheckboxInput.invalid }"><strong>
        By checking this box, I hearby agree to the Insurance Agreement, <a target="_blank"
          href="/assets/pdfs/guardian/online-terms-and-conditions.pdf">Online
          Terms & Conditions</a>,<a target="_blank"
          href="/assets/pdfs/guardian/vision-terms-and-conditions.pdf">
          Terms & Conditions for Vision Purchase</a>, and I have read the Legal Notices.</strong></label>
    <div [hidden]="!showValidation || onlineTermsCheckbox === true" class="invalid-feedback">
      Please check box
    </div>
  </div>
  <p>By clicking "Submit", you understand that you are
    electronically signing the enrollment form and/or application for insurance coverage and you are confirming
    that your responses to any questions are true and accurate.</p>
</div>