import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';

import { FamilyMember } from '../shared/models/family-member.model';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { Gender } from '../shared/models/gender.enum';
import { MaritalStatus } from '../shared/models/marital-status.enum';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-family-member',
    templateUrl: './family-member.component.html',
    styleUrls: ['./family-member.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass, NgFor, DatePipe]
})
export class FamilyMemberComponent {

  public relationships = FamilyRelationship;
  public genderOptions = Gender;
  public maritalStatusOptions = MaritalStatus;

  @Input() public model!: FamilyMember;
  @Input() public index!: number;
  @Input() public askMaritalStatus!: boolean;
  @Input() public askChildStatus!: boolean;
  @Input() public childStatusOptions!: Array<string>;
  @Input() public addGenderX!: boolean;
  @Input() public hideStudent!: boolean;
  @Input() public showValidation!: boolean;
}
