import { Component, OnInit } from '@angular/core';
import { MetlifeService } from '../../shared/services/metlife.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AffiliateService } from '../../shared/services/affiliate.service';
import { EnrollmentService } from '../../shared/services/enrollment.service';
import { environment } from 'src/environments/environment';
import { SupportWidgetComponent } from '../../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../../breadcrumbs/breadcrumbs.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-cancel',
    templateUrl: './cancel.component.html',
    styleUrls: ['./cancel.component.scss'],
    standalone: true,
    imports: [NgIf, BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent]
})
export class CancelComponent implements OnInit {

  public loading!: boolean;
  public requestId!: string;
  public planId!: string;
  public quotesWebUrl = environment.quotesWebUrl;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private enrollmentService: EnrollmentService,
    private metlifeService: MetlifeService,
    private affiliateService: AffiliateService,
  ) {

    // load SessionId from MetLife

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['SessionId'] != null) {



        this.loading = true;
        const sessionId = params['SessionId'];

        this.enrollmentService.loadStage(sessionId).subscribe(
          enrollment => {
            this.enrollmentService.planId = enrollment.planId;
            this.enrollmentService.requestId = enrollment.requestId;

            this.requestId = enrollment.requestId;
            this.planId = enrollment.planId;

            this.enrollmentService.premium = enrollment.premium;
            this.enrollmentService.totalInitialPaymentAmount = enrollment.totalInitialPaymentAmount;
            this.enrollmentService.totalOngoingPaymentAmount = enrollment.totalOngoingPaymentAmount;
            this.enrollmentService.selectedPaymentFrequency = enrollment.selectedPaymentFrequency;
            this.enrollmentService.existingCoverageHasCoverage = enrollment.existingCoverageHasCoverage;
            this.enrollmentService.existingCoverageCoverageReplacement = enrollment.existingCoverageCoverageReplacement;
            this.enrollmentService.existingCoverageCarrier = enrollment.existingCoverageCarrier;
            this.enrollmentService.existingCoveragePhone = enrollment.existingCoveragePhone;
            this.enrollmentService.existingCoveragePolicyNumber = enrollment.existingCoveragePolicyNumber;
            this.enrollmentService.existingCoverageEffectiveDate = enrollment.existingCoverageEffectiveDate;
            this.enrollmentService.census = enrollment.census;
            this.enrollmentService.address = enrollment.address;

            this.enrollmentService.getPlanAppInfo().subscribe(
              planAppInfo => {
                this.enrollmentService.plan = planAppInfo.plan;
                this.enrollmentService.createdOn = planAppInfo.createdOn;
                this.enrollmentService.paymentFrequencies = planAppInfo.paymentFrequencies;
                this.enrollmentService.selectedPaymentFrequency = this.enrollmentService.paymentFrequencies[enrollment.selectedPaymentFrequency];


                this.enrollmentService.cancellationsDescription = planAppInfo.cancellationsDescription;
                this.enrollmentService.paymentNotes = planAppInfo.paymentNotes;

                this.enrollmentService.enrollmentNotices = planAppInfo.enrollmentNotices;

                this.enrollmentService.requestApplicantSSN = planAppInfo.requestApplicantSSN;
                this.enrollmentService.requestCensusSSN = planAppInfo.requestCensusSSN;

                this.enrollmentService.requestApplicantDentistInfo = planAppInfo.requestApplicantDentistInfo;
                this.enrollmentService.requestCensusDentistInfo = planAppInfo.requestCensusDentistInfo;
                this.enrollmentService.requestDentistId = planAppInfo.requestDentistId;
                this.enrollmentService.requestDentistName = planAppInfo.requestDentistName;
                this.enrollmentService.requestFacilityId = planAppInfo.requestFacilityId;
                this.enrollmentService.requestFacilityName = planAppInfo.requestFacilityName;

                this.enrollmentService.dentistSearchUrl = planAppInfo.dentistSearchUrl;
                this.enrollmentService.dentistSearchNote = planAppInfo.dentistSearchNote;

                this.enrollmentService.initialPaymentsProcessingDescriptions = planAppInfo.initialPaymentsProcessingDescriptions;
                this.enrollmentService.ongoingPaymentsProcessingDescriptions = planAppInfo.ongoingPaymentsProcessingDescriptions;

                this.enrollmentService.authorizationNotices = planAppInfo.authorizationNotices;
                this.enrollmentService.ongoingPaymentDayOptions = planAppInfo.ongoingPaymentDayOptions;
                this.enrollmentService.allowDifferentBillingAddress = planAppInfo.allowDifferentBillingAddress;
                this.enrollmentService.spouseNote = planAppInfo.spouseNote;
                this.enrollmentService.agent = planAppInfo.agent;
                this.enrollmentService.agentId.next(this.enrollmentService.agent);
                if (!isNaN(planAppInfo.affiliateId)) {
                  this.affiliateService.affiliate.next(this.affiliateService.getAffiliateFromAPI(planAppInfo.affiliateId));
                  this.enrollmentService.affiliateId = planAppInfo.affiliateId;
                } else {
                  this.enrollmentService.affiliateId = this.affiliateService.defaultAffiliate.affiliateId;
                }
                this.loading = false;

              }
            );
          }

        );

      } else {
      }

    });

  }

  ngOnInit() {
  }

}
