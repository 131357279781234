import { Routes } from '@angular/router';
import { StartFormComponent } from './start-form/start-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { CensusFormComponent } from './census-form/census-form.component';
import { ApplicantFormComponent } from './applicant-form/applicant-form.component';
import { DentistFormComponent } from './dentist-form/dentist-form.component';
import { SsnFormComponent } from './ssn-form/ssn-form.component';
import { ExistingCoverageAnthemComponent } from './existing-coverage-anthem/existing-coverage-anthem.component';
import { PaymentOptionsFormComponent } from './payment-options-form/payment-options-form.component';
import { PaymentMethodFormComponent } from './payment-method-form/payment-method-form.component';
import { ThankYouFormComponent } from './thank-you-form/thank-you-form.component';
import { MetDentistComponent } from './metlife/met-dentist/met-dentist.component';
import { CancelComponent } from './metlife/cancel/cancel.component';
import { MetPrevCoverageDefaultComponent } from './metlife/met-prev-coverage-default/met-prev-coverage-default.component';
import { ExistingCoverageAmeritasComponent } from './existing-coverage-ameritas/existing-coverage-ameritas.component';
import { MetPrevCoverage1Component } from './metlife/met-prev-coverage1/met-prev-coverage1.component';
import { MetPrevCoverage2Component } from './metlife/met-prev-coverage2/met-prev-coverage2.component';
import { MetPrevCoverage3Component } from './metlife/met-prev-coverage3/met-prev-coverage3.component';
import { ExistingCoverageGuardianComponent } from './existing-coverage-guardian/existing-coverage-guardian.component';
import { ExistingCoverageHumanaComponent } from './existing-coverage-humana/existing-coverage-humana.component';
import { ExistingCoverageHumanaExchangeComponent } from './existing-coverage-humana-exchange/existing-coverage-humana-exchange.component';
import { ExistingCoverageNCDComponent } from './existing-coverage-ncd/existing-coverage-ncd.component';
import { ExistingCoverageCignaComponent } from './existing-coverage-cigna/existing-coverage-cigna.component';
import { ExistingCoverageAetnaComponent } from './existing-coverage-aetna/existing-coverage-aetna.component';


export const routes: Routes = [
{ path: 'start', component: StartFormComponent },
  { path: 'applicant', component: ApplicantFormComponent },
  { path: 'contact', component: ContactFormComponent },
  { path: 'census', component: CensusFormComponent },
  { path: 'dentist', component: DentistFormComponent },
  { path: 'ssn', component: SsnFormComponent },
  { path: 'paymentoptions', component: PaymentOptionsFormComponent },
  { path: 'paymentmethod', component: PaymentMethodFormComponent },
  { path: 'thankyou', component: ThankYouFormComponent },

  { path: 'existingcoverage', component: ExistingCoverageAmeritasComponent },
  { path: 'anthem-previous-coverage', component: ExistingCoverageAnthemComponent },
  { path: 'metlife-previous-coverage-default', component: MetPrevCoverageDefaultComponent },
  { path: 'metlife-previous-coverage-a', component: MetPrevCoverage1Component },
  { path: 'metlife-previous-coverage-b', component: MetPrevCoverage2Component },
  { path: 'metlife-previous-coverage-c', component: MetPrevCoverage3Component },  
  { path: 'guardian-previous-coverage', component: ExistingCoverageGuardianComponent },
  { path: 'humana-previous-coverage', component: ExistingCoverageHumanaComponent },
  { path: 'humana-exchange-previous-coverage', component: ExistingCoverageHumanaExchangeComponent },
  { path: 'ncd-previous-coverage', component: ExistingCoverageNCDComponent },
  { path: 'cigna-previous-coverage', component: ExistingCoverageCignaComponent },
  { path: 'aetna-previous-coverage', component: ExistingCoverageAetnaComponent },
      
  { path: 'metlife-dentist', component: MetDentistComponent },  
  { path: 'cancelled', component: CancelComponent }
];
