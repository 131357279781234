import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { Product } from '../shared/models/product.enum';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { DateComponent } from '../date/date.component';
import { NgClass, NgIf } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';


@Component({
    selector: 'app-existing-coverage-ncd',
    templateUrl: './existing-coverage-ncd.component.html',
    styleUrls: ['./existing-coverage-ncd.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, NgIf, DateComponent, DisclaimersComponent]
})
export class ExistingCoverageNCDComponent implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;
  
  public coverageReplacement!: boolean;
  public carrier!: string;
  public policyNumber!: string;
  public effectiveDate!: string;
  public terminationDate!: string;
  public plan!: string;
  public showValidation = false;

  public Products = Product;

  constructor(private router: Router, private enrollmentService: EnrollmentService) { }

  ngOnInit() {
    this.coverageReplacement = this.enrollmentService.existingCoverageCoverageReplacement;
    this.carrier = this.enrollmentService.existingCoverageCarrier;
    this.effectiveDate = this.enrollmentService.existingCoverageEffectiveDate;
    this.terminationDate = this.enrollmentService.existingCoverageTerminationDate;
    this.plan = this.enrollmentService.plan.name;
  }

onSubmit() {
  if (!this.existingCoverageForm.form.valid || (!this.coverageReplacement && this.plan.includes('No Waiting'))) {
    this.showValidation = true;
    return;
  }
  this.enrollmentService.existingCoverageCoverageReplacement = this.coverageReplacement;
  this.enrollmentService.existingCoverageCarrier = this.carrier;
  this.enrollmentService.existingCoverageEffectiveDate = this.effectiveDate;
  this.enrollmentService.existingCoverageTerminationDate = this.terminationDate;
  this.router.navigate(['/contact']);
}

onBackClick() {
  this.router.navigate(['/applicant']);
}

}
