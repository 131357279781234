import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location, NgClass, NgIf } from '@angular/common';

import { EnrollmentService } from '../shared/services/enrollment.service';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { FamilyMember } from '../shared/models/family-member.model';
import { Gender } from '../shared/models/gender.enum';
import { Plan } from '../shared/models/plan';
import { MaritalStatus } from '../shared/models/marital-status.enum';
import { NgForm, FormsModule } from '@angular/forms';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-applicant-form',
    templateUrl: './applicant-form.component.html',
    styleUrls: ['./applicant-form.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, NgIf, DisclaimersComponent]
})
export class ApplicantFormComponent implements OnInit {
  
  @ViewChild('applicantForm', { static: true }) public applicantForm!: NgForm;

  public showValidation = false;
  public model!: FamilyMember;
  public genderOptions = Gender;
  public maritalStatusOptions = MaritalStatus;
  public plan!: Plan;
  public stateAbbr!: string;

  constructor(private router: Router, private enrollmentService: EnrollmentService, private location: Location) { }

  ngOnInit() {
    this.model = this.enrollmentService.census.familyMembers.find(member => member.familyRelationship === FamilyRelationship.APPLICANT)!;
    this.plan = this.enrollmentService.plan;
    if (this.model.gender===this.genderOptions.UNKNOWN)
    {
      this.model.gender = null;
    }
    this.stateAbbr = this.enrollmentService.census.stateAbbr;
  }

  onSubmit() {

    if (!this.applicantForm.form.valid || !this.isGenderValid())
    {
      this.showValidation = true;
      return;
    }
    // TODO: make generic
    if (this.enrollmentService.plan.carrier.shortName.toLowerCase().startsWith('ameritas') && !this.enrollmentService.plan.name.toLowerCase().includes("waiting")
     && (this.enrollmentService.census.stateAbbr != 'OH' && this.enrollmentService.census.stateAbbr != 'NM' && this.enrollmentService.census.stateAbbr != 'NY' &&
      this.enrollmentService.census.stateAbbr != 'VA' )) {
      this.router.navigate(['/existingcoverage']);
    } else if (this.enrollmentService.plan.carrier.shortName.toLowerCase().startsWith('humana')
      && this.enrollmentService.census.stateAbbr === 'LA' && (this.enrollmentService.planId.toUpperCase() != '81AE2D34-50E7-4D11-A0BF-08DAD245240C' && 
      this.enrollmentService.planId.toUpperCase() != 'C1DB1B0A-685A-4A6B-6D42-08DA74C2EC30'&& this.enrollmentService.planId.toUpperCase() != '1106E4E6-3CF0-4987-6D41-08DA74C2EC30')) {
      this.router.navigate(['/humana-previous-coverage']);
    } else if (this.enrollmentService.plan.carrier.shortName.toLowerCase().startsWith('humana')
    && (this.enrollmentService.planId.toUpperCase() === '81AE2D34-50E7-4D11-A0BF-08DAD245240C' || this.enrollmentService.planId.toUpperCase() === 'C1DB1B0A-685A-4A6B-6D42-08DA74C2EC30' 
    || this.enrollmentService.planId.toUpperCase() === '1106E4E6-3CF0-4987-6D41-08DA74C2EC30')) {
    this.router.navigate(['/humana-exchange-previous-coverage']);
    } else if (this.enrollmentService.plan.carrier.shortName.toLowerCase().startsWith('guardian')
      && (this.enrollmentService.census.stateAbbr === 'LA' || this.enrollmentService.census.stateAbbr === 'MA')) {
      this.router.navigate(['/guardian-previous-coverage']);
   // } else if (this.enrollmentService.plan.carrier.shortName.toLowerCase() === 'ncd') {
   //   this.router.navigate(['/ncd-previous-coverage']);
    } else if (this.enrollmentService.plan.carrier.shortName.toLowerCase() === 'aetna') {
      this.router.navigate(['/aetna-previous-coverage']);
    } else if (this.enrollmentService.plan.carrier.shortName.toLowerCase() === 'metlife') {
      switch (this.enrollmentService.census.stateAbbr) {
        case 'CO': this.router.navigate(['/metlife-previous-coverage-a']); break;
        case 'FL': this.router.navigate(['/metlife-previous-coverage-b']); break;
        case 'NJ': this.router.navigate(['/metlife-previous-coverage-c']); break;
        case 'AR':
        case 'CT':
        case 'DE':
        case 'IA':
        case 'IL':
        case 'NE':
        case 'NH':
        case 'OK':
        case 'PA':
        case 'TX':
        case 'UT':
        case 'VA':
        case 'WA':
        case 'WV': this.router.navigate(['/metlife-previous-coverage-default']); break;
        default: this.router.navigate(['/contact']);
      }
    } else {
      this.router.navigate(['/contact']);
    }
  }

  onBackClick() {
    this.router.navigate(['/start']);
  }

  isGenderValid() : boolean {
    if (this.model.gender === Gender.UNKNOWN)
      return false;
    else return true;
  }

}
