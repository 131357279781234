<div *ngIf="loading" class="loader"></div>
<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">

    <app-breadcrumbs [index]="5"></app-breadcrumbs>
    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>
      <section class="application-content">
        <h1>Select Payment Method</h1>
        <div class="separator-left"></div>
        <h3>Selected Payment Frequency:
          <strong>{{frequencies[selectedPaymentFrequency.frequency]}}</strong>
        </h3>
        <div class="alert alert-danger highlight" role="alert" *ngIf="showAgentRecordingNotice">
          <img class="stop-sign" src="/assets/images/stop.png">
          <div>
            AGENT: PAUSE RECORDING BEFORE COLLECTING PAYMENT INFORMATION.<br><br>
            <div class="has-coverage">
              <input type="checkbox" class="form-check-input check-input-big " id="PauseRecording" name="PauseRecording"
                [(ngModel)]="pausedRecording" [disabled]="pausedRecording">
              <label for="PauseRecording"><strong>Check this box ONLY after recording is paused</strong></label>
            </div>

          </div>
        </div>

        <form (ngSubmit)="onSubmit()" #paymentMethodForm="ngForm">
          <ng-container *ngIf="showAgentRecordingNotice === pausedRecording">

            <div *ngIf="selectedPaymentFrequency.initialPaymentTypes.length > 1">
              <h4 class="payment-header">Select Initial Payment Method</h4>
              <div class="radio-group enroll-radio-group"
                [ngClass]="{'radioBtn-invalid': showValidation == true && selectedInitialPaymentType == null, 'radioBtn-valid': showValidation == true && selectedInitialPaymentType != null}">
                <label for="pmntType{{pmnt.paymentType}}"
                  *ngFor="let pmnt of selectedPaymentFrequency.initialPaymentTypes">
                  <input type="radio" id="pmntType{{pmnt.paymentType}}" [(ngModel)]="selectedInitialPaymentType"
                    name="pmntType{{pmnt.paymentType}}" [value]="pmnt" (change)="onInitialPaymentTypeChange()"
                    required />
                  <ng-container [ngSwitch]="pmnt.paymentType">
                    <ng-container *ngSwitchCase="0">Credit Card</ng-container>
                    <ng-container *ngSwitchCase="1">EFT</ng-container>
                  </ng-container>
                </label>
              </div>
              <div [hidden]="!showValidation || (selectedInitialPaymentType != null)" class="invalid-feedback">
                Please select payment method
              </div>
            </div>

            <br />

            <div
              *ngIf="selectedInitialPaymentType && selectedInitialPaymentType.paymentType === paymentTypes.CreditCard && carrierShortName.toLowerCase() === 'vsp1'">
              <app-paymetric-gateway #paymetric name="initialCreditCard" *ngIf="appId" [(ngModel)]="initialPaymentInfo"
                [applicantAddress]="applicantAddress" [showAgentRecordingNotice]="showAgentRecordingNotice"
                (resumed)="onRecordingResumed($event)" required>
              </app-paymetric-gateway>
            </div>

            <div
              *ngIf="selectedInitialPaymentType && selectedInitialPaymentType.paymentType === paymentTypes.CreditCard && carrierShortName.toLowerCase() !== 'vsp1'">
              <app-credit-card name="initialCreditCard" [(ngModel)]="initialPaymentInfo"
                [applicantAddress]="applicantAddress" [ongoingPaymentDayOptions]="ongoingPaymentDayOptions"
                [allowDifferentBillingAddress]="allowDifferentBillingAddress"
                [showSignatureField]="carrierShortName.toLowerCase().startsWith('anthem') || carrierShortName.toLowerCase().startsWith('empire')"
                [showAgentRecordingNotice]="showAgentRecordingNotice" [showValidation]="showValidation"
                (resumed)="onRecordingResumed($event)" required>
              </app-credit-card>
            </div>

            <div *ngIf="selectedInitialPaymentType && selectedInitialPaymentType.paymentType === paymentTypes.EFT">
              <app-eft name="initialEFT" [(ngModel)]="initialPaymentInfo" [applicantAddress]="applicantAddress"
                [ongoingPaymentDayOptions]="ongoingPaymentDayOptions"
                [initialPaymentDayOptions]="initialPaymentDayOptions"
                [showCheckingOnly]="carrierShortName.toLowerCase().startsWith('ncd')"
                [allowDifferentBillingAddress]="carrierShortName.toLowerCase().startsWith('cigna')"
                [askAccountUsage]="carrierShortName.toLowerCase().startsWith('anthem') || carrierShortName.toLowerCase().startsWith('empire')"
                [showAgentRecordingNotice]="showAgentRecordingNotice" [showValidation]="showValidation"
                [askPayerOwnerRelationship]="carrierShortName.toLowerCase().startsWith('aetna')"
                (resumed)="onRecordingResumed($event)" required>
              </app-eft>
            </div>

            <div *ngIf="availableDifferentOngoingPaymentType">
              <br />
              <div class="form-check">
                <input type="checkbox" class="form-check-input check-input-big" [(ngModel)]="useDifferentPaymentMethod"
                  id="differentPaymentMethod" name="differentPaymentMethod"
                  (ngModelChange)="toggleDifferentPaymentMethod()">
                <label for="differentPaymentMethod">
                  <strong>Use {{paymentTypes[availableDifferentOngoingPaymentType]}} for ongoing payments</strong>
                </label>
              </div>

              <div *ngIf="selectedOngoingPaymentType === paymentTypes.CreditCard">
                <app-credit-card name="ongoingCreditCard" [(ngModel)]="ongoingPaymentInfo"
                  [applicantAddress]="applicantAddress" [ongoingPaymentDayOptions]="ongoingPaymentDayOptions"
                  [allowDifferentBillingAddress]="allowDifferentBillingAddress"
                  [showSignatureField]="carrierShortName.toLowerCase().startsWith('anthem') || carrierShortName.toLowerCase().startsWith('empire')"
                  [showAgentRecordingNotice]="showAgentRecordingNotice" [showValidation]="showValidation"
                  (resumed)="onRecordingResumed($event)" required>
                </app-credit-card>
              </div>

              <div *ngIf="selectedOngoingPaymentType === paymentTypes.EFT">
                <app-eft name="ongoingEFT" [(ngModel)]="ongoingPaymentInfo"
                  [ongoingPaymentDayOptions]="ongoingPaymentDayOptions"
                  [initialPaymentDayOptions]="initialPaymentDayOptions"
                  [showCheckingOnly]="carrierShortName.toLowerCase().startsWith('ncd')"
                  [allowDifferentBillingAddress]="carrierShortName.toLowerCase().startsWith('cigna')"
                  [askAccountUsage]="carrierShortName.toLowerCase().startsWith('anthem') || carrierShortName.toLowerCase().startsWith('empire')"
                  [showAgentRecordingNotice]="showAgentRecordingNotice" [showValidation]="showValidation"
                  [askPayerOwnerRelationship]="carrierShortName.toLowerCase().startsWith('aetna')"
                  (resumed)="onRecordingResumed($event)" required>
                </app-eft>
              </div>
            </div>

            <div
              *ngIf="carrierShortName.toLowerCase().startsWith('anthem') || carrierShortName.toLowerCase().startsWith('empire')">
              <br>
              <p><strong>Your monthly payments will be debited on the first of each month.</strong></p>
            </div>

            <hr />
          </ng-container>

          <ng-container *ngIf="!showAgentRecordingNotice || (pausedRecording && resumedRecording)">

            <div>
              <h2>Notices and Authorizations</h2>
              <app-ameritas-authorizations *ngIf="carrierShortName.toLowerCase().startsWith('ameritas')"
                [authorizationNotices]="authorizationNotices" name="ameritasAuthorization"
                [showValidation]="showValidation" required>
              </app-ameritas-authorizations>
              <app-guardian-authorizations *ngIf="carrierShortName.toLowerCase() === 'guardian'"
                [initialPaymentType]="selectedInitialPaymentType" [authorizationNotices]="authorizationNotices"
                [emailAddress]="applicantAddress.email" [isAgentAssisted]="isAssisted" [(ngModel)]="authorizationBoxes"
                name="guardianAuthorization" [showValidation]="showValidation" required>
              </app-guardian-authorizations>
              <app-anthem-authorizations
                *ngIf="carrierShortName.toLowerCase().startsWith('anthem') || carrierShortName.toLowerCase().startsWith('empire')"
                [initialPaymentType]="selectedInitialPaymentType" [authorizationNotices]="authorizationNotices"
                [census]="census" [(ngModel)]="authorizationBoxes" [showValidation]="showValidation"
                name="anthemAuthorization" required>
              </app-anthem-authorizations>
              <app-cigna-authorizations *ngIf="carrierShortName.toLowerCase() === 'cigna'"
                [authorizationNotices]="authorizationNotices" [census]="census" [showValidation]="showValidation"
                name="cignaAuthorization" required>
              </app-cigna-authorizations>
              <ng-container
                *ngIf="!(carrierShortName.toLowerCase() === 'guardian' ||  carrierShortName.toLowerCase().startsWith('anthem') || carrierShortName.toLowerCase().startsWith('empire') 
                || carrierShortName.toLowerCase().startsWith('ameritas') || carrierShortName.toLowerCase() === 'cigna')">
                <div *ngFor="let notice of authorizationNotices; let i = index;">
                  <div *ngIf="notice.hasCheckbox" class="form-check"
                    [ngClass]="{'checkbox-invalid': showValidation == true && !noticeChkboxes[i]}">
                    <input type="checkbox" class="form-check-input check-input-big" name="notice-desc+{{i}}"
                      id="notice-desc+{{i}}" [(ngModel)]='noticeChkboxes[i]' required
                      [ngClass]="{'checkbox-invalid': showValidation == true && !noticeChkboxes[i]}">
                    <label for="notice-desc+{{i}}" [ngClass]="{ 'error-label': !noticeChkboxes[i] }"><strong
                        [innerHTML]="notice.text"></strong></label>
                    <div [hidden]="!showValidation || noticeChkboxes[i] === true" class="invalid-feedback">
                      Please check box
                    </div>
                  </div>

                  <div *ngIf="!notice.hasCheckbox">
                    <span *ngIf="notice.text.length <= 1000" [innerHTML]="notice.text"></span>
                  </div>
                  <div class="pre-scrollable" *ngIf="notice.text.length > 1000" [innerHTML]="notice.text"></div>
                </div>
              </ng-container>

              <div class="form-row"
                *ngIf="!(carrierShortName.toLowerCase().startsWith('anthem') || carrierShortName.toLowerCase().startsWith('empire') || (carrierShortName.toLowerCase() === 'cigna' && (stateAbbr === 'NJ' || stateAbbr === 'VA')))">
                <div class="form-group-block col-6">
                  <label for="signature">Applicant Full Name:</label>
                  <input type="text" readonly id="signature" [(ngModel)]="signature" name="signature"
                    placeholder="Applicant Full Name" #signatureField="ngModel">
                </div>
                <div class="form-group-block col-6">
                  <label for="signatureverification"
                    [ngClass]="{ 'error-label': !signatureverificationField.valid || (signatureverificationField.value && signatureField.value && signatureverificationField.value.toLowerCase() !== signatureField.value.toLowerCase()) }">Applicant
                    Full Name (re-enter for verification):</label>
                  <input type="text" id="signatureverification" [(ngModel)]="signatureverification"
                    name="signatureverification" #signatureverificationField="ngModel"
                    placeholder="Applicant Full Name (re-enter for verification)" required
                    [ngClass]="{'input-invalid': showValidation == true && (!signatureverificationField.valid || signatureverificationField.value.toLowerCase() !== signatureField.value.toLowerCase()),
                                'input-valid': showValidation == true && (signatureverificationField.valid && (signatureverificationField.value.toLowerCase() === signatureField.value.toLowerCase()))}">
                  <div [hidden]="!showValidation || signatureverificationField.valid" class="invalid-feedback">
                    Please re-enter full name (for verification)
                  </div>
                  <div
                    [hidden]="!showValidation || !(signatureverificationField.valid && (signatureverificationField.value.toLowerCase() !== signatureField.value.toLowerCase()))"
                    class="invalid-feedback">
                    Name must match for verification
                  </div>
                </div>
                <div class="separator-left"></div>
              </div>

              <div class="form-row" *ngIf="carrierShortName.toLowerCase().startsWith('cigna')">
                <div class="application-questions text-center">
                  <p>Did you (Primary Applicant), receive assistance with your application?</p>
                  <div class="radio-group enroll-radio-group"
                    [ngClass]="{'radioBtn-invalid': showValidation === true && hasEnrollmentAssistance === undefined, 'radioBtn-valid': showValidation === true && hasEnrollmentAssistance !== undefined}">
                    <label for="hasEnrollmentAssistanceYes">
                      <input type="radio" id="hasEnrollmentAssistanceYes" [(ngModel)]="hasEnrollmentAssistance"
                        name="hasEnrollmentAssistance" [value]="true" required />Yes
                    </label>
                    <label for="hasEnrollmentAssistanceNo">
                      <input type="radio" id="hasEnrollmentAssistanceNo" [(ngModel)]="hasEnrollmentAssistance"
                        name="hasEnrollmentAssistance" [value]="false" required />No
                    </label>
                  </div>
                  <div [hidden]="!showValidation || hasEnrollmentAssistance !== undefined" class="invalid-feedback">
                    Please select one
                  </div>
                </div>
                <div *ngIf="hasEnrollmentAssistance">
                  <h4>Statement of Accountability</h4>
                  <div class="separator-left"></div>

                  <div class="form-row">
                    <div class="form-group-block col-6">
                      <label for="translatorName"
                        [ngClass]="{ 'error-label': translatorNameField.invalid }">Name:</label>
                      <input type="text" id="translatorName" [(ngModel)]="translatorName" name="translatorName"
                        #translatorNameField="ngModel" required maxlength="32" placeholder="Name"
                        [ngClass]="{ showValidation: showValidation == true }">
                      <div [hidden]="!showValidation || translatorNameField.valid" class="invalid-feedback">
                        Please enter translator name
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <p>I personally read and completed this Application form for the Primary Applicant because:</p>
                  </div>

                  <div class="form-check">
                    <input type="checkbox" class="form-check-input check-input-big"
                      [(ngModel)]="applicantCantReadEnglish" id="applicantCantReadEnglish"
                      name="applicantCantReadEnglish">

                    <label for="applicantCantReadEnglish">
                      <strong>Applicant does not read English.</strong>
                    </label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input check-input-big"
                      [(ngModel)]="applicantCantSpeakEnglish" id="applicantCantSpeakEnglish"
                      name="applicantCantSpeakEnglish">

                    <label for="applicantCantSpeakEnglish">
                      <strong>Applicant does not speak English.</strong>
                    </label>
                  </div>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input check-input-big"
                      [(ngModel)]="applicantCantWriteEnglish" id="applicantCantWriteEnglish"
                      name="applicantCantWriteEnglish">

                    <label for="applicantCantWriteEnglish">
                      <strong>Applicant does not write English.</strong>
                    </label>
                  </div>
                  <div *ngIf="stateAbbr === 'NJ' || stateAbbr === 'NM'" class="form-check">
                    <input type="checkbox" class="form-check-input check-input-big"
                      [ngClass]="{'checkbox-invalid': showValidation == true && !translateApplication }"
                      [(ngModel)]="translateApplication" id="translateApplication" name="translateApplication" required>
                    <div [hidden]="!showValidation || translateApplication === true" class="invalid-feedback">
                      Please certify you translated the contents of application.
                    </div>

                    <label for="translateApplication">
                      <strong>I personally translated the contents of this application and, to the best of my knowledge,
                        obtained and listed all the personal information disclosed by the primary applicant.</strong>
                    </label>
                  </div>
                  <div *ngIf="stateAbbr === 'NJ' || stateAbbr === 'NM'" class="form-check">
                    <input type="checkbox" class="form-check-input check-input-big"
                      [ngClass]="{'checkbox-invalid': showValidation == true && !translateConditions}"
                      [(ngModel)]="translateConditions" id="translateConditions" name="translateConditions" required>
                    <div [hidden]="!showValidation || translateConditions === true" class="invalid-feedback">
                      Please certify your translated and explained the Conditions and Agreements.
                    </div>

                    <label for="translateConditions">
                      <strong>I also personally translated and fully explained the "Conditions and
                        Agreement/Authorization
                        Section".</strong>
                    </label>
                  </div>
                  <div class="form-row">
                    <div class="form-group-block col-6">
                      <label for="translatorName"
                        [ngClass]="{ 'error-label': translatorverificationField.invalid || (translatorverificationField.value && translatorNameField.value && translatorverificationField.value.toLowerCase() !== translatorNameField.value.toLowerCase())}">Translator's
                        Signature:</label>
                      <input type="text" id="translatorverification" [(ngModel)]="translatorverification"
                        name="translatorverification" #translatorverificationField="ngModel" required
                        placeholder="Translator's Name (re-enter for verification)"
                        [ngClass]="{'input-invalid': showValidation == true && (!translatorverificationField.valid || translatorverificationField.value.toLowerCase() !== translatorNameField.value.toLowerCase()),
                              'input-valid': showValidation == true && (translatorverificationField.valid && (translatorverificationField.value.toLowerCase() === translatorNameField.value.toLowerCase()))}">
                      <div
                        [hidden]="!showValidation || !(translatorverificationField.valid && (translatorverificationField.value.toLowerCase() !== translatorNameField.value.toLowerCase()))"
                        class="invalid-feedback">
                        Translator name must match for verification
                      </div>
                    </div>
                    <div class="form-group-block col-6">
                      <label for="assistanceDate" [ngClass]="{ 'error-label': assistanceDateField.invalid }">Today's
                        Date:</label>
                      <app-date id="assistanceDate" [(ngModel)]="assistanceDate" name="assistanceDate"
                        #assistanceDateField="ngModel" required
                        [ngClass]="{ showValidationComponent: showValidation == true }">
                      </app-date>
                      <div [hidden]="!showValidation || assistanceDateField.valid" class="invalid-feedback">
                        Please enter today's date
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="form-row" *ngIf="carrierShortName.toLowerCase().startsWith('aetna')">
                <div class="form-group-block col-4">
                  <label for="citySignature" [ngClass]="{ 'error-label': citySignatureField.invalid }">City:</label>
                  <input type="text" readonly id="citySignature" [(ngModel)]="citySignature" name="citySignature"
                    #citySignatureField="ngModel" placeholder="City">
                  <div [hidden]="!showValidation || citySignatureField.valid" class="invalid-feedback">
                    Please enter city
                  </div>
                </div>
                <div class="form-group-block col-4">
                  <label for="stateSignature" [ngClass]="{ 'error-label': stateSignatureField.invalid }">State:</label>
                  <input type="text" readonly id="stateSignature" [(ngModel)]="stateSignature" name="stateSignature"
                    #stateSignatureField="ngModel" placeholder="State">
                  <div [hidden]="!showValidation || stateSignatureField.valid" class="invalid-feedback">
                    Please enter state
                  </div>
                </div>
                <div class="form-group-block col-4">
                  <label for="signatureDate" [ngClass]="{ 'error-label': signatureDateField.invalid }">
                    Date signed:</label>
                  <app-date id="signatureDate" [(ngModel)]="signatureDate" name="signatureDate"
                    #signatureDateField="ngModel" required
                    [ngClass]="{ showValidationComponent: showValidation == true }">
                  </app-date>
                  <div [hidden]="!showValidation || signatureDateField.valid" class="invalid-feedback">
                    Please enter today's date
                  </div>
                </div>
              </div>


              <div class="separator-left"></div>
              <div *ngIf="showTrustPilot()">
                <div class="col-12 has-coverage">
                  <input type="checkbox" class="form-check-input check-input-big" name="allowTrustPilot"
                    id="allowTrustPilot" [(ngModel)]="allowTrustPilot">
                  <label for="allowTrustPilot"><strong>If this box is checked then the customer will get an automatic
                      email
                      sent to
                      them asking them to
                      rate us through a service we use called TrustPilot.</strong></label>
                </div>
                <div class="separator-left"></div>
              </div>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="error">
              {{error}}
            </div>
            <br />
            <br />
            <hr />
            <div class="two-buttons-block">
              <button type="submit" class="enroll button-right" [disabled]="loading"><span>{{!loading ? 'Submit':
                  'Please wait'}}</span></button>
              <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
            </div>
            <br />
            <br />
          </ng-container>
        </form>
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>