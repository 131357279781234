import { AccountType } from './account-type.enum';
import { IPaymentInfo } from './paymentInfo';
import { Address } from './address.model';

export class EFT implements IPaymentInfo {
    public payeeName!: string;
    public financialInstitution!: string;
    public accountType!: AccountType;
    public accountUsage!: number;
    public routingNumber!: string;
    public accountNumber!: string;
    public authorizedSignature!: string;
    public ongoingPaymentDay!: number;
    public billingAddress!: Address;
    public initialPaymentDay!: string;
    public payeeOwnerRelationship!: string;
    public payeeMemberRelationship!: string;
}
