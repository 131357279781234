import { Component, OnInit } from '@angular/core';
import { AffiliateService } from '../shared/services/affiliate.service';
import { Affiliate } from '../shared/models/affiliate.model';
import { Observable } from 'rxjs';
import { ExchangeCompany } from '../shared/models/exchange-company.model';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { Campaign } from '../shared/models/campaign.model';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-support-widget',
    templateUrl: './support-widget.component.html',
    styleUrls: ['./support-widget.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe]
})
export class SupportWidgetComponent implements OnInit {

  public affiliate!: Observable<Affiliate>;
  public defaultAffiliate: Affiliate;
  public exchangeCompany!: ExchangeCompany;
  public campaign!: Campaign;

  constructor(private affiliateService: AffiliateService,
    private enrollmentService: EnrollmentService) {
      this.defaultAffiliate = this.affiliateService.defaultAffiliate;
      this.affiliateService.affiliate.subscribe(x => {this.affiliate = x;
        this.affiliate.subscribe(x => { if (x.availableCampaigns != null && x.availableCampaigns.findIndex(x => x.codeName === this.enrollmentService.campaignName) >= 0 )
          this.campaign = x.availableCampaigns.find(x => x.codeName === this.enrollmentService.campaignName)! });
        });
      this.enrollmentService.exchCompany.subscribe(x => this.exchangeCompany = x!);      
  }

  ngOnInit() {
  }

}
