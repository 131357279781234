import { Component, OnInit } from '@angular/core';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { Observable } from 'rxjs';
import { Affiliate } from '../shared/models/affiliate.model';
import { AffiliateService } from '../shared/services/affiliate.service';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-disclaimers',
    templateUrl: './disclaimers.component.html',
    styleUrls: ['./disclaimers.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe]
})
export class DisclaimersComponent implements OnInit {
  public disclaimers!: string;
  public affiliate!: Observable<Affiliate>;

  constructor(private enrollmentService: EnrollmentService,
    private affiliateService: AffiliateService
  ) {
    this.affiliateService.affiliate.subscribe(x => this.affiliate = x);
    if (this.enrollmentService.plan) {
      this.disclaimers = this.enrollmentService.plan.carrier.disclaimers;
    }
  }

  ngOnInit() {
  }

}
