<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
    <div class="container">

        <app-breadcrumbs [index]="3"></app-breadcrumbs>

        <div class="plan-wrapper">
            <aside class="side-widget no-mobile">
                <app-plan-widget></app-plan-widget>
                <app-support-widget></app-support-widget>
            </aside>
            <section class="application-content">
                <h1>Replacement Coverage Information</h1>
                <div class="separator-left"></div>
                <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">

                    <div class="application-questions text-center">
                        <h4>Do/Did you, or anyone currently applying for Dental coverage, currently have or previously
                            had
                            dental coverage?
                        </h4>
                        <div class="radio-group enroll-radio-group"
                            [ngClass]="{'radioBtn-invalid': showValidation === true && hasCoverage === undefined, 'radioBtn-valid': showValidation === true && hasCoverage !== undefined}">
                            <label for="hasCoverageYes">
                                <input type="radio" id="hasCoverageYes" [(ngModel)]="hasCoverage" name="hasCoverage"
                                    [value]="true" required />Yes
                            </label>
                            <label for="hasCoverageNo">
                                <input type="radio" id="hasCoverageNo" [(ngModel)]="hasCoverage" name="hasCoverage"
                                    [value]="false" required />No
                            </label>
                        </div>
                        <div [hidden]="!showValidation || hasCoverage !== undefined" class="invalid-feedback">
                            Please select one
                        </div>
                    </div>


                    <div *ngIf="hasCoverage">
                        <h4>Please provide the following:</h4>
                        <div [hidden]="!showValidation || coverageInfoProvided()" class="invalid-feedback">
                            Please provide coverage information
                        </div>
                        <div class="separator-left"></div>

                        <div *ngFor="let member of anthemExistingCoverages; let i = index;">

                            <div class="has-coverage col-4">
                                <input type="checkbox" [(ngModel)]="member.hasCoverage" id="hasCoverage{{i}}"
                                    name="hasCoverage{{i}}" class="form-check-input check-input-big">

                                <label for="hasCoverage{{i}}">
                                    <strong>{{member.familyMember.firstName | titlecase}}
                                        ({{member.familyMember.birthdate | date : 'MM/dd/yyyy'}})</strong>
                                </label>
                            </div>
                            <div *ngIf="member.hasCoverage">
                                <div class="form-row">
                                    <div class="form-group-block col-4">
                                        <label for="policyId{{i}}"
                                            [ngClass]="{ 'error-label': policyId.invalid }">Policy ID No:</label>
                                        <input type="text" id="policyId{{i}}" [(ngModel)]="member.policyId"
                                            name="policyId{{i}}" #policyId="ngModel" required maxlength="32"
                                            placeholder="Policy ID No"
                                            [ngClass]="{ showValidation: showValidation == true }">
                                        <div [hidden]="!showValidation || policyId.valid" class="invalid-feedback">
                                            Please enter Policy ID number
                                        </div>
                                    </div>
                                    <div class="form-group-block col-4">
                                        <label for="insurerName{{i}}"
                                            [ngClass]="{ 'error-label': insurerNameField.invalid }">Insurer
                                            name:</label>
                                        <input type="text" id="insurerName{{i}}" [(ngModel)]="member.insurerName"
                                            name="insurerName{{i}}" #insurerNameField="ngModel" required maxlength="32"
                                            placeholder="Insurer name"
                                            [ngClass]="{ showValidation: showValidation == true }">
                                        <div [hidden]="!showValidation || insurerNameField.valid"
                                            class="invalid-feedback">
                                            Please enter insurer name
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group-block col-4">
                                        <label for="effectiveDate{{i}}"
                                            [ngClass]="{ 'error-label': effectiveDateField.invalid }">Effective date of
                                            coverage:</label>
                                        <app-date id="effectiveDate{{i}}" [(ngModel)]="member.effectiveDate"
                                            name="effectiveDate{{i}}" #effectiveDateField="ngModel" required
                                            [ngClass]="{ showValidationComponent: showValidation == true }">
                                        </app-date>
                                        <div [hidden]="!showValidation || effectiveDateField.valid"
                                            class="invalid-feedback">
                                            Please enter effective date
                                        </div>
                                    </div>
                                    <div class="form-group-block col-4">
                                        <label for="lastDateOfCoverage{{i}}">Last date of coverage:</label>
                                        <app-date id="lastDateOfCoverage{{i}}" [(ngModel)]="member.lastDateOfCoverage"
                                            name="lastDateOfCoverage{{i}}">
                                        </app-date>
                                    </div>
                                </div>

                                <br />

                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input check-input-big"
                                        [(ngModel)]="member.hadOrthoCoverage" id="hadOrthoCoverage{{i}}"
                                        name="hadOrthoCoverage{{i}}">

                                    <label for="hadOrthoCoverage{{i}}">
                                        I previously had orthodontia coverage
                                    </label>
                                </div>
                                <ng-container *ngIf="state === 'CO'">
                                    <div class="form-row">
                                        <div class="col-12 block-center">
                                            <h5>Will you be terminating this coverage if approved for Anthem
                                                coverage?
                                            </h5>
                                            <div class="radio-group enroll-radio-group"
                                                [ngClass]="{'radioBtn-invalid': showValidation === true && member.willTerminate === undefined, 'radioBtn-valid': showValidation === true && member.willTerminate !== undefined}">
                                                <label class="btn btn-input" for="willTerminateYes">
                                                    <input type="radio" id="willTerminateYes"
                                                        [(ngModel)]="member.willTerminate" name="willTerminate"
                                                        [value]="true" required />Yes
                                                </label>
                                                <label class="btn btn-input" for="willTerminateNo">
                                                    <input type="radio" id="willTerminateNo"
                                                        [(ngModel)]="member.willTerminate" name="willTerminate"
                                                        [value]="false" required />No
                                                </label>
                                            </div>
                                            <div [hidden]="!showValidation || member.willTerminate !== undefined"
                                                class="invalid-feedback">
                                                Please select one
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row" *ngIf="member.willTerminate">
                                        <div class="col-12 block-center">
                                            <h5>Do you intend to replace your current dental insurance with this
                                                policy
                                                (contract)?</h5>
                                            <div class="radio-group enroll-radio-group"
                                                [ngClass]="{'radioBtn-invalid': showValidation === true && member.willReplace === undefined, 'radioBtn-valid': showValidation === true && member.willReplace !== undefined}">
                                                <label class="btn btn-input" for="willReplaceYes">
                                                    <input type="radio" id="willReplaceYes"
                                                        [(ngModel)]="member.willReplace" name="willReplace"
                                                        [value]="true" required />Yes
                                                </label>
                                                <label class="btn btn-input" for="willReplaceNo">
                                                    <input type="radio" id="willReplaceNo"
                                                        [(ngModel)]="member.willReplace" name="willReplace"
                                                        [value]="false" required />No
                                                </label>
                                            </div>
                                            <div [hidden]="!showValidation || member.willReplace !== undefined"
                                                class="invalid-feedback">
                                                Please select one
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row" *ngIf="member.willReplace">
                                        <div class="col-12">
                                            <br>
                                            <p><strong>Please read the following: </strong>According to the
                                                information
                                                furnished by you, you intend to lapse or otherwise terminate your
                                                present
                                                policy and replace it with a policy to be issued by Anthem Blue
                                                Cross and Blue Shield or HMO Colorado. Your new policy will provide
                                                10
                                                days
                                                within which you may decide without cost whether you desire to keep
                                                the
                                                policy. You should review this new coverage carefully. Compare
                                                it with all dental coverage you now have. If, after due
                                                consideration,
                                                you
                                                find the purchase of this dental coverage is a wise decision you
                                                should
                                                evaluate the need for other dental coverage you have that may
                                                duplicate this policy.</p>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="state === 'VA'">
                                    <div class="form-row">
                                        <div class="col-12 block-center">
                                            <h5>Will you be replacing this dental coverage if approved for Anthem's
                                                coverage?</h5>
                                            <div class="radio-group enroll-radio-group"
                                                [ngClass]="{'radioBtn-invalid': showValidation === true && member.willReplace === undefined, 'radioBtn-valid': showValidation === true && member.willReplace !== undefined}">
                                                <label class="btn btn-input" for="willReplaceYes">
                                                    <input type="radio" id="willReplaceYes"
                                                        [(ngModel)]="member.willReplace" name="willReplaceYes"
                                                        [value]="true" required />Yes
                                                </label>
                                                <label class="btn btn-input" for="willReplaceNo">
                                                    <input type="radio" id="willReplaceNo"
                                                        [(ngModel)]="member.willReplace" name="willReplaceNo"
                                                        [value]="false" required />No
                                                </label>
                                            </div>
                                            <div [hidden]="!showValidation || member.willReplace !== undefined"
                                                class="invalid-feedback">
                                                Please select one
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-row" *ngIf="member.willReplace">
                                        <div class="col-12 block-center">
                                            <h5>What is the termination date?</h5>
                                            <div class="btn-group form-field pm">
                                                <app-date id="terminationDateOfCoverage{{i}}"
                                                    [(ngModel)]="member.lastDateOfCoverage"
                                                    name="terminationDateOfCoverage{{i}}"
                                                    #terminationDateOfCoverageField="ngModel" required
                                                    [ngClass]="{ showValidationComponent: showValidation == true }">
                                                </app-date>
                                                <br>
                                                <div [hidden]="!showValidation || terminationDateOfCoverageField.valid"
                                                    class="invalid-feedback">
                                                    Please enter termination date
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div class="col-12">
                                            <br>
                                            <p><strong>Note: </strong>You cannot be covered by more than one Anthem
                                                individual dental policy at the same time.</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <br />
                    <br />
                    <hr />
                    <div class="two-buttons-block">
                        <button type="submit" class="enroll button-right"><span>Continue</span></button>
                        <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
                    </div>

                </form>
                <app-disclaimers></app-disclaimers>
            </section>
        </div>

    </div>
</main>