import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Affiliate } from '../shared/models/affiliate.model';
import { AffiliateService } from '../shared/services/affiliate.service';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, DatePipe]
})
export class FooterComponent implements OnInit {
  public quotesWebUrl!: string;
  public currentDate = new Date();
  private readonly defaultLogo = 'assets/img/svg/new-logo.svg';
  private readonly poweredByLogo = 'assets/img/powered-by-dicom-logo-small.png'
  
  public logoUrl$!: Observable<string | undefined | null>;
  public phone$!: Observable<string | undefined>;
  public showPages$: Observable<boolean>;
  private campaignSourceName!: string;
  public quotesWebUrl$!: Observable<string | undefined | null>;
  public agentId!: string;
  

  constructor(private affiliateService: AffiliateService, private enrollmentService: EnrollmentService) {
    this.phone$ = this.affiliateService.affiliate$.pipe(map(aff => {
      return (aff?.availableCampaigns != null && aff.availableCampaigns.findIndex(x => x.codeName === this.enrollmentService.campaignName) >= 0) ?
        aff.availableCampaigns.find(x => x.codeName === this.enrollmentService.campaignName)?.phone : aff?.phone
    }));

    this.quotesWebUrl$ = this.affiliateService.affiliate$.pipe(map(aff => this.enrollmentService.getQuotesUrl(aff!.isPrerendered)));
    
    this.logoUrl$ =
    this.affiliateService.affiliate$.pipe(map(aff => {      
      return this.enrollmentService.getQuotesUrl(aff!.isPrerendered) + '/'+ (aff!.isPrerendered ? this.defaultLogo : this.poweredByLogo);
    }));
    this.showPages$ = this.affiliateService.affiliate$.pipe(map(aff => { return aff?.isPrerendered ? true : false; }));
    this.enrollmentService.agentId.subscribe((x: any) => this.agentId = x);
  }

  ngOnInit() {
  }
  getAffiliateUtmSuffix(): Observable<string> {
    return this.affiliateService.affiliate$.pipe(map(affiliate => {
      return this.getAffParameters(affiliate);
    }));
  }

  getAffParameters(affiliate: Affiliate | null): string {
    return (affiliate!.affiliateId === 1019) ? '' : ('?utm_campaign=' + affiliate!.affiliateId + '&utm_source=' + this.campaignSourceName + (this.agentId ? ('&utm_term=' + this.agentId) : ''))
  }

}
