<div *ngIf="loading" class="loader"></div>
<main id="application" class="main-wrapper">
    <article *ngIf="!loading">
        <div class="col-12">
            <app-breadcrumbs [index]="1"></app-breadcrumbs>
        </div>

        <aside class="d-none d-sm-block">
            <app-plan-widget></app-plan-widget>
            <app-support-widget></app-support-widget>
        </aside>

        <section class="application-content">
            <h1>Transaction Cancelled</h1>
            <p>Your transaction was cancelled. Please click here to <a
                    [attr.href]="quotesWebUrl + '/census?requestId=' + requestId">continue shopping</a> or start a <a
                    [attr.href]="'/start?planId=' + planId + '&quoteRequestId=' + requestId">new application</a>.</p>
            <br />
        </section>
    </article>
</main>