<div *ngIf="loading" class="loader"></div>
<form (ngSubmit)="onSubmit()" #startForm="ngForm">
    <section id="application" class="main-wrapper">
        <div class="container">

            <!-- <app-breadcrumbs [index]="0"></app-breadcrumbs> -->

            <div class="plan-wrapper">
                <aside class="side-widget no-mobile">
                    <app-support-widget></app-support-widget>
                    <div class="separator"></div>
                    <!-- <div class="text-center"><a
                            [attr.href]="quotesWebUrl + '/quoting/plans/'"
                            class="button button-primary button-block"><strong>Return to Plan List</strong></a></div> -->
                    <div class="separator"></div>
                </aside>
                <section class="application-content">
                    <h1>Ready. Set. Apply!</h1>
                    <div class="separator-left"></div>
                    <h3>Almost there! Complete your application in less than five minutes.</h3>
                    <section class="plan">
                        <div class="plan-wrap">
                            <div class="plan-summary">
                                <div class="plan-info-application">
                                    <a *ngIf="enrollmentService.agent && !careington"
                                        [attr.href]="quotesWebUrl + '/quoting/' +getPlanUrl(plan)+ '?requestId=' + requestId"
                                        target="_blank">
                                        <img src="{{plan.carrier.logoUrl}}" width="150" height="55"
                                            class="logo-150"></a>
                                    <img *ngIf="!enrollmentService.agent || careington" src="{{plan.carrier.logoUrl}}"
                                        width="150" height="55" class="logo-150">

                                    <div>
                                        <h4 class="plan-name">{{plan.name}} <span
                                                class="plan-type-badge">{{plan.planType.name}}</span></h4>
                                    </div>
                                    <div class="plan-buttons">
                                        <span class="premium">{{premium | currency:'USD':'symbol':'1.2-2'}}/mo.</span>
                                    </div>
                                </div>
                                <div class="separator-left"></div>

                                <div>
                                    <div class="alert alert-danger highlight" role="alert" *ngIf="startWarning">
                                        {{startWarning}}
                                    </div>
                                    <div class="alert alert-danger highlight" role="alert"
                                        *ngIf="getGuardianPaymentWarning(requestedEffectiveDate!) !== ''">
                                        {{getGuardianPaymentWarning(requestedEffectiveDate!)}}
                                    </div>
                                    <div class="alert alert-danger highlight" role="alert"
                                        *ngIf="getDeltaDentalPaymentWarning(requestedEffectiveDate!) !== ''">
                                        {{getDeltaDentalPaymentWarning(requestedEffectiveDate!)}}
                                    </div>

                                    <div class="form-group-block col-3 ef-date">
                                        <label for="ef-date-dc"><strong>Requested effective date:</strong></label>
                                        <select [(ngModel)]="requestedEffectiveDate" name="requestedEffectiveDate"
                                            id="ef-date-dc" #reqEffDate="ngModel" required
                                            [ngClass]="{ showValidation: showValidation == true }">
                                            <option *ngFor="let effectiveDate of availableEffectveDates"
                                                [ngValue]="effectiveDate">{{effectiveDate
                                                | date : 'MM/dd/yyyy' }}</option>
                                        </select>
                                    </div>
                                    <div [hidden]="!showValidation || reqEffDate.valid"
                                        class="invalid-feedback text-left">
                                        Please select requested effective date
                                    </div>

                                    <div *ngIf="startNote">
                                        <div class="separator-left"></div>
                                        <p [innerHTML]="startNote" class="plan-notes"></p>
                                    </div>

                                    <app-anthem-start-questions name="AnthemStartQuestions"
                                        *ngIf="plan?.carrier?.shortName?.toLowerCase()?.startsWith('anthem') || plan?.carrier?.shortName?.toLowerCase()?.startsWith('empire')"
                                        [(ngModel)]="anthemQuestions" [showValidation]="showValidation" required>
                                    </app-anthem-start-questions>

                                    <app-metlife-start-questions name="MetlifeStartQuestions"
                                        *ngIf="plan?.carrier?.shortName?.toLowerCase()?.startsWith('metlife')"
                                        [(ngModel)]="metlifeQuestions" [showValidation]="showValidation">
                                    </app-metlife-start-questions>
                                </div>
                            </div>

                        </div>
                    </section>
                    <hr>
                    <div class="one-button-block">
                        <button type="submit" class="enroll button-right"><span>Begin
                                Application</span></button>
                    </div>
                    <app-disclaimers></app-disclaimers>
                </section>
            </div>


        </div>
    </section>
    <br />
</form>