import { Component, OnInit, OnChanges, Input, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, FormsModule } from '@angular/forms';
import { InitialPaymentType } from '../shared/models/initialPaymentType';
import { Notice } from '../shared/models/notice.model';
import { PaymentType } from '../shared/models/paymentType';
import { Census } from '../shared/models/census.model';
import { FamilyMember } from '../shared/models/family-member.model';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-anthem-authorizations',
    templateUrl: './anthem-authorizations.component.html',
    styleUrls: ['./anthem-authorizations.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AnthemAuthorizationsComponent), multi: true }],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass, NgFor, DatePipe]
})
export class AnthemAuthorizationsComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('anthemForm', { static: true }) public anthemForm!: NgForm;

  @Input() public initialPaymentType!: InitialPaymentType;
  @Input() public authorizationNotices!: Array<Notice>;
  @Input() public census!: Census;
  @Input() public showValidation!: boolean;
  public requiredMembers!: Array<FamilyMember>;

  public paymentTypes = PaymentType;
  public paymentCheckbox!: boolean;
  public signatureCheckbox!: boolean;

  public generalPaymentAuthorization!: string;
  public ccPaymentAuthorization!: string;
  public achPaymentAuthorization!: string;
  public legalNotice!: string;
  public signatureAuthorization!: string;
  public signatureCheckboxAuthorization!: string;

  public paymentType!: PaymentType;

  public firstNameVerifications!: Array<string>;
  public firstNameVerificationsReTyped!: Array<string>;

  public lastNameVerifications!: Array<string>;
  public lastNameVerificationsReTyped!: Array<string>;

  public cityVerifications!: Array<string>;
  public stateVerifications!: Array<string | null>;

  public signatures!: Array<boolean>;

  public todaysDate!: Date;

  public statesAbbr = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: any) => { };

  constructor() {
  }

  ngOnChanges() {
    if (this.authorizationNotices) {
      this.generalPaymentAuthorization = this.authorizationNotices.find(n => n.type === 1) ? this.authorizationNotices.find(n => n.type === 1)?.text! : '';
      this.ccPaymentAuthorization = this.authorizationNotices.find(n => n.type === 2) ? this.authorizationNotices.find(n => n.type === 2)?.text! : '';
      this.achPaymentAuthorization = this.authorizationNotices.find(n => n.type === 3) ? this.authorizationNotices.find(n => n.type === 3)?.text! : '';
      this.legalNotice = this.authorizationNotices.find(n => n.type === 4) ? this.authorizationNotices.find(n => n.type === 4)?.text! : '';
      this.signatureAuthorization = this.authorizationNotices.find(n => n.type === 5) ? this.authorizationNotices.find(n => n.type === 5)?.text! : '';
      this.signatureCheckboxAuthorization = this.authorizationNotices.find(n => n.type === 6) ? this.authorizationNotices.find(n => n.type === 6)?.text! : '';
    }
    if (this.initialPaymentType) {
      this.paymentType = this.initialPaymentType.paymentType;
    }
    //this.paymentCheckbox = false;
    //this.signatureCheckbox = false;
  }


  ngOnInit() {

    this.todaysDate = new Date();
    this.requiredMembers = this.census.familyMembers.filter(x =>
      x.familyRelationship === FamilyRelationship.APPLICANT ||
      x.familyRelationship === FamilyRelationship.SPOUSE ||
      this.is18old(x));

    this.firstNameVerifications = new Array<string>(this.requiredMembers.length);
    this.firstNameVerificationsReTyped = new Array<string>(this.requiredMembers.length);

    this.lastNameVerifications = new Array<string>(this.requiredMembers.length);
    this.lastNameVerificationsReTyped = new Array<string>(this.requiredMembers.length);

    this.cityVerifications = new Array<string>(this.requiredMembers.length);
    this.stateVerifications = new Array<string>(this.requiredMembers.length);

    this.stateVerifications.fill(null);

    this.signatures = new Array<boolean>(this.requiredMembers.length);

    this.anthemForm.valueChanges?.subscribe(x => { this.propagateChange(this.value) });
  }

  get value(): boolean | null{

    if (!this.anthemForm.valid) { return null; }
    //if (!this.paymentCheckbox) { return null; }

    let c = this.anthemForm.value['firstName' + 0];

    for (let i = 0; i < this.requiredMembers.length; i++) {
      if (
        !this.anthemForm.value['firstName' + i] ||
        !this.anthemForm.value['firstNameRetyped' + i] ||
        !this.anthemForm.value['lastName' + i] ||
        !this.anthemForm.value['lastNameRetyped' + i] ||
        this.requiredMembers[i].firstName.toLowerCase() !== this.anthemForm.value['firstName' + i].toLowerCase() ||
        this.anthemForm.value['firstName' + i].toLowerCase() !== this.anthemForm.value['firstNameRetyped' + i].toLowerCase() ||
        this.requiredMembers[i].lastName.toLowerCase() !== this.anthemForm.value['lastName' + i].toLowerCase() ||
        this.anthemForm.value['lastName' + i].toLowerCase() !== this.anthemForm.value['lastNameRetyped' + i].toLowerCase() ||
        !this.anthemForm.value['signatures' + i]) {
        return null;
      }
    }
    return true;
  }

  set value(newValue: boolean) {
    this.propagateChange(this.value);
  }

  writeValue(value: boolean): void {
    this.value = value;
  }
  registerOnChange(fn: (value: string) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  getMemberTitle(member: FamilyMember): string {
    switch (member.familyRelationship) {
      case FamilyRelationship.APPLICANT: return 'Applicant/Representative'; break;
      case FamilyRelationship.SPOUSE: return 'Spouse/Domestic Partner'; break;
      case FamilyRelationship.CHILD: return 'Child'; break;
    }
  }

  is18old(member: FamilyMember): boolean {
    let birtday = new Date(member.birthdate);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let timeDiff = Math.abs(today.getTime() - birtday.getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    return (age >= 18);
  }
}
