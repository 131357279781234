<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">

    <app-breadcrumbs [index]="1"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>
      <section class="application-content">
        <h1>Replacement Coverage Information</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">

          <div class="application-questions text-center">
            <h4>Is this plan replacing another PPO dental coverage with $1,500 or more of annual benefits ?</h4>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && coverageReplacement === undefined, 'radioBtn-valid': showValidation === true && coverageReplacement !== undefined}">
              <label for="coverageReplacementYes">
                <input type="radio" id="coverageReplacementYes" [(ngModel)]="coverageReplacement"
                  name="coverageReplacement" [value]="true" required />Yes
              </label>
              <label for="coverageReplacementNo">
                <input type="radio" id="coverageReplacementNo" [(ngModel)]="coverageReplacement"
                  name="coverageReplacement" [value]="false" required />No
              </label>
            </div>
            <div [hidden]="!showValidation || coverageReplacement !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <div *ngIf="plan.includes('No Waiting')" class="row">
            <div class="col-12" *ngIf="coverageReplacement== false">
              <br />
              <p class="text-center application-text-warning"><strong>
                  This plan requires at least 12 months of continuous fully insured prior coverage with no lapse and
                  $1,500 or more of annual benefits.</strong>
              </p>
            </div>
          </div>

          <br />
          <div *ngIf="coverageReplacement">
            <h2>Coverage Replacement Information</h2>
            <div class="separator-left"></div>

            <div class="form-row">
              <div class="form-group-block col-6">
                <label for="carrier">Insurance Company Name / Dental Plan Name:</label>
                <input type="text" id="carrier" [(ngModel)]="carrier" name="carrier" #carrierField="ngModel" required
                  maxlength="32" placeholder="Insurance Company Name"
                  [ngClass]="{ showValidation: showValidation == true }">
                <div [hidden]="!showValidation || carrierField.valid" class="invalid-feedback">
                  Please enter insurance company name
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-6">
                <label for="effectiveDate">Effective Date of Previous Dental Plan<br>(Date the member began their
                  previous
                  Dental Plan -- the dental plan before enrolling into NCD Dental):</label>
                <app-date id="effectiveDate" [(ngModel)]="effectiveDate" name="effectiveDate"
                  #effectiveDateField="ngModel" required
                  [ngClass]="{ showValidationComponent: showValidation == true }">
                </app-date>
                <div [hidden]="!showValidation || effectiveDateField.valid" class="invalid-feedback">
                  Please enter effective date
                </div>
              </div>

              <div class="col-6">
                <label for="terminationDate" [ngClass]="{ 'error-label': terminationDateField.invalid }">Termination
                  Date of the Previous Dental Plan<br> (Date the member will end
                  their previous Dental Plan to begin NCD Dental):</label>
                <app-date id="terminationDate" [(ngModel)]="terminationDate" name="terminationDate"
                  #terminationDateField="ngModel" required
                  [ngClass]="{ showValidationComponent: showValidation == true }">
                </app-date>
                <div [hidden]="!showValidation || terminationDateField.valid" class="invalid-feedback">
                  Please enter termination date
                </div>
              </div>
            </div>
          </div>


          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>

        </form>

        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>