export class ProductInfo {
    public SourceCustomerNumber!: string;
    public FormNumbersCode!: string;
    public GroupCode!: string;
    public PlanCode!: string;
    public ReferralCode!: string;
    public CurrentPriorEmployerFlag!: boolean;
    public CurrentOrPriorEmployer!: string;
    public Coverage!: string;
    public CoverageStartDate!: string;
    public WaitingPeriodWaived!: boolean;
    public PriorCarrierName!: string;
    public PriorCarrierPhoneNumber!: string;
    public PolicyHolderName!: string;
    public PolicyOrGroupNumber!: string;
    public PriorCoverageStartDate!: string;
    public PriorCoverageEndDate!: string;
    public Agentcode!: string | null;
}
