<h5 *ngIf="loading">Please wait...</h5>
<iframe [src]="frameUrl" #payFrame (load)="frameLoaded()" frameborder="0" style="height: 300px; width: 100%;"></iframe>

<section class="card">
    <form #creditCardForm="ngForm">
        <div class="form-row" *ngIf="showAgentRecordingNotice">
            <div class="col-12">
                <div class="alert alert-danger highlight" role="alert">
                    <img class="stop-sign" src="/assets/images/stop.png">
                    <div>
                        AGENT: RESUME RECORDING BEFORE CONTINUING TO PROCESS THE APPLICATION.<br><br>
                        <div class="has-coverage">
                            <input type="checkbox" id="ResumedRecording" name="ResumedRecording"
                                class="form-check-input check-input-big" [(ngModel)]="resumedRecording"
                                (click)="resumed.emit(true)" [disabled]="resumedRecording">
                            <label for="ResumedRecording"><strong>Check this box ONLY after recording is
                                    resumed</strong></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="showAgentRecordingNotice === resumedRecording">
            <div *ngIf="model">
                <div class="form-row">
                    <div class="col-12 ">
                        <label for="nameCard">Name on a Card</label>
                        <input type="text" id="nameOnCard" name="nameOnCard" [(ngModel)]="model.nameOnCard"
                            #nameOnCard="ngModel" required maxlength="50" placeholder="Name on a Card">
                        <div [hidden]="nameOnCard.valid || nameOnCard.pristine" class="invalid-feedback">
                            Name on a Card is required
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 ">
                        <h5>Billing address</h5>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 ">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input check-input-big" id="useApplicantAddress"
                                [(ngModel)]="useApplicantAddress" (ngModelChange)="toggleApplicantAddress()"
                                name="useApplicantAddress">
                            <label for="useApplicantAddress"><strong>Use primary applicant's
                                    address</strong></label>
                        </div>
                    </div>
                </div>
                <div class="form-row" *ngIf="useApplicantAddress">
                    <div class="col-12 ">
                        <p><strong>{{model.billingAddress.addressLine1}}<br>
                                <span
                                    *ngIf="applicantAddress.addressLine2">{{model.billingAddress.addressLine2}}<br></span>
                                {{model.billingAddress.city}}, {{model.billingAddress.stateAbbr}}
                                {{model.billingAddress.zip}}
                            </strong></p>
                    </div>
                </div>
                <div class="form-row" *ngIf="!useApplicantAddress">
                    <div class="col-12 ">
                        <label for="addressLane1">Address Line 1</label>
                        <input type="text" id="addressLine1" [(ngModel)]="model.billingAddress.addressLine1"
                            name="addressLine1" #addressLine1="ngModel" required maxlength="128"
                            placeholder="Address Line 1">
                        <div [hidden]="addressLine1.valid || addressLine1.pristine" class="invalid-feedback">
                            Address is required
                        </div>
                    </div>
                    <div class="col-12 ">
                        <label for="addressLane2">Address Line 2</label>
                        <input type="text" id="addressLine2" [(ngModel)]="model.billingAddress.addressLine2"
                            name="addressLine2" maxlength="128" placeholder="Address Line 2">
                    </div>
                    <div class="col-4 ">
                        <label for="city">City</label>
                        <input type="text" id="city" [(ngModel)]="model.billingAddress.city" name="city" #city="ngModel"
                            required maxlength="64" placeholder="City">
                        <div [hidden]="city.valid || city.pristine" class="invalid-feedback">
                            City is required
                        </div>
                    </div>
                    <div class="col-2 ">
                        <label for="state">State</label>
                        <select [(ngModel)]="model.billingAddress.stateAbbr" id="state" name="state" class="ef-date-dc"
                            id="ef-date-dc" #state="ngModel" (ngModelChange)="onModelChange()" required>
                            <option [ngValue]="undefined" selected disabled>Select</option>
                            <option value="AK">AK</option>
                            <option value="AL">AL</option>
                            <option value="AR">AR</option>
                            <option value="AZ">AZ</option>
                            <option value="CA">CA</option>
                            <option value="CO">CO</option>
                            <option value="CT">CT</option>
                            <option value="DC">DC</option>
                            <option value="DE">DE</option>
                            <option value="FL">FL</option>
                            <option value="GA">GA</option>
                            <option value="HI">HI</option>
                            <option value="IA">IA</option>
                            <option value="ID">ID</option>
                            <option value="IL">IL</option>
                            <option value="IN">IN</option>
                            <option value="KS">KS</option>
                            <option value="KY">KY</option>
                            <option value="LA">LA</option>
                            <option value="MA">MA</option>
                            <option value="MD">MD</option>
                            <option value="ME">ME</option>
                            <option value="MI">MI</option>
                            <option value="MN">MN</option>
                            <option value="MO">MO</option>
                            <option value="MS">MS</option>
                            <option value="MT">MT</option>
                            <option value="NC">NC</option>
                            <option value="ND">ND</option>
                            <option value="NE">NE</option>
                            <option value="NH">NH</option>
                            <option value="NJ">NJ</option>
                            <option value="NM">NM</option>
                            <option value="NV">NV</option>
                            <option value="NY">NY</option>
                            <option value="OH">OH</option>
                            <option value="OK">OK</option>
                            <option value="OR">OR</option>
                            <option value="PA">PA</option>
                            <option value="RI">RI</option>
                            <option value="SC">SC</option>
                            <option value="SD">SD</option>
                            <option value="TN">TN</option>
                            <option value="TX">TX</option>
                            <option value="UT">UT</option>
                            <option value="VA">VA</option>
                            <option value="VT">VT</option>
                            <option value="WA">WA</option>
                            <option value="WI">WI</option>
                            <option value="WV">WV</option>
                            <option value="WY">WY</option>
                        </select>
                        <div [hidden]="state.valid || state.pristine" class="invalid-feedback">
                            State is required
                        </div>
                    </div>
                    <div class="col-3 ">
                        <label for="zip">Zip</label>
                        <input type="tel" pattern="[0-9]*" id="zip" name="zip" (input)="onInput($event.target)"
                            [(ngModel)]="model.billingAddress.zip" #zip="ngModel" required minlength="5" maxlength="5"
                            placeholder="Zip">
                        <div [hidden]="zip.valid || zip.pristine" class="invalid-feedback">
                            Zip code is required
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>
    </form>
</section>