import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { FamilyMember } from '../shared/models/family-member.model';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { Gender } from '../shared/models/gender.enum';
import { NgForm, FormsModule } from '@angular/forms';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { FamilyMemberComponent } from '../family-member/family-member.component';
import { NgFor, NgIf } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-census-form',
    templateUrl: './census-form.component.html',
    styleUrls: ['./census-form.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgFor, FamilyMemberComponent, NgIf, DisclaimersComponent]
})
export class CensusFormComponent implements OnInit {
  @ViewChild('censusForm', { static: true }) public censusForm!: NgForm;

  public model!: Array<FamilyMember>;
  public spouseNote!: string;
  public carrier!: string;
  public relationships = FamilyRelationship;
  public childStatusOptions!: Array<string>;
  public showValidation = false;
  public dependentOver26 = false;

  constructor(private router: Router, private enrollmentService: EnrollmentService) { }

  ngOnInit() {
    this.spouseNote = this.enrollmentService.spouseNote;
    this.model = this.enrollmentService.census.familyMembers.filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT);
    this.carrier = this.enrollmentService.plan.carrier.shortName.toLowerCase();
    this.model.forEach(member => {
      if (member.gender === Gender.UNKNOWN) {
        member.gender = null;
      }
    });
    if (this.model.length === 0) {
      this.onSubmit();
    } else if (this.carrier.startsWith('anthem') || this.carrier.startsWith('empire')) {
      this.childStatusOptions = new Array<string>();
      this.childStatusOptions.push('Child');
      this.childStatusOptions.push('Stepchild');
      this.childStatusOptions.push('Legally Adopted Child');
      if (this.enrollmentService.census.stateAbbr === 'IN') {
        this.childStatusOptions.push('Grandchild');
      } else if (this.enrollmentService.census.stateAbbr === 'VA') {
        this.childStatusOptions.push('Foster Child');
      }
    }


  }

  onSubmit() {
    if (!this.censusForm.form.valid || !this.isGenderValid() || this.isChildOver26Dependent()) {
      this.showValidation = true;
      return;
    }
    if (this.carrier.startsWith('anthem') || this.carrier.startsWith('empire')) {
      this.router.navigate(['/anthem-previous-coverage']);
    } else if (this.carrier.toLowerCase() === 'metlife') {
      this.router.navigate(['/metlife-dentist']);
    } else if (this.carrier.toLowerCase() === 'cigna') {
      this.router.navigate(['/cigna-previous-coverage']);
    } else {
      this.router.navigate(['/dentist']);
    }
  }

  onBackClick() {
    this.router.navigate(['/contact']);
  }

  askMaritalStatus(member: FamilyMember): boolean {
    // For Anthem (and Empire) ask Maritasl status only for spouse
    if ((this.carrier.startsWith('anthem') || this.carrier.startsWith('empire') || this.carrier.startsWith('guardian')) &&
      (member.familyRelationship === FamilyRelationship.SPOUSE)) {
      return true;
    } else {
      return false;
    }

  }

  addGenderX(member: FamilyMember): boolean {
    //For Anthem/Empire plans only in NY
    if (((this.carrier.startsWith('empire') || this.carrier.startsWith('anthem')) && this.enrollmentService.census.stateAbbr === 'NY') || this.carrier.startsWith('cigna'))
      return true;
    else {
      return false;
    }
  }

  isGenderValid(): boolean {
    if (this.enrollmentService.census.familyMembers.filter(member => member.gender === Gender.UNKNOWN).length > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  hideStudent(member: FamilyMember): boolean {
    if (this.carrier.startsWith('guardian') && (member.familyRelationship === FamilyRelationship.CHILD)) {
      return true;
    } else {
      return false;
    }
  }

  isChildOver26Dependent(): boolean 
  {
    if (this.carrier.startsWith('anthem') && this.enrollmentService.census.stateAbbr == 'ME' && this.enrollmentService.census.familyMembers.filter(m => m.isDisabled && m.familyRelationship === FamilyRelationship.CHILD).length > 0)
    {
      const birthDate = new Date(this.enrollmentService.census.familyMembers.find(m => m.isDisabled)!.birthdate);
      const today = new Date(this.enrollmentService.census.requestedEffectiveDate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;      
      }
      if (age >= 26)
      {
        this.dependentOver26 = true;
        return true;
      }        
      else return false;

    }   
    
    else return false;
  }


}
