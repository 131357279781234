import { Component, Input, forwardRef, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, FormsModule } from '@angular/forms';

import { CreditCard } from '../shared/models/creditCard';
import { Address } from '../shared/models/address.model';
import { NgIf, NgClass, NgFor, DecimalPipe } from '@angular/common';


@Component({
    selector: 'app-credit-card',
    templateUrl: './credit-card.component.html',
    styleUrls: ['./credit-card.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CreditCardComponent), multi: true }],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass, NgFor, DecimalPipe]
})
export class CreditCardComponent implements ControlValueAccessor, OnInit {

  public disabled = false;
  public readonly = false;

  public model!: CreditCard;
  public years!: number[];
  public useApplicantAddress!: boolean;
  public allowOngoingDaySelection!: boolean;
  public resumedRecording!: boolean;
  public signature!: string;
  @ViewChild('creditCardForm', {static: true}) public creditCardForm!: NgForm;

  @Input() applicantAddress!: Address;
  @Input() ongoingPaymentDayOptions!: Array<number>;
  @Input() allowDifferentBillingAddress!: boolean;
  @Input() showAgentRecordingNotice!: boolean;
  @Input() showSignatureField!: boolean;
  @Input() showValidation!: boolean;

  @Output() resumed = new EventEmitter<boolean>();

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: CreditCard) => { };

  constructor() { }

  ngOnInit(): void {

    this.allowOngoingDaySelection = (this.ongoingPaymentDayOptions && this.ongoingPaymentDayOptions.length > 0);

    const currentYear = (new Date()).getFullYear();
    this.years = new Array<number>();
    for (let i = 0; i < 10; i++) {
      this.years.push(currentYear + i);
    }

    this.creditCardForm.statusChanges?.subscribe(x => { this.propagateChange(this.value) });
  }


  onModelChange() {
    this.propagateChange(this.value);
  }


  get value(): CreditCard | null{
    if (!this.creditCardForm.valid || this.creditCardForm.pristine || !this.isValidExpirationDate() || !this.validateCardNumber()) {
      return null;
    } else {
      return this.model;
    }

  }

  set value(newValue: CreditCard) {
    if (newValue) {
      this.model = newValue;

      if (!this.allowDifferentBillingAddress) {
        this.model.billingAddress = this.applicantAddress;
      }
      this.propagateChange(this.value);
    }
  }

  writeValue(value: CreditCard): void {
    this.value = value;
  }
  registerOnChange(fn: (value: CreditCard) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleApplicantAddress() {
    if (this.useApplicantAddress) {
      this.model.billingAddress = this.applicantAddress;
    } else {
      this.model.billingAddress = new Address();
    }

  }

  isValidExpirationDate(): boolean {
    const expDate = new Date(this.model.expirationYear, this.model.expirationMonth - 1, 1);
    const today = new Date();

    return (today < expDate);
  }

  onInput(target: any) {
    target.value = this.stripNonDigits(target.value);
  }

  getMaxCardLength() {
    if (this.model && this.model.cardNumber && this.model.cardNumber.length > 0) {
      if (this.model.cardNumber.startsWith('34') || // American Express
        this.model.cardNumber.startsWith('37')) {
        return 15;
      } else if (this.model.cardNumber.startsWith('51') ||
        this.model.cardNumber.startsWith('52') ||
        this.model.cardNumber.startsWith('53') ||
        this.model.cardNumber.startsWith('54') ||
        this.model.cardNumber.startsWith('55') ||
        this.model.cardNumber.startsWith('2')) { // MasterCard
        return 16;
      } else if (this.model.cardNumber.startsWith('4')) { // Visa
        return 16;
      } else if (this.model.cardNumber.startsWith('6')) { // Discover
        return 16;
      } else {
        return 16;
      }
    }
    return -1;
  }

  validateCardNumber() {
    let cardType;
    if (this.model && this.model.cardNumber && this.model.cardNumber.length > 0) {
      if (this.model.cardNumber.startsWith('34') || // American Express
        this.model.cardNumber.startsWith('37')) {
        cardType = 'AmEx';
      } else if (this.model.cardNumber.startsWith('51') ||
        this.model.cardNumber.startsWith('52') ||
        this.model.cardNumber.startsWith('53') ||
        this.model.cardNumber.startsWith('54') ||
        this.model.cardNumber.startsWith('55') ||
        this.model.cardNumber.startsWith('2')) { // MasterCard
        cardType = 'MasterCard';
      } else if (this.model.cardNumber.startsWith('4')) { // Visa
        cardType = 'Visa';
      } else if (this.model.cardNumber.startsWith('6')) { // Discover
        cardType = 'Discover';
      } else {
        cardType = 'Visa';
      }
    }
    return this.checkCreditCard(this.model.cardNumber, cardType!);
  }


  private stripNonDigits(value: string): string | null{
    let newValue = '';
    for (let i = 0; i <= value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        newValue += value[i];
      }
    }
    if (newValue === '') {
      return null;
    } else {
      return newValue;
    }
  }



  /*================================================================================================*/

  /*

  This routine checks the credit card number. The following checks are made:

  1. A number has been provided
  2. The number is a right length for the card
  3. The number has an appropriate prefix for the card
  4. The number has a valid modulus 10 number check digit if required

  If the validation fails an error is reported.

  The structure of credit card formats was gleaned from a letiety of sources on the web, although the
  best is probably on Wikepedia ('Credit card number'):

    http://en.wikipedia.org/wiki/Credit_card_number

  Parameters:
              cardnumber           number on the card
              cardname             name of card as defined in the card list below

  Author:     John Gardner
  Date:       1st November 2003
  Updated:    26th Feb. 2005      Additional cards added by request
  Updated:    27th Nov. 2006      Additional cards added from Wikipedia
  Updated:    18th Jan. 2008      Additional cards added from Wikipedia
  Updated:    26th Nov. 2008      Maestro cards extended
  Updated:    19th Jun. 2009      Laser cards extended from Wikipedia
  Updated:    11th Sep. 2010      Typos removed from Diners and Solo definitions (thanks to Noe Leon)
  Updated:    10th April 2012     New matches for Maestro, Diners Enroute and Switch
  Updated:    17th October 2012   Diners Club prefix 38 not encoded

  */

  /*
     If a credit card number is invalid, an error reason is loaded into the global ccErrorNo letiable.
     This can be be used to index into the global error  string array to report the reason to the user
     if required:
     e.g. if (!checkCreditCard (number, name) alert (ccErrors(ccErrorNo);
  */



  checkCreditCard(cardnumber: string, cardname: string) {


    let ccErrorNo = 0;
    let ccErrors = new Array()

    ccErrors[0] = 'Unknown card type';
    ccErrors[1] = 'No card number provided';
    ccErrors[2] = 'Credit card number is in invalid format';
    ccErrors[3] = 'Credit card number is invalid';
    ccErrors[4] = 'Credit card number has an inappropriate number of digits';
    ccErrors[5] = 'Warning! This credit card number is associated with a scam attempt';
    // Array to hold the permitted card characteristics
    let cards = new Array();


    // If card type not found, report an error
    if (!cardname) {
      ccErrorNo = 0;
      return false;
    }

    // Define the cards we support. You may add addtional card types as follows.

    //  Name:         As in the selection box of the form - must be same as user's
    //  Length:       List of possible valid lengths of the card number for the card
    //  prefixes:     List of possible prefixes for the card
    //  checkdigit:   Boolean to say whether there is a check digit

    cards[0] = {
      name: 'Visa',
      length: '13,16',
      prefixes: '4',
      checkdigit: true
    };
    cards[1] = {
      name: 'MasterCard',
      length: '16',
      prefixes: '51,52,53,54,55',
      checkdigit: true
    };
    cards[2] = {
      name: 'DinersClub',
      length: '14,16',
      prefixes: '36,38,54,55',
      checkdigit: true
    };
    cards[3] = {
      name: 'CarteBlanche',
      length: '14',
      prefixes: '300,301,302,303,304,305',
      checkdigit: true
    };
    cards[4] = {
      name: 'AmEx',
      length: '15',
      prefixes: '34,37',
      checkdigit: true
    };
    cards[5] = {
      name: 'Discover',
      length: '16',
      prefixes: '6011,622,64,65',
      checkdigit: true
    };
    cards[6] = {
      name: 'JCB',
      length: '16',
      prefixes: '35',
      checkdigit: true
    };
    cards[7] = {
      name: 'enRoute',
      length: '15',
      prefixes: '2014,2149',
      checkdigit: true
    };
    cards[8] = {
      name: 'Solo',
      length: '16,18,19',
      prefixes: '6334,6767',
      checkdigit: true
    };
    cards[9] = {
      name: 'Switch',
      length: '16,18,19',
      prefixes: '4903,4905,4911,4936,564182,633110,6333,6759',
      checkdigit: true
    };
    cards[10] = {
      name: 'Maestro',
      length: '12,13,14,15,16,18,19',
      prefixes: '5018,5020,5038,6304,6759,6761,6762,6763',
      checkdigit: true
    };
    cards[11] = {
      name: 'VisaElectron',
      length: '16',
      prefixes: '4026,417500,4508,4844,4913,4917',
      checkdigit: true
    };
    cards[12] = {
      name: 'LaserCard',
      length: '16,17,18,19',
      prefixes: '6304,6706,6771,6709',
      checkdigit: true
    };


    // Establish card type
    let cardType = -1;
    for (let i = 0; i < cards.length; i++) {

      // See if it is this card (ignoring the case of the string)
      if (cardname.toLowerCase() === cards[i].name.toLowerCase()) {
        cardType = i;
        break;
      }
    }

    // If card type not found, report an error
    if (cardType === -1) {
      ccErrorNo = 0;
      return false;
    }

    // Ensure that the user has provided a credit card number
    if (cardnumber.length === 0) {
      ccErrorNo = 1;
      return false;
    }

    // Now remove any spaces from the credit card number
    cardnumber = cardnumber.replace(/\s/g, '');

    // Check that the number is numeric
    let cardNo = cardnumber
    let cardexp = /^[0-9]{13,19}$/;
    if (!cardexp.exec(cardNo)) {
      ccErrorNo = 2;
      return false;
    }

    // Now check the modulus 10 check digit - if required
    if (cards[cardType].checkdigit) {
      let checksum = 0;                                  // running checksum total
      let mychar = '';                                   // next char to process
      let j = 1;                                         // takes value of 1 or 2

      // Process each digit one by one starting at the right
      let calc;
      for (let i = cardNo.length - 1; i >= 0; i--) {

        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(cardNo.charAt(i)) * j;

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
          checksum = checksum + 1;
          calc = calc - 10;
        }

        // Add the units element to the checksum total
        checksum = checksum + calc;

        // Switch the value of j
        if (j === 1) { j = 2 } else { j = 1 };
      }

      // All done - if checksum is divisible by 10, it is a valid modulus 10.
      // If not, report an error.
      if (checksum % 10 !== 0) {
        ccErrorNo = 3;
        return false;
      }
    }

    // Check it's not a spam number
    if (cardNo === '5490997771092064') {
      ccErrorNo = 5;
      return false;
    }

    // The following are the card-specific checks we undertake.
    let LengthValid = false;
    let PrefixValid = false;
    let undefined;

    // We use these for holding the valid lengths and prefixes of a card type
    let prefix = new Array();
    let lengths = new Array();

    // Load an array with the valid prefixes for this card
    prefix = cards[cardType].prefixes.split(',');

    // Now see if any of them match what we have in the card number
    for (let i = 0; i < prefix.length; i++) {
      let exp = new RegExp('^' + prefix[i]);
      if (exp.test(cardNo)) { PrefixValid = true; }
    }

    // If it isn't a valid prefix there's no point at looking at the length
    if (!PrefixValid) {
      ccErrorNo = 3;
      return false;
    }

    // See if the length is valid for this card
    lengths = cards[cardType].length.split(',').map(Number);
    for (let j = 0; j < lengths.length; j++) {
      if (cardNo.length === lengths[j]) { LengthValid = true; }
    }

    // See if all is OK by seeing if the length was valid. We only check the length if all else was
    // hunky dory.
    if (!LengthValid) {
      ccErrorNo = 4;
      return false;
    };

    // The credit card is in the required format.
    return true;
  }

  /*================================================================================================*/

}
