<main id="application" class="main-wrapper">
  <div class="container">

    <app-breadcrumbs [index]="3"></app-breadcrumbs>

    <div class="plan-wrapper">

      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>

      <section class="application-content">
        <h1>Find Your Dentist</h1>
        <div class="separator-left"></div>
        <div *ngIf="dentistSearchNote" [innerHTML]="dentistSearchNote"></div>
        <br>
        <form (ngSubmit)="onSubmit()" #dentistForm="ngForm">
          <div class="form-row">
            <p *ngIf="isCDN">Please select your Primary Care Dentist. All family members MUST use the same office.
              Search for a dentist by name or location using the "Dentist Search" button, then enter their Office ID in
              the field below.</p>
            <div class="col-6">
              <a *ngIf="dentistSearchUrl" href="{{dentistSearchUrl}}" target="_blank"
                class="button button-medium button-outline-w-corner">Dentist Search</a>
              <hr>
            </div>
          </div>
          <div *ngIf="applicant">

            <label for="dentist">{{getFullName(applicant)}}:</label>

            <app-dentist [(ngModel)]="applicant.dentist" [askFacilityId]="askFacilityId"
              [askFacilityName]="askFacilityName" [askDentistId]="askDentistId" [askDentistName]="askDentistName"
              [facilityIdTitle]="facilityIdTitle" [facilityIdMaxLength]="facilityIdMaxLength"
              [facilityIdOnlyDigits]="facilityIdOnlyDigits" name="dentist" required
              [showValidation]="showValidation"></app-dentist>

          </div>
          <ng-container *ngIf="otherMembers">
            <div *ngFor="let member of otherMembers; let i = index;">
              <hr>
              <label for="dentist + {{i}}">{{getFullName(member)}}:</label>
              <app-dentist [(ngModel)]="member.dentist" [askFacilityId]="askFacilityId"
                [askFacilityName]="askFacilityName" [askDentistId]="askDentistId" [askDentistName]="askDentistName"
                name="dentist + {{i}}" required [showValidation]="showValidation"></app-dentist>

            </div>
          </ng-container>
          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>
        </form>
        <br />
        <br />
        <app-disclaimers></app-disclaimers>
      </section>
    </div>

  </div>
</main>