import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { ReplacementCoverageProduct } from '../shared/models/replacement-coverage-product.enum';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { DateComponent } from '../date/date.component';
import { NgIf, NgClass } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-existing-coverage-humana',
    templateUrl: './existing-coverage-humana-exchange.component.html',
    styleUrls: ['./existing-coverage-humana-exchange.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgIf, NgClass, DateComponent, DisclaimersComponent]
})
export class ExistingCoverageHumanaExchangeComponent implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;
  
  public todayDate!: string;
  public coverageReplacement!: boolean;
  public carrier!: string;
  public policyNumber!: string;
  public effectiveDate!: string;
  public terminationDate!: string;
  public plan!: string;
  public showValidation = false;
  public coverageTypeOptions = ReplacementCoverageProduct;
  public coverageType!: string;
  constructor(private router: Router, private enrollmentService: EnrollmentService) { }

  ngOnInit(): void {
    const availableDate = new Date();
    this.todayDate = availableDate.toLocaleDateString('en-US');
    this.plan = this.enrollmentService.plan.name;
    this.coverageReplacement = this.enrollmentService.existingCoverageCoverageReplacement;
    this.carrier = this.enrollmentService.existingCoverageCarrier;
    this.policyNumber = this.enrollmentService.existingCoveragePolicyNumber;    
    this.terminationDate = this.enrollmentService.existingCoverageTerminationDate;
    this.coverageType = this.enrollmentService.existingCoverageProduct;
  }

 onSubmit() {
    if (!this.existingCoverageForm.form.valid || (!this.coverageReplacement && this.plan.includes('No Waiting')) ) {
      this.showValidation = true;
      return;
    }
     this.enrollmentService.existingCoverageCoverageReplacement = this.coverageReplacement;
     this.enrollmentService.existingCoverageCarrier = this.carrier;
     this.enrollmentService.existingCoveragePolicyNumber = this.policyNumber;
     this.enrollmentService.existingCoverageTerminationDate = this.terminationDate;
     this.enrollmentService.existingCoverageProduct = this.coverageType;    
    this.router.navigate(['/contact']);
  }

  onBackClick() {
    this.router.navigate(['/applicant']);
  }

  getMinTermDate(): string{    

      const addDays = (date: Date, days: number): Date => {
        let result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
      };

      let reqDate = new Date(this.enrollmentService.census.requestedEffectiveDate);
      let termDate = new Date(this.terminationDate);
      let diff = reqDate.getTime() - termDate.getTime();
      if ( Math.round(diff / (1000 * 60 * 60 * 24)) > 63) {
       const date: Date = new Date(this.enrollmentService.census.requestedEffectiveDate);
        const result: Date = addDays(date, 63); 
        return result.toDateString();
      }
      else return this.terminationDate;
    }
}
