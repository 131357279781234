import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, NgIf, NgFor, CurrencyPipe } from '@angular/common';

import { EnrollmentService } from '../shared/services/enrollment.service';
import { Plan } from '../shared/models/plan';
import { Frequency } from '../shared/models/frequency';
import { PaymentFrequency } from '../shared/models/paymentFrequency';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { MetlifeService } from '../shared/services/metlife.service';
import { EnrollResponse } from '../metlife/models/enrollResponse.model';
import { AffiliateService } from '../shared/services/affiliate.service';
import { StateSpecificQuestions } from '../metlife/models/state-specific-questions.model';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-payment-options-form',
    templateUrl: './payment-options-form.component.html',
    styleUrls: ['./payment-options-form.component.scss'],
    standalone: true,
    imports: [NgIf, BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, NgFor, DisclaimersComponent, CurrencyPipe]
})
export class PaymentOptionsFormComponent implements OnInit {
  //@HostBinding('class.wrap') wrap = true;

  public model!: Plan;
  public paymentFrequencies!: Array<PaymentFrequency>;
  public cancellationsDescription!: string;
  public paymentNotes!: string;
  public errorMessage!: string | null;
  public loading!: boolean;
  public frequencies = Frequency;
  public sessionId!: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private enrollmentService: EnrollmentService,
    private location: Location
    , private metlifeService: MetlifeService, private affiliateService: AffiliateService,
  ) {

    // load SessionId from MetLife

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['SessionId'] != null) {



        this.loading = true;
        this.sessionId = params['SessionId'];

        this.enrollmentService.loadStage(this.sessionId).subscribe(
          enrollment => {
            this.enrollmentService.planId = enrollment.planId;
            this.enrollmentService.requestId = enrollment.requestId;
            this.enrollmentService.premium = enrollment.premium;
            this.enrollmentService.totalInitialPaymentAmount = enrollment.totalInitialPaymentAmount;
            this.enrollmentService.totalOngoingPaymentAmount = enrollment.totalOngoingPaymentAmount;
            this.enrollmentService.selectedPaymentFrequency = enrollment.selectedPaymentFrequency;
            this.enrollmentService.existingCoverageHasCoverage = enrollment.existingCoverageHasCoverage;
            this.enrollmentService.existingCoverageCoverageReplacement = enrollment.existingCoverageCoverageReplacement;
            this.enrollmentService.existingCoverageCarrier = enrollment.existingCoverageCarrier;
            this.enrollmentService.existingCoveragePhone = enrollment.existingCoveragePhone;
            this.enrollmentService.existingCoveragePolicyNumber = enrollment.existingCoveragePolicyNumber;
            this.enrollmentService.existingCoverageEffectiveDate = enrollment.existingCoverageEffectiveDate;
            this.enrollmentService.census = enrollment.census;
            this.enrollmentService.address = enrollment.address;

            if (enrollment.stateSpecificQuestions) {
              this.metlifeService.stateSpecifics = new StateSpecificQuestions();
              this.metlifeService.stateSpecifics.PreviousCoverage = enrollment.stateSpecificQuestions.previousCoverage;
              this.metlifeService.stateSpecifics.Duplication = enrollment.stateSpecificQuestions.duplication;
              this.metlifeService.stateSpecifics.PreviousCoverageCompany = enrollment.stateSpecificQuestions.previousCoverageCompany;
              this.metlifeService.stateSpecifics.PreviousCoveragePolicy = enrollment.stateSpecificQuestions.previousCoveragePolicy;
              this.metlifeService.stateSpecifics.Lapse = enrollment.stateSpecificQuestions.lapse;
              this.metlifeService.stateSpecifics.OtherInsurance = enrollment.stateSpecificQuestions.otherInsurance;
              this.metlifeService.stateSpecifics.PreviousCoverageCompany2 = enrollment.stateSpecificQuestions.previousCoverageCompany2;
              this.metlifeService.stateSpecifics.PreviousCoveragePolicy2 = enrollment.stateSpecificQuestions.previousCoveragePolicy2;
              this.metlifeService.stateSpecifics.SLMB = enrollment.stateSpecificQuestions.slmb;
              this.metlifeService.stateSpecifics.Medicare = enrollment.stateSpecificQuestions.medicare;
              this.metlifeService.stateSpecifics.Medicaid = enrollment.stateSpecificQuestions.medicaid;
            }

            this.enrollmentService.getPlanAppInfo().subscribe(
              planAppInfo => {
                this.enrollmentService.plan = planAppInfo.plan;
                this.enrollmentService.createdOn = planAppInfo.createdOn;
                this.enrollmentService.paymentFrequencies = planAppInfo.paymentFrequencies;
                this.enrollmentService.selectedPaymentFrequency = this.enrollmentService.paymentFrequencies[enrollment.selectedPaymentFrequency];

                this.enrollmentService.cancellationsDescription = planAppInfo.cancellationsDescription;
                this.enrollmentService.paymentNotes = planAppInfo.paymentNotes;

                this.enrollmentService.enrollmentNotices = planAppInfo.enrollmentNotices;

                this.enrollmentService.requestApplicantSSN = planAppInfo.requestApplicantSSN;
                this.enrollmentService.requestCensusSSN = planAppInfo.requestCensusSSN;

                this.enrollmentService.requestApplicantDentistInfo = planAppInfo.requestApplicantDentistInfo;
                this.enrollmentService.requestCensusDentistInfo = planAppInfo.requestCensusDentistInfo;
                this.enrollmentService.requestDentistId = planAppInfo.requestDentistId;
                this.enrollmentService.requestDentistName = planAppInfo.requestDentistName;
                this.enrollmentService.requestFacilityId = planAppInfo.requestFacilityId;
                this.enrollmentService.requestFacilityName = planAppInfo.requestFacilityName;

                this.enrollmentService.dentistSearchUrl = planAppInfo.dentistSearchUrl;
                this.enrollmentService.dentistSearchNote = planAppInfo.dentistSearchNote;

                this.enrollmentService.initialPaymentsProcessingDescriptions = planAppInfo.initialPaymentsProcessingDescriptions;
                this.enrollmentService.ongoingPaymentsProcessingDescriptions = planAppInfo.ongoingPaymentsProcessingDescriptions;

                this.enrollmentService.authorizationNotices = planAppInfo.authorizationNotices;
                this.enrollmentService.ongoingPaymentDayOptions = planAppInfo.ongoingPaymentDayOptions;
                this.enrollmentService.allowDifferentBillingAddress = planAppInfo.allowDifferentBillingAddress;
                this.enrollmentService.spouseNote = planAppInfo.spouseNote;
                this.enrollmentService.agent = planAppInfo.agent;
                this.enrollmentService.agentId.next(this.enrollmentService.agent);

                if (!isNaN(planAppInfo.affiliateId)) {
                  this.affiliateService.affiliate.next(this.affiliateService.getAffiliateFromAPI(planAppInfo.affiliateId));
                  this.enrollmentService.affiliateId = planAppInfo.affiliateId;
                } else {
                  this.enrollmentService.affiliateId = this.affiliateService.defaultAffiliate.affiliateId;
                }
                this.init();
                this.loading = false;

              }
            );
          }

        );

      } else {
        this.init();
      }

    });

  }

  init() {
    this.model = this.enrollmentService.plan;
    this.paymentFrequencies = this.enrollmentService.paymentFrequencies;
    this.cancellationsDescription = this.enrollmentService.cancellationsDescription;
    this.paymentNotes = this.enrollmentService.paymentNotes;
  }

  ngOnInit() {

  }

  // calculate total amount of all fees for each frequency
  public getTotals(): Array<number> {
    let totals = new Array<number>();
    for (let i = 0; i < this.enrollmentService.paymentFrequencies.length; i++) {
      totals[i] = this.getTotalInitalPaymemnt(i);
    }

    return totals;
  }

  public onSelectPaymentFreqBtnClick(paymentFrequency: PaymentFrequency, index: number) {
    this.errorMessage = null;
    this.enrollmentService.selectedPaymentFrequency = paymentFrequency;
    this.enrollmentService.totalInitialPaymentAmount = this.getTotalInitalPaymemnt(index);
    this.enrollmentService.totalOngoingPaymentAmount = this.getTotalOngoingPaymemnt(index);
    if (this.model.carrier.shortName.toLowerCase() === 'metlife') {
      this.loading = true;

      const enrollmentObj = this.metlifeService.generateEnrollment();
      let ssns = new Array<string>();
      ssns.push(enrollmentObj.Member.SSN);
      enrollmentObj.MemberDependents.forEach(d => {
        ssns.push(d.SSN);
      });
      this.metlifeService.encryptSSN(ssns).subscribe(x => {

        for (let i = 0; i < x.length; i++) {
          if (i === 0) {
            enrollmentObj.Member.SSN = x[0];
          } else {
            enrollmentObj.MemberDependents[i - 1].SSN = x[i];
          }

        }

        this.metlifeService.postEnrollment(enrollmentObj).subscribe((response: EnrollResponse) => {

          if (response.Success === 'true') {
            this.enrollmentService.clientIP = response.SessionID;
            this.enrollmentService.postStageEnrollmentToAPI().subscribe(x => {
              window.location.href = `${this.metlifeService.paymentPageUrl}${response.SessionID}`;
            });


          } else {
            this.loading = false;
            this.errorMessage = JSON.stringify(response.ErrorDetails);
          }
        });
        // const obs = Array<Observable<string>>();
        // enrollmentObj.MemberDependents.forEach(m => {
        //   obs.push(this.metlifeService.encryptSSN(m.SSN));
        //   obs[obs.length - 1].subscribe(s => m.SSN = s);
        // });
        // forkJoin(obs).toPromise().then(x => {

        //  });

      });
    } else {
      this.router.navigate(['/paymentmethod']);
    }
  }

  private getTotalInitalPaymemnt(index: number): number {
    let total = 0;
    this.model.fees.forEach(fee => {
      total += fee.amounts[index].amount;
    });
    return total;
  }

  private getTotalOngoingPaymemnt(index: number): number {
    let total = 0;
    this.model.fees.forEach(fee => {
      if (!fee.isOneTime) {
        total += fee.amounts[index].amount;
      }
    });
    return total;
  }

  onBackClick() {
    const dependentsCount = this.enrollmentService.census.familyMembers
      .filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT).length;

    if (this.enrollmentService.requestApplicantSSN) {
      this.router.navigate(['/ssn']);
    } else if (dependentsCount > 0) {
      this.router.navigate(['/census']);
    } else {
      this.router.navigate(['/contact']);
    }
  }
}
