export class MemberDependent {
    public SSN!: string;
    public FirstName!: string;
    public MiddleInitial!: string;
    public LastName!: string;
    public NameSuffix!: string;
    public DOB!: string;
    public Gender!: string;
    public RelationCode!: string;
    public StudentFlag!: boolean;
    public DisabledFlag!: boolean;
    public FacilityID!: string;
    public FacilityName!: string;
}
