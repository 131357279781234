import { Component, OnInit, forwardRef, ViewChild, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Affiliate } from '../shared/models/affiliate.model';
import { AffiliateService } from '../shared/services/affiliate.service';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { Campaign } from '../shared/models/campaign.model';
import { DateComponent } from '../date/date.component';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-anthem-start-questions',
    templateUrl: './anthem-start-questions.component.html',
    styleUrls: ['./anthem-start-questions.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AnthemStartQuestionsComponent), multi: true }],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass, DateComponent, AsyncPipe]
})
export class AnthemStartQuestionsComponent implements OnInit, ControlValueAccessor {
  @ViewChild('anthemStartQuestionsForm', { static: true }) public anthemStartQuestionsForm!: NgForm;

  @Input() showValidation!: boolean;

  public isIncarcerated!: boolean;
  public isResident!: boolean;
  public affiliate!: Observable<Affiliate>;
  public defaultAffiliate: Affiliate;
  public state: string;
  public campaign!: Campaign;
  public dependentOver26!: boolean;

  private propagateChange = (_: boolean | null) => { };
  private propagateTouched = (_: boolean) => { };

  constructor(private affiliateService: AffiliateService, public enrollmentService: EnrollmentService) {
    this.defaultAffiliate = this.affiliateService.defaultAffiliate;
    this.affiliateService.affiliate.subscribe(x => {this.affiliate = x;
      this.affiliate.subscribe(x => { if (x.availableCampaigns != null && x.availableCampaigns.findIndex(x => x.codeName === this.enrollmentService.campaignName) >= 0 )
        this.campaign = x.availableCampaigns.find(x => x.codeName === this.enrollmentService.campaignName)! });
      });
    this.state = this.enrollmentService.census.stateAbbr;
    if (this.state === 'CA') {
      this.isResident = true;
    }
    if (this.state === 'MO' || this.state === 'NY' || this.state === 'OH') {
      this.isIncarcerated = false;
    }

    if (this.state !== 'NY') {
      this.enrollmentService.anthemDisabilityQuestions.isSSDisability = false;
    }
    if (this.state !== 'ME') {
      this.dependentOver26 = false;
    }

  }

  ngOnInit(): void {
    this.anthemStartQuestionsForm.statusChanges?.subscribe(x => { this.onModelChange(); });
  }

  onModelChange() {
    if (!this.anthemStartQuestionsForm.valid) {
      this.propagateChange(true);
    } else {
      if (this.isIncarcerated !== undefined && this.isResident !== undefined && this.enrollmentService.anthemDisabilityQuestions.isSSDisability !== undefined && this.dependentOver26 !== undefined) {
        if (this.isIncarcerated || !this.isResident || this.dependentOver26) {
          this.propagateChange(true);
        } else {
          this.propagateChange(false);
        }
      } else {
        this.propagateChange(null);
      }
    }
  }

  get value(): boolean | null{
    if (this.isIncarcerated !== undefined && this.isResident !== undefined && this.dependentOver26 !== undefined) {
      if (this.isIncarcerated || !this.isResident || this.dependentOver26) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  }

  set value(newValue: boolean) {
  }

  writeValue(value: boolean): void {
  }
  registerOnChange(fn: (value: boolean  | null) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
}
