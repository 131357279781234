<div class="form-row" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div *ngIf="askFacilityId" class="form-group-block col-2">
    <label for="facilityId" [ngClass]="{ 'error-label': facility_id.invalid }">{{facilityIdTitle}}</label>
    <input id="facilityId" name="facilityId" type="text" [(ngModel)]="facilityId" #facility_id="ngModel"
      [disabled]="disabled" [readonly]="readonly" (ngModelChange)="onModelChange()"
      (input)="onFacilityInput($event.target)" [maxlength]="facilityIdMaxLength" required
      [ngClass]="{ showValidation: showValidation == true }">
    <div [hidden]="!showValidation || facility_id.valid" class="invalid-feedback">
      Please enter Office ID
    </div>
  </div>
  <div *ngIf="askFacilityName" class="form-group-block col-5">
    <label for="facilityName" [ngClass]="{ 'error-label': facility_name.invalid }">Facility Name</label>
    <input id="facilityName" name="facilityName" type="text" [(ngModel)]="facilityName" #facility_name="ngModel"
      [disabled]="disabled" [readonly]="readonly" (ngModelChange)="onModelChange()" maxlength="64" required
      [ngClass]="{ showValidation: showValidation == true }">
    <div [hidden]="!showValidation || facility_name.valid" class="invalid-feedback">
      Please enter Facility Name
    </div>
  </div>
  <div *ngIf="askDentistId" class="form-group-block col-3">
    <label id="dentistId" for="dentistId" [ngClass]="{ 'error-label': dentist_id.invalid }">Dentist Id</label>
    <input name="dentistId" type="tel" pattern="[0-9]*" [(ngModel)]="dentistId" #dentist_id="ngModel"
      [disabled]="disabled" [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)"
      maxlength="10" required [ngClass]="{ showValidation: showValidation == true }">
    <div [hidden]="!showValidation || dentist_id.valid" class="invalid-feedback">
      Please enter Dentist Id
    </div>
  </div>
  <div *ngIf="askDentistName" class="form-group-block col-2 ">
    <label for="dentistName" [ngClass]="{ 'error-label': dentist_name.invalid }">Dentist Id</label>
    <input id="dentistName" name="dentistName" type="text" [(ngModel)]="dentistName" #dentist_name="ngModel"
      [disabled]="disabled" [readonly]="readonly" (ngModelChange)="onModelChange()" maxlength="64" required
      [ngClass]="{ showValidation: showValidation == true }">
    <div [hidden]="!showValidation || dentist_name.valid" class="invalid-feedback">
      Please enter Dentist Name
    </div>
  </div>
</div>