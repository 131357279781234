<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
    <div class="container">

        <app-breadcrumbs [index]="1"></app-breadcrumbs>

        <div class="plan-wrapper">
            <aside class="side-widget no-mobile">
                <app-plan-widget></app-plan-widget>
                <app-support-widget></app-support-widget>
            </aside>
            <section class="application-content">
                <h1>Replacement Coverage Information</h1>
                <br />
                <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">

                    <div class="application-questions text-center">
                        <h4>Do you have prior or current dental coverage?</h4>
                        <div class="radio-group enroll-radio-group"
                            [ngClass]="{'radioBtn-invalid': showValidation === true && hasCoverage === undefined, 'radioBtn-valid': showValidation === true && hasCoverage !== undefined}">
                            <label for="hasCoverageYes">
                                <input type="radio" id="hasCoverageYes" [(ngModel)]="hasCoverage" name="hasCoverage"
                                    [value]="true" required />Yes
                            </label>
                            <label for="hasCoverageNo">
                                <input type="radio" id="hasCoverageNo" [(ngModel)]="hasCoverage" name="hasCoverage"
                                    [value]="false" required />No
                            </label>
                        </div>
                        <div [hidden]="!showValidation || hasCoverage !== undefined" class="invalid-feedback">
                            Please select one
                        </div>
                    </div>
                    <div *ngIf="hasCoverage">
                        <div class="application-questions text-center">
                            <h4>If you have current dental coverage, is this plan intended to replace that
                                coverage?<br />
                                (Note: If "Yes", you must read and date the Notice to Applicant Regarding Replacement of
                                Dental Insurance.) </h4>
                            <div class="radio-group enroll-radio-group"
                                [ngClass]="{'radioBtn-invalid': showValidation === true && coverageReplacement === undefined, 'radioBtn-valid': showValidation === true && coverageReplacement !== undefined}">
                                <label for="coverageReplacementYes">
                                    <input type="radio" id="coverageReplacementYes" [(ngModel)]="coverageReplacement"
                                        name="coverageReplacement" [value]="true" required />Yes
                                </label>
                                <label for="coverageReplacementNo">
                                    <input type="radio" id="coverageReplacementNo" [(ngModel)]="coverageReplacement"
                                        name="coverageReplacement" [value]="false" required />No
                                </label>
                            </div>
                            <div [hidden]="!showValidation || coverageReplacement !== undefined"
                                class="invalid-feedback">
                                Please select one
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12" *ngIf="coverageReplacement== false">
                            <br />
                            <p class="text-center application-text-warning"><strong>
                                    Since the intent is not to replace the current dental coverage, we are unable to
                                    accept this application at this time.</strong>
                            </p>
                        </div>
                    </div>

                    <br />
                    <div *ngIf="hasCoverage  && coverageReplacement">
                        <h4>Coverage Information</h4>
                        <div [hidden]="!showValidation || coverageInfoProvided()" class="invalid-feedback">
                            Please provide coverage information
                        </div>
                        <div class="separator-left"></div>


                        <div *ngFor="let member of cignaExistingCoverages; let i = index;">
                            <div class="has-coverage col-4">
                                <input type="checkbox" [(ngModel)]="member.hasCoverage" id="hasCoverage{{i}}"
                                    name="hasCoverage{{i}}" class="form-check-input check-input-big">

                                <label for="hasCoverage{{i}}">
                                    <strong>{{member.familyMember.firstName | titlecase}}
                                        ({{member.familyMember.birthdate | date : 'MM/dd/yyyy'}})</strong>
                                </label>
                            </div>
                            <div *ngIf="member.hasCoverage">
                                <div class="form-row">
                                    <div class="form-group-block col-4">
                                        <label for="policyId{{i}}"
                                            [ngClass]="{ 'error-label': policyId.invalid }">Policy Number:</label>
                                        <input type="text" id="policyId{{i}}" [(ngModel)]="member.policyId"
                                            name="policyId{{i}}" #policyId="ngModel" required maxlength="32"
                                            placeholder="Policy ID No"
                                            [ngClass]="{ showValidation: showValidation == true }">
                                        <div [hidden]="!showValidation || policyId.valid" class="invalid-feedback">
                                            Please enter Policy number
                                        </div>
                                    </div>
                                    <div class="form-group-block col-4">
                                        <label for="insurerName{{i}}"
                                            [ngClass]="{ 'error-label': insurerNameField.invalid }">Insurance Company
                                            name:</label>
                                        <input type="text" id="insurerName{{i}}" [(ngModel)]="member.insurerName"
                                            name="insurerName{{i}}" #insurerNameField="ngModel" required maxlength="32"
                                            placeholder="Insurer name"
                                            [ngClass]="{ showValidation: showValidation == true }">
                                        <div [hidden]="!showValidation || insurerNameField.valid"
                                            class="invalid-feedback">
                                            Please enter insurer name
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group-block col-4">
                                        <label for="effectiveDate{{i}}"
                                            [ngClass]="{ 'error-label': effectiveDateField.invalid }">Effective date of
                                            coverage:</label>
                                        <app-date id="effectiveDate{{i}}" [(ngModel)]="member.effectiveDate"
                                            name="effectiveDate{{i}}" #effectiveDateField="ngModel" required
                                            [ngClass]="{ showValidationComponent: showValidation == true }">
                                        </app-date>
                                        <div [hidden]="!showValidation || effectiveDateField.valid"
                                            class="invalid-feedback">
                                            Please enter effective date
                                        </div>
                                    </div>
                                    <div class="form-group-block col-4">
                                        <label for="lastDateOfCoverage{{i}}">Last date of coverage:</label>
                                        <app-date id="lastDateOfCoverage{{i}}" [(ngModel)]="member.lastDateOfCoverage"
                                            name="lastDateOfCoverage{{i}}">
                                        </app-date>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group-block col-9">
                                        <label for="coverageType{{i}}"
                                            [ngClass]="{ 'error-label': coverageTypeField.invalid }">Coverage Type:
                                        </label>
                                        <select id="coverageType{{i}}" [(ngModel)]="member.coverageType"
                                            name="coverageType{{i}}" #coverageTypeField="ngModel" required
                                            [ngClass]="{ showValidation: showValidation == true }">
                                            <option [ngValue]="null" disabled selected>Select</option>
                                            <option [ngValue]="coverageTypeOptions.BASIC">Preventive Only Dental Plan
                                            </option>
                                            <option [ngValue]="coverageTypeOptions.FULL">Full Coverage Dental Plan
                                            </option>
                                            <option [ngValue]="coverageTypeOptions.DISCOUNT">Discount Dental Plan
                                            </option>
                                        </select>
                                        <div [hidden]="!showValidation || coverageTypeField.valid"
                                            class="invalid-feedback">
                                            Please select one
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <br>
                    <ng-container *ngIf="hasCoverage  && coverageReplacement">

                        <div *ngIf="stateAbbr != 'CO' && stateAbbr !='LA' && stateAbbr != 'NY' && stateAbbr != 'NJ' && stateAbbr != 'VA'"
                            class="col-12">
                            <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF DENTAL INSURANCE</h3>
                            <p>According to your application, you intend to lapse or otherwise terminate your existing
                                insurance and replace it with
                                a policy to be issued by Cigna Health and Life Insurance Company. For your own
                                information and protection, you should
                                be aware of and seriously consider certain factors which may affect the insurance
                                protection available to you under
                                the new policy.</p>
                            <ol>

                                <li>You may wish to secure the advice of your present insurer or its agent regarding the
                                    proposed replacement of
                                    your present policy. This is not only your right, but it is also in your best
                                    interest to make sure you
                                    understand all the relevant factors involved in replacing your present coverage.
                                </li>
                                <li>Questions in the application for the new policy must be answered truthfully and
                                    completely; otherwise, the
                                    validity of the policy and the payment of any benefits thereunder may be voided.
                                </li>
                                <li>The new policy may be issued at a higher age than that used for issuance of your
                                    present policy; therefore,
                                    the cost of the new policy, depending upon the benefits, may be higher than you are
                                    paying for your present policy.</li>
                                <li>The renewal provisions of the new policy should be reviewed so as to make sure of
                                    your rights to periodically
                                    renew the policy.</li>
                                <li>After the application has been completed and before you sign it, reread it carefully
                                    to be certain that all
                                    information has been properly recorded.</li>
                            </ol>
                        </div>
                        <div *ngIf="stateAbbr === 'CO'" class="col-12">
                            <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF DENTAL INSURANCE</h3>
                            <p>According to your application, you intend to lapse or otherwise terminate your existing
                                insurance and replace it with
                                a policy to be issued by Cigna Health and Life Insurance Company. For your own
                                information and protection, you
                                should be aware of and seriously consider certain factors which may affect the insurance
                                protection available to you
                                under the new policy.</p>
                            <ol>

                                <li>You may wish to secure the advice of your present insurer or its agent regarding the
                                    proposed replacement of your
                                    present policy. This is not only your right, but it is also in your best interest to
                                    make sure you understand all
                                    the relevant factors involved in replacing your present coverage.</li>
                                <li>Questions in the application for the new policy must be answered truthfully and
                                    completely; otherwise, the validity
                                    of the policy and the payment of any benefits thereunder may be voided.</li>
                                <li>The new policy may be issued at a higher age than that used for issuance of your
                                    present policy; therefore, the
                                    cost of the new policy, depending upon the benefits, may be higher than you are
                                    paying for your present policy.</li>
                                <li>The renewal provisions of the new policy should be reviewed so as to make sure of
                                    your rights to periodically renew
                                    the policy.</li>
                                <li>After the application has been completed and before you sign it, reread it carefully
                                    to be certain that all
                                    information has been properly recorded.</li>

                            </ol>
                        </div>
                        <div *ngIf="stateAbbr === 'LA'" class="col-12">
                            <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF DENTAL INSURANCE</h3>
                            <p>According to your application, you intend to lapse or otherwise terminate your existing
                                limited benefit insurance and
                                replace it with a policy to be issued by Cigna Health and Life Insurance Company. For
                                your own information and
                                protection, you should be aware of and seriously consider certain factors which may
                                affect the insurance protection
                                available to you under the new policy.</p>
                            <ol>

                                <li>Health conditions which you may presently have, (preexisting conditions) may not be
                                    immediately or fully covered
                                    under the new policy. This could result in denial or delay of a claim for benefits
                                    present under the new policy,
                                    whereas a similar claim might have been payable under your present policy.</li>
                                <li>You may wish to secure the advice of your present insurer or its producer regarding
                                    the proposed replacement of
                                    your present policy. This is not only your right but it is also in your best
                                    interests to make sure you
                                    understand all the relevant factors involved in replacing your present coverage.
                                </li>
                                <li>If, after due consideration, you still wish to terminate your present policy and
                                    replace it with new coverage,
                                    be certain to truthfully and completely answer all questions on the application.
                                    After the application has been
                                    completed and before you sign it, reread it carefully to be certain that all
                                    information has been properly recorded.</li>

                            </ol>
                        </div>
                        <div *ngIf="stateAbbr === 'NJ'" class="col-12">
                            <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF DENTAL INSURANCE</h3>
                            <p>According to your application, you intend to lapse or otherwise terminate existing
                                accident and health insurance and
                                replace it with a policy to be issued by Cigna Health and Life Insurance Company. For
                                your own information and
                                protection, you should be aware of and seriously consider certain factors which may
                                affect the insurance protection
                                available to you under the new policy.</p>
                            <ol>

                                <li>You may wish to secure the advice of your present insurer or its agent regarding the
                                    proposed replacement of
                                    your present policy. This is not only your right, but it is also in your best
                                    interest to make sure you understand
                                    all the relevant factors involved in replacing your present coverage.</li>
                                <li>If, after due consideration, you still wish to terminate your present policy and
                                    replace it with new coverage,
                                    be certain to truthfully and completely answer all questions on the application
                                    concerning your medical/health
                                    history. Failure to include all material medical information on an application may
                                    provide a basis for the company
                                    to deny any future claims and to refund your premium as though your policy had never
                                    been in force. After the
                                    application has been completed and before you sign it, reread it carefully to be
                                    certain that all information
                                    has been properly recorded.</li>

                            </ol>
                        </div>
                        <div *ngIf="stateAbbr === 'NY'" class="col-12">
                            <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF DENTAL INSURANCE</h3>
                            <p>According to your application, you intend to lapse or otherwise terminate your existing
                                dental insurance and replace
                                it with a policy to be issued by Cigna Health and Life Insurance Company. For your own
                                information and protection,
                                you should be aware of and seriously consider certain factors which may affect the
                                insurance protection available
                                to you under the new policy.</p>
                            <ol>

                                <li>You may wish to secure the advice of your present insurer or its agent regarding the
                                    proposed replacement of
                                    your present policy. This is not only your right, but it is also in your best
                                    interest to make sure you
                                    understand all the relevant factors involved in replacing your present coverage.
                                </li>
                                <li>The new policy may be issued at a higher age than that used for issuance of your
                                    present policy; therefore,
                                    the cost of the new policy, depending upon the benefits, may be higher than you are
                                    paying for your present
                                    policy.</li>
                                <li>The renewal provisions of the new policy should be reviewed so as to make sure of
                                    your rights to periodically
                                    renew the policy.</li>
                                <li>After the application has been completed and before you sign it, reread it carefully
                                    to be certain that all
                                    information has been properly recorded.</li>

                            </ol>
                        </div>
                        <div *ngIf="stateAbbr === 'VA'" class="col-12">
                            <h3>NOTICE TO APPLICANT REGARDING REPLACEMENT OF DENTAL INSURANCE</h3>
                            <p>According to your application, you intend to lapse or otherwise terminate existing dental
                                insurance and replace it with
                                a policy to be issued by Cigna Health and Life Insurance Company. For your own
                                information and protection, you should
                                be aware of and seriously consider certain factors which may affect the insurance
                                protection available to you under
                                the new policy.</p>
                            <ol>

                                <li>Health conditions which you may presently have (preexisting conditions) may not be
                                    immediately or fully covered
                                    under the new policy. This could result in denial or delay of a claim for benefits
                                    under the new policy, whereas
                                    a similar claim might have been payable under your present policy.</li>
                                <li>You may wish to secure the advice of your present insurer or its agent or agency
                                    regarding the proposed
                                    replacement of your present policy. This is not only your right, but it is also in
                                    your best interests to make
                                    sure you understand all the relevant factors involved in replacing your present
                                    coverage.</li>
                                <li>Your new policy provides 10 days within which you may decide without cost whether
                                    you desire to keep the policy.</li>

                            </ol>
                        </div>
                        <div class="form-row">
                            <div class="col-12">
                                The above “Notice to Applicant” was delivered to me on: {{todayDate}}
                            </div>
                        </div><br>
                        <div class="form-row">
                            <div class="form-group-block col-6">
                                <label for="noticeDate" [ngClass]="{ 'error-label': noticeDateField.invalid }">Today's
                                    Date:</label>
                                <app-date id="noticeDate" [(ngModel)]="noticeDate" name="noticeDate"
                                    #noticeDateField="ngModel" required
                                    [ngClass]="{ showValidationComponent: showValidation == true }"></app-date>

                                <div [hidden]="!showValidation || noticeDateField.valid" class="invalid-feedback">
                                    Today's Date is required
                                </div>
                            </div>
                        </div>
                        <div *ngIf="stateAbbr === 'VA' || stateAbbr === 'NJ' || stateAbbr === 'LA'" class="form-row">
                            <div class="col-6">
                                <label for="signature" [ngClass]="{ 'error-label': signatureInput.invalid }">Primary
                                    Applicant's Signature:</label>
                                <input type="text" id="signature" [(ngModel)]="signature" name="signature"
                                    #signatureInput="ngModel" required
                                    [ngClass]="{ showValidation: showValidation == true }" />

                                <div [hidden]="!showValidation || signatureInput.valid" class="invalid-feedback">
                                    Applicant's signature is required
                                </div>
                            </div>
                        </div>
                        <!--<div *ngIf="stateAbbr === 'LA'" class="form-row">
                            <div class="col-6">
                                <label for="prodsignature">Producer's Signature:</label>
                                <input type="text" id="prodsignature" [(ngModel)]="prodsignature" name="prodsignature"
                                    #prodsignatureInput="ngModel" required
                                    [ngClass]="{ showValidation: showValidation == true }" />

                                <div [hidden]="!showValidation || prodsignatureInput.valid" class="invalid-feedback">
                                    Producer's signature is required
                                </div>
                            </div>
                        </div>-->
                    </ng-container>

                    <br />
                    <br />
                    <hr />
                    <div class="two-buttons-block">
                        <button type="submit" class="enroll button-right"><span>Continue</span></button>
                        <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
                    </div>
                </form>
                <app-disclaimers></app-disclaimers>
            </section>
        </div>
    </div>
</main>