import { ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CreditCard } from '../shared/models/creditCard';
import { Address } from '../shared/models/address.model';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-paymetric-gateway',
    templateUrl: './paymetric-gateway.component.html',
    styleUrls: ['./paymetric-gateway.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PaymetricGatewayComponent), multi: true }],
    standalone: true,
    imports: [NgIf, FormsModule]
})
export class PaymetricGatewayComponent implements ControlValueAccessor, OnInit {
  @ViewChild('payFrame') myDiv!: ElementRef;
  @ViewChild('creditCardForm', { static: true }) public creditCardForm!: NgForm;

  public model!: CreditCard;
  public disabled = false;
  public useApplicantAddress!: boolean;
  private appId: number;
  private premium: number;
  public frameUrl;
  public loading = true;
  @Input() showAgentRecordingNotice!: boolean;
  public resumedRecording!: boolean;

  @Output() resumed = new EventEmitter<boolean>();

  @Input() applicantAddress!: Address;

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: CreditCard) => { };

  constructor(private sanitizer: DomSanitizer, private enrollmentService: EnrollmentService) {
    const url = this.enrollmentService.getEnrollmentUrl();
    this.appId = this.enrollmentService.confirmationId;
    this.premium = this.enrollmentService.premium!;
    this.frameUrl = sanitizer.bypassSecurityTrustResourceUrl(`${url}/vsp?appId=${this.appId}&premium=${this.premium}`);

  }

  ngOnInit(): void {
    this.creditCardForm.statusChanges?.subscribe(x => { this.propagateChange(this.value) });
  }

  submitPayment() {
    this.myDiv.nativeElement.contentWindow.payNow();
  }

  onModelChange() {
    this.propagateChange(this.value);
  }


  get value(): CreditCard | null {
    if (!this.creditCardForm.valid || this.creditCardForm.pristine) {
      return null;
    } else {
      return this.model;
    }

  }

  set value(newValue: CreditCard) {
    if (newValue) {
      this.model = newValue;
    }
    this.propagateChange(this.value);
  }

  frameLoaded() {
    this.loading = false;
  }


  writeValue(value: CreditCard): void {
    this.value = value;
  }
  registerOnChange(fn: (value: CreditCard) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInput(target: any) {
    target.value = this.stripNonDigits(target.value);
  }

  toggleApplicantAddress() {
    if (this.useApplicantAddress) {
      this.model.billingAddress = this.applicantAddress;
    } else {
      this.model.billingAddress = new Address();
    }

  }

  private stripNonDigits(value: string): string | null {
    let newValue = '';
    for (let i = 0; i <= value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        newValue += value[i];
      }
    }
    if (newValue === '') {
      return null;
    } else {
      return newValue;
    }
  }

}
