export const environment = {
  production: true,
  apiEndpoint: 'https://enrollmentapidevelop.azurewebsites.net/api',
  quotesWebUrl: 'https://dev.dentalinsurance.com',
  affQuotesWebUrl: 'https://aff-dev.dentalinsurance.com',
  quotesYDEWebUrl: 'https://dev.yourdentalexchange.com',
  quotesRDEWebUrl: 'https://dev.retireedentalexchange.com',
  quotesDentalSavantsWebUrl: 'https://aff-dev.dentalsavants.com',  
  quotesQuoteSavantDentalWebUrl: 'https://aff-dev.quotesavantdental.com',  
  quotesVisionSavantsWebUrl: 'https://aff-dev.visionsavants.com',  
  quotesQuoteSavantVisionWebUrl: 'https://aff-dev.quotesavantvision.com',  
  geoApiEndpoint: 'https://geoappservice.azurewebsites.net/api/v1',
  enrollmentWebUrl: 'https://enrollmentwebdevelop.azurewebsites.net',
  enrollmentYDEWebUrl: 'https://dev-enrollment.yourdentalexchange.com',
  enrollmentRDEWebUrl: 'https://dev-enrollment.retireedentalexchange.com',
  enrollmentDentalSavantsWebUrl: 'https://enroll-dev.dentalsavants.com',  
  enrollmentQuoteSavantDentalWebUrl: 'https://enroll-dev.quotesavantdental.com',  
  enrollmentVisionSavantsWebUrl: 'https://enroll-dev.visionsavants.com',  
  enrollmentQuoteSavantVisionWebUrl: 'https://enroll-dev.quotesavantvision.com',
  metlifeServiceUrl: 'https://metlifeservices.careingtonsandbox.com/EnrollmentServices/Enroll',
  metlifePaymentsUrl: 'https://metlifetakealongdental-dental.careingtonsandbox.com/#/metlifepayment/',
  metlifeServiceApiKey: 'qU8T9DezfDVG9zFF9bmlMpF4mFl77mXP1NCZuWIf1oM=',
  metlifeProviderSearchUrl: 'https://qa.providers.online.metlife.com/findDentist',
  enrollmentPdfUrl: 'https://enrollmentpdfs.blob.core.windows.net/enrollmentpdfs/',
  inhouseAgents: ['kelseytest', 'malcala', 'sbrown', 'rtowns', 'charbour', 'astack', 'eshanavian', 'meking', 'aarias', 'galeman'],
  merchantGuid: 'dd0764a7-940f-409a-8457-c4367e6a229c',
  paymetricPostUrl: 'https://cert-xiecomm.paymetric.com/DIeComm/Ajax'
};
