<section *ngIf="enrollmentService.plan" class="widget-box">
    <h4>Plan Details:</h4>
    <a *ngIf="enrollmentService.agent && !careington"
        [attr.href]="quotesWebUrl + '/quoting/' +getPlanUrl(enrollmentService.plan)+ '?requestId=' + requestId"
        target="_blank">
        <img src="{{enrollmentService.plan.carrier.logoUrl}}" width="150" height="55" class="img-fluid"> </a>
    <img *ngIf="!enrollmentService.agent || careington" src="{{enrollmentService.plan.carrier.logoUrl}}" width="150" height="55"
        class="img-fluid">

    <div class="separator-left"></div>
    <h3 class="plan-name">{{enrollmentService.plan.name}} <span
            class="plan-type-badge">{{enrollmentService.plan.planType.name}}</span></h3>

    <div class="separator-left"></div>
    Location: {{enrollmentService.census.zip}}, {{enrollmentService.census.stateAbbr}}
    <br>
    <a *ngIf="exchangeCompany"
        [attr.href]="quotesWebUrl + '/quoting/census'">change</a>
    <a *ngIf="!hideChangeLinks && !exchangeCompany" [attr.href]="quotesWebUrl + '/quoting/plans/?censusEdit=true&requestId=' + requestId">change</a>

    <div class="separator-left"></div>
    Quote for:<br>
    <div *ngFor="let member of enrollmentService.census.familyMembers">
        <ng-container [ngSwitch]="member.familyRelationship">
            <span *ngSwitchCase="familyRelationships.APPLICANT">Applicant, </span>
            <span *ngSwitchCase="familyRelationships.SPOUSE">Spouse, </span>
            <span *ngSwitchCase="familyRelationships.CHILD">Child {{getChildNumber(member)}}, </span>
        </ng-container>
        <ng-container [ngSwitch]="member.gender">
            <span *ngSwitchCase="genders.MALE">M, </span>
            <span *ngSwitchCase="genders.FEMALE">F, </span>
            <span *ngSwitchCase="genders.X">X, </span>
        </ng-container>
        {{member.birthdate}}<br>
    </div>
    <a *ngIf="exchangeCompany"
        [attr.href]="quotesWebUrl + '/quoting/census'">change</a>
    <a *ngIf="!hideChangeLinks && !exchangeCompany"
        [attr.href]="quotesWebUrl + '/quoting/plans/?censusEdit=true&requestId=' + requestId">change</a>

    <div class="separator-left"></div>
    <div class="separator"></div>
        <div class="text-center"><a *ngIf="!hideChangeLinks" [attr.href]="quotesWebUrl + '/quoting/plans/'" class="button button-primary button-block">Return to Plan List</a></div>
        <div class="separator"></div>
    <p>
        <span>Requested Effective Date: {{enrollmentService.census.requestedEffectiveDate | date : 'MM/dd/yyyy'}}</span>
    </p>
</section>