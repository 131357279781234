<hr>
<form #metlifeStartQuestionsForm="ngForm">
  <div *ngIf="isAssisted">
    <div class="application-questions">
      <h4>Are you licensed in this state?</h4>
      <div class="radio-group enroll-radio-group"
        [ngClass]="{'radioBtn-invalid': showValidation === true && (isLicensed === false || isLicensed === undefined) , 'radioBtn-valid': showValidation === true && isLicensed === true}">
        <label for="isLicensedYes">
          <input type="radio" id="isLicensedYes" [(ngModel)]="isLicensed" name="isLicensedYes" [value]="true"
            required />Yes
        </label>
        <label for="isLicensedNo">
          <input type="radio" id="isLicensedNo" [(ngModel)]="isLicensed" name="isLicensedNo" [value]="false"
            required />No
        </label>
      </div>
      <div [hidden]="!showValidation || isLicensed !== undefined" class="invalid-feedback">
        Please select one
      </div>
    </div>
  </div>
  <div class="text-center application-text-warning" *ngIf="isLicensed === false">
    <p><strong>
        You need to be licensed in this state to sell this plan.</strong>
    </p>
  </div>
  <ng-container *ngIf="isAssisted">

    <div class="application-questions">
      <h4 *ngIf="isDHMO">Did you cover: In-Network Benefits Only, Plan Max, Waiting periods, Missing tooth clause, Work
        in Progress, Advise Full Schedule of Benefits is available online?</h4>
      <h4 *ngIf="!isDHMO">Did you cover: In and Out of Network – MAC/R&C, Deductible, Plan Max, Waiting periods, Waiting
        period waiver option, Missing tooth clause, Work in Progress, Advise Full Schedule of Benefits is available
        online?</h4>
      <div class="radio-group enroll-radio-group"
        [ngClass]="{'radioBtn-invalid': showValidation === true && (confirmCovered === false || confirmCovered === undefined) , 'radioBtn-valid': showValidation === true && confirmCovered === true}">
        <label for="confirmCoveredYes">
          <input type="radio" id="confirmCovereddYes" [(ngModel)]="confirmCovered" name="confirmCoveredYes"
            [value]="true" required />Yes
        </label>
        <label for="askCoveredNo">
          <input type="radio" id="confirmCoveredNo" [(ngModel)]="confirmCovered" name="confirmCovereddNo"
            [value]="false" required />No
        </label>
      </div>
      <div [hidden]="!showValidation || confirmCovered !== undefined" class="invalid-feedback">
        Please select one
      </div>
    </div>
    <p *ngIf="confirmCovered === false" class="application-text-warning text-center"><strong>
        You must confirm that you have covered all of the topics above prior to completing this enrollment.</strong>
    </p>
  </ng-container>

</form>