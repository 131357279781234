import { IPaymentInfo } from './paymentInfo';
import { Address } from './address.model';

export class CreditCard implements IPaymentInfo {
    public cardNumber!: string;
    public nameOnCard!: string;
    public expirationMonth!: number;
    public expirationYear!: number;
    public cvc!: string;
    public billingAddress!: Address;
    public ongoingPaymentDay!: number;
}
