import { Injectable } from '@angular/core';
import { EnrollmentService } from '../services/enrollment.service';
import { Member } from '../../metlife/models/member.model';
import { FamilyRelationship } from '../models/family-relationship.enum';
import { Gender } from '../models/gender.enum';
import { MemberDependent } from '../../metlife/models/member-dependent.model';
import { MemberRate } from '../../metlife/models/member-rate.model';
import { Frequency } from '../models/frequency';
import { ProductInfo } from '../../metlife/models/product-info.model';
import { StateSpecificQuestions } from '../../metlife/models/state-specific-questions.model';
import { EnrollOption } from '../../metlife/models/enroll-option.model';
import { Enrollment } from '../../metlife/models/enrollment.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnrollResponse } from '../../metlife/models/enrollResponse.model';
import { environment } from 'src/environments/environment';
import { AffiliateService } from './affiliate.service';

@Injectable()
export class MetlifeService {
  private CLIENT_CODE = 'KL';
  private LANGUAGE = 'EN';

  private encryptionServiceUrl = environment.apiEndpoint + '/enrollments/encryptssn';

  private serviceUrl = environment.metlifeServiceUrl;

  public paymentPageUrl = environment.metlifePaymentsUrl;
  public apiKey = environment.metlifeServiceApiKey;

  public stateSpecifics!: StateSpecificQuestions;


  constructor(private http: HttpClient, private enrollmentService: EnrollmentService,
    private affiliateService: AffiliateService) { }

  public postEnrollment(enrollmentObj: Enrollment): Observable<EnrollResponse> {

    return this.http.post<EnrollResponse>(`${this.serviceUrl}`, enrollmentObj, {
      headers: { 'Content-Type': 'application/json', 'APIKey': this.apiKey }
    });
  }

  public encryptSSN(ssn: Array<string>): Observable<Array<string>> {
    return this.http.post<Array<string>>(`${this.encryptionServiceUrl}`, { ssn: ssn }, {
      headers: { 'Content-Type': 'application/json' }, responseType: 'json'
    });
  }

  public generateEnrollment(): Enrollment {
    const applicant = this.enrollmentService.census.familyMembers.find(x => x.familyRelationship === FamilyRelationship.APPLICANT);
    const address = this.enrollmentService.address;

    const member = new Member();
    member.ClientCode = this.CLIENT_CODE;
    member.FirstName = applicant?.firstName!;
    member.MiddleInitial = applicant?.middleInitial!;
    member.LastName = applicant?.lastName!;
    member.SSN = this.stripNonDigits(applicant?.ssn!);
    member.DOB = applicant?.birthdate!;
    member.Gender = (applicant?.gender === Gender.MALE) ? 'M' : 'F';
    member.Email = address.email;
    member.Addr1 = address.addressLine1;
    member.Addr2 = address.addressLine2;
    member.City = address.city;
    member.StateCode = address.stateAbbr;
    member.ZipCode = address.zip;
    member.Language = this.LANGUAGE;
    member.HomePhone = this.stripNonDigits(address.phone);
    member.WorkPhone = '';
    member.MobilePhone = member.HomePhone;
    if (applicant?.dentist) {
      member.FacilityID = applicant.dentist.facilityId;
      member.FacilityName = applicant.dentist.facilityName;
    } else {
      member.FacilityID = '';
      member.FacilityName = '';
    }

    const memberDependents = new Array<MemberDependent>();
    this.enrollmentService.census.familyMembers.filter(x => x.familyRelationship !== FamilyRelationship.APPLICANT).forEach(m => {
      const dependent = new MemberDependent();
      dependent.FirstName = m.firstName;
      dependent.MiddleInitial = m.middleInitial;
      dependent.LastName = m.lastName;

      dependent.SSN = this.stripNonDigits(m.ssn);
      dependent.DOB = m.birthdate;
      dependent.Gender = (m.gender === Gender.MALE) ? 'M' : 'F';
      dependent.RelationCode = (m.familyRelationship === FamilyRelationship.SPOUSE) ? 'S' : 'C';
      dependent.StudentFlag = m.isStudent;
      dependent.DisabledFlag = m.isDisabled;
      if (m.dentist) {
        dependent.FacilityID = m.dentist.facilityId;
        dependent.FacilityName = m.dentist.facilityName;
      } else {
        dependent.FacilityID = '';
        dependent.FacilityName = '';
      }
      memberDependents.push(dependent);
    });

    const memberRate = new MemberRate();
    memberRate.Rate = this.enrollmentService.totalOngoingPaymentAmount;
    switch (this.enrollmentService.selectedPaymentFrequency.frequency) {
      case Frequency.Monthly: memberRate.Frequency = 'M'; break;
      case Frequency.Quarterly: memberRate.Frequency = 'Q'; break;
      case Frequency.Semiannual: memberRate.Frequency = 'S'; break;
      case Frequency.Annual: memberRate.Frequency = 'A'; break;
    }

    const productInfo = new ProductInfo();

    if (this.enrollmentService.planId.toUpperCase() === '92B57CD7-A109-495E-9258-559ED1C33865') { // TakeAlong Dental Low
      productInfo.PlanCode = 'MP01';
    } else if (this.enrollmentService.planId.toUpperCase() === '2E09AE8B-7A7C-453B-B992-84220BC7FE42') { // TakeAlong Dental Medium
      productInfo.PlanCode = 'MP09';
    } else if (this.enrollmentService.planId.toUpperCase() === '75893B9F-E084-4AE9-A0FD-58F07F04A4F0') { //  TakeAlong Dental High
      productInfo.PlanCode = 'MP14';
      // tslint:disable-next-line: max-line-length
    } else if (this.enrollmentService.planId.toUpperCase() === '2C8ED77D-53D2-455E-AA56-CEFA23D9260E') { // TakeAlong Dental HMO-Managed Care 245 (High)
      productInfo.PlanCode = 'MD02';
      // tslint:disable-next-line: max-line-length
    } else if (this.enrollmentService.planId.toUpperCase() === '51AFE7D6-E051-4A83-99A1-405CDA522D89') { // TakeAlong Dental HMO-Managed Care 350 (Low)
      productInfo.PlanCode = 'MD01';
    }

    productInfo.Agentcode = (this.enrollmentService.agent && this.enrollmentService.agent.length <= 10 && !this.enrollmentService.isEmailedQuote && this.enrollmentService.affiliateId != 2576) ? this.enrollmentService.agent : null;
        
    productInfo.ReferralCode = 'Kelsey';
    productInfo.CurrentPriorEmployerFlag = false;
    productInfo.CurrentOrPriorEmployer = '';

    switch (this.enrollmentService.census.familyMembers.length) {
      case 1: productInfo.Coverage = 'M'; break;
      case 2: productInfo.Coverage = 'MD'; break;
      default: productInfo.Coverage = 'MF'; break;
    }

    const coverageDate = new Date(this.enrollmentService.census.requestedEffectiveDate);
    productInfo.CoverageStartDate = this.appendLeadingZeroes(coverageDate.getMonth() + 1) + '/'
      + this.appendLeadingZeroes(coverageDate.getDate()) + '/'
      + this.appendLeadingZeroes(coverageDate.getFullYear());

    if (!this.stateSpecifics) {
      const stateSpecificQuestions = new StateSpecificQuestions();
      stateSpecificQuestions.PreviousCoverage = false;
      stateSpecificQuestions.Duplication = false;
      stateSpecificQuestions.PreviousCoverageCompany = '';
      stateSpecificQuestions.PreviousCoveragePolicy = '';
      stateSpecificQuestions.Lapse = false;
      stateSpecificQuestions.OtherInsurance = false;
      stateSpecificQuestions.PreviousCoverageCompany2 = '';
      stateSpecificQuestions.PreviousCoveragePolicy2 = '';
      stateSpecificQuestions.SLMB = false;
      stateSpecificQuestions.Medicare = false;
      stateSpecificQuestions.Medicaid = false;
      this.stateSpecifics = stateSpecificQuestions;
    }

    const enrollOptionSubmissionId = new EnrollOption();
    enrollOptionSubmissionId.Name = 'SubmissionID';
    enrollOptionSubmissionId.Value = '12345';

    const logoUrl = new EnrollOption();

    const phoneNumber = new EnrollOption();

    this.affiliateService.affiliate.subscribe((x:any) => {
      x.subscribe((z:any) => {
        logoUrl.Name = 'LogoURL';
        if (z.logoUrl === 'www')
          logoUrl.Value = 'https://www.dentalinsurance.com/assets/img/svg/new-logo.svg';
        else
          logoUrl.Value = 'https://www.dentalinsurance.com/assets/img/affiliate_logos/' + z.logoUrl + '.png';

        phoneNumber.Name = 'PhoneNumber';
        //phoneNumber.Value = this.stripNonDigits(z.phone);
        if (z.availableCampaigns != null && z.availableCampaigns.findIndex((z:any) => z.codeName === this.enrollmentService.campaignName) >= 0)
          phoneNumber.Value = this.stripNonDigits(z.availableCampaigns.find((z:any) => z.codeName === this.enrollmentService.campaignName).phone);
        else phoneNumber.Value = this.stripNonDigits(z.phone);
      });

    })


    const enrollOptionBackURL = new EnrollOption();
    enrollOptionBackURL.Name = 'BackURL';
    enrollOptionBackURL.Value = this.enrollmentService.getEnrollmentUrl() + '/paymentoptions';

    const enrollOptionConfirmURL = new EnrollOption();
    enrollOptionConfirmURL.Name = 'ConfirmURL';
    enrollOptionConfirmURL.Value = this.enrollmentService.getEnrollmentUrl() + '/thankyou';

    const enrollOptionCancelURL = new EnrollOption();
    enrollOptionCancelURL.Name = 'CancelURL';
    enrollOptionCancelURL.Value = this.enrollmentService.getEnrollmentUrl() + '/cancelled';

    const enrollOptions = new Array<EnrollOption>();
    enrollOptions.push(enrollOptionSubmissionId);
    enrollOptions.push(logoUrl);
    enrollOptions.push(phoneNumber);

    enrollOptions.push(enrollOptionBackURL);
    enrollOptions.push(enrollOptionConfirmURL);
    enrollOptions.push(enrollOptionCancelURL);

    const enrollment = new Enrollment();
    enrollment.Member = member;
    enrollment.MemberDependents = memberDependents;
    enrollment.MemberRate = memberRate;
    enrollment.ProductInfo = productInfo;
    enrollment.EnrollOptions = enrollOptions;
    enrollment.StateSpecificQuestions = this.stateSpecifics;
    return enrollment;
  }

  appendLeadingZeroes(n:number) {
    if (n <= 9) {
      return '0' + n;
    }
    return n
  }

  stripNonDigits(input: string) {
    if (input) {
      return input.replace(/\D/g, '');
    } else {
      return input;
    }
  }
}
