import { Component, OnInit, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class BreadcrumbsComponent implements OnInit {

  @Input() public index!: number;
  constructor() { }

  ngOnInit() {
  }

  public getClass(tabIndex: number): string {
    if (tabIndex === this.index) {
      return 'active';
    } else if (tabIndex < this.index) {
      return 'completed';
    } else {
      return '';
    }


  }

}
