<section class="card" [ngClass]="{ showValidationContainer: showValidation === true }">
  <form #creditCardForm="ngForm">
    <h2>Credit Card</h2>
    <div class="separator-left"></div>

    <div *ngIf="model">
      <div class="form-row">
        <div class="form-group-block col-8">
          <label for="nameCard" [ngClass]="{ 'error-label': nameOnCard.invalid }">Name on a Card</label>
          <input type="text" id="nameOnCard" name="nameOnCard" [(ngModel)]="model.nameOnCard" #nameOnCard="ngModel"
            required maxlength="50" [ngClass]="{ showValidation: showValidation == true }"
            placeholder="Name on a Card" />
          <div [hidden]="!showValidation || nameOnCard.valid" class="invalid-feedback">
            Please enter the name on the card
          </div>
        </div>
      </div>

      <div class="form-row">
        <div *ngIf="showSignatureField" class="form-group-block col-8">
          <label for="signature" [ngClass]="{ 'error-label': signatureField.invalid }">Authorized Signature</label>
          <input type="text" id="signature" name="signature" [(ngModel)]="signature" #signatureField="ngModel" required
            maxlength="50" [ngClass]="{ showValidation: showValidation == true }" placeholder="Authorized Signature" />
          <div [hidden]="!showValidation || signatureField.valid" class="invalid-feedback">
            Please enter the authorized signature
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group-block col-8">
          <label for="cardNumber" [ngClass]="{ 'error-label': (!cardNumber.valid || !validateCardNumber()) }">Card
            Number</label>
          <input type="tel" pattern="[0-9]*" id="cardNumber" name="cardNumber" [(ngModel)]="model.cardNumber"
            #cardNumber="ngModel" (input)="onInput($event.target)" [disabled]="disabled"
            (ngModelChange)="onModelChange()" [readonly]="readonly" required [attr.maxlength]="getMaxCardLength()"
            [attr.minlength]="getMaxCardLength()" [ngClass]="{'input-invalid': showValidation == true && (!cardNumber.valid || !validateCardNumber()),
            'input-valid': showValidation == true && cardNumber.valid && validateCardNumber()}"
            placeholder="Card Number" />
          <div [hidden]="!showValidation || cardNumber.valid" class="invalid-feedback">
            Please enter a valid card number
          </div>
          <div [hidden]="
              !showValidation || !(cardNumber.valid && !validateCardNumber())
            " class="invalid-feedback">
            Please enter a valid card number
          </div>
        </div>
      </div>

      <div class="form-row form-row-valign-bottom">
        <div class="form-group-block col-3">
          <label for="expDateMonth" [ngClass]="{ 'error-label':  expirationMonth.invalid }">Expiration Month</label>
          <select [(ngModel)]="model.expirationMonth" id="expirationMonth" name="expirationMonth" class="ef-date-dc"
            id="ef-date-dc2" #expirationMonth="ngModel" (ngModelChange)="onModelChange()" required
            [ngClass]="{'showValidation': showValidation == true}">
            <option [ngValue]="undefined" selected disabled>Select</option>
            <option [ngValue]="1">01</option>
            <option [ngValue]="2">02</option>
            <option [ngValue]="3">03</option>
            <option [ngValue]="4">04</option>
            <option [ngValue]="5">05</option>
            <option [ngValue]="6">06</option>
            <option [ngValue]="7">07</option>
            <option [ngValue]="8">08</option>
            <option [ngValue]="9">09</option>
            <option [ngValue]="10">10</option>
            <option [ngValue]="11">11</option>
            <option [ngValue]="12">12</option>
          </select>
          <div [hidden]="!showValidation || expirationMonth.valid" class="invalid-feedback">
            Select expiration month
          </div>
          <div [hidden]="
              !showValidation ||
              !(
                expirationMonth.valid &&
                expirationYear.valid &&
                !isValidExpirationDate()
              )
            " class="invalid-feedback">
            Card must not be expired
          </div>
        </div>
        <div class="form-group-block col-3">
          <label for="expDateYear" [ngClass]="{'error-label':  expirationYear.invalid }">Expiration Year</label>
          <select [(ngModel)]="model.expirationYear" id="expirationYear" name="expirationYear" id="ef-date-dc"
            id="ef-date-dc2" #expirationYear="ngModel" (ngModelChange)="onModelChange()" required
            [ngClass]="{'showValidation': showValidation == true}">
            <option [ngValue]="undefined" selected disabled>Select</option>
            <option *ngFor="let year of years" [ngValue]="year">
              {{ year }}
            </option>
          </select>
          <div [hidden]="!showValidation || expirationYear.valid" class="invalid-feedback">
            Select expiration year
          </div>
        </div>
        <div class="form-group-block col-2">
          <label for="cvc" [ngClass]="{'error-label':  cvc.invalid }">CVC</label>
          <input type="tel" pattern="[0-9]*" id="cvc" name="cvc" (input)="onInput($event.target)"
            [(ngModel)]="model.cvc" #cvc="ngModel" minlength="3" maxlength="4" required
            [ngClass]="{'showValidation': showValidation == true}" placeholder="CVC" />
          <div [hidden]="!showValidation || cvc.valid" class="invalid-feedback">
            Enter CVC
          </div>
        </div>
      </div>

      <div *ngIf="allowOngoingDaySelection" class="form-row">
        <div class="form-group-block col-6">
          <label for="ongoingPaymentDay" [ngClass]="{'error-label':  ongoingPaymentDay.invalid }">Ongoing Payment Charge
            Day</label>
          <select [(ngModel)]="model.ongoingPaymentDay" id="ongoingPaymentDay" name="ongoingPaymentDay"
            class="ef-date-dc" #ongoingPaymentDay="ngModel" (ngModelChange)="onModelChange()" required
            [ngClass]="{'showValidation': showValidation == true}">
            <option [ngValue]="undefined" selected disabled>Select</option>
            <option *ngFor="let option of ongoingPaymentDayOptions" [ngValue]="option">
              {{ option | number : "2.0-0" }}
            </option>
          </select>
          <div [hidden]="!showValidation || ongoingPaymentDay.valid" class="invalid-feedback">
            Select payment day
          </div>
        </div>
      </div>

      <hr />
      <div class="form-row" *ngIf="showAgentRecordingNotice">
        <div class="col-12">
          <div class="alert alert-danger highlight" role="alert">
            <img class="stop-sign" src="/assets/images/stop.png" />
            <div>
              AGENT: RESUME RECORDING BEFORE CONTINUING TO PROCESS THE
              APPLICATION.<br /><br />
              <div class="has-coverage">
                <input type="checkbox" class="form-check-input check-input-big" id="ResumedRecording"
                  name="ResumedRecording" [(ngModel)]="resumedRecording" (click)="resumed.emit(true)"
                  [disabled]="resumedRecording" />
                <label for="ResumedRecording"><strong>Check this box ONLY after recording is resumed</strong></label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="showAgentRecordingNotice === resumedRecording">
        <div class="form-row">
          <div class="form-group-block col-12">
            <h4>Billing address</h4>
          </div>
        </div>
        <div class="form-row" *ngIf="allowDifferentBillingAddress">
          <div class="col-12">
            <div class="form-check">
              <input type="checkbox" class="form-check-input check-input-big" id="useApplicantAddress"
                [(ngModel)]="useApplicantAddress" (ngModelChange)="toggleApplicantAddress()"
                name="useApplicantAddress" />
              <label for="useApplicantAddress"><strong>Use primary applicant's address</strong></label>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="!allowDifferentBillingAddress || useApplicantAddress">
          <div class="form-group-block col-12">
            <p>
              <strong>{{ model.billingAddress.addressLine1 }}<br />
                <span *ngIf="applicantAddress.addressLine2">{{ model.billingAddress.addressLine2 }}<br /></span>
                {{ model.billingAddress.city }},
                {{ model.billingAddress.stateAbbr }}
                {{ model.billingAddress.zip }}
              </strong>
            </p>
          </div>
        </div>
        <div class="form-row" *ngIf="allowDifferentBillingAddress && !useApplicantAddress">
          <div class="form-group-block col-12">
            <label for="addressLane1">Address Line 1</label>
            <input type="text" id="addressLine2" [(ngModel)]="model.billingAddress.addressLine1" name="addressLine1"
              #addressLine1="ngModel" required maxlength="128" [ngClass]="{'showValidation': showValidation == true}"
              placeholder="Address Line 1" />
            <div [hidden]="!showValidation || addressLine1.valid" class="invalid-feedback">
              Please enter the billing address
            </div>
          </div>
          <div class="form-group-block col-12">
            <label for="addressLane2">Address Line 2</label>
            <input type="text" id="addressLine2" [(ngModel)]="model.billingAddress.addressLine2" name="addressLine2"
              maxlength="128" placeholder="Address Line 2" />
          </div>
          <div class="form-group-block col-4">
            <label for="city">City</label>
            <input type="text" id="city6" [(ngModel)]="model.billingAddress.city" name="city" #city="ngModel" required
              maxlength="64" [ngClass]="{'showValidation': showValidation == true}" placeholder="City" />
            <div [hidden]="!showValidation || city.valid" class="invalid-feedback">
              Please enter city
            </div>
          </div>
          <div class="form-group-block col-2">
            <label for="state">State</label>
            <select [(ngModel)]="model.billingAddress.stateAbbr" id="state1" name="state" class="ef-date-dc"
              id="ef-date-dc2" #state="ngModel" (ngModelChange)="onModelChange()" required
              [ngClass]="{'showValidation': showValidation == true}">
              <option [ngValue]="undefined" selected disabled>Select</option>
              <option value="AK">AK</option>
              <option value="AL">AL</option>
              <option value="AR">AR</option>
              <option value="AZ">AZ</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DC">DC</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="IA">IA</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="MA">MA</option>
              <option value="MD">MD</option>
              <option value="ME">ME</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MO">MO</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="NE">NE</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NV">NV</option>
              <option value="NY">NY</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VA">VA</option>
              <option value="VT">VT</option>
              <option value="WA">WA</option>
              <option value="WI">WI</option>
              <option value="WV">WV</option>
              <option value="WY">WY</option>
            </select>
            <div [hidden]="!showValidation || state.valid" class="invalid-feedback">
              Select State
            </div>
          </div>
          <div class="form-group-block col-3">
            <label for="zip">Zip</label>
            <input type="tel" pattern="[0-9]*" id="zip" name="zip" (input)="onInput($event.target)"
              [(ngModel)]="model.billingAddress.zip" #zip="ngModel" required minlength="5" maxlength="5"
              [ngClass]="{'showValidation': showValidation == true}" placeholder="Zip" />
            <div [hidden]="!showValidation || zip.valid" class="invalid-feedback">
              Enter valid zip code
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</section>