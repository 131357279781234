<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">

    <app-breadcrumbs [index]="2"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>
      <section class="application-content">
        <h1>Primary Applicant Contact Information</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
          <div class="form-row">
            <div class="form-group-block col-12">
              <label for="addressLane1" [ngClass]="{ 'error-label': addressLine1.invalid }">Address Line 1:</label>
              <input type="text" id="addressLine1" [(ngModel)]="model.addressLine1" name="addressLine1"
                #addressLine1="ngModel" required maxlength="128" placeholder="Address Line 1"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || addressLine1.valid" class="invalid-feedback">
                Please enter address
              </div>
            </div>
            <div class="form-group-block col-12">
              <label for="addressLane2">Address Line 2:</label>
              <input type="text" id="addressLine2" [(ngModel)]="model.addressLine2" name="addressLine2"
                placeholder="Address Line 2" maxlength="128">
            </div>
            <div class="form-group-block col-4">
              <label for="city" [ngClass]="{ 'error-label': city.invalid }">City: </label>
              <input type="text" id="city" [(ngModel)]="model.city" name="city" #city="ngModel" required maxlength="64"
                placeholder="City" [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || city.valid" class="invalid-feedback">
                Please enter city
              </div>
            </div>
            <div class="form-group-block col-2">
              <label for="state">State:</label>
              <input type="text" value="{{model.stateAbbr}}" disabled>
            </div>
            <div class="form-group-block col-3">
              <label for="zip">Zip:</label>
              <input type="text" value="{{model.zip}}" disabled>
            </div>
            <div class="form-group-block col-3">
              <label for="county">County:</label>
              <input type="text" value="{{model.county}}" disabled>
            </div>
          </div>

          <br />
          <br />
          <div class="form-row">
            <div class="form-group-block col-6">
              <label for="phone" [ngClass]="{ 'error-label': phone.invalid }">Phone:</label>
              <app-phone-number id="phone" [(ngModel)]="model.phone" name="phone" #phone="ngModel" required
                [ngClass]="{ showValidationComponent: showValidation == true }">
              </app-phone-number>
              <div [hidden]="!showValidation || phone.valid" class="invalid-feedback">
                Phone is required
              </div>
            </div>
          </div>
          
          <div *ngIf="carrierShortName.includes('anthem') && model.stateAbbr =='NY'" class="form-row">
          <div class="form-group-block col-12">
            <p>Would you like to be added to the Donate Life Registry?</p>
          <div class="radio-group enroll-radio-group"
          [ngClass]="{'radioBtn-invalid': showValidation === true && donationLifeRegistry === undefined , 'radioBtn-valid': showValidation === true && donationLifeRegistry !== undefined}">            
          <label for="donationLifeRegistryYes">
            <input type="radio" id="donationLifeRegistryYes"
              [(ngModel)]="donationLifeRegistry" name="donationLifeRegistryYes"
              [value]="true" required />Yes
          </label>
          <label for="donationLifeRegistryNo">
            <input type="radio" id="donationLifeRegistryNo"
              [(ngModel)]="donationLifeRegistry" name="donationLifeRegistryNo"
              [value]="false" required />Skip Question
          </label>
          </div>
          </div>
          </div>
          <div class="form-row">
            <div class="form-group-block col-6">
              <label for="email" [ngClass]="{ 'error-label': email.invalid }">Email:</label>
              <input type="email" id="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
                [(ngModel)]="model.email" name="email" #email="ngModel" required maxlength="256" placeholder="Email"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || email.valid " class="invalid-feedback">
                Please enter valid email
              </div>
            </div>
            <div class="form-group-block col-6">
              <label for="confirmEmail"
                [ngClass]="{ 'error-label': (!confirmEmailRef.valid || (confirmEmailRef.value && email.value && confirmEmailRef.value.toLowerCase() !== email.value.toLowerCase())) }">Confirm
                Email:</label>
              <input type="email" id="confirmEmail" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
                name="confirmEmail" [(ngModel)]="confirmEmail" #confirmEmailRef="ngModel" required maxlength="256"
                placeholder="Email"
                [ngClass]="{'input-invalid': showValidation == true && (!confirmEmailRef.valid || confirmEmailRef.value.toLowerCase() !== email.value.toLowerCase()),
                'input-valid': showValidation == true && (confirmEmailRef.valid && (confirmEmailRef.value.toLowerCase() === email.value.toLowerCase()))}">
              <div [hidden]="!showValidation || confirmEmailRef.valid" class="invalid-feedback">
                Please enter valid email confirmation
              </div>
              <div
                [hidden]="!showValidation || !confirmEmailRef.valid  || (email.value && confirmEmailRef.value && (email.value.toLowerCase() === confirmEmailRef.value.toLowerCase()))"
                class="invalid-feedback">
                Entered values must match
              </div>
            </div>
            <div *ngIf="carrierShortName === ('cigna')" class="form-check">
              <input type="checkbox" class="form-check-input check-input-big" [(ngModel)]="model.writtenCorrespondence"
                id="writtenCorrespondence" name="writtenCorrespondence">

              <label for="writtenCorrespondence">
                <strong>I prefer to receive written correspondence regarding this application at the email address
                  provided at this application. </strong>
              </label>
            </div>            
            <div class="form-group-block col-12">
              <p *ngIf="carrierShortName === 'anthembcbs'">
                <em>I’m providing my email address because <strong>I, and my enrolled dependents, want to receive
                    information about our benefits
                    electronically</strong>. These communications may include Identification (ID) Cards, Contracts or
                  Certificates of Coverage,
                  billing invoices, Explanation of Benefits, required notices including cancellations and renewals, and
                  helpful or specific
                  personalized information to help get the most out of the benefits. I understand I need to register on
                  anthem.com or the
                  Anthem mobile app to get the most out of my plan’s digital tools, and I will make sure Anthem has my
                  most up to date email
                  address. I, and my enrolled dependents, understand that we can update our email addresses, change our
                  communication
                  preferences, and request free copies of any materials at any time by going to anthem.com or calling
                  the
                  Member Services
                  number on my ID card.</em>
              </p>
              <p *ngIf="carrierShortName.startsWith('empire')">
                <em>I’m providing my email address because <strong>I want to receive information about my benefits
                    electronically</strong>. These
                  communications may include Identification (ID) Cards, Contracts or Certificates of Coverage, billing
                  invoices, Explanation of
                  Benefits, required notices including cancellations and renewals, and helpful or specific personalized
                  information to help get the
                  most out of the benefits. I understand I need to register on empireblue.com or the Empire mobile app
                  to
                  get the most out
                  of my plan’s digital tools, and I will make sure Empire has my most up to date email address. I
                  understand that I can
                  update my email address, communication preferences, and request free copies of any materials by going
                  to
                  empireblue.com or
                  calling the Member Services number on my ID card.</em>
              </p>
              <p *ngIf="carrierShortName === ('cigna')">
                <em>By signing this application and providing my phone number or email, I agree that Cigna, its
                  affiliates,
                  and representatives may contact me regarding additional products or services by calling or texting me
                  at
                  the number above, by email, or by letter. I agree that Cigna may use the information provided or
                  obtained
                  in connection with this application, or insurance coverage provided by Cigna including my personal
                  information,
                  to offer me additional products and services or to send related marketing communications regarding
                  Cigna
                  products. I acknowledge that I am not required to provide consent to receive these communications as a
                  condition of applying for coverage. If I choose not to receive marketing communications, I will
                  indicate
                  that below or can withdraw my consent at anytime by contacting Cigna.</em>
              </p>
            </div>
          </div>
          <div *ngIf="carrierShortName === ('cigna')" class="form-check">
            <input type="checkbox" class="form-check-input check-input-big" [(ngModel)]="model.marketingConsent"
              id="makertingConsent" name="marketingConsent">

            <label for="marketingConsent">
              <strong>I do not consent to receive marketing communications.</strong>
            </label>
          </div>

          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>

        </form>
        <br />

        <app-disclaimers></app-disclaimers>
      </section>
    </div>

  </div>
</main>