<footer>
    <section id="footer" class="footer">
        <div class="section-wrapper block content-1170 center-relative">
            <div class="content-wrapper">

                <div class="one_half">
                    <div>
                        <div class="brand">
                            <img id="footerLogo" [src]="logoUrl$ | async" alt="Brand">
                        </div>
                        <div class="phone">
                            <p>{{phone$ | async}}</p>
                        </div>
                    </div>
                </div>

                <div id="footerLearningCenter" class="one_half last">
                    <div class="sep-1">
                        <ul *ngIf="showPages$ | async">
                            <li><a [attr.href]="(quotesWebUrl$ | async) + '/resources/' + (getAffiliateUtmSuffix() | async)"
                                    title="Link to the learning center to access all resources.">Learning Center</a>
                            </li>
                        </ul>
                    </div>

                    <div class="sep-2" *ngIf="showPages$ | async">
                        <ul>
                            <li><a [attr.href]="(quotesWebUrl$ | async) + '/about/' + (getAffiliateUtmSuffix() | async)"
                                    title="Link to the About Us page with company information.">About
                                    Us</a></li>
                            <li><a [attr.href]="(quotesWebUrl$ | async) + '/contact-us/' + (getAffiliateUtmSuffix() | async)"
                                    title="Link to the Contact Us page for additional ways to reach us.">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div class="content-wrapper">
                <section class="copyright copyright-holder">
                    <div class="privacy">
                        <div class="year">© 1999 - {{currentDate | date:'yyyy'}}</div>
                        <div class="info">
                            Kelsey National Corporation or its affiliates.
                        </div>
                        <div class="all-rights">All rights reserved.</div>
                    </div>

                    <div class="site-map">
                        <ul>
                            <li><a [attr.href]="(quotesWebUrl$ | async) + '/privacy-policy/' + (getAffiliateUtmSuffix() | async)"
                                    title="Link to our full privacy policy.">Privacy Policy</a>
                            </li>
                            <li><a [attr.href]="(quotesWebUrl$ | async) + '/legal-terms-and-conditions/' + (getAffiliateUtmSuffix() | async)"
                                    title="Link to the Terms for using this site.">Terms of Use</a></li>
                            <li><a *ngIf="showPages$ | async"
                                    [attr.href]="(quotesWebUrl$ | async) + '/site-map/' + (getAffiliateUtmSuffix() | async)"
                                    title="Link to the our full site map of all pages.">Sitemap</a>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>

        </div>

    </section>

</footer>

<!-- <footer *ngIf="(affiliate | async) || defaultAffiliate; let affiliate" class="no-print">
    <div class="container">
        <div class="footer-sections">
            <div class="footer-section">
                <img *ngIf="affiliate.isPrerendered" [src]="quotesWebUrl + '/assets/img/dicom-logo-small.png'"
                    width="200" alt="Dental Insurance" class="minilogo">
                <img *ngIf="!affiliate.isPrerendered"
                    [src]="quotesWebUrl + '/assets/img/powered-by-dicom-logo-small.png'" width="200"
                    alt="Dental Insurance" class="minilogo">
                <p>Apply for new dental plans online or enhance existing benefits easily and affordably.</p>

            </div>
            <div *ngIf="affiliate.isPrerendered" class="footer-section">
                <h3>Useful</h3>
                <div class="separator-left"></div>
                <ul>
                    <li><a [attr.href]="quotesWebUrl + '/resources' + getAffiliateUtmSuffix(affiliate)"
                            title="Resources">Learning Center</a></li>
                </ul>
            </div>
            <div *ngIf="affiliate.isPrerendered" class="footer-section">
                <h3>Company</h3>
                <div class="separator-left"></div>
                <ul>
                    <li><a [attr.href]="quotesWebUrl + '/about' + getAffiliateUtmSuffix(affiliate)">About Us</a></li>
                    <li><a [attr.href]="quotesWebUrl + '/contact-us' + getAffiliateUtmSuffix(affiliate)">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="copyrights">
        <div class="container copyrights-sections">
            <div>
                <p>© 1999 - {{currentDate | date:'yyyy'}} Kelsey National Corporation or its affiliates. All
                    rights reserved.</p>

            </div>
            <div>
                <a [attr.href]="quotesWebUrl + '/privacy-policy' + getAffiliateUtmSuffix(affiliate)">Privacy
                    Policy</a>
                <a [attr.href]="quotesWebUrl + '/legal-terms-and-conditions' + getAffiliateUtmSuffix(affiliate)">Terms
                    of Use</a>
                <a *ngIf="affiliate.isPrerendered" [attr.href]="quotesWebUrl + '/site-map' + getAffiliateUtmSuffix(affiliate)">Site Map</a>
            </div>
        </div>
    </div>
</footer> -->