<div [ngClass]="{ showValidationContainer: showValidation === true }">
  <div>
    <span [innerHTML]="fraudNotice"></span>
  </div>
  <div class="pre-scrollable" [innerHTML]="authorizationNotice"></div>
  <div class="pre-scrollable" [innerHTML]="additionalNotice"></div>
  <div class="form-check" [ngClass]="{'checkbox-invalid': showValidation == true && !authorizationNoticeCheckboxModel}">
    <input type="checkbox" class="form-check-input check-input-big" name="authorizationNoticeCheckbox"
      id="authorizationNoticeCheckbox" [(ngModel)]='authorizationNoticeCheckboxModel' required
      #authorizationNoticeCheckboxInput="ngModel">
    <label for="authorizationNoticeCheckbox"
      [ngClass]="{ 'error-label': authorizationNoticeCheckboxInput.invalid }"><strong
        [innerHTML]="authorizationNoticeCheckbox"></strong></label>
    <div [hidden]="!showValidation || authorizationNoticeCheckboxModel === true" class="invalid-feedback">
      Please check box
    </div>
  </div>
  <form #cignaForm="ngForm" *ngIf="(stateAbbr === 'NJ' || stateAbbr === 'VA')">

    <div *ngFor="let member of requiredMembers; let i=index">
      <hr>
      <div class="form-row">
        <div class="form-group-block col-12">
          <p><strong>Electronic Signature for {{member.firstName}} {{member.lastName}} ({{member.birthdate}})</strong>
          </p>
          <br />
          <p>All applicants over age 18 must personally read, agree to, sign and date this application.</p>
          <br />
          <p class="font-italic">Please type your name in the spaces below to electronically sign your application</p>
        </div>
      </div>
      <div class="form-row form-row-valign-bottom">
        <div class="form-group-block col-3">
          <label for="firstName{{i}}"
            [ngClass]="{ 'error-label': (!firstNameField.valid || (firstNameField.value && firstNameField.value.toLowerCase() !== member.firstName.toLowerCase()))  }">{{getMemberTitle(member)}}<br
              *ngIf="getMemberTitle(member) === 'Child'" /> first
            name:</label>
          <input type="text" id="firstName{{i}}" [(ngModel)]="firstNameVerifications[i]" name="firstName{{i}}"
            #firstNameField="ngModel" required
            [ngClass]="{'input-invalid': showValidation == true && (!firstNameField.valid || firstNameField.value.toLowerCase() !== member.firstName.toLowerCase()),
            'input-valid': showValidation == true && firstNameField.valid && firstNameField.value.toLowerCase() === member.firstName.toLowerCase()}">
          <div [hidden]="!showValidation || firstNameField.valid" class="invalid-feedback">
            {{getMemberTitle(member)}} first name is required
          </div>
          <div
            [hidden]="!showValidation || !(firstNameField.valid && firstNameField.value.toLowerCase() !== member.firstName.toLowerCase())"
            class="invalid-feedback">
            Entered name doesn't match "{{member.firstName}}"
          </div>
        </div>
        <div class="form-group-block col-2">
          <label for="mi{{i}}">MI <span class="break">(optional):</span></label>
          <input type="text" id="mi{{i}}" name="mi{{i}}">
        </div>
        <div class="form-group-block col-3">
          <label for="lastName{{i}}"
            [ngClass]="{ 'error-label': (!lastNameField.valid || (lastNameField.value && lastNameField.value.toLowerCase() !== member.lastName.toLowerCase()))  }">{{getMemberTitle(member)}}<br
              *ngIf="getMemberTitle(member) === 'Child'" /> last
            name:</label>
          <input type="text" id="lastName{{i}}" [(ngModel)]="lastNameVerifications[i]" name="lastName{{i}}"
            #lastNameField="ngModel" required
            [ngClass]="{'input-invalid': showValidation == true && (!lastNameField.valid || lastNameField.value.toLowerCase() !== member.lastName.toLowerCase()),
            'input-valid': showValidation == true && lastNameField.valid && lastNameField.value.toLowerCase() === member.lastName.toLowerCase()}">
          <div [hidden]="!showValidation || lastNameField.valid" class="invalid-feedback">
            {{getMemberTitle(member)}} last name is required
          </div>
          <div
            [hidden]="!showValidation || !(lastNameField.valid && lastNameField.value.toLowerCase() !== member.lastName.toLowerCase())"
            class="invalid-feedback">
            Entered name doesn't match "{{member.lastName}}"
          </div>
        </div>

      </div>

      <br />

      <div class="form-row">
        <div class="form-group-block col-12">
          <p class="font-italic">Please re-type your name in the spaces below to confirm your electronic signature</p>
        </div>
      </div>

      <div class="form-row form-row-valign-bottom">
        <div class="form-group-block col-3">
          <label for="firstNameRetyped{{i}}"
            [ngClass]="{ 'error-label': (!firstNameRetypedField.valid || (firstNameRetypedField.value && firstNameField.value && firstNameRetypedField.value.toLowerCase() !== firstNameField.value.toLowerCase()))  }">{{getMemberTitle(member)}}<br
              *ngIf="getMemberTitle(member) === 'Child'" />
            first name:</label>
          <input type="text" id="firstNameRetyped{{i}}" [(ngModel)]="firstNameVerificationsReTyped[i]"
            name="firstNameRetyped{{i}}" #firstNameRetypedField="ngModel" required
            [ngClass]="{'input-invalid': showValidation == true && (!firstNameRetypedField.valid || firstNameRetypedField.value.toLowerCase() !== firstNameField.value.toLowerCase()),
            'input-valid': showValidation == true && firstNameRetypedField.valid && firstNameRetypedField.value.toLowerCase() === firstNameField.value.toLowerCase()}">
          <div [hidden]="!showValidation || firstNameRetypedField.valid" class="invalid-feedback">
            {{getMemberTitle(member)}} first name is required
          </div>
          <div
            [hidden]="!showValidation || !(firstNameRetypedField.valid && firstNameRetypedField.value.toLowerCase() !== firstNameField.value.toLowerCase())"
            class="invalid-feedback">
            Entered values must match
          </div>
        </div>
        <div class="form-group-block col-2">
          <label for="miv{{i}}">MI <span class="break">(optional):</span></label>
          <input type="text" id="miv{{i}}" name="miv{{i}}">
        </div>
        <div class="form-group-block col-3">
          <label for="lastNameRetyped{{i}}"
            [ngClass]="{ 'error-label': (!lastNameRetypedField.valid || (lastNameRetypedField.value && lastNameField.value &&lastNameRetypedField.value.toLowerCase() !== lastNameField.value.toLowerCase()))  }">{{getMemberTitle(member)}}<br
              *ngIf="getMemberTitle(member) === 'Child'" />
            last
            name:</label>
          <input type="text" id="lastNameRetyped{{i}}" [(ngModel)]="lastNameVerificationsReTyped[i]"
            name="lastNameRetyped{{i}}" #lastNameRetypedField="ngModel" required
            [ngClass]="{'input-invalid': showValidation == true && (!lastNameRetypedField.valid || lastNameRetypedField.value.toLowerCase() !== lastNameField.value.toLowerCase()),
            'input-valid': showValidation == true && lastNameRetypedField.valid && lastNameRetypedField.value.toLowerCase() === lastNameField.value.toLowerCase()}">
          <div [hidden]="!showValidation || lastNameRetypedField.valid" class="invalid-feedback">
            {{getMemberTitle(member)}} last name is required
          </div>
          <div
            [hidden]="!showValidation || !(lastNameRetypedField.valid && lastNameRetypedField.value.toLowerCase() !== lastNameField.value.toLowerCase())"
            class="invalid-feedback">
            Entered values must match
          </div>
        </div>
        <div class="form-group-block col-3">
          <label for="date{{i}}">Date Electronically Signed:</label>
          <input type="text" id="date{{i}}" [value]="todaysDate | date : 'MM/dd/yyyy'" name="date{{i}}" disabled>
        </div>
      </div>
      <br>
    </div>
  </form>
</div>