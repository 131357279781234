import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { Dentist } from '../shared/models/dentist.model';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-dentist',
    templateUrl: './dentist.component.html',
    styleUrls: ['./dentist.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DentistComponent), multi: true }],
    standalone: true,
    imports: [NgIf, FormsModule, NgClass]
})
export class DentistComponent implements ControlValueAccessor {
  public disabled = false;


  public facilityId!: string;
  public dentistId!: string;
  public facilityName!: string;
  public dentistName!: string;

  @Input() public askFacilityId!: boolean;
  @Input() public askFacilityName!: boolean;
  @Input() public askDentistId!: boolean;
  @Input() public askDentistName!: boolean;
  @Input() public readonly!: boolean;
  @Input() public facilityIdMaxLength = 32;

  @Input() public facilityIdTitle!: string;
  @Input() public facilityIdOnlyDigits!: boolean;

  @Input() public showValidation!: boolean;



  private propagateChange = (_: Dentist | null) => { };
  private propagateTouched = (_: Dentist) => { };


  constructor() { }

  get value(): Dentist | null {
    let valid = true;

    if ((this.askFacilityId && !this.facilityId) ||
      (this.askFacilityName && !this.facilityName) ||
      (this.askDentistId && !this.dentistId) ||
      (this.askDentistName && !this.dentistName)) {
      valid = false;
    }

    if (valid) {
      return new Dentist(this.facilityId, this.facilityName, this.dentistId, this.dentistName);
    } else {
      return null;
    }
  }

  set value(newValue: Dentist) {
    if (newValue) {
      this.facilityId = newValue.facilityId;
      this.facilityName = newValue.facilityName;
      this.dentistId = newValue.dentistId!;
      this.dentistName = newValue.dentistName!;
      this.propagateChange(this.value);
    }
  }

  writeValue(value: Dentist): void {
    this.value = value;
  }
  registerOnChange(fn: (value: Dentist | null) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onModelChange() {
    this.propagateChange(this.value);
  }

  onFacilityInput(target: any) {
    if (this.facilityIdOnlyDigits) {
      target.value = this.stripNonDigits(target.value);
    }
  }

  onInput(target: any) {
    target.value = this.stripNonDigits(target.value);
  }

  private stripNonDigits(value: string): string {
    let newValue = '';
    for (let i = 0; i <= value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        newValue += value[i];
      }
    }
    return newValue;
  }

}
