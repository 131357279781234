<div class="date enroll-date">
    <input name="month" type="tel" pattern="[0-9]*" [(ngModel)]="month" [disabled]="disabled" [readonly]="readonly"
        (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="mm" maxlength="2"
        class="month">/
    <input name="day" type="tel" pattern="[0-9]*" [(ngModel)]="day" [disabled]="disabled" [readonly]="readonly"
        (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="dd" maxlength="2"
        (focus)="onFocusDayFunction()" class="day">/
    <input name="year" type="tel" pattern="[0-9]*" [(ngModel)]="year" [disabled]="disabled" [readonly]="readonly"
        (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="yyyy" maxlength="4"
        (focus)="onFocusYearFunction()" class="year">
</div>