import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Plan } from '../models/plan';
import { PaymentFrequency } from '../models/paymentFrequency';
import { IPaymentInfo } from '../models/paymentInfo';
import { InitialPaymentType } from '../models/initialPaymentType';
import { PaymentType } from '../models/paymentType';
import { PlanAppInfo } from '../models/planAppInfo';
import { Census } from '../models/census.model';
import { Address } from '../models/address.model';
import { ExchangeCompany } from '../models/exchange-company.model';
import { PaymentProcessingDescription } from '../models/payment-processing-description.model';
import { Notice } from '../models/notice.model';
import { CreditCard } from '../models/creditCard';
import { EFT } from '../models/eft';
import { StateSpecificQuestions } from '../../metlife/models/state-specific-questions.model';
import { AnthemExistingCoverage } from '../models/anthem-existing-coverage';
import { AnthemDisabilityQuestions } from '../models/anthem-disability-questions.model';
import { BehaviorSubject } from 'rxjs';
import { Frequency } from '../models/frequency';
import { Observable } from 'rxjs';
import { Campaign } from '../models/campaign.model';
import { environment } from 'src/environments/environment';
import { CignaExistingCoverage } from '../models/cigna-existing-coverage';


@Injectable()
export class EnrollmentService {

  public exchCompany = new BehaviorSubject<ExchangeCompany | null>(null);


  private serviceUrl = environment.apiEndpoint + '/enrollments';
  public confirmationId!: number;
  public createdOn!: string;
  public planId!: string;
  public requestId!: string;
  public plan!: Plan;
  public census!: Census;
  public premium!: number | undefined;
  public address!: Address;
  public affiliateId!: number;

  public cancellationsDescription!: string;
  public paymentNotes!: string;

  public requestApplicantSSN!: boolean;
  public requestCensusSSN!: boolean;

  public requestApplicantDentistInfo!: boolean;
  public requestCensusDentistInfo!: boolean;
  public requestFacilityId!: boolean;
  public requestFacilityName!: boolean;
  public requestDentistId!: boolean;
  public requestDentistName!: boolean;
  public dentistSearchUrl!: string;
  public dentistSearchNote!: string;

  public paymentFrequencies!: Array<PaymentFrequency>;
  public selectedPaymentFrequency!: PaymentFrequency;
  public selectedInitialPaymentType!: InitialPaymentType;
  public selectedOngoingPaymentType!: PaymentType;
  public initialPaymentInfo!: IPaymentInfo;
  public ongoingPaymentInfo!: IPaymentInfo;
  public enrollmentNotices!: Array<Notice>;
  public initialPaymentsProcessingDescriptions!: Array<PaymentProcessingDescription>;
  public ongoingPaymentsProcessingDescriptions!: Array<PaymentProcessingDescription>;
  public totalInitialPaymentAmount!: number;
  public totalOngoingPaymentAmount!: number;
  public authorizationNotices!: Array<Notice>;
  public ongoingPaymentDayOptions!: Array<number>;
  public initialPaymentDayOptions!: Array<string>;
  public allowDifferentBillingAddress!: boolean;
  public allowTrustPilot!: boolean;

  public existingCoverageHasCoverage!: boolean;
  public existingCoverageCoverageReplacement!: boolean;
  public existingCoverageCarrier!: string;
  public existingCoveragePhone!: string;
  public existingCoveragePolicyNumber!: string;
  public existingCoverageEffectiveDate!: string;
  public existingCoverageTerminationDate!: string;
  public existingCoverageProduct!: string;

  public eDelivery: boolean = true;

  public agent!: string;
  public agentId = new BehaviorSubject<string|null>(null);

  public exchangeCompany!: ExchangeCompany;
  public isEmailedQuote!: boolean;

  public stateSpecificQuoestions!: StateSpecificQuestions;

  public clientIP!: string | null;

  public spouseNote!: string;

  public anthemHasCoverage!: boolean;
  public cignaHasCoverage!: boolean;
  public anthemExistingCoverages!: Array<AnthemExistingCoverage> | null;
  public cignaExistingCoverages!: Array<CignaExistingCoverage> | null;
  public anthemDisabilityQuestions = new AnthemDisabilityQuestions();
  public hostName: string;

  public accessToken!: string;
  public signatureToken!: string;

  public campaign!: Campaign; 
  public campaignName!: string;
  public campaignSource!: string;
  public campaignSourceName = new BehaviorSubject<string|null>(null);

  public enrollmentAssistance!: boolean;
  public enrollmentTranslator!: string;
  public cantReadEnglish!: boolean;
  public cantWriteEnglish!: boolean;
  public cantSpeakEnglish!: boolean;
  public donationLifeRegistry!: boolean;

  constructor(private http: HttpClient) {
    this.hostName = document.location.hostname;
  }

  getQuotesUrl(isPrerendered: boolean) {
    if (this.hostName.indexOf('yourdentalexchange') !== -1) {
      return environment.quotesYDEWebUrl;
    } else if (this.hostName.indexOf('retireedentalexchange') !== -1) {
      return environment.quotesRDEWebUrl;
    } else if (this.hostName.indexOf('dentalsavants') !== -1) {
      return environment.quotesDentalSavantsWebUrl;
    }  else if (this.hostName.indexOf('quotesavantdental') !== -1) {
      return environment.quotesQuoteSavantDentalWebUrl;
    }  else if (this.hostName.indexOf('visionsavants') !== -1) {
      return environment.quotesVisionSavantsWebUrl;
    }  else if (this.hostName.indexOf('quotesavantvision') !== -1) {
      return environment.quotesQuoteSavantVisionWebUrl;
    }  
    else {      
      return isPrerendered ? environment.quotesWebUrl : environment.affQuotesWebUrl;
    }
  }

  getEnrollmentUrl() {
    if (this.hostName.indexOf('yourdentalexchange') !== -1) {
      return environment.enrollmentYDEWebUrl;
    } else if (this.hostName.indexOf('retireedentalexchange') !== -1) {
      return environment.enrollmentRDEWebUrl;
    }  else if (this.hostName.indexOf('dentalsavants') !== -1) {
      return environment.enrollmentDentalSavantsWebUrl;
    }  else if (this.hostName.indexOf('quotesavantdental') !== -1) {
      return environment.enrollmentQuoteSavantDentalWebUrl;
    }  else if (this.hostName.indexOf('visionsavants') !== -1) {
      return environment.enrollmentVisionSavantsWebUrl;
    }  else if (this.hostName.indexOf('quotesavantvision') !== -1) {
      return environment.enrollmentQuoteSavantVisionWebUrl;
    } else {
      return environment.enrollmentWebUrl;
    }
  }

  getAmeritasTier(): number {
    if (this.census.familyMembers.length > 2) {
      return 3;
    } else {
      return this.census.familyMembers.length;
    }
  }

  getAmeritasOOCReq(): Observable<boolean> {
    return this.http.get<boolean>(`${this.serviceUrl}/getameritasoocreq?stateAbbr=${this.census.stateAbbr}`);
  }

  getAmeritasOOC(): any {
    let headers = new HttpHeaders({
      'Accept': 'application/pdf,application/json'
    });

    return this.http.get(`${this.serviceUrl}/getameritasooc?coverageTier=${this.getAmeritasTier()}&planId=${this.planId}&stateAbbr=${this.census.stateAbbr}&zip=${this.census.zip}`,
      { headers: headers, responseType: 'blob' });
  }

  getPlanAppInfo(): Observable<PlanAppInfo> {
    return this.http.get<PlanAppInfo>(`${this.serviceUrl}/?planId=${this.planId}&requestId=${this.requestId}`);
  }

  public loadStage(sessionId: string): Observable<any> {
    return this.http.get(`${this.serviceUrl}/loadstage?sessionId=${sessionId}`)
  }

  public postEnrollmentToAPI(): Observable<number> {
    return this.http.post<number>(`${this.serviceUrl}`, this.getEnrollmentData());
  }

  public postCCToAPI(): Observable<number> {
    return this.http.post<number>(`${this.serviceUrl}/postcc`, this.getEnrollmentData());
  }

  public checkVSP(): Observable<boolean> {
    return this.http.post<boolean>(`${this.serviceUrl}/checkvsp`, this.getEnrollmentData());
  }

  public postStageEnrollmentToAPI(): Observable<string> {
    return this.http.post<string>(`${this.serviceUrl}/stage`, this.getEnrollmentData());
  }  

  private getEnrollmentData(): object {
    return {
      applicationId: this.confirmationId,
      planId: this.planId,
      requestId: this.requestId,
      plan: this.plan,
      census: this.census,
      address: this.address,
      premium: this.premium,
      totalInitialPaymentAmount: this.totalInitialPaymentAmount,
      totalOngoingPaymentAmount: this.totalOngoingPaymentAmount,
      selectedPaymentFrequency: (this.selectedPaymentFrequency ? this.selectedPaymentFrequency.frequency : Frequency.Monthly),
      initialPaymentInfoCC: (this.initialPaymentInfo instanceof CreditCard) ? this.initialPaymentInfo : null,
      initialPaymentInfoEFT: (this.initialPaymentInfo instanceof EFT) ? this.initialPaymentInfo : null,
      ongoingPaymentInfoCC: (this.ongoingPaymentInfo instanceof CreditCard) ? this.ongoingPaymentInfo : null,
      ongoingPaymentInfoEFT: (this.ongoingPaymentInfo instanceof EFT) ? this.ongoingPaymentInfo : null,
      existingCoverageHasCoverage: this.existingCoverageHasCoverage,
      existingCoverageCoverageReplacement: this.existingCoverageCoverageReplacement,
      existingCoverageCarrier: this.existingCoverageCarrier,
      existingCoveragePhone: this.existingCoveragePhone,
      existingCoveragePolicyNumber: this.existingCoveragePolicyNumber,
      existingCoverageEffectiveDate: this.existingCoverageEffectiveDate,
      existingCoverageTerminationDate: this.existingCoverageTerminationDate,
      existingCoverageProduct: this.existingCoverageProduct,
      eDelivery: this.eDelivery,
      stateSpecificQuestions: this.stateSpecificQuoestions,
      anthemExistingCoverages: this.anthemExistingCoverages,
      cignaExistingCoverages: this.cignaExistingCoverages,
      anthemDisabilityQuestions: this.anthemDisabilityQuestions,
      createdOn: this.createdOn,
      clientIP: this.clientIP,
      allowTrustPilot: this.allowTrustPilot,
      hostName: this.hostName,
      isEmailedQuote: this.isEmailedQuote,
      enrollmentAssistance: this.enrollmentAssistance,
      enrollmentTranslator: this.enrollmentTranslator,
      cantReadEnglish: this.cantReadEnglish,
      cantSpeakEnglish: this.cantSpeakEnglish,
      cantWriteEnglish: this.cantWriteEnglish,
      donationLifeRegistry: this.donationLifeRegistry
    };
  }

  public getVSPAccessAjaxToken(): Observable<string> {
    return this.http.post<string>(`${this.serviceUrl}/accesstoken`, {  } , {
      headers: { 'Content-Type': 'application/json' }, responseType: 'text' as 'json'
    });
  }

  public getVSPResponsePacket(): Observable<any> {
    return this.http.get(`${this.serviceUrl}/responsepacket?signature=${encodeURIComponent(this.signatureToken)}&accesstoken=${this.accessToken}`,
    {headers: { 'Content-Type': 'application/x-www-form-urlencoded' } , responseType: 'json'});   
  }
  
}

