import { Injectable } from '@angular/core';
import { Affiliate } from '../models/affiliate.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CoreAffiliates } from './core-affiliates';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  public readonly defaultAffiliate = CoreAffiliates[0];

  private readonly _affiliate = new BehaviorSubject<Affiliate | null>(this.defaultAffiliate);

  public readonly affiliate$ = this._affiliate.asObservable();

  public affiliate = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    
  }

  set affiliate_id(val: number) {


    /*const todaysDate = new Date();
    const sessionAffiliateId = localStorage.getItem('affiliateId');

    if (sessionAffiliateId) {
      const expires = new Date(localStorage.getItem('expiresOn')!);
      if (expires < todaysDate) {
        localStorage.removeItem('expiresOn');
        localStorage.removeItem('affiliateId');
      }

      if (+sessionAffiliateId !== val && val === this.defaultAffiliate.affiliateId) {
        val = +sessionAffiliateId;
      }
    }
    if (+sessionAffiliateId! !== val && val !== this.defaultAffiliate.affiliateId) {
      this.saveAffiliate(val);
    }*/


    const coreAffiliate = CoreAffiliates.find(x => x.affiliateId === val);
    if (coreAffiliate) {
      this.emmitAffiliate(coreAffiliate);
    }
    else {
      this.http.get<Affiliate>(environment.apiEndpoint + '/affiliates/' + val).subscribe(aff => this.emmitAffiliate(aff));
    }
  }

  private emmitAffiliate(affiliate: Affiliate) {
    let setAffiliate = { ...affiliate };

    if (!setAffiliate.phone || setAffiliate.phone === '') {
      setAffiliate.phone = this.defaultAffiliate.phone;
    }
    if (!setAffiliate.hoursOfOperation || setAffiliate.hoursOfOperation === '') {
      setAffiliate.hoursOfOperation = this.defaultAffiliate.hoursOfOperation;
    }
    if (!setAffiliate.logoUrl || setAffiliate.logoUrl === '' || setAffiliate.logoUrl === 'www' || setAffiliate.logoUrl === 'assets/img/logo.png' ) {
      setAffiliate.logoUrl = 'assets/img/svg/new-logo.svg';
    } else {
      setAffiliate.logoUrl = `assets/img/affiliate_logos/${setAffiliate.logoUrl}.png`;
    }

    // Set vars for Comm100 chat
    window.affiliate_name = setAffiliate.name;
    window.utm_campaign = setAffiliate.affiliateId;

    this._affiliate.next(setAffiliate);
  }

  saveAffiliate(id: Number): void {
    const todaysDate = new Date();
    const currentMonth = todaysDate.getMonth();
    todaysDate.setMonth(currentMonth + 6);
    localStorage.setItem('expiresOn', todaysDate.toDateString());
    localStorage.setItem('affiliateId', id.toString());
  }

    
  public getAffiliateFromAPI(affiliateId: number): Observable<Affiliate> {
    const aff = this.http.get<Affiliate>(environment.apiEndpoint + '/affiliates/' + affiliateId);
    this.affiliate.next(aff);
    return aff;
	}

}
