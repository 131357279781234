<div *ngIf="loading" class="loader"></div>
<div [ngClass]="{ showValidationContainer: showValidation === true }">
    <div>
        <span [innerHTML]="fraudNotice"></span>
    </div>
    <div class="pre-scrollable" [innerHTML]="authorizationNotice"></div>
    <div class="form-check"
        [ngClass]="{'checkbox-invalid': showValidation == true && !authorizationNoticeCheckboxModel}">
        <input type="checkbox" class="form-check-input check-input-big" name="authorizationNoticeCheckbox"
            id="authorizationNoticeCheckbox" [(ngModel)]='authorizationNoticeCheckboxModel' required #authorizationNoticeCheckboxInput="ngModel">
        <label for="authorizationNoticeCheckbox" [ngClass]="{ 'error-label': authorizationNoticeCheckboxInput.invalid }"><strong [innerHTML]="authorizationNoticeCheckbox"></strong></label>
        <div [hidden]="!showValidation || authorizationNoticeCheckboxModel === true" class="invalid-feedback">
            Please check box
        </div>
    </div>
    <div *ngIf="showOOC" class="form-check"
        [ngClass]="{'checkbox-invalid': showValidation == true && !oocNoticeCheckboxModel}">
        <input type="checkbox" class="form-check-input check-input-big" name="oocNoticeCheckbox" id="oocNoticeCheckbox"
            [(ngModel)]='oocNoticeCheckboxModel' #oocNoticeCheckboxInput="ngModel" required>
        <label for="oocNoticeCheckbox" [ngClass]="{ 'error-label': oocNoticeCheckboxInput.invalid }"><strong>By submitting this application I agree to reviewing
                the <button type="button" (click)="onClick()" class="btn btn-link">outline of
                    coverage</button>.</strong></label>
        <div [hidden]="!showValidation || oocNoticeCheckboxModel === true" class="invalid-feedback">
            Please check box
        </div>
    </div>
    <div class="pre-scrollable" [innerHTML]="additionalNotice"></div>
    <div>
        <span [innerHTML]="applicantAndDependentsNotice"></span>
    </div>
    <div class="form-check" [ngClass]="{'checkbox-invalid': showValidation == true && !eDelivery}">
        <input type="checkbox" class="form-check-input check-input-big" name="eDelivery" id="eDelivery"
            [(ngModel)]='eDelivery' (ngModelChange)="eDeliveryClick()" required #eDeliveryInput="ngModel">
        <label for="eDelivery" [ngClass]="{ 'error-label': eDeliveryInput.invalid }"><strong>I agree to receive plan documents, ID cards and Explanation of Benefits (EOB)
                electronically, and agree to
                the terms and conditions.</strong></label>
        <div [hidden]="!showValidation || eDelivery === true" class="invalid-feedback">
            Please check box
        </div>
    </div>
</div>

<ng-container *ngIf="eDelivery">
    <div>
        <p>Email for electronic delivery: <br><strong>{{email}}</strong></p>
    </div>
    <br>

    <p><strong>Terms and Conditions</strong></p>
    <div class="pre-scrollable" *ngIf="stateAbbr !== 'NY'">
        <p>We are pleased to offer our Policyholders the ability to receive their electronic plan documents and
            information online. If you choose to receive this online service, you will no longer receive paper copies of
            your plan documents. Instead you will be able to access this information through your personal computer.*
        </p>
        <p>You understand that by choosing this option you will no longer receive paper copies of your plan documents.
            You will be given access to view them in electronic form in lieu of physical copies. You also understand
            that you can unsubscribe from electronic delivery of plan documents by visiting our member portal at <a
                href="https://www.ameritas.com/applications/group/member"
                target="_blank">https://www.ameritas.com/applications/group/member</a>. If you choose to unsubscribe,
            you will no longer have access to view these plan documents electronically. There is no additional charge to
            have these plan documents mailed to you in paper form.</p>
        <p><em>*Enrollment in this electronic delivery service requires that you have a personal computer with
                appropriate browser software, e-mail software as well as communications access to the Internet. Browser
                requirements include the most current or previous version of one of the following: Netscape, Internet
                Explorer, Firefox Mozilla, Safari or Opera. Web pages are best viewed at an 800 x 600 screen resolution
                (web browser size of 760 x 480), and allowed to be viewed at various resolutions with minimal
                degradation of content display. Although we will not charge for electronic delivery you may incur
                charges from Internet Service Providers and local telephone companies. Should you wish to print
                materials that have been delivered electronically, you must have a printer as well. Some files are
                available in Adobe Acrobat Format. In order to view this format (.pdf), you will need to have Adobe
                Acrobat Reader software. Visit Adobe.com to obtain the most current free version of Adobe Reader.</em>
        </p>
    </div>
    <div class="pre-scrollable" *ngIf="stateAbbr === 'NY'">
        <p>We are pleased to offer our Policyholders the ability to receive their electronic plan documents and
            information online. If you choose to receive this online service, you will no longer receive paper copies of
            your plan documents. Instead you will be able to access this information through your personal computer.*
        </p>
        <p>You understand that by choosing this option you will no longer receive paper copies of your plan documents.
            You will be given access to view them in electronic form in lieu of physical copies. You also understand
            that you can unsubscribe from electronic delivery of plan documents by visiting our member portal at <a
                href="https://www.ameritas.com/applications/group/member-ny"
                target="_blank">https://www.ameritas.com/applications/group/member-ny</a>. If you choose to unsubscribe,
            you will no longer have access to view these plan documents electronically. There is no additional charge to
            have these plan documents mailed to you in paper form.</p>

        <p>*Enrollment in this electronic delivery service requires that you have a personal computer with appropriate
            browser software, e-mail software as well as communications access to the Internet. Browser requirements
            include the most current or previous version of one of the following: Netscape, Internet Explorer, Firefox
            Mozilla, Safari or Opera. Web pages are best viewed at an 800 x 600 screen resolution (web browser size of
            760 x 480), and allowed to be viewed at various resolutions with minimal degradation of content display.
            Although we will not charge for electronic delivery you may incur charges from Internet Service Providers
            and local telephone companies. Should you wish to print materials that have been delivered electronically,
            you must have a printer as well. Some files are available in Adobe Acrobat Format. In order to view this
            format (.pdf), you will need to have Adobe Acrobat Reader software. Visit Adobe.com to obtain the most
            current free version of Adobe Reader.</p>
    </div>
</ng-container>