import { NgForm, FormsModule } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { ReplacementCoverageProduct } from '../shared/models/replacement-coverage-product.enum';
import { Product } from '../shared/models/product.enum';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { DateComponent } from '../date/date.component';
import { NgClass, NgIf, NgFor, TitleCasePipe, DatePipe } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { CignaExistingCoverage } from '../shared/models/cigna-existing-coverage';
import { Gender } from '../shared/models/gender.enum';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';


@Component({
    selector: 'app-existing-coverage-cigna',
    templateUrl: './existing-coverage-cigna.component.html',
    styleUrls: ['./existing-coverage-cigna.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, NgIf, NgFor, DateComponent, DisclaimersComponent, TitleCasePipe, DatePipe]
})
export class ExistingCoverageCignaComponent implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;
  
  public hasCoverage!: boolean;
  public cignaExistingCoverages: Array<CignaExistingCoverage>;
  public coverageReplacement!: boolean;
  public carrier!: string;
  public policyNumber!: string;
  public stateAbbr!: string;
  //public product!: Product;
  //public Products = Product;
  public showValidation = false;
  public effectiveDate!: string;
  public terminationDate!: string;
  public todayDate!: string;
  public noticeDate!: string;
  public coverageTypeOptions = ReplacementCoverageProduct;
  public coverageType!: string;
  public signature!: string;
  public prodsignature!: string;

  constructor(private router: Router, private enrollmentService: EnrollmentService) { 
    this.hasCoverage = this.enrollmentService.cignaHasCoverage;
    if (enrollmentService.cignaExistingCoverages) {
      this.cignaExistingCoverages = enrollmentService.cignaExistingCoverages;
      this.hasCoverage = true;
    } else {
      this.cignaExistingCoverages = new Array<CignaExistingCoverage>();
      enrollmentService.census.familyMembers.forEach(member => {
        let cignaMember = new CignaExistingCoverage();
        cignaMember.familyMember = member;
        this.cignaExistingCoverages.push(cignaMember);
      });
    }
  }

  ngOnInit() {
    this.stateAbbr = this.enrollmentService.census.stateAbbr;
  /*  this.hasCoverage = this.enrollmentService.existingCoverageHasCoverage;
    this.coverageReplacement = this.enrollmentService.existingCoverageCoverageReplacement;
    this.carrier = this.enrollmentService.existingCoverageCarrier;
    this.policyNumber = this.enrollmentService.existingCoveragePolicyNumber;
    this.effectiveDate = this.enrollmentService.existingCoverageEffectiveDate;
    //this.product = <Product>this.enrollmentService.plan.planType.productId;
    this.terminationDate = this.enrollmentService.existingCoverageTerminationDate;
    this.coverageType = this.enrollmentService.existingCoverageProduct;*/
  }

onSubmit() {
  if (!this.existingCoverageForm.form.valid || (this.hasCoverage && !this.coverageReplacement) || !this.coverageInfoProvided()) {
    this.showValidation = true;
    return;
  }
  this.saveCoverage();
  /*this.enrollmentService.existingCoverageHasCoverage = this.hasCoverage;
  this.enrollmentService.existingCoverageCoverageReplacement = this.coverageReplacement;
  this.enrollmentService.existingCoverageCarrier = this.carrier;
  this.enrollmentService.existingCoveragePolicyNumber = this.policyNumber;
  this.enrollmentService.existingCoverageEffectiveDate = this.effectiveDate;
  this.enrollmentService.existingCoverageTerminationDate = this.terminationDate;
  this.enrollmentService.existingCoverageProduct = this.coverageType;  */
  this.router.navigate(['/ssn']);//this.router.navigate(['/contact']);
}

coverageInfoProvided() {
  let provided = false;
  if (this.hasCoverage) {
    this.cignaExistingCoverages.forEach(e => {
      if (e.hasCoverage === true) {
        provided = true;
      }
    });
    return provided;
  }
  return true;
}

saveCoverage() {
  this.enrollmentService.cignaHasCoverage = this.hasCoverage;
  if (this.hasCoverage) {
    this.enrollmentService.cignaExistingCoverages = this.cignaExistingCoverages;
  } else {
    this.enrollmentService.cignaExistingCoverages = null;
  }
}

onBackClick() {
  const dependentsCount = this.enrollmentService.census.familyMembers
  .filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT).length;

this.saveCoverage();

if (dependentsCount > 0) {
  this.router.navigate(['/census']);
} else {
  this.router.navigate(['/contact']);
}
}

}
