import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { AnthemExistingCoverage } from '../shared/models/anthem-existing-coverage';
import { Gender } from '../shared/models/gender.enum';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { NgForm, FormsModule } from '@angular/forms';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { DateComponent } from '../date/date.component';
import { NgClass, NgIf, NgFor, TitleCasePipe, DatePipe } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-existing-coverage-anthem',
    templateUrl: './existing-coverage-anthem.component.html',
    styleUrls: ['./existing-coverage-anthem.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, NgIf, NgFor, DateComponent, DisclaimersComponent, TitleCasePipe, DatePipe]
})
export class ExistingCoverageAnthemComponent implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;
  public hasCoverage: boolean;
  public anthemExistingCoverages: Array<AnthemExistingCoverage>;
  public state: string;
  public showValidation = false;

  public genderOptions = Gender;

  constructor(private router: Router, private enrollmentService: EnrollmentService) {
    this.state = this.enrollmentService.census.stateAbbr;
    this.hasCoverage = this.enrollmentService.anthemHasCoverage;
    if (enrollmentService.anthemExistingCoverages) {
      this.anthemExistingCoverages = enrollmentService.anthemExistingCoverages;
      this.hasCoverage = true;
    } else {
      this.anthemExistingCoverages = new Array<AnthemExistingCoverage>();
      enrollmentService.census.familyMembers.forEach(member => {
        let anthemMember = new AnthemExistingCoverage();
        anthemMember.familyMember = member;
        this.anthemExistingCoverages.push(anthemMember);
      });
    }
  }

  ngOnInit() {

  }

  onSubmit() {
    if (!this.existingCoverageForm.form.valid || !this.coverageInfoProvided()) {
      this.showValidation = true;
      return;
    }
    this.saveCoverage();
    this.router.navigate(['/ssn']);
  }

  coverageInfoProvided() {
    let provided = false;
    if (this.hasCoverage) {
      this.anthemExistingCoverages.forEach(e => {
        if (e.hasCoverage === true) {
          provided = true;
        }
      });
      return provided;
    }
    return true;
  }

  onBackClick() {
    const dependentsCount = this.enrollmentService.census.familyMembers
      .filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT).length;

    this.saveCoverage();

    if (dependentsCount > 0) {
      this.router.navigate(['/census']);
    } else {
      this.router.navigate(['/contact']);
    }
  }

  saveCoverage() {
    this.enrollmentService.anthemHasCoverage = this.hasCoverage;
    if (this.hasCoverage) {
      this.enrollmentService.anthemExistingCoverages = this.anthemExistingCoverages;
    } else {
      this.enrollmentService.anthemExistingCoverages = null;
    }
  }

}
