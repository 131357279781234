<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">

    <app-breadcrumbs [index]="1"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>

      <section class="application-content">
        <h1>Replacement Coverage Information</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">

          <div class="application-questions text-center">
            <h4>Do you have any dental insurance presently in force?</h4>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && duplication === undefined, 'radioBtn-valid': showValidation === true && duplication !== undefined}">
              <label rfor="duplicationYes">
                <input type="radio" id="duplicationYes" [(ngModel)]="duplication" name="duplication" [value]="true"
                  required />Yes
              </label>
              <label rfor="duplicationNo">
                <input type="radio" id="duplicationNo" [(ngModel)]="duplication" name="duplication" [value]="false"
                  required />No
              </label>
            </div>
            <div [hidden]="!showValidation || duplication !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <div *ngIf="duplication" class="form-row">
            <div class="form-group-block col-6">
              <label for="carrier" [ngClass]="{ 'error-label': dentalCarrierField.invalid }">If yes, with which
                company:</label>
              <input type="text" id="dentalCarrier" [(ngModel)]="dentalCarrier" name="dentalCarrier"
                #dentalCarrierField="ngModel" required maxlength="32" placeholder="Insurance Company Name"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || dentalCarrierField.valid" class="invalid-feedback">
                Please enter Dental Insurance Company Name
              </div>
            </div>
            <div class="form-group-block col-6">
              <label for="carrier" [ngClass]="{ 'error-label': dentalPolicyField.invalid }">Policy number:</label>
              <input type="text" id="dentalPolicy" [(ngModel)]="dentalPolicy" name="dentalPolicy"
                #dentalPolicyField="ngModel" required maxlength="32" placeholder="Policy number"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || dentalPolicyField.valid" class="invalid-feedback">
                Please enter Policy number
              </div>
            </div>
          </div>

          <div class="application-questions text-center">
            <h4>By applying for this insurance coverage, do you intend to lapse or otherwise terminate any existing
              dental insurance currently held by you?</h4>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && lapse === undefined, 'radioBtn-valid': showValidation === true && lapse !== undefined}">
              <label rfor="lapseYes">
                <input type="radio" id="lapseYes" [(ngModel)]="lapse" name="lapse" [value]="true" required />Yes
              </label>
              <label rfor="lapseNo">
                <input type="radio" id="lapseNo" [(ngModel)]="lapse" name="lapse" [value]="false" required />No
              </label>
            </div>
            <div [hidden]="!showValidation || lapse !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <div *ngIf="lapse" class="form-row">
            <div class="form-group-block col-6">
              <label for="dentalCarrier2" [ngClass]="{ 'error-label': dentalCarrier2Field.invalid }">If yes, with which
                company:</label>
              <input type="text" id="dentalCarrier2" [(ngModel)]="dentalCarrier2" name="dentalCarrier2"
                #dentalCarrier2Field="ngModel" required maxlength="32" placeholder="Insurance Company Name"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || dentalCarrier2Field.valid" class="invalid-feedback">
                Please enter Insurance Company Name
              </div>
            </div>
            <div class="form-group-block col-6">
              <label for="dentalPolicy2" [ngClass]="{ 'error-label': dentalPolicy2Field.invalid }">Policy
                number:</label>
              <input type="text" id="dentalPolicy2" [(ngModel)]="dentalPolicy2" name="dentalPolicy2"
                #dentalPolicy2Field="ngModel" required maxlength="32" placeholder="Policy number"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || dentalPolicy2Field.valid" class="invalid-feedback">
                Please enter Policy number
              </div>
            </div>
          </div>

          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>


        </form>
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>