import { NgForm, FormsModule } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { Product } from '../shared/models/product.enum';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { DateComponent } from '../date/date.component';
import { NgClass, NgIf } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';


@Component({
    selector: 'app-existing-coverage-ameritas',
    templateUrl: './existing-coverage-ameritas.component.html',
    styleUrls: ['./existing-coverage-ameritas.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, NgIf, DateComponent, DisclaimersComponent]
})
export class ExistingCoverageAmeritasComponent implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;
  
  public hasCoverage!: boolean;
  public coverageReplacement!: boolean;
  public isFullyInsured!: boolean;
  public carrier!: string;
  public policyNumber!: string;
  public stateAbbr!: string;
  public product!: Product;
  public Products = Product;
  public showValidation = false;
  public terminationDate!: string;

  constructor(private router: Router, private enrollmentService: EnrollmentService) { }

  ngOnInit() {
    this.stateAbbr = this.enrollmentService.census.stateAbbr;
    this.hasCoverage = this.enrollmentService.existingCoverageHasCoverage;
    this.coverageReplacement = this.enrollmentService.existingCoverageCoverageReplacement;
    this.carrier = this.enrollmentService.existingCoverageCarrier;
    this.policyNumber = this.enrollmentService.existingCoveragePolicyNumber;
    this.product = <Product>this.enrollmentService.plan.planType.productId;
    this.terminationDate = this.enrollmentService.existingCoverageTerminationDate;
  }

onSubmit() {
  if (!this.existingCoverageForm.form.valid) {
    this.showValidation = true;
    return;
  }
  this.enrollmentService.existingCoverageHasCoverage = this.hasCoverage;
  this.enrollmentService.existingCoverageCoverageReplacement = this.coverageReplacement && this.isFullyInsured;
  this.enrollmentService.existingCoverageCarrier = this.carrier;
  this.enrollmentService.existingCoveragePolicyNumber = this.policyNumber;
  this.enrollmentService.existingCoverageTerminationDate = this.terminationDate;
  this.router.navigate(['/contact']);
}

onBackClick() {
  this.router.navigate(['/applicant']);
}

}
