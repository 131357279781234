import { NgForm, FormsModule } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MetlifeService } from '../../shared/services/metlife.service';
import { StateSpecificQuestions } from '../models/state-specific-questions.model';
import { EnrollmentService } from '../../shared/services/enrollment.service';
import { DisclaimersComponent } from '../../disclaimers/disclaimers.component';
import { NgClass } from '@angular/common';
import { SupportWidgetComponent } from '../../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../../breadcrumbs/breadcrumbs.component';


@Component({
    selector: 'app-met-prev-coverage-default',
    templateUrl: './met-prev-coverage-default.component.html',
    styleUrls: ['./met-prev-coverage-default.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, DisclaimersComponent]
})
export class MetPrevCoverageDefaultComponent implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;

  public lapse!: boolean;
  public showValidation = false;
  constructor(private router: Router, private metlifeService: MetlifeService, private enrollmentService: EnrollmentService) { }

  ngOnInit() {
    if (this.metlifeService.stateSpecifics) {
      this.lapse = this.metlifeService.stateSpecifics.Lapse;
    }
  }


  onSubmit() {
    if (!this.existingCoverageForm.form.valid) {
      this.showValidation = true;
      return;
    }
    this.metlifeService.stateSpecifics = new StateSpecificQuestions();

    this.metlifeService.stateSpecifics.Lapse = this.lapse;
    this.metlifeService.stateSpecifics.PreviousCoverage = this.lapse;
    this.enrollmentService.stateSpecificQuoestions = this.metlifeService.stateSpecifics;

    this.router.navigate(['/contact']);
  }

  onBackClick() {
    this.router.navigate(['/applicant']);
  }

}
