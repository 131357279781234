<div class="ef-ssn enroll-ssn-el">
    <input name="ssn1" [type]="hide ? 'password' : 'text'" pattern="[0-9]*" [(ngModel)]="ssn1" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="XXX"
        maxlength="3" class="three-digits">-
    <input name="ssn2" [type]="hide ? 'password' : 'text'" pattern="[0-9]*" [(ngModel)]="ssn2" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="XX"
        maxlength="2" (focus)="onFocusSSN2Function()" class="two-digits">-
    <input name="ssn3" [type]="hide ? 'password' : 'text'" pattern="[0-9]*" [(ngModel)]="ssn3" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="XXXX"
        maxlength="4" (focus)="onFocusSSN3Function()" class="four-digits">
    <button type="button" (click)="showPassword()" class="button button-light">
        <svg width="20px" height="20px" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path *ngIf="!showPasswordText"
                d="M320,256a64,64,0,1,1-64-64A64.07,64.07,0,0,1,320,256Zm189.81,9.42C460.86,364.89,363.6,426.67,256,426.67S51.14,364.89,2.19,265.42a21.33,21.33,0,0,1,0-18.83C51.14,147.11,148.4,85.33,256,85.33s204.86,61.78,253.81,161.25A21.33,21.33,0,0,1,509.81,265.42ZM362.67,256A106.67,106.67,0,1,0,256,362.67,106.79,106.79,0,0,0,362.67,256Z" />
            <path *ngIf="showPasswordText"
                d="M409.84,132.33l95.91-95.91A21.33,21.33,0,1,0,475.58,6.25L6.25,475.58a21.33,21.33,0,1,0,30.17,30.17L140.77,401.4A275.84,275.84,0,0,0,256,426.67c107.6,0,204.85-61.78,253.81-161.25a21.33,21.33,0,0,0,0-18.83A291,291,0,0,0,409.84,132.33ZM256,362.67a105.78,105.78,0,0,1-58.7-17.8l31.21-31.21A63.29,63.29,0,0,0,256,320a64.07,64.07,0,0,0,64-64,63.28,63.28,0,0,0-6.34-27.49l31.21-31.21A106.45,106.45,0,0,1,256,362.67ZM2.19,265.42a21.33,21.33,0,0,1,0-18.83C51.15,147.11,148.4,85.33,256,85.33a277,277,0,0,1,70.4,9.22l-55.88,55.88A105.9,105.9,0,0,0,150.44,270.52L67.88,353.08A295.2,295.2,0,0,1,2.19,265.42Z" />
        </svg>
    </button>
</div>