<div *ngIf="loading" class="loader"></div>
<main id="application" class="main-wrapper">
  <div class="container">

    <app-breadcrumbs [index]="4"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>

      <section *ngIf="!loading || !sessionId" class="application-content">
        <h1>Plan Cost and Payment Options</h1>
        <table class="table table-bordered table-condensed">
          <thead class="table-header">
            <tr>
              <th class="col-6">Plan Cost</th>
              <th *ngFor="let p of paymentFrequencies" class="col-2">
                <strong>{{frequencies[p.frequency]}}</strong>
              </th>
            </tr>
          </thead>
          <tr *ngFor="let p of model.fees">
            <td>
              {{p.title}}
            </td>
            <td *ngFor="let a of p.amounts">
              <span *ngIf="a.amount || a.amount ==0">{{a.amount | currency:'USD':'symbol':'1.2-2'}}</span>
              <span *ngIf="!a.amount && a.amount != 0">N/A</span>
            </td>
          </tr>
          <tr class="red">
            <td>
              <strong>Total Amount Due:</strong>
            </td>
            <td *ngFor="let t of getTotals()">
              <span *ngIf="t || t == 0">
                <strong>{{t | currency:'USD':'symbol':'1.2-2'}}</strong>
              </span>
              <span *ngIf="!t && t != 0">N/A</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td *ngFor="let p of paymentFrequencies; let i=index;">
              <button class="button button-medium button-warning button-right "
                (click)="onSelectPaymentFreqBtnClick(p, i)">Select
                {{frequencies[p.frequency]}}</button>
            </td>
          </tr>
          <tr *ngIf="paymentNotes">
            <td class="bold" [attr.colspan]="paymentFrequencies.length + 1" [innerHTML]="paymentNotes"></td>
          </tr>
        </table>
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
          {{errorMessage}}
        </div>
        <hr />
        <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>

        <br />
        <h3 *ngIf="cancellationsDescription">Cancellations</h3>
        <div *ngIf="cancellationsDescription" [innerHTML]="cancellationsDescription"></div>
        <br />
        <app-disclaimers></app-disclaimers>
      </section>
    </div>

  </div>
</main>