import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { EnrollmentService } from "../shared/services/enrollment.service";
import { FamilyRelationship } from "../shared/models/family-relationship.enum";
import { FamilyMember } from "../shared/models/family-member.model";
import { Plan } from "../shared/models/plan";
import { Address } from "../shared/models/address.model";
import { PaymentFrequency } from "../shared/models/paymentFrequency";
import { ActivatedRoute, Router } from "@angular/router";
import { AffiliateService } from "../shared/services/affiliate.service";

import { environment } from "src/environments/environment";
import { DisclaimersComponent } from "../disclaimers/disclaimers.component";
import { SupportWidgetComponent } from "../support-widget/support-widget.component";
import { PlanWidgetComponent } from "../plan-widget/plan-widget.component";
import { BreadcrumbsComponent } from "../breadcrumbs/breadcrumbs.component";
import { NgIf, NgFor, CurrencyPipe, DatePipe } from "@angular/common";

declare global {
  interface Window {
    dataLayer: any;
    applicationId: any;
  }
}

@Component({
    selector: "app-thank-you-form",
    templateUrl: "./thank-you-form.component.html",
    styleUrls: ["./thank-you-form.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        BreadcrumbsComponent,
        PlanWidgetComponent,
        SupportWidgetComponent,
        NgFor,
        DisclaimersComponent,
        CurrencyPipe,
        DatePipe,
    ],
})
export class ThankYouFormComponent implements OnInit {
  // //@HostBinding('class.wrap') wrap = true;

  public applicant!: FamilyMember;
  public totalNumber!: number;
  public spouse!: FamilyMember;
  public children!: FamilyMember[];
  public carrier!: string;
  public plan!: Plan;
  public address!: Address;
  public premium!: number;
  public selectedPaymentFrequency!: PaymentFrequency;
  public totalInitialPaymentAmount!: number;
  public totalOngoingPaymentAmount!: number;
  public confirmationId: number;
  public error!: string;
  public email!: string;
  public eDelivery!: boolean;

  public loading!: boolean;

  private sessionId!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private enrollmentService: EnrollmentService,
    private affiliateService: AffiliateService
  ) {
    this.confirmationId = enrollmentService.confirmationId;
    window.applicationId = this.confirmationId;

    // load SessionId from MetLife

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["SessionId"] != null) {
        this.loading = true;
        this.sessionId = params["SessionId"];

        this.enrollmentService.loadStage(this.sessionId).subscribe((enrollment) => {
          this.enrollmentService.planId = enrollment.planId;
          this.enrollmentService.requestId = enrollment.requestId;
          this.enrollmentService.premium = enrollment.premium;
          this.enrollmentService.totalInitialPaymentAmount =
            enrollment.totalInitialPaymentAmount;
          this.enrollmentService.totalOngoingPaymentAmount =
            enrollment.totalOngoingPaymentAmount;
          this.enrollmentService.selectedPaymentFrequency =
            enrollment.selectedPaymentFrequency;
          this.enrollmentService.existingCoverageHasCoverage =
            enrollment.existingCoverageHasCoverage;
          this.enrollmentService.existingCoverageCoverageReplacement =
            enrollment.existingCoverageCoverageReplacement;
          this.enrollmentService.existingCoverageCarrier =
            enrollment.existingCoverageCarrier;
          this.enrollmentService.existingCoveragePhone =
            enrollment.existingCoveragePhone;
          this.enrollmentService.existingCoveragePolicyNumber =
            enrollment.existingCoveragePolicyNumber;
          this.enrollmentService.existingCoverageEffectiveDate =
            enrollment.existingCoverageEffectiveDate;
          this.enrollmentService.census = enrollment.census;
          this.enrollmentService.address = enrollment.address;

          this.enrollmentService.getPlanAppInfo().subscribe((planAppInfo) => {
            this.enrollmentService.plan = planAppInfo.plan;
            this.enrollmentService.createdOn = planAppInfo.createdOn;
            this.enrollmentService.paymentFrequencies =
              planAppInfo.paymentFrequencies;
            this.enrollmentService.selectedPaymentFrequency =
              this.enrollmentService.paymentFrequencies[
              enrollment.selectedPaymentFrequency
              ];

            this.plan = this.enrollmentService.plan;
            this.carrier = this.plan.carrier.shortName.toLowerCase();
            //   this.requestedEffectiveDate = this.enrollmentService.census.requestedEffectiveDate;
            this.premium = this.enrollmentService.premium!;

            this.enrollmentService.cancellationsDescription =
              planAppInfo.cancellationsDescription;
            this.enrollmentService.paymentNotes = planAppInfo.paymentNotes;

            this.enrollmentService.enrollmentNotices =
              planAppInfo.enrollmentNotices;
            //    this.enrollmentNotices = planAppInfo.enrollmentNotices;

            this.enrollmentService.requestApplicantSSN =
              planAppInfo.requestApplicantSSN;
            this.enrollmentService.requestCensusSSN =
              planAppInfo.requestCensusSSN;

            this.enrollmentService.dentistSearchUrl =
              planAppInfo.dentistSearchUrl;
            this.enrollmentService.dentistSearchNote =
              planAppInfo.dentistSearchNote;

            this.enrollmentService.requestApplicantDentistInfo =
              planAppInfo.requestApplicantDentistInfo;
            this.enrollmentService.requestCensusDentistInfo =
              planAppInfo.requestCensusDentistInfo;

            this.enrollmentService.initialPaymentsProcessingDescriptions =
              planAppInfo.initialPaymentsProcessingDescriptions;
            this.enrollmentService.ongoingPaymentsProcessingDescriptions =
              planAppInfo.ongoingPaymentsProcessingDescriptions;

            this.enrollmentService.authorizationNotices =
              planAppInfo.authorizationNotices;
            this.enrollmentService.ongoingPaymentDayOptions =
              planAppInfo.ongoingPaymentDayOptions;
            this.enrollmentService.allowDifferentBillingAddress =
              planAppInfo.allowDifferentBillingAddress;
            this.enrollmentService.spouseNote = planAppInfo.spouseNote;
            this.enrollmentService.agent = planAppInfo.agent;
            this.enrollmentService.agentId.next(this.enrollmentService.agent);

            //this.startNote = planAppInfo.enrollmentStartNote;
            //this.startWarning = planAppInfo.enrollmentStartWarning;

            //this.availableEffectveDates = this.getAvailableEffectiveDates(this.plan.carrier.shortName);

            //this.disclaimers = this.enrollmentService.plan.carrier.disclaimers;
            
            if (!isNaN(planAppInfo.affiliateId)) {
              this.affiliateService.affiliate.next(
                this.affiliateService.getAffiliateFromAPI(
                  planAppInfo.affiliateId
                )
              );
              this.enrollmentService.affiliateId = planAppInfo.affiliateId;
            } else {
              this.enrollmentService.affiliateId =
                this.affiliateService.defaultAffiliate.affiliateId;
            }

            if (enrollment.stepId !== 3) {
              this.enrollmentService
                .postEnrollmentToAPI()
                .subscribe({
                  next: (confirmationId) => {
                    this.loading = false;
                    this.enrollmentService.confirmationId = confirmationId;
                    this.confirmationId = confirmationId;
                    this.init();
                    this.ngOnInit();
                  }, error: (err: HttpErrorResponse) => {
                    this.loading = false;
                    this.error = err.error;
                  }
                });
            } else {
              this.loading = false;
            }
          });
        });
      } else {
        this.init();
      }
    });
  }

  init() {
    let adminFee = 0;
    const enrollmentFee =
      this.enrollmentService.totalInitialPaymentAmount -
      this.enrollmentService.totalOngoingPaymentAmount;

    if (
      this.enrollmentService.plan.carrier.shortName.toLowerCase() === "humana"
    ) {
      adminFee = 1;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: this.confirmationId,
            affiliation: this.enrollmentService.affiliateId,
            revenue: this.enrollmentService.totalInitialPaymentAmount,
            tax:
              this.enrollmentService.totalInitialPaymentAmount -
              this.enrollmentService.totalOngoingPaymentAmount,
            coupon: this.enrollmentService.census.stateAbbr,
          },
          products: [
            {
              name:
                this.enrollmentService.plan.carrier.name +
                " - " +
                this.enrollmentService.plan.planType.name +
                " - " +
                this.enrollmentService.plan.name,
              // id: this.enrollmentService.plan.planId,
              price: this.enrollmentService.totalOngoingPaymentAmount,
              brand: this.enrollmentService.plan.carrier.name,
              category: this.enrollmentService.plan.planType.name,
              quantity: 1,
            },
          ],
        },
      },
    });
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "purchase4",
      ecommerce: {
        transaction_id: this.confirmationId,
        affiliation: this.enrollmentService.affiliateId,
        value: this.enrollmentService.totalInitialPaymentAmount,
        tax: this.enrollmentService.totalInitialPaymentAmount - this.enrollmentService.totalOngoingPaymentAmount,
        currency: "USD",
        items: [
          {
            item_id: this.enrollmentService.planId,
            item_name: this.enrollmentService.plan.name,
            affiliation: this.enrollmentService.affiliateId,
            currency: "USD",
            item_category: this.enrollmentService.plan.planType.name,
            item_category2: this.enrollmentService.census.stateAbbr,
            item_category3: this.enrollmentService.census.familyMembers.length, //total census
            price: this.enrollmentService.totalOngoingPaymentAmount,
            item_brand: this.enrollmentService.plan.carrier.name,
            quantity: 1,
          },
        ],
      },
    });
  }

  ngOnInit() {
    this.selectedPaymentFrequency =
      this.enrollmentService.selectedPaymentFrequency;
    this.totalNumber = this.enrollmentService.census.familyMembers.length;
    this.applicant = this.enrollmentService.census.familyMembers.find((member) => member.familyRelationship === FamilyRelationship.APPLICANT)!;
    this.spouse = this.enrollmentService.census.familyMembers.find((member) => member.familyRelationship === FamilyRelationship.SPOUSE)!;
    this.children = this.enrollmentService.census.familyMembers.filter((member) => member.familyRelationship === FamilyRelationship.CHILD);
    this.plan = this.enrollmentService.plan;
    this.carrier = this.plan.carrier.shortName.toLowerCase();
    this.address = this.enrollmentService.address;
    this.premium = this.enrollmentService.premium!;
    this.totalInitialPaymentAmount =
      this.enrollmentService.totalInitialPaymentAmount;
    this.totalOngoingPaymentAmount =
      this.enrollmentService.totalOngoingPaymentAmount;

    this.email = this.enrollmentService.address.email; console.log("this email ", this.email);
    this.eDelivery = this.enrollmentService.eDelivery; console.log("this edelivery ", this.eDelivery);

  }

  getPdfUrl() {
    return environment.enrollmentPdfUrl + this.confirmationId + ".pdf";
  }
}
