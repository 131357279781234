<footer>
    <section class="copyrights">
        <section class="wrap">
            <section class="form-row">
                <div class="col-8">
                    <span>© 1999 - {{currentDate | date:'yyyy'}} Kelsey National Corporation or its affiliates. All
                        rights reserved.</span>
                </div>
            </section>
        </section>
    </section>
</footer>