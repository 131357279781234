import { Component, forwardRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-social-security',
    templateUrl: './social-security.component.html',
    styleUrls: ['./social-security.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SocialSecurityComponent), multi: true }],
    standalone: true,
    imports: [FormsModule, NgIf]
})
export class SocialSecurityComponent implements ControlValueAccessor {
  public disabled = false;
  public readonly = false;

  public ssn1!: string;
  public ssn2!: string;
  public ssn3!: string;
  public hide = true;
  public showPasswordText = true;

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: any) => { };

  constructor(private element: ElementRef) { }

  get value(): string | null {
    if (this.ssn1 && this.ssn1.length === 3 &&
      this.ssn2 && this.ssn2.length === 2 &&
      this.ssn3 && this.ssn3.length === 4) {
      return `${this.ssn1}-${this.ssn2}-${this.ssn3}`;
    } else {
      return null;
    }
  }

  set value(newValue: string) {
    if (newValue && newValue.length === 11) {
      this.ssn1 = newValue.substr(0, 3);
      this.ssn2 = newValue.substr(4, 2);
      this.ssn3 = newValue.substr(7, 4);
      this.propagateChange(this.value);
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: (value: string) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onModelChange() {
    this.propagateChange(this.value);
  }

  onInput(target: any) {
    target.value = this.stripNonDigits(target.value);
    if (target.name === 'ssn1' && target.value.length === 3) {
      this.element.nativeElement.children[0].children[1].focus();
    } else if (target.name === 'ssn2' && target.value.length === 2) {
      this.element.nativeElement.children[0].children[2].focus();
    }
  }

  onFocusSSN2Function() {
    // if (this.element.nativeElement.children[0].children[0].value === '') {
    //   this.element.nativeElement.children[0].children[0].focus();
    // }
  }

  onFocusSSN3Function() {
    // if (this.element.nativeElement.children[0].children[1].value === '') {
    //   this.element.nativeElement.children[0].children[1].focus();
    // }
  }

  private stripNonDigits(value: string): string {
    let newValue = '';
    for (let i = 0; i <= value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        newValue += value[i];
      }
    }
    return newValue;
  }

  showPassword(){
    this.hide = !this.hide;
    this.hide ?  this.showPasswordText = true : this.showPasswordText = false;
  }
}
