export class StateSpecificQuestions {
    public PreviousCoverage!: boolean;
    public Duplication!: boolean;
    public PreviousCoverageCompany!: string;
    public PreviousCoveragePolicy!: string;
    public Lapse!: boolean;
    public OtherInsurance!: boolean;
    public PreviousCoverageCompany2!: string;
    public PreviousCoveragePolicy2!: string;
    public SLMB!: boolean;
    public Medicare!: boolean;
    public Medicaid!: boolean;
}
