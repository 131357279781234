import { Member } from './member.model';
import { MemberDependent } from './member-dependent.model';
import { MemberRate } from './member-rate.model';
import { ProductInfo } from './product-info.model';
import { StateSpecificQuestions } from './state-specific-questions.model';
import { EnrollOption } from './enroll-option.model';

export class Enrollment {
    public Member!: Member;
    public MemberDependents!: Array<MemberDependent>;
    public MemberRate!: MemberRate;
    public ProductInfo!: ProductInfo;
    public StateSpecificQuestions!: StateSpecificQuestions;
    public EnrollOptions!: Array<EnrollOption>;
}
