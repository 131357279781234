import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MetlifeService } from '../../shared/services/metlife.service';
import { StateSpecificQuestions } from '../models/state-specific-questions.model';
import { EnrollmentService } from '../../shared/services/enrollment.service';
import { DisclaimersComponent } from '../../disclaimers/disclaimers.component';
import { NgClass, NgIf } from '@angular/common';
import { SupportWidgetComponent } from '../../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-met-prev-coverage1',
    templateUrl: './met-prev-coverage1.component.html',
    styleUrls: ['./met-prev-coverage1.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgClass, NgIf, DisclaimersComponent]
})
export class MetPrevCoverage1Component implements OnInit {
  @ViewChild('existingCoverageForm', { static: true }) public existingCoverageForm!: NgForm;
  public duplication!: boolean;

  public dentalCarrier!: string;
  public lapse!: boolean;
  public replaceDental!: boolean;

  public hasAccidentalCoverage!: boolean;
  public accidentalCarrier!: string;
  public accidentalPolicy!: string;

  public slmb!: boolean;
  public qmb!: boolean;
  public medicaid!: boolean;

  public showValidation = false;

  constructor(private router: Router, private metlifeService: MetlifeService, private enrollmentService: EnrollmentService) { }

  ngOnInit() {
    if (this.metlifeService.stateSpecifics) {
      this.duplication = this.metlifeService.stateSpecifics.Duplication;
      this.dentalCarrier = this.metlifeService.stateSpecifics.PreviousCoverageCompany;
      this.lapse = this.metlifeService.stateSpecifics.Lapse;
      this.hasAccidentalCoverage = this.metlifeService.stateSpecifics.OtherInsurance;
      this.accidentalCarrier = this.metlifeService.stateSpecifics.PreviousCoverageCompany2;
      this.accidentalPolicy = this.metlifeService.stateSpecifics.PreviousCoveragePolicy2;
      this.slmb = this.metlifeService.stateSpecifics.SLMB;
      this.qmb = this.metlifeService.stateSpecifics.Medicare;
      this.medicaid = this.metlifeService.stateSpecifics.Medicaid;
    }
  }

  onSubmit() {
    if (!this.existingCoverageForm.form.valid) {
      this.showValidation = true;
      return;
    }
    this.metlifeService.stateSpecifics = new StateSpecificQuestions();

    this.metlifeService.stateSpecifics.Duplication = this.duplication;
    if (this.duplication) {
      this.metlifeService.stateSpecifics.PreviousCoverageCompany = this.dentalCarrier;
      this.metlifeService.stateSpecifics.Lapse = this.lapse;
    } else {
      this.metlifeService.stateSpecifics.PreviousCoverageCompany = '';
      this.metlifeService.stateSpecifics.Lapse = false;
    }

    this.metlifeService.stateSpecifics.OtherInsurance = this.hasAccidentalCoverage;
    if (this.hasAccidentalCoverage) {
      this.metlifeService.stateSpecifics.PreviousCoverageCompany2 = this.accidentalCarrier;
      this.metlifeService.stateSpecifics.PreviousCoveragePolicy2 = this.accidentalPolicy;
    } else {
      this.metlifeService.stateSpecifics.PreviousCoverageCompany2 = '';
      this.metlifeService.stateSpecifics.PreviousCoveragePolicy2 = '';
    }

    this.metlifeService.stateSpecifics.SLMB = this.slmb;
    this.metlifeService.stateSpecifics.Medicare = this.qmb;
    this.metlifeService.stateSpecifics.Medicaid = this.medicaid;
    this.enrollmentService.stateSpecificQuoestions = this.metlifeService.stateSpecifics;
    this.router.navigate(['/contact']);
  }

  onBackClick() {
    this.router.navigate(['/applicant']);
  }

}
