import { FamilyMember } from './family-member.model';

export class AnthemExistingCoverage {
    public familyMember!: FamilyMember;
    public hasCoverage!: boolean;
    public policyId!: string;
    public insurerName!: string;
    public insurerPhone!: string;
    public effectiveDate!: string;
    public lastDateOfCoverage!: string;
    public hadOrthoCoverage!: boolean;
    public willTerminate!: boolean;
    public willReplace!: boolean;
}
