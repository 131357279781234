import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { FamilyMember } from '../shared/models/family-member.model';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { NgForm, FormsModule } from '@angular/forms';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { DentistComponent } from '../dentist/dentist.component';
import { NgIf, NgFor } from '@angular/common';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'app-dentist-form',
    templateUrl: './dentist-form.component.html',
    styleUrls: ['./dentist-form.component.scss'],
    standalone: true,
    imports: [BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, NgIf, FormsModule, DentistComponent, NgFor, DisclaimersComponent]
})
export class DentistFormComponent implements OnInit {
  @ViewChild('dentistForm', { static: true }) public dentistForm!: NgForm;

  public applicant!: FamilyMember;
  public otherMembers!: Array<FamilyMember>;
  public askDentistId: boolean;
  public askDentistName: boolean;
  public askFacilityId: boolean;
  public askFacilityName: boolean;
  public askCensusInfo: boolean;
  public dentistSearchUrl: string;
  public dentistSearchNote: string;
  public facilityIdTitle: string;
  public facilityIdMaxLength: number;
  public facilityIdOnlyDigits: boolean;
  public showValidation = false;
  public isCDN = false;

  constructor(private router: Router, private enrollmentService: EnrollmentService) { 
    this.askCensusInfo = enrollmentService.requestCensusDentistInfo;
    this.askDentistId = enrollmentService.requestDentistId;
    this.askDentistName = enrollmentService.requestDentistName;
    this.askFacilityId = enrollmentService.requestFacilityId;
    this.askFacilityName = enrollmentService.requestFacilityName;
    this.dentistSearchUrl = enrollmentService.dentistSearchUrl;
    this.dentistSearchNote = enrollmentService.dentistSearchNote;
    this.facilityIdMaxLength = (enrollmentService.plan.carrier.shortName.toLowerCase() === 'humana') ? 6 : 32;
    this.facilityIdOnlyDigits = (enrollmentService.plan.carrier.shortName.toLowerCase() === 'humana');
   if (this.enrollmentService.plan.carrier.shortName.toLowerCase() === 'californiadental') {
    this.facilityIdTitle = 'Office Id'; this.isCDN = true;
   } else {
    this.facilityIdTitle = 'Facility Id';
   }

  }

  ngOnInit() {
    if (!this.enrollmentService.requestApplicantDentistInfo) {
      this.router.navigate(['/ssn']);
    } else {
      this.applicant = this.enrollmentService.census.familyMembers.find(member => member.familyRelationship === FamilyRelationship.APPLICANT)!;
      if (this.enrollmentService.requestCensusDentistInfo) {
        this.otherMembers = this.enrollmentService.census.familyMembers.filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT);
      }

    }
  }

  onSubmit() {
    if (!this.dentistForm.form.valid) {
      this.showValidation = true;
      return;
    }
    this.router.navigate(['/ssn']);
  }

  onBackClick() {
    const dependentsCount = this.enrollmentService.census.familyMembers
      .filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT).length;

    if (dependentsCount > 0) {
      this.router.navigate(['/census']);
    } else {
      this.router.navigate(['/contact']);
    }
  }

  getFullName(familyMember: FamilyMember): string {
    let name = familyMember.firstName;
    if (familyMember.middleInitial != null && familyMember.middleInitial.length > 0) {
      name += ' ' + familyMember.middleInitial;
    }
    name += ' ' + familyMember.lastName;
    return name;
  }
}
