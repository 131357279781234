<section class="disclaimers">
  <h6>Disclaimers and Disclosures:</h6>
  <div *ngIf="affiliate | async; let affiliate;" [innerHTML]="affiliate.disclaimer"></div>
  <div [innerHTML]="disclaimers"></div>
  <p>Rates shown are based upon the information you provided, and are subject to change based on the dental
    and/or vision plan's underwriting practices and your selection of available optional benefits, if any.
    Final rates and effective dates are subject to underwriting and are always determined by the dental
    insurance and/or vision insurance company. To be considered for reimbursement, expenses must qualify as
    covered expenses.</p>
  <p>This site was designed to provide you with a general description of the plans you requested. Keep in mind
    that it does not include all the benefits and limitations outlined in the policies -- it is the insurance
    contract, not the general descriptions on this website, which forms the contract between you and the
    insurance company.</p>
  <p>DentalInsurance.com is paid by the Insurance Company we represent. We earn commissions for each policy we
    sell. The commission rates vary by Insurance Company and may increase based on sales volume. There may also be
    a bonus or incentive. For more information on a purchased plan or plan quoted to you, please reach out through
    our contact us page.</p>
  <p>DentalInsurance.com Inc, its suppliers or any third parties named in this site are not liable for any
    damages that result from using, or inability to use, the website and material listed within it, whether
    based on warranty, contract, tort or any other legal theory and whether or not DentalInsurance.com Inc is
    advised of the possibility of such damages. This includes, but is not limited to, incidental and
    consequential damages, lost profits or damages resulting from lost data or business interruptions.</p>
  <p>The use of the website and its material, some of it supplied by third parties, is at your own risk.
    Although we strive to provide the most current and complete information on this website, we make no
    representations about the accuracy, completeness or timeliness of the material found here. Please note that
    updates are made periodically to the website and we reserve the right to make them at any time.</p>
</section>