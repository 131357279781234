<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">
    <app-breadcrumbs [index]="1"></app-breadcrumbs>
    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>
      <section class="application-content">
        <h1>Replacement Coverage Information</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">
          <div class="application-questions text-center">
            <h4>Do you have any {{(Products[product]).toLowerCase()}} insurance currently in force?</h4>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && hasCoverage === undefined, 'radioBtn-valid': showValidation === true && hasCoverage !== undefined}">
              <label for="hasCoverageYes">
                <input type="radio" id="hasCoverageYes" [(ngModel)]="hasCoverage" name="hasCoverage" [value]="true"
                  required />Yes
              </label>
              <label for="hasCoverageNo">
                <input type="radio" id="hasCoverageNo" [(ngModel)]="hasCoverage" name="hasCoverage" [value]="false"
                  required />No
              </label>
            </div>
            <div [hidden]="!showValidation || hasCoverage !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>
          <div *ngIf="hasCoverage">
            <div class="application-questions text-center">
              <h4>Is the insurance applied for intended to replace any existing insurance?</h4>
              <div class="radio-group enroll-radio-group"
                [ngClass]="{'radioBtn-invalid': showValidation === true && coverageReplacement === undefined, 'radioBtn-valid': showValidation === true && coverageReplacement !== undefined}">
                <label for="coverageReplacementYes">
                  <input type="radio" id="coverageReplacementYes" [(ngModel)]="coverageReplacement"
                    name="coverageReplacement" [value]="true" required />Yes
                </label>
                <label for="coverageReplacementNo">
                  <input type="radio" id="coverageReplacementNo" [(ngModel)]="coverageReplacement"
                    name="coverageReplacement" [value]="false" required />No
                </label>
              </div>
              <div [hidden]="!showValidation || coverageReplacement !== undefined" class="invalid-feedback">
                Please select one
              </div>
            </div>
          </div>
          <br />
          <div *ngIf="hasCoverage && coverageReplacement">
            <h4>Coverage Replacement Information</h4>
            <div class="separator-left"></div>
            <div class="form-row">
              <div class="form-group-block col-6">
                <label for="policyNumber" [ngClass]="{ 'error-label': policyNumberField.invalid }">Contract Type or
                  Policy Number:</label>
                <input type="text" id="policyNumber" [(ngModel)]="policyNumber" name="policyNumber"
                  #policyNumberField="ngModel" required maxlength="32" placeholder="Policy Number"
                  [ngClass]="{ showValidation: showValidation == true }">
                <div [hidden]="!showValidation || policyNumberField.valid" class="invalid-feedback">
                  Please enter contract type or policy number
                </div>
              </div>
              <div class="form-group-block col-6">
                <label for="carrier" [ngClass]="{ 'error-label': carrierField.invalid }">Company Name:</label>
                <input type="text" id="carrier" [(ngModel)]="carrier" name="carrier" #carrierField="ngModel" required
                  maxlength="32" placeholder="Company Name" [ngClass]="{ showValidation: showValidation == true }">
                <div [hidden]="!showValidation || carrierField.valid" class="invalid-feedback">
                  Please enter company name
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>
        </form>
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>