<div *ngIf="loading" class="loader"></div>
<main id="application" class="main-wrapper">
  <div class="container">

    <app-breadcrumbs [index]="3"></app-breadcrumbs>
    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>

      <section class="application-content">
        <h1>Find Your Dentist</h1>
        <div class="separator-left"></div>
        <div>
          <p>Under MetLife TakeAlong Dental DHMO/Managed Care Program, in order to receive benefits, you must pre-select
            your Primary Care Dentist, at time of enrollment.</p>
          <p>Your participating in network Primary Care Dentist, should be listed under “General Dentist” specialty and
            accept new patients.</p>
          <p>Each enrolled family member may select a different participating dentist and has the ability to change
            dentists up to one time each month.</p>
          <p><strong>To choose a dentist, please search for a dentist below. Once you find the dentist of your choice,
              click “Make this my Dental Office” and the Facility ID and Facility Name will be updated below.</strong>
          </p>
        </div>
        <br>
        <form (ngSubmit)="onSubmit()" #dentistForm="ngForm">
          <ng-container *ngIf="familyMembers">
            <div *ngFor="let member of familyMembers; let i = index;">


              <label for="dentist + {{i}}"><strong>{{getFullName(member)}}</strong>:</label>
              <br>


              <app-dentist [(ngModel)]="member.dentist" [askFacilityId]="askFacilityId" #mem="ngModel"
                [askFacilityName]="askFacilityName" [askDentistId]="askDentistId" [askDentistName]="askDentistName"
                [facilityIdTitle]="'Facility Id'" name="dentist + {{i}}" required
                [showValidation]="showValidation">
              </app-dentist>


              <div class="form-row">
                <div class="col-3">
                  <a *ngIf="dentistSearchUrl" href="{{dentistSearchUrl + (i+1)}}"
                    class="button button-medium button-outline-w-corner">Dentist Search</a>
                </div>

                <div *ngIf="i !== 0" class="col-1 or">OR

                </div>

                <div class="col-4">
                  <button *ngIf="i !== 0" type="button" name="copyFacilityFromApplicant"
                    class="button button-success button-medium button-block"
                    (click)="copyFacilityFromApplicant(member)">Same as main
                    applicant</button>
                </div>
              </div>
              <hr>
            </div>

          </ng-container>

          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"
              [disabled]="!dentistForm.form.valid"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>
        </form>
        <br />
        <br />
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>