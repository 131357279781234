<main id="application" class="main-wrapper" [ngClass]="{ showValidationContainer: showValidation === true }">
  <div class="container">

    <app-breadcrumbs [index]="1"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>

      <section class="application-content">
        <h1>Replacement Coverage Information</h1>
        <div class="separator-left"></div>

        <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">

          <div class="col-12">
            <p>You normally do not require more than one of the same type of policy. If you purchase this policy, you
              may
              want to evaluate your existing dental coverage and decide if you need multiple coverages. You may be
              eligible for benefits under Medicaid or Medicare and may not need a dental policy. If you are eligible for
              Medicare, you may want to purchase a Medicare Supplemental policy. If you are eligible for Medicare due to
              age or disability, counseling services are available in Colorado to provide advice concerning your
              purchase
              of Medicare supplement insurance and concerning medical assistance through the state Medicaid program.</p>
            <p>To the best of your knowledge:</p>
          </div>

          <div class="application-questions text-center">
            <h4>a. Do you have another dental insurance policy or contract in force?</h4>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && duplication === undefined, 'radioBtn-valid': showValidation === true && duplication !== undefined}">
              <label for="duplicationYes">
                <input type="radio" id="duplicationYes" [(ngModel)]="duplication" name="duplication" [value]="true"
                  required />Yes
              </label>
              <label for="duplicationNo">
                <input type="radio" id="duplicationNo" [(ngModel)]="duplication" name="duplication" [value]="false"
                  required />No
              </label>
            </div>
            <div [hidden]="!showValidation || duplication !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <div *ngIf="duplication">
            <div class="form-group-block col-6">
              <label for="carrier" [ngClass]="{ 'error-label': dentalCarrierField.invalid }">If so, with which
                company:</label>
              <input type="text" id="dentalCarrier" [(ngModel)]="dentalCarrier" name="dentalCarrier"
                #dentalCarrierField="ngModel" required maxlength="32" placeholder="Insurance Company Name"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || dentalCarrierField.valid" class="invalid-feedback">
                Please enter Dental Insurance Company Name
              </div>
            </div>
            <div class="application-questions text-center">
              <p>If so, do you intend to replace your current dental insurance with this policy (contract)?</p>
              <div class="radio-group enroll-radio-group"
                [ngClass]="{'radioBtn-invalid': showValidation === true && lapse === undefined, 'radioBtn-valid': showValidation === true && lapse !== undefined}">
                <label for="lapseYes">
                  <input type="radio" id="lapseYes" [(ngModel)]="lapse" name="lapse" [value]="true" required />Yes
                </label>
                <label for="lapseNo">
                  <input type="radio" id="lapseNo" [(ngModel)]="lapse" name="lapse" [value]="false" required />No
                </label>
              </div>
              <div [hidden]="!showValidation || lapse !== undefined" class="invalid-feedback">
                Please select one
              </div>
            </div>
          </div>


          <div class="application-questions text-center">
            <h4>b. Do you have any other accident and sickness insurance that provides dental benefits similar to this
              dental policy?</h4>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && hasAccidentalCoverage === undefined, 'radioBtn-valid': showValidation === true && hasAccidentalCoverage !== undefined}">
              <label for="hasAccidentalCoverageYes">
                <input type="radio" id="hasAccidentalCoverageYes" [(ngModel)]="hasAccidentalCoverage"
                  name="hasAccidentalCoverage" [value]="true" required />Yes
              </label>
              <label for="hasAccidentalCoverageNo">
                <input type="radio" id="hasAccidentalCoverageNo" [(ngModel)]="hasAccidentalCoverage"
                  name="hasAccidentalCoverage" [value]="false" required />No
              </label>
            </div>
            <div [hidden]="!showValidation || hasAccidentalCoverage !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <div *ngIf="hasAccidentalCoverage" class="form-row">
            <div class="form-group-block col-6">
              <label for="accidentalCarrier" [ngClass]="{ 'error-label': accidentalCarrierField.invalid }">If so, with
                which company:</label>
              <input type="text" id="accidentalCarrier" [(ngModel)]="accidentalCarrier" name="accidentalCarrier"
                #accidentalCarrierField="ngModel" required maxlength="32" placeholder="Insurance Company Name"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || accidentalCarrierField.valid" class="invalid-feedback">
                Please enter Accidental Insurance Company Name
              </div>
            </div>
            <div class="form-group-block col-6">
              <label for="accidentalPolicy" [ngClass]="{ 'error-label': accidentalPolicyField.invalid }">What kind of
                policy:</label>
              <input type="text" id="accidentalPolicy" [(ngModel)]="accidentalPolicy" name="accidentalPolicy"
                #accidentalPolicyField="ngModel" required maxlength="32" placeholder="Policy info"
                [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation ||accidentalPolicyField.valid" class="invalid-feedback">
                Please enter Application policy info
              </div>
            </div>
          </div>


          <div class="application-questions text-center">
            <h4>c. Are you covered for medical assistance through the state Medicaid program:</h4>
            <p>(1) As a Specified Low-Income Medicare Beneficiary (SLMB)?</p>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && slmb === undefined, 'radioBtn-valid': showValidation === true && slmb !== undefined}">
              <label for="slmbYes">
                <input type="radio" id="slmbYes" [(ngModel)]="slmb" name="slmb" [value]="true" required />Yes
              </label>
              <label for="slmbNo">
                <input type="radio" id="slmbNo" [(ngModel)]="slmb" name="slmb" [value]="false" required />No
              </label>
            </div>
            <div [hidden]="!showValidation || slmb !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <div class="application-questions text-center">
            <p>(2) As a Qualified Medicare Beneficiary (QMB)?</p>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && qmb === undefined, 'radioBtn-valid': showValidation === true && qmb !== undefined}">
              <label for="qmbYes">
                <input type="radio" id="qmbYes" [(ngModel)]="qmb" name="qmb" [value]="true" required />Yes
              </label>
              <label for="qmbNo">
                <input type="radio" id="qmbNo" [(ngModel)]="qmb" name="qmb" [value]="false" required />No
              </label>
            </div>
            <div [hidden]="!showValidation || qmb !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <div class="application-questions text-center">
            <p>(3) For other Medicaid medical benefits?</p>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && medicaid === undefined, 'radioBtn-valid': showValidation === true && medicaid !== undefined}">
              <label for="medicaidYes">
                <input type="radio" id="medicaidYes" [(ngModel)]="medicaid" name="medicaid" [value]="true"
                  required />Yes
              </label>
              <label for="medicaidNo">
                <input type="radio" id="medicaidNo" [(ngModel)]="medicaid" name="medicaid" [value]="false" required />No
              </label>
            </div>
            <div [hidden]="!showValidation || medicaid !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>


          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>

        </form>
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>