import { Component, Input, OnInit, forwardRef, ViewChild, EventEmitter, Output } from '@angular/core';

import { EFT } from '../shared/models/eft';
import { Address } from '../shared/models/address.model';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgForm, FormsModule } from '@angular/forms';
import { NgIf, NgClass, NgFor, DecimalPipe } from '@angular/common';
import { AccountPayerOwnerRelationship } from '../shared/models/accountpayer-owner-relationship.enum';

@Component({
    selector: 'app-eft',
    templateUrl: './eft.component.html',
    styleUrls: ['./eft.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EftComponent), multi: true }],
    standalone: true,
    imports: [FormsModule, NgIf, NgClass, NgFor, DecimalPipe]
})
export class EftComponent implements OnInit, ControlValueAccessor {

  public disabled = false;
  public readonly = false;

  public allowOngoingDaySelection!: boolean;
  public useApplicantAddress!: boolean;
  public allowInitialDaySelection!: boolean;
  public payeeOwnerType = AccountPayerOwnerRelationship;
  public payeeFamilyMember!: boolean;

  public resumedRecording!: boolean;

  public model!: EFT;
  @Input() ongoingPaymentDayOptions!: Array<number>;
  @Input() askAccountUsage!: boolean;
  @Input() showAgentRecordingNotice!: boolean;
  @Input() showCheckingOnly!: boolean;
  @Input() showValidation!: boolean;
  @Input() applicantAddress!: Address;
  @Input() allowDifferentBillingAddress!: boolean;
  @Input() askAccountOwner!: boolean;
  @Input() initialPaymentDayOptions!: Array<string>;
  @Input() askPayerOwnerRelationship!: boolean;

  @Output() resumed = new EventEmitter<boolean>();

  @ViewChild('eftForm', {static: true}) public eftForm!: NgForm;

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: EFT) => { };

  onInput(target: any) {
    target.value = this.stripNonDigits(target.value);
  }

  ngOnInit(): void {

    this.allowOngoingDaySelection = (this.ongoingPaymentDayOptions && this.ongoingPaymentDayOptions.length > 0);
    this.eftForm.statusChanges?.subscribe(x => { this.propagateChange(this.value) });
    this.allowInitialDaySelection = (this.initialPaymentDayOptions && this.initialPaymentDayOptions.length > 0);
  }


  onModelChange() {
    this.propagateChange(this.value);
  }


  get value(): EFT | null {
    if (!this.eftForm.valid || this.eftForm.pristine || (this.model.authorizedSignature !== this.model.payeeName)) {
      return null;
    } else {
      return this.model;
    }

  }

  set value(newValue: EFT) {
    if (newValue) {
      this.model = newValue;
      if (!this.allowDifferentBillingAddress) {
        this.model.billingAddress = this.applicantAddress;
      }
      this.propagateChange(this.value);
    }
  }

  writeValue(value: EFT): void {
    this.value = value;
  }
  registerOnChange(fn: (value: EFT) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleApplicantAddress() {
    if (this.useApplicantAddress) {
      this.model.billingAddress = this.applicantAddress;
    } else {
      this.model.billingAddress = new Address();
    }

  }

  private stripNonDigits(value: string): string | null {
    let newValue = '';
    for (let i = 0; i <= value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        newValue += value[i];
      }
    }
    if (newValue === '') {
      return null;
    } else {
      return newValue;
    }
  }
}
