<div class="widget-box widget-help" *ngIf="(affiliate | async) || defaultAffiliate; let affiliate">
  <h4>Need help?</h4>
  <p class="call" *ngIf="!exchangeCompany">
    <span><a
        href="tel:{{ campaign ? campaign.phone : ((affiliate.phone.length >= 12) ? affiliate.phone : defaultAffiliate.phone) }}">{{ campaign ? campaign.phone : ((affiliate.phone.length >= 12) ? affiliate.phone : defaultAffiliate.phone) }}</a></span>
  </p>
  <p class="call" *ngIf="exchangeCompany">
    <span><a
        href="tel:{{(exchangeCompany.phone) ? exchangeCompany.phone : exchangeCompany.exchangeCampaign.phone}}">{{(exchangeCompany.phone)
        ? exchangeCompany.phone : exchangeCompany.exchangeCampaign.phone}}</a></span>
  </p>
  <p *ngIf="!exchangeCompany">
    {{ campaign ? campaign.hoursOfOperation : ((affiliate.hoursOfOperation !== '') ? affiliate.hoursOfOperation : defaultAffiliate.hoursOfOperation )}}
  </p>
  <p *ngIf="exchangeCompany">
    {{(exchangeCompany.hoursOfOperation) ? exchangeCompany.hoursOfOperation :
    exchangeCompany.exchangeCampaign.hoursOfOperation}}
  </p>
</div>