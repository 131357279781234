import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { Plan } from '../shared/models/plan';
import { Frequency } from '../shared/models/frequency';
import { Address } from '../shared/models/address.model';
import { environment } from 'src/environments/environment';
import { Notice } from '../shared/models/notice.model';
import { AffiliateService } from '../shared/services/affiliate.service';
import { NgForm, FormsModule } from '@angular/forms';
import { DisclaimersComponent } from '../disclaimers/disclaimers.component';
import { MetlifeStartQuestionsComponent } from '../metlife-start-questions/metlife-start-questions.component';
import { AnthemStartQuestionsComponent } from '../anthem-start-questions/anthem-start-questions.component';
import { SupportWidgetComponent } from '../support-widget/support-widget.component';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { NgIf, NgClass, NgFor, CurrencyPipe, DatePipe } from '@angular/common';

declare global {
  interface Window { utm_campaign: any; affiliate_name: any; }
}

@Component({
    selector: 'app-start-form',
    templateUrl: './start-form.component.html',
    styleUrls: ['./start-form.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, BreadcrumbsComponent, SupportWidgetComponent, NgClass, NgFor, AnthemStartQuestionsComponent, MetlifeStartQuestionsComponent, DisclaimersComponent, CurrencyPipe, DatePipe]
})
export class StartFormComponent implements OnInit {
  @ViewChild('startForm', { static: true }) public startForm!: NgForm;

  public plan!: Plan;
  public premium!: number;
  public requestedEffectiveDate!: string | null;
  public loading: boolean;
  public quotesWebUrl!: string;
  public requestId!: string;
  public enrollmentNotices!: Array<Notice>;
  public availableEffectveDates!: Array<string>;
  public disclaimers!: string;
  public startNote!: string;
  public startWarning!: string;
  public anthemQuestions!: boolean;
  public metlifeQuestions!: boolean;
  public isEmailedQuote!: boolean;
  public careington = false;

  public showValidation = false;

  public constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public enrollmentService: EnrollmentService,
    private affiliateService: AffiliateService) {


    this.loading = true;
    this.affiliateService.affiliate.subscribe((x:any) => {
      x.subscribe((a:any) => {
        this.quotesWebUrl = this.enrollmentService.getQuotesUrl(a.isPrerendered);
        this.affiliateService.affiliate_id = a.affiliateId;
        if (a.affiliateId >= 2572 && a.affiliateId <= 2576) {
          this.careington = true;
        }
      })
    });

    if (!this.enrollmentService.requestId) {
      this.activatedRoute.queryParams.subscribe(params => {
        if (params['quoteRequestId'] != null) {
          this.enrollmentService.requestId = params['quoteRequestId'];
          this.requestId = this.enrollmentService.requestId;
        }

        if (params['isFlag'] != null) {
          if (params['isFlag'] === 'true') {
            this.enrollmentService.isEmailedQuote = true;
            this.isEmailedQuote = this.enrollmentService.isEmailedQuote;
          }
        }
        if (params['planId'] != null) {
          this.enrollmentService.planId = params['planId'];

          this.enrollmentService.getPlanAppInfo().subscribe(
            planAppInfo => {
              this.enrollmentService.plan = planAppInfo.plan;
              this.enrollmentService.createdOn = planAppInfo.createdOn;
              this.enrollmentService.premium = planAppInfo?.plan?.fees?.find((fee:any) => (fee?.title?.startsWith('Premium') || fee?.title === 'Plan Cost'))?.amounts?.find((amount:any) => amount?.frequency === Frequency.Monthly)?.amount!;
              this.enrollmentService.census = planAppInfo.census;
              this.enrollmentService.paymentFrequencies = planAppInfo.paymentFrequencies;
              this.enrollmentService.address = new Address();
              this.enrollmentService.address.county = planAppInfo.census.county;
              this.enrollmentService.address.stateAbbr = planAppInfo.census.stateAbbr;
              this.enrollmentService.address.zip = planAppInfo.census.zip;
              this.plan = this.enrollmentService.plan;
              this.requestedEffectiveDate = this.enrollmentService.census.requestedEffectiveDate;
              this.premium = this.enrollmentService.premium;

              this.enrollmentService.cancellationsDescription = planAppInfo.cancellationsDescription;
              this.enrollmentService.paymentNotes = planAppInfo.paymentNotes;

              this.enrollmentService.enrollmentNotices = planAppInfo.enrollmentNotices;
              this.enrollmentNotices = planAppInfo.enrollmentNotices;

              this.enrollmentService.requestApplicantSSN = planAppInfo.requestApplicantSSN;
              this.enrollmentService.requestCensusSSN = planAppInfo.requestCensusSSN;

              this.enrollmentService.requestApplicantDentistInfo = planAppInfo.requestApplicantDentistInfo;
              this.enrollmentService.requestCensusDentistInfo = planAppInfo.requestCensusDentistInfo;
              this.enrollmentService.requestFacilityId = planAppInfo.requestFacilityId;
              this.enrollmentService.requestFacilityName = planAppInfo.requestFacilityName;
              this.enrollmentService.requestDentistId = planAppInfo.requestDentistId;
              this.enrollmentService.requestDentistName = planAppInfo.requestDentistName;
              this.enrollmentService.dentistSearchUrl = planAppInfo.dentistSearchUrl;
              this.enrollmentService.dentistSearchNote = planAppInfo.dentistSearchNote;

              this.enrollmentService.initialPaymentsProcessingDescriptions = planAppInfo.initialPaymentsProcessingDescriptions;
              this.enrollmentService.ongoingPaymentsProcessingDescriptions = planAppInfo.ongoingPaymentsProcessingDescriptions;

              this.enrollmentService.authorizationNotices = planAppInfo.authorizationNotices;
              this.enrollmentService.ongoingPaymentDayOptions = planAppInfo.ongoingPaymentDayOptions;
              this.enrollmentService.initialPaymentDayOptions = planAppInfo.initialPaymentDayOptions;
              this.enrollmentService.allowDifferentBillingAddress = planAppInfo.allowDifferentBillingAddress;
              this.enrollmentService.spouseNote = planAppInfo.spouseNote;

              this.startNote = planAppInfo.enrollmentStartNote;
              this.startWarning = planAppInfo.enrollmentStartWarning;

              this.enrollmentService.agent = planAppInfo.agent;
              this.enrollmentService.agentId.next(this.enrollmentService.agent);

              this.enrollmentService.exchangeCompany = planAppInfo.exchangeCompany;
              this.enrollmentService.exchCompany.next(this.enrollmentService.exchangeCompany);

              this.availableEffectveDates = this.getAvailableEffectiveDates(this.plan.carrier.shortName);
              if (this.availableEffectveDates.findIndex(x => x === this.requestedEffectiveDate) < 0) {
                this.requestedEffectiveDate = null;
              }

              this.disclaimers = this.enrollmentService.plan.carrier.disclaimers;

              if (!isNaN(planAppInfo.affiliateId)) {
                this.affiliateService.affiliate.subscribe(x => {
                  if (x) {
                    x.subscribe((y:any) => {
                      window.affiliate_name = y.name;
                      window.utm_campaign = planAppInfo.affiliateId;
                    });
                  }
                }
                );
                this.affiliateService.affiliate.next(this.affiliateService.getAffiliateFromAPI(planAppInfo.affiliateId));
                this.enrollmentService.affiliateId = planAppInfo.affiliateId;
              } else {

                window.affiliate_name = this.affiliateService.defaultAffiliate.name;
                window.utm_campaign = this.affiliateService.defaultAffiliate.affiliateId;
                this.enrollmentService.affiliateId = this.affiliateService.defaultAffiliate.affiliateId;
              }
              this.enrollmentService.isEmailedQuote = planAppInfo.emailedQuote;
              this.isEmailedQuote = this.enrollmentService.isEmailedQuote;
              this.enrollmentService.campaignName = planAppInfo.campaignName;
              this.enrollmentService.campaignSource = planAppInfo.campaignSource;
              this.enrollmentService.campaignSourceName.next(this.enrollmentService.campaignSource);

              this.loading = false;
            }
          );
        } else if (!this.enrollmentService.requestId) {
          window.location.href = environment.quotesWebUrl;
        };

      });
    } else {
      this.requestId = this.enrollmentService.requestId;
      this.plan = this.enrollmentService.plan;
      this.requestedEffectiveDate = this.enrollmentService.census.requestedEffectiveDate;
      this.premium = this.enrollmentService.premium!;

      this.availableEffectveDates = this.getAvailableEffectiveDates(this.plan.carrier.shortName);
      if (this.availableEffectveDates.findIndex(x => x === this.requestedEffectiveDate) < 0) {
        this.requestedEffectiveDate = null;
      }
      //this.emailFlagId = this.enrollmentService.emailFlagId;
      this.isEmailedQuote = this.enrollmentService.isEmailedQuote;
      this.loading = false;
    }
  }

  ngOnInit() {

  }

  getAvailableEffectiveDates(carrier: string): Array<string> {
    const availableEffectiveDates: Array<string> = new Array<string>();
    const availableDate = new Date();
    let currentMonth = availableDate.getMonth();

    // TODO: refactor
    if (carrier.toLowerCase() === 'careington') {
      availableDate.setDate(1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

      if (currentMonth >= 11) {
        currentMonth -= 12;
      }
      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

      if (currentMonth >= 11) {
        currentMonth -= 12;

        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 3);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      }
    } else if (carrier.toLowerCase() === 'dhs') {
      if (availableDate.getDate() <= 10) {

        availableDate.setDate(1);

        if (currentMonth >= 11) {
          currentMonth -= 12;
        }

        availableDate.setMonth(currentMonth + 1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      } else {

        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      }
    } else if (carrier.toLowerCase() === 'nationwide' || carrier.toLowerCase().startsWith('nationwide')) {

      if (availableDate.getDate() <= 5) {
        availableDate.setDate(1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      }

      availableDate.setDate(1);
      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

      //if (currentMonth >= 11) {
      //  currentMonth -= 12;
      //}

      //availableDate.setMonth(currentMonth + 2);
      //availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

    } else if (carrier.toLowerCase() === 'humana') {
      if (this.plan.planType.planTypeId === 2) { // dhmo  - 15th

        if (availableDate.getDate() <= 15) {
          availableDate.setMonth(currentMonth + 1);
          availableDate.setDate(1);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

          if (currentMonth >= 11) {
            currentMonth -= 12;
          }
          availableDate.setMonth(currentMonth + 2);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        } else {
          availableDate.setDate(1);
          availableDate.setMonth(currentMonth + 2);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        }


      } else {
        availableDate.setDate(availableDate.getDate() + 5);
        for (let i = 0; i < 90; i++) {
          availableDate.setDate(availableDate.getDate() + 1);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        }
      }
    } else if (carrier.toLowerCase() === 'deltadentalky') {
      if (availableDate.getDate() <= 25) {

        availableDate.setMonth(currentMonth + 1);
        availableDate.setDate(1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

        if (currentMonth >= 11) {
          currentMonth -= 12;
        }

        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      } else {


        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));


        if (currentMonth >= 11) {
          currentMonth -= 12;

          availableDate.setDate(1);
          availableDate.setMonth(currentMonth + 3);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        }
      }
    } else if (carrier.toLowerCase() === 'deltadentaltn') {
      if (availableDate.getDate() <= 15) {

        availableDate.setMonth(currentMonth + 1);
        availableDate.setDate(1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

        if (currentMonth >= 11) {
          currentMonth -= 12;
        }

        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      } else {


        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        if (currentMonth >= 11) {
          currentMonth -= 12;

          availableDate.setDate(1);
          availableDate.setMonth(currentMonth + 3);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        }
      }
    }
    else if (carrier.toLowerCase() === 'deltadental' || carrier.toLowerCase() === 'renaissancemw') {
      if (availableDate.getDate() <= 20) {

        availableDate.setMonth(currentMonth + 1);
        availableDate.setDate(1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

        if (currentMonth >= 11) {
          currentMonth -= 12;
        }
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      } else if (availableDate.getDate() <= 25) {
        availableDate.setMonth(currentMonth + 1);
        availableDate.setDate(1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

        if (currentMonth >= 11) {
          currentMonth -= 12;
        }
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      } else {
        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        if (currentMonth >= 11) {
          currentMonth -= 12;

          availableDate.setDate(1);
          availableDate.setMonth(currentMonth + 3);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        }
      }
    }
    else if (carrier.toLowerCase() === 'dominion') {
      if (availableDate.getDate() <= 25) {

        availableDate.setMonth(currentMonth + 1);
        availableDate.setDate(1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

        if (currentMonth >= 11) {
          currentMonth -= 12;
        }

        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      } else {


        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

        if (currentMonth >= 11) {
          currentMonth -= 12;
          availableDate.setDate(1);
          availableDate.setMonth(currentMonth + 3);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        }
      }
    } else if (carrier.toLowerCase() === 'ameritas' || carrier.toLowerCase() === 'ameritasny') {
      availableDate.setDate(availableDate.getDate() + 1);
      for (let i = 0; i < 88; i++) {
        availableDate.setDate(availableDate.getDate() + 1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      }

    }
    else if (carrier.toLowerCase() === 'metliferetiree') {
      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

      if (currentMonth >= 11) {
        currentMonth -= 12;
      }
      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

    }
    else if (carrier.toLowerCase() === 'renaissance') {

      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

    /*} else if (carrier.toLowerCase() === 'renaissancemw') {

      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));*/
    } else if (carrier.toLowerCase() === 'guardian') {

      if (this.plan.planType.planTypeId === 7) { // dvh - end of month
         availableDate.setDate(1);
          availableDate.setMonth(currentMonth + 1);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      }
      else {
        if (availableDate.getDate() <= 15) {

          availableDate.setMonth(currentMonth + 1);
          availableDate.setDate(1);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
          if (currentMonth >= 11) {
            currentMonth -= 12;
          }
          if (currentMonth >= 11) {
            currentMonth -= 12;
          }
          availableDate.setMonth(currentMonth + 2);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        } else if (availableDate.getDate() <= 25) {
          availableDate.setMonth(currentMonth + 1);
          availableDate.setDate(1);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
  
          if (currentMonth >= 11) {
            currentMonth -= 12;
          }
          availableDate.setMonth(currentMonth + 2);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        } else {
          availableDate.setDate(1);
          availableDate.setMonth(currentMonth + 2);
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
  
          if (currentMonth >= 11) {
            currentMonth -= 12;
            availableDate.setDate(1);
            availableDate.setMonth(currentMonth + 3);
            availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
          }
        }        
      }     
      
    } else if (carrier.toLowerCase().startsWith('anthem') || carrier.toLowerCase().startsWith('empire')) {
      availableDate.setDate(1);
      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
    } else if (carrier.toLowerCase() === 'metlife') {
      if (availableDate.getDate() <= 20) {
        availableDate.setMonth(currentMonth + 1);
      } else {
        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 2);
      }
      availableDate.setDate(1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

    } else if (carrier.toLowerCase() === 'ncd') {
      if (availableDate.getDate() <= 26) {
        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 1);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

       /* if (currentMonth >= 11) {
          currentMonth -= 12;
        }
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));*/
      } else {
        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 2);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      }
      //availableDate.setDate(1);
      //availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

    }
    else if (carrier.toLowerCase() === 'ncdmetlife') {

      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

      if (currentMonth >= 11) {
        currentMonth -= 12;
      }
      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

      if (currentMonth >= 11) {
        currentMonth -= 12;

        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 3);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      }

    } else if (carrier.toLowerCase() === 'cigna') {

      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, '')); 
    } 
    else if (carrier.toLowerCase() === 'aetna') {
      availableDate.setDate(availableDate.getDate() + 1);
      for (let i = 0; i < 90; i++) {
        availableDate.setDate(availableDate.getDate() + 1);
         if (availableDate.getDate() < 29){        
          availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
        }
      }

    } else if (carrier.toLowerCase() === 'vsp') {
      if (availableDate.getDate() <= 25) {
        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 1);
      } else {
        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 2);
      }
      availableDate.setDate(1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

    } else if (availableDate.getDate() <= 20) {
      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      if (currentMonth >= 11) {
        currentMonth -= 12;
      }
      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
    } else {
      availableDate.setDate(1);


      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));

      if (currentMonth >= 11) {
        currentMonth -= 12;

        availableDate.setDate(1);
        availableDate.setMonth(currentMonth + 3);
        availableEffectiveDates.push(availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
      }
    }

    if (this.enrollmentService.exchangeCompany) {
      return this.addExchnageEffectiveDates(availableEffectiveDates);
    }

    return availableEffectiveDates;
  }

  addExchnageEffectiveDates(effectiveDates: string[]): string[] {

    const itemsToAdd = 3 - effectiveDates.length;

    const lastDate = new Date(effectiveDates[effectiveDates.length - 1]);
    let currentMonth = lastDate.getMonth();

    for (let i = 1; i <= itemsToAdd; i++) {

      let m = currentMonth + i;
      let newDate = new Date(lastDate);

      if (m > 11) {
        m -= 12;
        newDate.setMonth(m);
        newDate.setFullYear(lastDate.getFullYear() + 1);
      } else {
        newDate.setMonth(m);
      }

      effectiveDates.push(newDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, ''));
    }

    return effectiveDates;
  }

  onSubmit() {
    if (!this.startForm.form.valid || this.anthemQuestions || this.metlifeQuestions)
    {
      this.showValidation = true;
      return;
    }
    this.enrollmentService.census.requestedEffectiveDate = this.requestedEffectiveDate!;
    this.router.navigate(['/applicant']);
  }

  getGuardianPaymentWarning(selectedEffectiveDate?: string): string {
    if (!this.plan || this.plan.carrier.shortName.toLowerCase() !== 'guardian' || (this.plan.carrier.shortName.toLowerCase() == 'guardian' && this.plan.name.includes('VSP Dental Vision'))) {
      return '';
    }

    const availableDate = new Date();
    let currentMonth = availableDate.getMonth();

    if (availableDate.getDate() > 15 && availableDate.getDate() <= 25) {
      availableDate.setMonth(currentMonth + 1);
      availableDate.setDate(1);
      if (selectedEffectiveDate === availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, '')) {
        return 'Payment Option:  You must enroll using a credit card for payment.';
      }
    }

    return '';
  }

  getDeltaDentalPaymentWarning(selectedEffectiveDate?: string): string {
    if (!this.plan || (this.plan.carrier.shortName.toLowerCase() !== 'deltadental' && this.plan.carrier.shortName.toLowerCase() !== 'renaissancemw')) {
      return '';
    }

    const availableDate = new Date();
    let currentMonth = availableDate.getMonth();

    if (availableDate.getDate() > 20 && availableDate.getDate() <= 25) {
      availableDate.setMonth(currentMonth + 1);
      availableDate.setDate(1);
      if (selectedEffectiveDate === availableDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/[^ -~]/g, '')) {
        return 'Payment Option:  You must enroll using a credit card for payment.';
      }
    }

    return '';
  }

  getPlanUrl(plan: Plan) {
    if (!plan) {
      return '';
    }
    return plan.carrier.name.toLowerCase().split(' ').join('-') + '/' + plan.name.toLowerCase().split(' ').join('-').split(')').join('').split('(').join('');
  }

}
