<section class="card" [ngClass]="{ showValidationContainer: showValidation === true }">
  <form #eftForm="ngForm">
    <h2>Electronic funds transfer</h2>
    <div class="separator-left"></div>

    <div *ngIf="model">
      <div class="form-row">

        <div class="col-6">

          <h4>Account type</h4>
          <span *ngIf="!showCheckingOnly">
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation == true && model.accountType == null, 'radioBtn-valid': showValidation == true && model.accountType != null}">
              <label for="accntChecking">
                <input type="radio" id="accntChecking" #acctType="ngModel" [(ngModel)]="model.accountType"
                  name="accountType" value="0" (ngModelChange)="onModelChange()" required />Checking
              </label>
              <label for="accntSavings">
                <input type="radio" id="accntSavings" [(ngModel)]="model.accountType" name="accountType" value="1"
                  (ngModelChange)="onModelChange()" required />Savings
              </label>
            </div>
            <div [hidden]="!showValidation || acctType.valid" class="invalid-feedback">
              Please select one
            </div>
          </span>



          <span *ngIf="showCheckingOnly">
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation == true && model.accountType == null, 'radioBtn-valid': showValidation == true && model.accountType != null}">
              <label for="accntChecking">
                <input type="radio" id="accntChecking" [(ngModel)]="model.accountType" name="accountType" value="0"
                  (ngModelChange)="onModelChange()" required />Checking
              </label>
            </div>
          </span>

          <span *ngIf="askAccountUsage">
            <div class="separator-left"></div>
            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation == true && model.accountUsage == null, 'radioBtn-valid': showValidation == true && model.accountUsage != null}">
              <label for="accntPersonal">
                <input type="radio" id="accntPersonal" #acctUsage="ngModel" [(ngModel)]="model.accountUsage"
                  name="accountUsage" value="0" (ngModelChange)="onModelChange()" required />Personal
              </label>
              <label for="accntBusiness">
                <input type="radio" id="accntBusiness" [(ngModel)]="model.accountUsage" name="accountUsage" value="1"
                  (ngModelChange)="onModelChange()" required />Business
              </label>
            </div>
            <div [hidden]="!showValidation || acctUsage.valid" class="invalid-feedback">
              Please select one
            </div>
            <div class="separator-left"></div>
          </span>

          <div class="form-group-block col-10">
            <label for="payeeName" [ngClass]="{ 'error-label': payeeName.invalid }">Account holder name</label>
            <input type="text" id="payeeName" name="payeeName" [(ngModel)]="model.payeeName" #payeeName="ngModel"
              (ngModelChange)="onModelChange()" required maxlength="64"
              [ngClass]="{'showValidation': showValidation == true}" placeholder="Account holder name" />
            <div [hidden]="!showValidation || payeeName.valid" class="invalid-feedback">
              Please enter name
            </div>
          </div>

          <div class="form-group-block col-10">
          <div *ngIf="askPayerOwnerRelationship">
            <label for="payeeOwnerRelationship" [ngClass]="{ 'error-label': payeeOwnerRelationship.invalid }">Account Owner Relationship to Applicant</label>
            <select [(ngModel)]="model.payeeOwnerRelationship" id="payeeOwnerRelationship" name="payeeOwnerRelationship"
              class="ef-date-dc" #payeeOwnerRelationship="ngModel" (ngModelChange)="onModelChange()" required
              [ngClass]="{'showValidation': showValidation == true}">
              <option [ngValue]="undefined" selected disabled>Select</option>
              <option [ngValue]="payeeOwnerType.BUSINESS">Business Owned by Applicant</option>
              <option [ngValue]="payeeOwnerType.LIVINGTRUST">Living Trust</option>
              <option [ngValue]="payeeOwnerType.POWEROFATTORNEY">Power of Attorney</option>
              <option [ngValue]="payeeOwnerType.CONSERVATOR">Conservator/Guardian</option>
              <option [ngValue]="payeeOwnerType.EMPLOYER">Employer</option>
              <option [ngValue]="payeeOwnerType.FAMILYMEMBER">Family Member</option>
              <option [ngValue]="payeeOwnerType.SELF">Self</option>
            </select>           
            <div [hidden]="!showValidation || payeeOwnerRelationship.valid" class="invalid-feedback">
              Select account owner relationship to enrollee
            </div>
          </div>
        </div>
        <div class="form-group-block col-10">
          <div *ngIf="model.payeeOwnerRelationship === payeeOwnerType.FAMILYMEMBER">
            <input type="text" id="payeeMemberRelationship" name="payeeMemberRelationship" [(ngModel)]="model.payeeMemberRelationship" #payeeMemberRelationship="ngModel"
            (ngModelChange)="onModelChange()" required maxlength="50"
            [ngClass]="{'showValidation': showValidation == true}" placeholder="Family Member Relationship" />
          </div>
        </div>
          
          <div class="form-group-block col-10">
            <label for="financialInstitution" [ngClass]="{ 'error-label': financialInstitution.invalid }">Financial
              Institution</label>
            <input type="text" id="financialInstitution" name="financialInstitution"
              [(ngModel)]="model.financialInstitution" #financialInstitution="ngModel" (ngModelChange)="onModelChange()"
              required minlength="3" maxlength="64" [ngClass]="{'showValidation': showValidation == true}"
              placeholder="Financial Institution" />
            <div [hidden]="!showValidation || financialInstitution.valid" class="invalid-feedback">
              Please enter financial institution name
            </div>
          </div>
        </div>

        <div class="col-6">
          <br class="check" /><br class="check" />
          <img src="../../assets/images/check.gif" alt="check example" class="img-fluid check" />
        </div>

      </div>

      <div class="separator-left"></div>
      <div class="form-row">
        <div class="form-group-block col-6">
          <label for="routingNumber" [ngClass]="{ 'error-label': routingNumber.invalid }">Routing Number</label>
          <input type="tel" pattern="[0-9]*" id="routingNumber" name="routingNumber" [(ngModel)]="model.routingNumber"
            (input)="onInput($event.target)" #routingNumber="ngModel" (ngModelChange)="onModelChange()" required
            maxlength="9" minlength="9" [ngClass]="{'showValidation': showValidation == true}"
            placeholder="Routing Number" />
          <div [hidden]="!showValidation || routingNumber.valid" class="invalid-feedback">
            Please enter routing number
          </div>
        </div>
        <div class="form-group-block col-6">
          <label for="accountNumber" [ngClass]="{ 'error-label': accountNumber.invalid }">Account Number</label>
          <input type="tel" pattern="[0-9]*" id="accountNumber" name="accountNumber" [(ngModel)]="model.accountNumber"
            (input)="onInput($event.target)" #accountNumber="ngModel" (ngModelChange)="onModelChange()" required
            minlength="3" maxlength="32" [ngClass]="{'showValidation': showValidation == true}"
            placeholder="Account Number" />
          <div [hidden]="!showValidation || accountNumber.valid" class="invalid-feedback">
            Please enter account number
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group-block col-6">
          <label for="authorizedSignature" [ngClass]="{ 'error-label': authorizedSignature.invalid }">Authorized Account
            Signature</label>
          <input type="text" id="authorizedSignature" name="authorizedSignature" [(ngModel)]="model.authorizedSignature"
            (ngModelChange)="onModelChange()" #authorizedSignature="ngModel" required maxlength="64"
            [ngClass]="{'showValidation': showValidation == true}" placeholder="Authorized Account Signature" />
          <div [hidden]="!showValidation || authorizedSignature.valid" class="invalid-feedback">
            Please enter authorized signature
          </div>
          <div
            [hidden]="!showValidation || (payeeName.value === authorizedSignature.value) && payeeName.valid && authorizedSignature.valid"
            class="invalid-feedback">
            Authorized account signature must match account holder name
          </div>
        </div>
        <div class="form-group-block col-6">
          <div *ngIf="allowInitialDaySelection">
            <label for="initialPaymentDay" [ngClass]="{ 'error-label': initialPaymentDay.invalid }">Initial Payment
              Draft Day</label>
            <select [(ngModel)]="model.initialPaymentDay" id="initialPaymentDay" name="initialPaymentDay"
              class="ef-date-dc" #initialPaymentDay="ngModel" (ngModelChange)="onModelChange()" required
              [ngClass]="{'showValidation': showValidation == true}">
              <option [ngValue]="undefined" selected disabled>Select</option>
              <option *ngFor="let option of initialPaymentDayOptions" [ngValue]="option">{{option}}
              </option>
            </select>
            <div [hidden]="!showValidation || initialPaymentDay.valid" class="invalid-feedback">
              Select initial payment day
            </div>
          </div>
        </div>
        <div class="form-group-block col-6">
          <div *ngIf="allowOngoingDaySelection">
            <label for="ongoingPaymentDay" [ngClass]="{ 'error-label': ongoingPaymentDay.invalid }">Ongoing Payment
              Draft Day</label>
            <select [(ngModel)]="model.ongoingPaymentDay" id="ongoingPaymentDay" name="ongoingPaymentDay"
              class="ef-date-dc" #ongoingPaymentDay="ngModel" (ngModelChange)="onModelChange()" required
              [ngClass]="{'showValidation': showValidation == true}">
              <option [ngValue]="undefined" selected disabled>Select</option>
              <option *ngFor="let option of ongoingPaymentDayOptions" [ngValue]="option">{{option | number : '2.0-0'}}
              </option>
            </select>            
            <div [hidden]="!showValidation || ongoingPaymentDay.valid" class="invalid-feedback">
              Select payment day
            </div>
          </div>
        </div>
        <div class="col-12"><p>Draft date cannot be on the 29th, 30th or 31st of the month. Requesting to have a draft date more than 
          15 days greater than the policy's paid to date will draft a month in advance.</p></div>
      </div>

      <div class="form-row" *ngIf="showAgentRecordingNotice">
        <div class="col-12">
          <div class="alert alert-danger highlight" role="alert">
            <img class="stop-sign" src="/assets/images/stop.png">
            <div>
              AGENT: RESUME RECORDING BEFORE CONTINUING TO PROCESS THE APPLICATION.<br><br>
              <div class="has-coverage">
                <input type="checkbox" class="form-check-input check-input-big" id="ResumedRecording"
                  name="ResumedRecording" [(ngModel)]="resumedRecording" (click)="resumed.emit(true)"
                  [disabled]="resumedRecording">
                <label for="ResumedRecording"><strong>Check this box ONLY after recording is resumed</strong></label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="showAgentRecordingNotice === resumedRecording && allowDifferentBillingAddress">
        <div class="form-row">
          <div class="form-group-block col-12">
            <h4>Billing address</h4>
          </div>
        </div>
        <div class="form-row" *ngIf="allowDifferentBillingAddress">
          <div class="col-12">
            <div class="form-check">
              <input type="checkbox" class="form-check-input check-input-big" id="useApplicantAddress"
                [(ngModel)]="useApplicantAddress" (ngModelChange)="toggleApplicantAddress()"
                name="useApplicantAddress" />
              <label for="useApplicantAddress"><strong>Use primary applicant's address</strong></label>
            </div>
          </div>
        </div>
        <div class="form-row" *ngIf="!allowDifferentBillingAddress || useApplicantAddress">
          <div class="form-group-block col-12">
            <p>
              <strong>{{ model.billingAddress.addressLine1 }}<br />
                <span *ngIf="applicantAddress.addressLine2">{{ model.billingAddress.addressLine2 }}<br /></span>
                {{ model.billingAddress.city }},
                {{ model.billingAddress.stateAbbr }}
                {{ model.billingAddress.zip }}
              </strong>
            </p>
          </div>
        </div>
        <div class="form-row" *ngIf="allowDifferentBillingAddress && !useApplicantAddress">
          <div class="form-group-block col-12">
            <label for="addressLane1">Address Line 1</label>
            <input type="text" id="addressLine2" [(ngModel)]="model.billingAddress.addressLine1" name="addressLine1"
              #addressLine1="ngModel" required maxlength="128" [ngClass]="{'showValidation': showValidation == true}"
              placeholder="Address Line 1" />
            <div [hidden]="!showValidation || addressLine1.valid" class="invalid-feedback">
              Please enter the billing address
            </div>
          </div>
          <div class="form-group-block col-12">
            <label for="addressLane2">Address Line 2</label>
            <input type="text" id="addressLine2" [(ngModel)]="model.billingAddress.addressLine2" name="addressLine2"
              maxlength="128" placeholder="Address Line 2" />
          </div>
          <div class="form-group-block col-4">
            <label for="city">City</label>
            <input type="text" id="city6" [(ngModel)]="model.billingAddress.city" name="city" #city="ngModel" required
              maxlength="64" [ngClass]="{'showValidation': showValidation == true}" placeholder="City" />
            <div [hidden]="!showValidation || city.valid" class="invalid-feedback">
              Please enter city
            </div>
          </div>
          <div class="form-group-block col-2">
            <label for="state">State</label>
            <select [(ngModel)]="model.billingAddress.stateAbbr" id="state1" name="state" class="ef-date-dc"
              id="ef-date-dc2" #state="ngModel" (ngModelChange)="onModelChange()" required
              [ngClass]="{'showValidation': showValidation == true}">
              <option [ngValue]="undefined" selected disabled>Select</option>
              <option value="AK">AK</option>
              <option value="AL">AL</option>
              <option value="AR">AR</option>
              <option value="AZ">AZ</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DC">DC</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="IA">IA</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="MA">MA</option>
              <option value="MD">MD</option>
              <option value="ME">ME</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MO">MO</option>
              <option value="MS">MS</option>
              <option value="MT">MT</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="NE">NE</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NV">NV</option>
              <option value="NY">NY</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VA">VA</option>
              <option value="VT">VT</option>
              <option value="WA">WA</option>
              <option value="WI">WI</option>
              <option value="WV">WV</option>
              <option value="WY">WY</option>
            </select>
            <div [hidden]="!showValidation || state.valid" class="invalid-feedback">
              Select State
            </div>
          </div>
          <div class="form-group-block col-3">
            <label for="zip">Zip</label>
            <input type="tel" pattern="[0-9]*" id="zip" name="zip" (input)="onInput($event.target)"
              [(ngModel)]="model.billingAddress.zip" #zip="ngModel" required minlength="5" maxlength="5"
              [ngClass]="{'showValidation': showValidation == true}" placeholder="Zip" />
            <div [hidden]="!showValidation || zip.valid" class="invalid-feedback">
              Enter valid zip code
            </div>
          </div>
        </div>
      </ng-container>
      <br />

    </div>
  </form>
</section>