<section ngModelGroup="member{{index}}" [ngClass]="{ showValidationContainer: showValidation === true }">
  <h3>{{relationships[model.familyRelationship]}}<span
      *ngIf="model.familyRelationship === relationships.SPOUSE">/DOMESTIC PARTNER</span></h3>
  <div class="form-row">
    <div class="form-group-block col-5">
      <label for="firstName" [ngClass]="{ 'error-label': firstName.invalid }">First Name:</label>
      <input type="text" id="firstName" [(ngModel)]="model.firstName" name="firstName" #firstName="ngModel" required
        maxlength="32" [ngClass]="{ showValidation: showValidation == true }">
      <div [hidden]="!showValidation || firstName.valid" class="invalid-feedback">Please enter first name
      </div>
    </div>
    <div class="form-group-block col-2">
      <label for="middleName">Middle:</label>
      <input type="text" id="middleInitial" maxlength="1" [(ngModel)]="model.middleInitial" name="middleInitial"
        #middleInitial="ngModel">
    </div>
    <div class="form-group-block col-5">
      <label for="lastName" [ngClass]="{ 'error-label': lastName.invalid }">Last Name:</label>
      <input type="text" id="lastName" [(ngModel)]="model.lastName" name="lastName" #lastName="ngModel" required
        maxlength="32" [ngClass]="{ showValidation: showValidation == true }">
      <div [hidden]="!showValidation || lastName.valid" class="invalid-feedback">
        Please enter last name
      </div>
    </div>
  </div>
  <div *ngIf="askMaritalStatus" class="form-row">
    <div class="form-group-block col-5">
      <label for="maritalStatus" [ngClass]="{ 'error-label': maritalStatusSelect.invalid }">Marital status: </label>
      <select id="maritalStatus" [(ngModel)]="model.maritalStatus" name="maritalStatus" required
        [ngClass]="{ showValidation: showValidation == true }" #maritalStatusSelect="ngModel" >
        <option [ngValue]="maritalStatusOptions.SINGLE">Single</option>
        <option [ngValue]="maritalStatusOptions.MARRIED">Married</option>
        <option [ngValue]="maritalStatusOptions.DOMESTIC_PARTNER">Domestic Partner</option>
      </select>
      <div [hidden]="!showValidation || model.maritalStatus !== null" class="invalid-feedback">
        Please select one
      </div>
    </div>
  </div>
  <div *ngIf="askChildStatus" class="form-row">
    <div class="form-group-block col-5">
      <label for="childStatus" [ngClass]="{ 'error-label': childStatusSelect.invalid }">Relationship to Applicant: </label>
      <select id="childStatus" [(ngModel)]="model.childStatus" name="childStatus" required
        [ngClass]="{ showValidation: showValidation == true }" #childStatusSelect="ngModel">
        <option *ngFor="let childStatus of childStatusOptions" [ngValue]="childStatus">{{childStatus}}</option>
      </select>
      <div [hidden]="!showValidation || model.childStatus !== null" class="invalid-feedback">
        Please select one
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="form-group-block col-4">
      <label for="dob">Birthdate:</label>
      <input type="text" value="{{model.birthdate | date : 'MM/dd/yyyy'}}" disabled />
    </div>
    <div class="form-group-block col-2">
      <label for="gender" [ngClass]="{ 'error-label': genderSelect.invalid }">Gender:</label>
      <select id="genderStatus" [(ngModel)]="model.gender" name="genderStatus" required
        [ngClass]="{ showValidation: showValidation == true }" #genderSelect="ngModel">
        <option [ngValue]="genderOptions.MALE">Male</option>
        <option [ngValue]="genderOptions.FEMALE">Female</option>
        <option *ngIf="addGenderX" [ngValue]="genderOptions.X">X</option>
      </select>
      <div [hidden]="!showValidation || model.gender !== null" class="invalid-feedback">
        Please select one
      </div>
    </div>
    <div *ngIf="model.familyRelationship === relationships.CHILD && !hideStudent" class="form-group-block col-2">
      <label for="student">Student:</label>
      <input type="text" value="{{model.isStudent ? 'Yes' : 'No'}}" disabled />
    </div>
    <div *ngIf="model.familyRelationship === relationships.CHILD" class="form-group-block col-2">
      <label for="disabled">Disabled:</label>
      <input type="text" value="{{model.isDisabled ? 'Yes' : 'No'}}" disabled />
    </div>
  </div>
  <br />
</section>