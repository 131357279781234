import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { Notice } from '../shared/models/notice.model';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { saveAs } from 'file-saver';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-ameritas-authorizations',
    templateUrl: './ameritas-authorizations.component.html',
    styleUrls: ['./ameritas-authorizations.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    standalone: true,
    imports: [NgIf, NgClass, FormsModule]
})
export class AmeritasAuthorizationsComponent implements OnInit {
  @Input() public authorizationNotices!: Array<Notice>;
  @Input() public showValidation!: boolean;
  public noticeChkboxes: Array<boolean> = new Array<boolean>();

  public fraudNotice!: string;
  public authorizationNotice!: string;
  public authorizationNoticeCheckbox!: string;
  public additionalNotice!: string;
  public applicantAndDependentsNotice!: string;
  public authorizationNoticeCheckboxModel!: boolean;
  public oocNoticeCheckbox!: string;
  public oocNoticeCheckboxModel!: boolean;
  public eDelivery: boolean = true;

  public loading!: boolean;
  public showOOC!: boolean;
  public stateAbbr: string;
  public email: string;

  constructor(private enrollmentService: EnrollmentService) {
    this.stateAbbr = this.enrollmentService.address.stateAbbr;
    this.email = this.enrollmentService.address.email;
  }

  ngOnInit(): void {
    if (this.authorizationNotices) {
      this.fraudNotice = this.authorizationNotices.find(n => n.type === 1) ? this.authorizationNotices.find(n => n.type === 1)?.text! : '';
      this.authorizationNotice = this.authorizationNotices.find(n => n.type === 2) ? this.authorizationNotices.find(n => n.type === 2)?.text! : '';
      this.authorizationNoticeCheckbox = this.authorizationNotices.find(n => n.type === 3) ? this.authorizationNotices.find(n => n.type === 3)?.text! : '';
      this.additionalNotice = this.authorizationNotices.find(n => n.type === 4) ? this.authorizationNotices.find(n => n.type === 4)?.text! : '';
      this.applicantAndDependentsNotice = this.authorizationNotices.find(n => n.type === 5) ? this.authorizationNotices.find(n => n.type === 5)?.text! : '';
      this.enrollmentService.getAmeritasOOCReq().subscribe(res => this.showOOC = res);
    }
  }

  public onClick(): any {
    this.loading = true;
    var mediaType = 'application/pdf';
    this.enrollmentService.getAmeritasOOC().subscribe(
      (response:any) => {
        this.loading = false;
        var blob = new Blob([response], { type: mediaType });
        saveAs(blob, 'ooc.pdf');
      }
    );
  }

  eDeliveryClick() {
    this.enrollmentService.eDelivery = this.eDelivery;
  }

}
