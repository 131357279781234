<main id="application" class="main-wrapper">
  <div class="container">

    <app-breadcrumbs [index]="1"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>
      <section class="application-content" [ngClass]="{ showValidationContainer: showValidation == true }">
        <h1>Primary Applicant Information</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" #applicantForm="ngForm">
          <div class="form-row">
            <div class="form-group-block col-5">
              <label for="firstName" [ngClass]="{ 'error-label': firstName.invalid }">First Name: </label>
              <input type="text" id="firstName" [(ngModel)]="model.firstName" name="firstName" #firstName="ngModel"
                required maxlength="32" placeholder="First Name" [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation || firstName.valid" class="invalid-feedback">
                Please enter first name
              </div>
            </div>
            <div class="form-group-block col-2">
              <label for="middleInitial">Middle: </label>
              <input type="text" id="middleInitial" [(ngModel)]="model.middleInitial" name="middleInitial" maxlength="1"
                placeholder="MI">
            </div>
            <div class="form-group-block col-5">
              <label for="lastName" [ngClass]="{ 'error-label': lastName.invalid }">Last Name: </label>
              <input type="text" id="lastName" [(ngModel)]="model.lastName" name="lastName" #lastName="ngModel" required
                maxlength="32" placeholder="Last Name" [ngClass]="{ showValidation: showValidation == true }">
              <div [hidden]="!showValidation ||lastName.valid" class="invalid-feedback">
                Please enter last name
              </div>
            </div>
          </div>

          <div class="form-row">
            <div
              *ngIf="plan?.carrier?.shortName?.toLowerCase()?.startsWith('anthem') || plan?.carrier?.shortName?.toLowerCase()?.startsWith('empire') 
              || plan?.carrier?.shortName?.toLowerCase()?.startsWith('guardian') || plan?.carrier?.shortName?.toLowerCase()?.startsWith('cigna')"
              class="form-group-block col-3">
              <label for="maritalStatus" [ngClass]="{ 'error-label': maritalStatus.invalid }">Marital Status: </label>
              <select id="maritalStatus" [(ngModel)]="model.maritalStatus" name="maritalStatus" required
                [ngClass]="{ showValidation: showValidation == true }" #maritalStatus="ngModel">
                <option [ngValue]="maritalStatusOptions.SINGLE">Single</option>
                <option [ngValue]="maritalStatusOptions.MARRIED">Married</option>
                <option [ngValue]="maritalStatusOptions.DOMESTIC_PARTNER">Domestic Partner</option>
              </select>
              <div [hidden]="!showValidation || model.maritalStatus !== null" class="invalid-feedback">
                Please select one
              </div>
            </div>
            <div class="form-group-block col-2">
              <label for="gender" [ngClass]="{ 'error-label': gender.invalid }">Gender:</label>
              <select id="genderStatus" [(ngModel)]="model.gender" name="genderStatus" required
                [ngClass]="{ showValidation: showValidation == true }" #gender="ngModel">
                <option [ngValue]="genderOptions.MALE">Male</option>
                <option [ngValue]="genderOptions.FEMALE">Female</option>
                <option *ngIf="(plan?.carrier?.shortName?.toLowerCase()?.startsWith('empire') || plan?.carrier?.shortName?.toLowerCase()?.startsWith('anthem') && stateAbbr == 'NY')
                  || plan?.carrier?.shortName?.toLowerCase()?.startsWith('cigna')" [ngValue]="genderOptions.X">X
                </option>
              </select>
              <div [hidden]="!showValidation || model.gender !== null" class="invalid-feedback">
                Please select one
              </div>
            </div>
          </div>
          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>

          </div>

        </form>
        <app-disclaimers></app-disclaimers>
      </section>
    </div>

  </div>
</main>