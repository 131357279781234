import { Component, forwardRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

@Component({
    selector: 'app-phone-number',
    templateUrl: './phone-number.component.html',
    styleUrls: ['./phone-number.component.scss'],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PhoneNumberComponent), multi: true }],
    standalone: true,
    imports: [FormsModule]
})
export class PhoneNumberComponent implements ControlValueAccessor {
  public disabled = false;
  public readonly = false;

  public phoneArea!: string;
  public phonePrefix!: string;
  public phoneSuffix!: string;

  private propagateChange = (_: any | null) => { };
  private propagateTouched = (_: Date) => { };

  constructor(private element: ElementRef) { }

  get value(): string | null{
    if (this.phoneArea && this.phoneArea.length === 3 &&
      this.phonePrefix && this.phonePrefix.length === 3 &&
      this.phoneSuffix && this.phoneSuffix.length === 4) {
      return `(${this.phoneArea})${this.phonePrefix}-${this.phoneSuffix}`;
    } else {
      return null;
    }
  }

  set value(newValue: string) {
    if (newValue && newValue.length === 13) {
      this.phoneArea = newValue.substring(1, 4);
      this.phonePrefix = newValue.substring(5, 8);
      this.phoneSuffix = newValue.substring(9);
      this.propagateChange(this.value);
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: (value: string) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onModelChange() {
    this.propagateChange(this.value);
  }

  onInput(target: any) {
    target.value = this.stripNonDigits(target.value);
    if (target.name === 'phoneArea' && target.value.length === 3) {
      this.element.nativeElement.children[0].children[1].focus();
    } else if (target.name === 'phonePrefix' && target.value.length === 3) {
      this.element.nativeElement.children[0].children[2].focus();
    }
  }

  onFocusPhonePrefixFunction() {
    if (this.element.nativeElement.children[0].children[0].value === '') {
      this.element.nativeElement.children[0].children[0].focus();
    }
  }

  onFocusPhoneSuffixFunction() {
    if (this.element.nativeElement.children[0].children[1].value === '') {
      this.element.nativeElement.children[0].children[1].focus();
    }
  }

  private stripNonDigits(value: string): string {
    let newValue = '';
    for (let i = 0; i <= value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        newValue += value[i];
      }
    }
    return newValue;
  }
}
