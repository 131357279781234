export class Member {
    public ClientCode!: string;
    public FirstName!: string;
    public MiddleInitial!: string;
    public LastName!: string;
    public SSN!: string;
    public NameSuffix!: string;
    public DOB!: string;
    public Gender!: string;
    public MaritalStatus!: string;
    public Ethnicity!: string;
    public Email!: string;
    public Addr1!: string;
    public Addr2!: string;
    public City!: string;
    public StateCode!: string;
    public ZipCode!: string;
    public Language!: string;
    public HomePhone!: string;
    public WorkPhone!: string;
    public MobilePhone!: string;
    public FacilityID!: string;
    public FacilityName!: string;
}
