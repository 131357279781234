import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormsModule } from '@angular/forms';
import { Dentist } from '../../shared/models/dentist.model';
import { FamilyMember } from '../../shared/models/family-member.model';
import { FamilyRelationship } from '../../shared/models/family-relationship.enum';
import { AffiliateService } from '../../shared/services/affiliate.service';
import { EnrollmentService } from '../../shared/services/enrollment.service';
import { MetlifeService } from '../../shared/services/metlife.service';
import { environment } from 'src/environments/environment';
import { StateSpecificQuestions } from '../models/state-specific-questions.model';
import { DisclaimersComponent } from '../../disclaimers/disclaimers.component';
import { DentistComponent } from '../../dentist/dentist.component';
import { SupportWidgetComponent } from '../../support-widget/support-widget.component';
import { PlanWidgetComponent } from '../../plan-widget/plan-widget.component';
import { BreadcrumbsComponent } from '../../breadcrumbs/breadcrumbs.component';
import { NgIf, NgFor } from '@angular/common';


@Component({
    selector: 'app-met-dentist',
    templateUrl: './met-dentist.component.html',
    styleUrls: ['./met-dentist.component.scss'],
    standalone: true,
    imports: [NgIf, BreadcrumbsComponent, PlanWidgetComponent, SupportWidgetComponent, FormsModule, NgFor, DentistComponent, DisclaimersComponent]
})
export class MetDentistComponent implements OnInit {
  @ViewChild('dentistForm', { static: true }) public dentistForm!: NgForm;

  public familyMembers!: Array<FamilyMember>;
  public askDentistId!: boolean;
  public askDentistName!: boolean;
  public askFacilityId!: boolean;
  public askFacilityName!: boolean;
  public askCensusInfo!: boolean;
  public dentistSearchUrl!: string;
  public dentistSearchUrlTemplate = environment.metlifeProviderSearchUrl;
  public dentistSearchNote!: string;
  public facilityIdTitle!: string;
  public loading = true;
  public sessionId!: string;
  public showValidation = false;

  constructor(private router: Router,
    private enrollmentService: EnrollmentService,
    private activatedRoute: ActivatedRoute,
    private affiliateService: AffiliateService,
    private metlifeService: MetlifeService) {



  }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.queryParams.subscribe(params => {
      const externalData = params['externalData'] as string;

      if (externalData) {

        const requestId = externalData.substr(3, 36);
        const facilityName = params['facilityName'];
        const facilityId = params['facilityId'];
        const censusIndex = +externalData.substr(externalData.lastIndexOf('_') + 1) - 1;

        this.sessionId = requestId;
        this.enrollmentService.loadStage(requestId).subscribe(
          enrollment => {
            this.enrollmentService.requestApplicantDentistInfo = true;
            this.enrollmentService.planId = enrollment.planId;
            this.enrollmentService.requestId = enrollment.requestId;
            this.enrollmentService.premium = enrollment.premium;
            this.enrollmentService.totalInitialPaymentAmount = enrollment.totalInitialPaymentAmount;
            this.enrollmentService.totalOngoingPaymentAmount = enrollment.totalOngoingPaymentAmount;
            this.enrollmentService.selectedPaymentFrequency = enrollment.selectedPaymentFrequency;
            this.enrollmentService.existingCoverageHasCoverage = enrollment.existingCoverageHasCoverage;
            this.enrollmentService.existingCoverageCoverageReplacement = enrollment.existingCoverageCoverageReplacement;
            this.enrollmentService.existingCoverageCarrier = enrollment.existingCoverageCarrier;
            this.enrollmentService.existingCoveragePhone = enrollment.existingCoveragePhone;
            this.enrollmentService.existingCoveragePolicyNumber = enrollment.existingCoveragePolicyNumber;
            this.enrollmentService.existingCoverageEffectiveDate = enrollment.existingCoverageEffectiveDate;
            this.enrollmentService.census = enrollment.census;
            this.enrollmentService.address = enrollment.address;

            if (enrollment.stateSpecificQuestions) {
              this.metlifeService.stateSpecifics = new StateSpecificQuestions();
              this.metlifeService.stateSpecifics.PreviousCoverage = enrollment.stateSpecificQuestions.previousCoverage;
              this.metlifeService.stateSpecifics.Duplication = enrollment.stateSpecificQuestions.duplication;
              this.metlifeService.stateSpecifics.PreviousCoverageCompany = enrollment.stateSpecificQuestions.previousCoverageCompany;
              this.metlifeService.stateSpecifics.PreviousCoveragePolicy = enrollment.stateSpecificQuestions.previousCoveragePolicy;
              this.metlifeService.stateSpecifics.Lapse = enrollment.stateSpecificQuestions.lapse;
              this.metlifeService.stateSpecifics.OtherInsurance = enrollment.stateSpecificQuestions.otherInsurance;
              this.metlifeService.stateSpecifics.PreviousCoverageCompany2 = enrollment.stateSpecificQuestions.previousCoverageCompany2;
              this.metlifeService.stateSpecifics.PreviousCoveragePolicy2 = enrollment.stateSpecificQuestions.previousCoveragePolicy2;
              this.metlifeService.stateSpecifics.SLMB = enrollment.stateSpecificQuestions.slmb;
              this.metlifeService.stateSpecifics.Medicare = enrollment.stateSpecificQuestions.medicare;
              this.metlifeService.stateSpecifics.Medicaid = enrollment.stateSpecificQuestions.medicaid;
              this.enrollmentService.stateSpecificQuoestions = this.metlifeService.stateSpecifics;
            }


            this.enrollmentService.getPlanAppInfo().subscribe(
              planAppInfo => {
                this.enrollmentService.plan = planAppInfo.plan;
                this.enrollmentService.createdOn = planAppInfo.createdOn;
                this.enrollmentService.paymentFrequencies = planAppInfo.paymentFrequencies;
                this.enrollmentService.selectedPaymentFrequency = this.enrollmentService.paymentFrequencies[enrollment.selectedPaymentFrequency];

                this.enrollmentService.cancellationsDescription = planAppInfo.cancellationsDescription;
                this.enrollmentService.paymentNotes = planAppInfo.paymentNotes;

                this.enrollmentService.enrollmentNotices = planAppInfo.enrollmentNotices;
                this.enrollmentService.requestApplicantSSN = planAppInfo.requestApplicantSSN;
                this.enrollmentService.requestCensusSSN = planAppInfo.requestCensusSSN;
                this.enrollmentService.requestApplicantDentistInfo = planAppInfo.requestApplicantDentistInfo;

                this.enrollmentService.initialPaymentsProcessingDescriptions = planAppInfo.initialPaymentsProcessingDescriptions;
                this.enrollmentService.ongoingPaymentsProcessingDescriptions = planAppInfo.ongoingPaymentsProcessingDescriptions;

                this.enrollmentService.authorizationNotices = planAppInfo.authorizationNotices;
                this.enrollmentService.ongoingPaymentDayOptions = planAppInfo.ongoingPaymentDayOptions;
                this.enrollmentService.allowDifferentBillingAddress = planAppInfo.allowDifferentBillingAddress;
                this.enrollmentService.spouseNote = planAppInfo.spouseNote;

                this.enrollmentService.agent = planAppInfo.agent;
                this.enrollmentService.agentId.next(this.enrollmentService.agent);

                this.enrollmentService.census.familyMembers = this.sortFamilyMembers(this.enrollmentService.census.familyMembers);
                this.enrollmentService.census.familyMembers[censusIndex].dentist = new Dentist(facilityId, facilityName, null, null)
                this.init();

                if (!isNaN(planAppInfo.affiliateId)) {
                  this.affiliateService.affiliate.next(this.affiliateService.getAffiliateFromAPI(planAppInfo.affiliateId));
                  this.enrollmentService.affiliateId = planAppInfo.affiliateId;
                } else {
                  this.enrollmentService.affiliateId = this.affiliateService.defaultAffiliate.affiliateId;
                }

              }
            );

          }
        );
      } else {
        if (!this.enrollmentService.requestApplicantDentistInfo) {
          this.router.navigate(['/ssn']);
        } else {
          this.init();
        }
      }
    });


  }

  sortFamilyMembers(members: FamilyMember[]): FamilyMember[] {
    return members.sort((a: FamilyMember, b: FamilyMember): number => {
      if (a.familyRelationship === FamilyRelationship.APPLICANT) return -1;
      if (b.familyRelationship === FamilyRelationship.APPLICANT) return 1;

      if (a.familyRelationship === FamilyRelationship.SPOUSE && b.familyRelationship === FamilyRelationship.CHILD) return -1;
      if (b.familyRelationship === FamilyRelationship.SPOUSE && a.familyRelationship === FamilyRelationship.CHILD) return 1;

      if (a.familyRelationship === FamilyRelationship.CHILD && b.familyRelationship === FamilyRelationship.CHILD) {
        return new Date(a.birthdate).getTime() - new Date(b.birthdate).getTime();
      }
      return 0;
    })
  }

  init() {
    this.enrollmentService.census.familyMembers = this.sortFamilyMembers(this.enrollmentService.census.familyMembers);
    this.familyMembers = this.enrollmentService.census.familyMembers;

    this.enrollmentService.requestCensusDentistInfo = true;
    this.enrollmentService.requestDentistId = false;
    this.enrollmentService.requestDentistName = false;
    this.enrollmentService.requestFacilityId = true;
    this.enrollmentService.requestFacilityName = true;


    this.askCensusInfo = this.enrollmentService.requestCensusDentistInfo;
    this.askDentistId = this.enrollmentService.requestDentistId;
    this.askDentistName = this.enrollmentService.requestDentistName;
    this.askFacilityId = this.enrollmentService.requestFacilityId;
    this.askFacilityName = this.enrollmentService.requestFacilityName;

    this.enrollmentService.clientIP = null;
    this.enrollmentService.postStageEnrollmentToAPI().subscribe(x => {
      this.enrollmentService.clientIP = x;
      this.sessionId = x;

      const planId = this.enrollmentService.plan.name.toLowerCase().includes('care 350') ? '3880' : '3879';
      this.dentistSearchUrl = `${this.dentistSearchUrlTemplate}?searchType=findDentistEnroll&planType=DHMO&zip=${this.enrollmentService.census.zip}&qsType=DHMO&planId=${planId}&fcInd=true&externalData=KL-${this.sessionId}_`;
      this.loading = false;
    });
  }

  onSubmit() {
    if (!this.dentistForm.form.valid) {
      this.showValidation = true;
      return;
    }
    this.router.navigate(['/ssn']);
  }

  onBackClick() {
    const dependentsCount = this.enrollmentService.census.familyMembers
      .filter(member => member.familyRelationship !== FamilyRelationship.APPLICANT).length;

    if (dependentsCount > 0) {
      this.router.navigate(['/census']);
    } else {
      this.router.navigate(['/contact']);
    }
  }

  getFullName(familyMember: FamilyMember): string {
    let name = familyMember.firstName;
    if (familyMember.middleInitial != null && familyMember.middleInitial.length > 0) {
      name += ' ' + familyMember.middleInitial;
    }
    name += ' ' + familyMember.lastName;
    return name;
  }

  copyFacilityFromApplicant(member: FamilyMember) {
    this.loading = true;
    const applicant = this.familyMembers.find(x => x.familyRelationship === FamilyRelationship.APPLICANT);
    const dentist = new Dentist(applicant?.dentist.facilityId!, applicant?.dentist.facilityName!, null, null);
    member.dentist = dentist;

    this.enrollmentService.clientIP = null;
    this.enrollmentService.postStageEnrollmentToAPI().subscribe(x => {
      this.enrollmentService.clientIP = x;
      this.sessionId = x;

      const planId = this.enrollmentService.plan.name.toLowerCase().includes('care 350') ? '3880' : '3879';
      this.dentistSearchUrl = `${this.dentistSearchUrlTemplate}?searchType=findDentistEnroll&planType=DHMO&zip=${this.enrollmentService.census.zip}&qsType=DHMO&planId=${planId}&fcInd=true&externalData=KL-${this.sessionId}_`;
      this.loading = false;
    });
  }

}
