<main id="application" class="main-wrapper">
  <div class="container">

    <app-breadcrumbs [index]="1"></app-breadcrumbs>

    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>

      <section class="application-content">
        <h1>Replacement Coverage Information</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" name="existingCoverageForm" #existingCoverageForm="ngForm">


          <div class="application-questions text-center">
            <h4>By applying for this insurance coverage, do you intend to lapse or otherwise terminate any existing
              dental
              insurance currently held by you?</h4>

            <div class="radio-group enroll-radio-group"
              [ngClass]="{'radioBtn-invalid': showValidation === true && lapse === undefined, 'radioBtn-valid': showValidation === true && lapse !== undefined}">
              <label for="lapseYes">
                <input type="radio" id="lapseYes" [(ngModel)]="lapse" name="lapse" [value]="true" required />Yes
              </label>
              <label for="lapseNo">
                <input type="radio" id="lapseNo" [(ngModel)]="lapse" name="lapse" [value]="false" required />No
              </label>
            </div>
            <div [hidden]="!showValidation || lapse !== undefined" class="invalid-feedback">
              Please select one
            </div>
          </div>

          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>
          </div>

        </form>
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>