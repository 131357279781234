<main id="application" class="main-wrapper">
  <div class="container">

    <app-breadcrumbs [index]="3"></app-breadcrumbs>
    <div class="plan-wrapper">
      <aside class="side-widget no-mobile">
        <app-plan-widget></app-plan-widget>
        <app-support-widget></app-support-widget>
      </aside>

      <section class="application-content">
        <h1>Additional persons to be covered</h1>
        <div class="separator-left"></div>
        <form (ngSubmit)="onSubmit()" #censusForm="ngForm">
          <div *ngFor="let member of model; let i = index">
            <app-family-member [index]="i" [model]="member" [askMaritalStatus]="askMaritalStatus(member)"
              [addGenderX]="addGenderX(member)" [hideStudent]="hideStudent(member)"
              [askChildStatus]="(this.carrier.startsWith('anthem') || this.carrier.startsWith('empire')) && (member.familyRelationship === relationships.CHILD)"
              [childStatusOptions]="childStatusOptions" [showValidation]="showValidation"></app-family-member>
            <div *ngIf="(member.familyRelationship === relationships.SPOUSE) && spouseNote" [innerHTML]="spouseNote">
            </div>
            <hr>
          </div>

          <div *ngIf="carrier.startsWith('anthem') || carrier.startsWith('empire')">
            <p>NOTE: IF ELECTING DEPENDENT COVERAGE, PLEASE LIST ALL ELIGIBLE CHILDREN UP TO AGE 26. An eligible child
              dependent may be your children or your spouse’s or your Domestic Partner’s children (to the end of the
              calendar month in which they turn age 26). You must complete a Certification form for a Mentally or
              Physically Incapacitated Dependent Child if your child is disabled, incapable of self-support and age 26
              or
              over. The form must also be completed by your physician.</p>
            <p *ngIf="dependentOver26 === true" class="application-text-warning text-center"><strong>
                Unfortunately, you can not enroll for this coverage online. You must submit a paper application and
                separate
                disabled dependent form, which can be found on anthem.com</strong>
            </p>
          </div>

          <br />
          <br />
          <hr />
          <div class="two-buttons-block">
            <button type="submit" class="enroll button-right"><span>Continue</span></button>
            <button class="enroll-back button-left" (click)="onBackClick()"><span>Back</span></button>

          </div>
        </form>
        <br />
        <br />
        <app-disclaimers></app-disclaimers>
      </section>
    </div>
  </div>
</main>