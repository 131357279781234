<ul class="breadcrumb">
  <li [ngClass]="getClass(0)">
    <a>Start</a>
  </li>
  <li [ngClass]="getClass(1)">
    <a>Applicant Information</a>
  </li>
  <li [ngClass]="getClass(2)">
    <a>Contact Information</a>
  </li>
  <li [ngClass]="getClass(3)">
    <a>Census Information</a>
  </li>
  <li [ngClass]="getClass(4)">
    <a>Payment Options</a>
  </li>
  <li [ngClass]="getClass(5)">
    <a>Payment Method</a>
  </li>
  <li [ngClass]="getClass(6)">
    <a>Thank you</a>
  </li>
</ul>
