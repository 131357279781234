import { Component, Input, OnInit } from '@angular/core';
import { EnrollmentService } from '../shared/services/enrollment.service';
import { Plan } from '../shared/models/plan';
import { Census } from '../shared/models/census.model';
import { Gender } from '../shared/models/gender.enum';
import { AffiliateService } from '../shared/services/affiliate.service';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { FamilyMember } from '../shared/models/family-member.model';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, DatePipe, AsyncPipe } from '@angular/common';
import { ExchangeCompany } from '../shared/models/exchange-company.model';
import { Observable, map } from 'rxjs';
import { Affiliate } from '../shared/models/affiliate.model';

@Component({
    selector: 'app-plan-widget',
    templateUrl: './plan-widget.component.html',
    styleUrls: ['./plan-widget.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgSwitch, NgSwitchCase, DatePipe, AsyncPipe]
})
export class PlanWidgetComponent implements OnInit {
  public plan!: Plan;
  public requestedEffectiveDate!: string;
  public census!: Census;
  public genders = Gender;
  public quotesWebUrl!: string;
  public requestId: string;
  public familyRelationships = FamilyRelationship;
  public careington = false;
  private campaignSourceName!: string;
  public exchangeCompany!: ExchangeCompany;
  public agentId!: string;

  @Input() public hideChangeLinks = false;


  constructor(public enrollmentService: EnrollmentService, private affiliateService: AffiliateService) {
    // this.plan = this.enrollmentService.plan;
    // this.requestedEffectiveDate = this.enrollmentService.census.requestedEffectiveDate;
    // this.census = this.enrollmentService.census;
    this.requestId = this.enrollmentService.requestId;
    this.affiliateService.affiliate.subscribe(x => {
      x.subscribe((a:any) => {
        this.quotesWebUrl = this.enrollmentService.getQuotesUrl(a.isPrerendered);
        if (a.affiliateId >= 2572 && a.affiliateId <= 2576) {
          this.careington = true;
        }
      });
    });

    this.enrollmentService.campaignSourceName.subscribe((x: any) => this.campaignSourceName = x);
    this.affiliateService.affiliate$.subscribe(x => {
      if (x?.affiliateId! >= 2572 && x?.affiliateId! <= 2576)
        this.careington = true;
    });

    this.enrollmentService.exchCompany.subscribe((x: any) => this.exchangeCompany = x);
    this.enrollmentService.agentId.subscribe((x: any) => this.agentId = x);

  }

  ngOnInit() {
  }

  getPlanUrl(plan: Plan) {
    if (!plan) {
      return '';
    }
    return plan.carrier.name.toLowerCase().split(' ').join('-') + '/' + plan.name.toLowerCase().split(' ').join('-').split(')').join('').split('(').join('');
  }

  getChildNumber(familyMember: FamilyMember): number {
    let x = this.enrollmentService.census.familyMembers.filter(familyMember => familyMember.familyRelationship === FamilyRelationship.CHILD);
    const index: number = x.indexOf(familyMember);
    return index + 1;
  }

  getAffiliateUtmSuffix(): Observable<string> {
    return this.affiliateService.affiliate$.pipe(map(affiliate => {
      return this.getAffParameters(affiliate);
    }));
  }

  getAffParameters(affiliate: Affiliate | null): string {
    return (affiliate!.affiliateId === 1019) ? '' : ('?utm_campaign=' + affiliate!.affiliateId + '&utm_source=' + this.campaignSourceName + (this.agentId ? ('&utm_term=' + this.agentId) : ''))
  }

}
